import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import { useModalState } from 'hooks/modal';
import { viewerUrl } from 'utils/modules/model';
import { copyToClipboard } from 'utils/clipboard';
import HelpBox from 'components/common/HelpBox';

type Props = {
  modelId: number;
  modelName: string;
};

function CopyIframeLink({ modelId, modelName }: Props) {
  const { show, showModal, hideModal } = useModalState();

  const iframeHtml = `<iframe
  src="${viewerUrl(modelId)}"
  title="${modelName} | V3Geo"
  style="width: 100%; min-height: 300px;"
  frameborder="0"
/>`;

  function handleCopy(e: React.MouseEvent<HTMLButtonElement>) {
    const target = e.target as HTMLButtonElement;
    copyToClipboard(iframeHtml);
    target.focus();
    toast.info('Embed code copied to clipboard.');
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-primary btn-block"
        onClick={showModal}
      >
        <FontAwesomeIcon icon="code" className="mr-2" /> Show Code
      </button>

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Embed 3D Model</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <HelpBox>
            V3Geo allows public models to be embedded on other websites. Copy
            the code below to get started.
          </HelpBox>

          <p className="mt-3">
            <textarea
              value={iframeHtml}
              onChange={() => {}}
              rows={6}
              className="form-control"
              style={{ fontFamily: 'monospace' }}
              spellCheck={false}
            />
          </p>

          <p className="text-center">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={handleCopy}
            >
              <FontAwesomeIcon icon="copy" className="mr-2" />
              Copy to clipboard
            </button>
          </p>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={hideModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CopyIframeLink;
