import React, { useMemo } from 'react';
import {
  OverlayTrigger,
  Popover as BSPopover,
  PopoverProps,
} from 'react-bootstrap';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { v4 } from 'uuid';

export type Props = {
  children: JSX.Element;
  trigger?: OverlayTriggerType | OverlayTriggerType[];
  placement?: PopoverProps['placement'];
  title?: React.ReactNode;
  tooltip?: boolean;
  content: React.ReactNode;
  rootClose?: boolean;
};

function Popover({
  trigger = ['click', 'focus'],
  placement = 'top',
  title,
  content,
  rootClose = true,
  children,
}: Props) {
  const overlayId = useMemo(v4, []);

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      rootClose={rootClose}
      overlay={
        <BSPopover id={overlayId}>
          {title && <BSPopover.Title as="h3">{title}</BSPopover.Title>}
          <BSPopover.Content>{content}</BSPopover.Content>
        </BSPopover>
      }
    >
      {children}
    </OverlayTrigger>
  );
}

export default Popover;
