import React from 'react';
import { Link } from 'react-router-dom';

import { orgRoute, profileRoute } from 'routes';
import { useAuth } from 'contexts/auth';
import { LoginButton } from 'components/modules/auth/LoginButton';
import { LogoutButton } from 'components/modules/auth/LogoutButton';
import RoleSecured from 'components/common/RoleSecured';

const CurrentUser: React.FC = () => {
  const { isAuthenticated, authority } = useAuth();

  if (!isAuthenticated || !authority) {
    return <LoginButton className="nav-link" />;
  }

  return (
    <li className="nav-item dropdown">
      <a
        href="#user-menu"
        id="currentUserDropdown"
        className="nav-link dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-testid="navbar-currentUser"
      >
        {authority.metadata.name}
      </a>
      <div className="dropdown-menu" id="currentUserDropdown">
        <div
          className="dropdown-item"
          style={{
            marginTop: '-8px',
            marginBottom: '8px',
            backgroundColor: '#efefef',
            padding: '10px 20px 10px 20px',
          }}
        >
          <div>
            <b>{authority.metadata.name}</b>
          </div>
          <div>{authority.metadata.email}</div>
          {authority.metadata.organization && (
            <div className="mt-2">{authority.metadata.organization.name}</div>
          )}
          {!authority.metadata.organization &&
            authority.metadata.affiliation && (
              <div className="mt-2">{authority.metadata.affiliation}</div>
            )}
          {authority.metadata.isSubscriptionActive && (
            <div className="mt-2 text-success">
              <b>Subscription Active</b>
            </div>
          )}
        </div>

        <Link to={profileRoute()} className="dropdown-item">
          My Profile
        </Link>

        {/* Hidden per #225 */}
        <RoleSecured roles={['admin']}>
          <Link to={orgRoute()} className="dropdown-item">
            Organization
          </Link>
        </RoleSecured>

        <LogoutButton />
      </div>
    </li>
  );
};

export default CurrentUser;
