import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import classnames from 'classnames';

import { ModelRequirementsMet } from 'apollo/schema/types';
import Tooltip from 'components/common/Tooltip';

type Props = {
  requirements: ModelRequirementsMet;
};

function RequirementsChecklist({ requirements }: Props): JSX.Element {
  return (
    <Card>
      <Card.Body style={{ marginTop: '-5px' }}>
        <Card.Title>Publishing Checklist</Card.Title>

        <ul className="list-unstyled my-0">
          <RequirementItem
            label="Model info"
            reason="Your model is missing some basic details."
            isFulfilled={requirements.details}
          />
          <RequirementItem
            label="Images"
            reason="At least one picture is required."
            isFulfilled={requirements.images}
          />
          <RequirementItem
            label="Georeferences"
            reason="At least one georeference is required."
            isFulfilled={requirements.georeferences}
          />
          <RequirementItem
            label="Geology tags"
            reason="At least one geology tag is required."
            isFulfilled={requirements.geologyTags}
          />
          <RequirementItem
            label="3D model files"
            reason="At least one zip file containing 3D model files is required."
            isFulfilled={requirements.modelUserFiles}
          />
        </ul>
      </Card.Body>
    </Card>
  );
}

type ItemProps = {
  label: string;
  reason: string;
  isFulfilled: boolean | null;
};

function RequirementItem({
  label,
  isFulfilled,
  reason,
}: ItemProps): JSX.Element | null {
  if (isFulfilled === null) return null;

  return (
    <li
      className={classnames(
        'mt-2',
        isFulfilled ? 'text-success' : 'text-primary',
      )}
      style={{ cursor: 'default' }}
    >
      <Tooltip disabled={isFulfilled} overlay={reason}>
        <span>
          <FontAwesomeIcon
            icon={isFulfilled ? 'check' : 'times-circle'}
            className={classnames('mr-2', {
              'text-danger': !isFulfilled,
            })}
          />
          {label}
        </span>
      </Tooltip>
      {/* {!isFulfilled && <div style={{ marginLeft: '22px' }}>{reason}</div>} */}
    </li>
  );
}

export default RequirementsChecklist;
