import React from 'react';
import * as R from 'ramda';
import { camelize, decamelize } from 'humps';
import moment from 'moment';

/** Capitalizes the first letter of each word */
export const ucwords = (str: string): string =>
  R.pipe(
    camelize,
    str => decamelize(str, { separator: ' ' }),
    str => str.replace(/\b\w/g, m => m.toUpperCase()),
  )(str);

export const capitalize = (str: string): string => {
  const first = str.substr(0, 1);
  const rest = str.substr(1);
  return `${first.toUpperCase()}${rest}`;
};

/** Removes all HTML tags */
export const stripTags = (str: string): string =>
  str.replace(/<(?:.|\n)*?>/gm, '');

/** Returns a new string of given length. If truncated, a `...` will be appended. */
export const truncate = (
  numCharacters: number,
  append = true,
  appendText: string | React.ReactNode = '...',
) => (text: string): typeof appendText => {
  if (!text) return '';

  let newString = text.substring(0, numCharacters).trim();

  if (append && text.length > numCharacters) {
    if (typeof appendText === 'string') {
      return newString + appendText;
    } else if (typeof appendText === 'object') {
      return (
        <>
          {newString}
          {appendText}
        </>
      );
    }
  }

  return newString;
};

export const camelToHuman = (text: string) =>
  R.pipe(
    (val: string) => decamelize(val, { separator: ' ' }),
    ucwords,
    //
  )(text);

export const snakeCapsToHuman = (text: string) =>
  R.pipe(
    R.toLower,
    camelize,
    val => decamelize(val, { separator: ' ' }),
    ucwords,
  )(text);

export const prettyDate = (dateTimeStr: string) =>
  moment(dateTimeStr).format('ll');

export const fullDateTime = (dateTimeStr: string) =>
  moment(dateTimeStr).format('LLLL ZZ');
