import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { Page } from 'apollo/schema/types';
import { PAGE_LIST } from 'apollo/operations/page';
import NotFound from 'components/errors/NotFound';
import Spinner from 'components/common/Spinner';

export const PageContentContainer = styled.div`
  max-width: 100%;

  img {
    max-width: 100%;
    height: auto;
  }
`;

type Props = {
  slug: string;
  render?: (page: Page) => JSX.Element;
  showTitle?: boolean;
  fallback?: JSX.Element | null;
};

function PageLoader({
  slug,
  render,
  showTitle = true,
  fallback = <NotFound />,
}: Props) {
  const { data, loading, called } = useQuery(PAGE_LIST, {
    variables: { slugs: [slug] },
    fetchPolicy: 'cache-first',
  });

  const page = R.pipe(
    R.propOr<Page[]>([], 'pageList'),
    R.find<Page>(R.whereEq({ slug })),
  )(data);

  if (loading) return <Spinner />;

  if (called && !loading && !page) {
    return fallback;
  }

  if (render) {
    return render(page!);
  }

  return (
    <>
      {showTitle && <h1>{page!.title}</h1>}
      <PageContentContainer
        className="cms-page-content"
        dangerouslySetInnerHTML={{ __html: page!.content }}
      />
    </>
  );
}

export default PageLoader;
