import React from 'react';
import * as R from 'ramda';
import { Field } from 'formik';
import { useQuery } from '@apollo/client';

import { AgeTag } from 'apollo/schema/types';
import { AGE_TAG_OPTIONS } from 'apollo/operations/ageTag';
import { AgeTagOptionsData } from 'apollo/schema/operations';
import FormikField from 'components/common/FormikField';

type Props = {
  currentAgeTags: AgeTag[];
  disabled: boolean;
};

const AgeTagFormFields: React.FC<Props> = ({
  currentAgeTags,
  disabled,
}: Props) => {
  const { data, loading } = useQuery<AgeTagOptionsData>(AGE_TAG_OPTIONS);

  const options = data?.ageTagOptions || [];

  const currentSystems = R.pluck('system', currentAgeTags);
  const isOptionDisabled = R.contains(R.__, currentSystems);

  return (
    <Field
      name="system"
      label="System"
      component={FormikField}
      type="select"
      disabled={disabled || loading || !options.length}
      required
      options={options.map(opt => ({
        value: opt,
        label: opt,
        disabled: isOptionDisabled(opt),
      }))}
    />
  );
};

export default AgeTagFormFields;
