import React from 'react';
import classnames from 'classnames';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import FormLabel from './FormLabel';

function FormikSelectField({
  field,
  onChange,
  className,
  isInvalid,
  label,
  options,
  showBlankOption,
  blankOptionText,
  renderInput,
  renderOption,
  helpText,
  multiple,
  required,
  ...props
}) {
  const defaultOptionRenderer = option => (
    <option
      key={option.value}
      value={option.value}
      disabled={option.disabled || false}
    >
      {option.label}
    </option>
  );

  const input = (
    <select
      {...field}
      {...props}
      id={field.name}
      // Use onChange fn if provided through props
      value={field.value || ''}
      onChange={onChange || field.onChange}
      className={classnames(className, 'form-control')}
      multiple={multiple}
    >
      {showBlankOption && !multiple && (
        <option value="">{blankOptionText}</option>
      )}
      {options.map(renderOption ? renderOption : defaultOptionRenderer)}
    </select>
  );

  return (
    <div
      className={classnames('form-group', {
        'has-error': isInvalid,
        'text-danger': isInvalid,
      })}
    >
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
      />
      {renderInput ? (
        renderInput(input)
      ) : (
        <>
          {input}
          {multiple && (
            <div>
              <small className="text-muted">
                Hold [ctrl] or ⌘ to select multiple items.
              </small>
            </div>
          )}
        </>
      )}
      <ErrorMessage name={field.name} />
    </div>
  );
}

FormikSelectField.propTypes = {
  // form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  /** onChange can be set to override the default change handler */
  onChange: PropTypes.func,
  className: PropTypes.string,
  isInvalid: PropTypes.bool.isRequired,
  label: PropTypes.node,
  renderInput: PropTypes.func,
  renderOption: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ).isRequired,
  showBlankOption: PropTypes.bool,
  helpText: PropTypes.node,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  blankOptionText: PropTypes.string,
};

FormikSelectField.defaultProps = {
  onChange: null,
  className: null,
  label: null,
  renderInput: null,
  renderOption: null,
  showBlankOption: true,
  helpText: null,
  multiple: false,
  required: false,
  blankOptionText: '- Select -',
};

export default FormikSelectField;
