import { Model } from 'apollo/schema/types';
import AdminIcon from 'components/common/AdminIcon';
import NavLink from 'components/common/NavLink';
import RoleSecured from 'components/common/RoleSecured';
import Tooltip from 'components/common/Tooltip';
import React from 'react';
import { Nav } from 'react-bootstrap';
import * as routes from 'routes';

type Props = {
  model: Model;
  isOwnerOrAdmin: boolean;
};

function ModelEditPageNavigation({
  model,
  isOwnerOrAdmin,
}: Props): JSX.Element {
  const modelId = model.id;

  if (!model.reviews) throw new Error('Reviews are not loaded');
  const disableReviewTab = model.isPrivate && model.reviews.length === 0;

  return (
    <>
      <Nav variant="pills" className="flex-column">
        <Nav.Item>
          <NavLink
            to={routes.modelEditInfoTabRoute(modelId)}
            className="nav-link"
          >
            Model Info
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            to={routes.modelEdit3DModelFilesTabRoute(modelId)}
            className="nav-link"
          >
            3D Model Files
          </NavLink>
        </Nav.Item>

        <Tooltip
          placement="right"
          overlay="Private models don't need to be reviewed before being published."
          disabled={!disableReviewTab}
        >
          <Nav.Item>
            <NavLink
              to={routes.modelEditTechnicalReviewTabRoute(modelId)}
              className="nav-link"
              disabled={disableReviewTab}
            >
              Technical Review
            </NavLink>
          </Nav.Item>
        </Tooltip>

        {isOwnerOrAdmin && (
          <>
            <Nav.Item>
              <NavLink
                to={routes.modelEditPublishingTabRoute(modelId)}
                className="nav-link"
              >
                Publishing
              </NavLink>
            </Nav.Item>

            <Nav.Item>
              <NavLink
                to={routes.modelEditSharingTabRoute(modelId)}
                className="nav-link"
              >
                Sharing
              </NavLink>
            </Nav.Item>
          </>
        )}
      </Nav>

      <RoleSecured roles={['admin']}>
        <hr />
        <div className="pl-2 mb-1 text-muted" style={{ cursor: 'default' }}>
          <AdminIcon /> Administration
        </div>

        <Nav variant="pills" className="flex-column">
          <Nav.Item>
            <NavLink
              to={routes.modelEditOwnershipTabRoute(modelId)}
              className="nav-link"
            >
              Model Ownership
            </NavLink>
          </Nav.Item>

          <Nav.Item>
            <NavLink
              to={routes.modelEditModelFilesTabRoute(modelId)}
              className="nav-link"
            >
              Model Files
            </NavLink>
          </Nav.Item>
        </Nav>
      </RoleSecured>
    </>
  );
}

export default ModelEditPageNavigation;
