import React from 'react';

export function useSafeState<T>(
  initialState?: T,
): [T | undefined, (nextValue: T) => void] {
  const [state, setState] = React.useState(initialState);
  const isMounted = React.useRef(true);

  const setStateSafe = React.useCallback((nextValue: T) => {
    if (isMounted.current) {
      setState(nextValue);
    }
  }, []);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return [state, setStateSafe];
}
