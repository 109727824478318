import React, { CSSProperties } from 'react';
import * as R from 'ramda';
import { Carousel } from 'react-bootstrap';
import { ModelImage } from 'apollo/schema/types';
import './index.css';

type Props = {
  images: ModelImage[];
  href: string;
  imageContainerStyle?: CSSProperties;
};

function ModelImagesCarousel({
  images,
  href,
  imageContainerStyle,
}: Props): JSX.Element {
  const files = R.sortBy(R.propOr(Infinity, 'order'), images).map(
    image => image.file,
  );

  const imageStyle = (url: string): CSSProperties => ({
    height: '430px',
    width: '100%',
    backgroundImage: `url(${url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    ...imageContainerStyle,
  });

  if (!files.length) {
    return (
      <Image
        href={href}
        style={imageStyle('/assets/images/placeholder-thumbnail-sm.png')}
      />
    );
  }

  if (files.length === 1) {
    return <Image href={href} style={imageStyle(files[0].signedUrl)} />;
  }

  return (
    <Carousel>
      {files.map(file => (
        <Carousel.Item key={file.id}>
          <Image href={href} style={imageStyle(file.signedUrl)} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

type ImageProps = {
  href: string;
  style: CSSProperties;
};
function Image({ href, style }: ImageProps): JSX.Element {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <div style={style} />
    </a>
  );
}

export default ModelImagesCarousel;
