import React, { useState } from 'react';
import * as R from 'ramda';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { Georeference, GeoreferenceDataType } from 'apollo/schema/types';
import {
  defaultGeoreference,
  GeoreferenceFormValues,
  GeoreferenceLimits,
  validationSchema,
} from 'utils/modules/georeference';
import MapEditorContainer from './MapEditorContainer';
import GeoreferenceListItem from './GeoreferenceListItem';
import CreateGeoreference from './CreateGeoreference';

export type SelectableGeoref = Georeference & {
  isSelected: boolean;
  onSelect: (nextSelected: boolean) => void;
};

type Props = {
  georeferences: Georeference[];
  dataTypes: GeoreferenceDataType[];
  onCreate: (
    georeference: GeoreferenceFormValues,
    helpers: FormikHelpers<GeoreferenceFormValues>,
  ) => void;
  onDelete: (georeferenceId: number) => void;
  disabled?: boolean;
  limits?: GeoreferenceLimits;
};

const GeoreferenceManager: React.FC<Props> = ({
  georeferences,
  dataTypes = [],
  onCreate,
  onDelete,
  disabled = false,
  limits = {},
}: Props) => {
  const [selectedGeoreferences, setSelectedGeoreferences] = useState<
    Record<number, boolean>
  >({});

  const georefsWithSelection: SelectableGeoref[] = georeferences.map(georef => {
    const isSelected = R.pathOr(false, [georef.id], selectedGeoreferences);

    const onSelect = (selected: boolean) =>
      setSelectedGeoreferences({
        ...selectedGeoreferences,
        [georef.id]: selected,
      });

    return {
      ...georef,
      isSelected,
      onSelect,
    };
  });

  return (
    <Formik
      onSubmit={onCreate}
      initialValues={defaultGeoreference()}
      validationSchema={validationSchema}
    >
      <Form>
        <Row>
          <Col md={4}>
            <h3>Georeference</h3>
            {!georeferences.length && (
              <CreateGeoreference
                georeferences={georefsWithSelection}
                dataTypes={dataTypes}
                limits={limits}
                disabled={disabled}
              />
            )}

            {georefsWithSelection.map(georef => (
              <GeoreferenceListItem
                key={georef.id}
                georeference={georef}
                onDelete={onDelete}
                disabled={disabled}
              />
            ))}
          </Col>

          <Col md={8}>
            <Field
              name="coordinates"
              component={MapEditorContainer}
              georeferences={georefsWithSelection}
            />
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

export default GeoreferenceManager;
