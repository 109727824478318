import React from 'react';

import { UserInvite } from 'apollo/schema/types';
import { prettyDate } from 'utils/text';

type Props = {
  userInvites: UserInvite[];
};

const MyInvitesTable: React.FC<Props> = ({ userInvites }: Props) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Email</th>
          <th>Created</th>
          <th>Status</th>
          <th />
        </tr>
      </thead>

      <tbody>
        {userInvites.map(ui => (
          <tr key={ui.id}>
            <td>{ui.targetUserEmail}</td>
            <td>{prettyDate(ui.createdAt)}</td>
            <td>{!!ui.targetUserId ? 'accepted' : 'sent'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MyInvitesTable;
