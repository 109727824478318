import React from 'react';
import Helmet from 'react-helmet';

import Navbar from 'components/layouts/Navbar';
import Hero from 'components/modules/home/Hero';
import Footer from 'components/layouts/Footer';
import PageLoader from 'components/modules/page/PageLoader';
import HashTarget from 'components/common/HashTarget';

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Home | V3Geo</title>
      </Helmet>
      <Navbar />
      <Hero />

      <HashTarget id="about">
        {hashProps => (
          <div {...hashProps} className="container mt-3 mb-5">
            <PageLoader slug="home" />
          </div>
        )}
      </HashTarget>

      <Footer />
    </>
  );
}

export default HomePage;
