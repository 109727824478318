import React, { useState } from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import * as config from 'config/constants';

type Props = {
  children: React.ReactNode;
};

function ApolloProviderWrapper({ children }: Props) {
  const cache = new InMemoryCache({
    typePolicies: {
      InitializedModelUserFile: {
        keyFields: ['modelId', 'uploadId'],
      },
    },
  });

  const uploadLink = createUploadLink({
    uri: config.GRAPHQL_URL,
    credentials: 'include',
  });

  const [client] = useState(
    new ApolloClient({
      cache,
      link: uploadLink,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'network-only',
        },
      },
    }),
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ApolloProviderWrapper;
