import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Model } from 'apollo/schema/types';
import { modelRoute } from 'routes';
import { useSortFilter } from 'hooks/data';
import { useAuth } from 'contexts/auth';
import SortTrigger from 'components/common/SortTrigger';
import Tooltip from 'components/common/Tooltip';
import PrivateModelIcon from 'components/modules/model/PrivateModelIcon';
import PublicModelIcon from 'components/modules/model/PublicModelIcon';
import { ModelWithCenter } from '.';

interface ModelWithMeta extends Model {
  isShared: boolean;
  sharedBy?: string;
}

type Props = {
  models: Model[];
  selectedModels: ModelWithCenter[];
  onToggleSelected: (modelWithCenter: ModelWithCenter) => void;
};

function ModelMapList({ models, selectedModels, onToggleSelected }: Props) {
  const { authority } = useAuth();

  const modelsWithMeta: ModelWithMeta[] = models.map(model => {
    if (!authority?.userId) {
      return { ...model, isShared: false };
    }
    const share = model.shares.find(
      share => share.targetUserId === authority.userId,
    );
    return {
      ...model,
      isShared: !!share,
      sharedBy: share?.sourceUser.name,
    };
  });

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    modelsWithMeta,
    'name',
    'name',
  );

  const anyModelsShared = React.useMemo(() => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].isShared) return true;
    }
    return false;
  }, [models]);

  const anyModelsPrivate = React.useMemo(() => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].isPrivate) return true;
    }
    return false;
  }, [models]);

  const modelCenter = (model: ModelWithMeta) =>
    model.georeferences.find(g => g.dataType === 'CENTER');

  const modelHasCenter = (model: ModelWithMeta) =>
    typeof modelCenter(model) !== 'undefined';

  const toMWC = (model: ModelWithMeta) => {
    const center = modelCenter(model);
    if (!center) {
      throw new Error(
        'Trying to convert to ModelWithCenter but not center is set. Check with modelHasCenter first.',
      );
    }
    return new ModelWithCenter(model, center);
  };

  const isSelected = (model: ModelWithMeta) =>
    selectedModels.map(m => m.model.id).includes(model.id);

  return (
    <table className="table">
      <thead>
        <tr>
          <th style={{ width: '35%' }}>
            <SortTrigger colName="name" sortIndicatorProps={siProps}>
              Name
            </SortTrigger>
          </th>
          <th style={{ width: '23%' }}>
            <SortTrigger
              colName="country"
              sortIndicatorProps={siProps}
              filterable
            >
              Country
            </SortTrigger>
          </th>
          <th>
            <SortTrigger colName="author" sortIndicatorProps={siProps}>
              Author
            </SortTrigger>
          </th>
          <th style={{ width: '20%' }}>
            <SortTrigger
              colName="copyrightLicense"
              sortIndicatorProps={siProps}
              filterable
            >
              License
            </SortTrigger>
          </th>
          {anyModelsShared && (
            <th style={{ width: '12%' }}>
              <SortTrigger
                colName="isShared"
                sortIndicatorProps={siProps}
                filterable
                renderFilterOption={value =>
                  value === 'true' ? 'Models shared with me' : 'Public models'
                }
              >
                <FontAwesomeIcon icon="user-friends" />
              </SortTrigger>
            </th>
          )}
          {anyModelsPrivate && (
            <th style={{ width: '10%' }}>
              <SortTrigger
                colName="isPrivate"
                sortIndicatorProps={siProps}
                filterable
                renderFilterOption={value =>
                  value === 'true' ? (
                    <>
                      Private Models{' '}
                      <PrivateModelIcon style={{ marginLeft: '2px' }} />
                    </>
                  ) : (
                    <>
                      Public models{' '}
                      <PublicModelIcon style={{ marginLeft: '6px' }} />
                    </>
                  )
                }
              >
                <FontAwesomeIcon icon="lock" />
              </SortTrigger>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {items.map(model => (
          <tr key={model.id}>
            <td>
              <Link to={modelRoute(model.id)} target="_blank">
                {model.name}
              </Link>
              {modelHasCenter(model) && (
                <Tooltip overlay="Show model on map">
                  <FontAwesomeIcon
                    icon="map-marker-alt"
                    onClick={() => onToggleSelected(toMWC(model))}
                    style={{
                      marginLeft: '5px',
                      marginBottom: '-2px',
                      cursor: 'pointer',
                      fontSize: '14pt',
                      color: isSelected(model) ? '#ff9800' : '#bbbbbb',
                    }}
                  />
                </Tooltip>
              )}
            </td>
            <td>{model.country}</td>
            <td>{model.author}</td>
            <td>{model.copyrightLicense}</td>
            {anyModelsShared && (
              <td>
                {model.isShared && (
                  <Tooltip overlay={<>Shared with you by {model.sharedBy}</>}>
                    <FontAwesomeIcon icon="user-friends" />
                  </Tooltip>
                )}
              </td>
            )}
            {anyModelsPrivate && (
              <td>
                {model.isPrivate && (
                  <Tooltip overlay="This model is private">
                    <PrivateModelIcon />
                  </Tooltip>
                )}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ModelMapList;
