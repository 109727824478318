import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { GeologyTag, RefetchQueries } from 'apollo/schema/types';
import { DELETE_GEOLOGY_TAG } from 'apollo/operations/geologyTag';
import {
  DeleteGeologyTagData,
  DeleteGeologyTagVars,
} from 'apollo/schema/operations';

type Props = {
  geologyTag: GeologyTag;
  refetchQueries: RefetchQueries;
  disabled: boolean;
};

const GeologyTagRow: React.FC<Props> = ({
  geologyTag,
  refetchQueries,
  disabled,
}: Props) => {
  const [deleteGeologyTag, { loading }] = useMutation<
    DeleteGeologyTagData,
    DeleteGeologyTagVars
  >(DELETE_GEOLOGY_TAG, {
    refetchQueries,
    variables: {
      modelId: geologyTag.modelId,
      geologyTagId: geologyTag.id,
    },
  });

  async function handleDelete() {
    const confirmText = 'The selected tag will be permanently deleted.';
    if (!window.confirm(confirmText)) return;

    try {
      await deleteGeologyTag();
      toast.success('Tag deleted.');
    } catch (err) {
      console.log('Error deleting geology tag', err);
      toast.error(
        'There was a problem deleting the tag. It may have already been removed.',
      );
    }
  }

  return (
    <tr>
      <td className="align-middle">{geologyTag.category}</td>
      <td className="align-middle">{geologyTag.subcategory || '--'}</td>
      <td className="text-right align-middle">
        <button
          type="button"
          className="btn btn-link"
          style={{ margin: 0, padding: 0, verticalAlign: 'top' }}
          onClick={handleDelete}
          disabled={disabled || loading}
        >
          remove
        </button>
      </td>
    </tr>
  );
};

export default GeologyTagRow;
