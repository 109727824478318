import { gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangePasswordData } from 'apollo/schema/operations';
import { Card } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { profileRoute } from 'routes';

const CHANGE_PASSWORD = gql`
  mutation ChangePassword {
    changePassword
  }
`;

function SecurityTab() {
  const [changePassword, { loading, data }] = useMutation<ChangePasswordData>(
    CHANGE_PASSWORD,
  );

  async function handleClick() {
    try {
      await changePassword();
      toast.success(
        'Your request has been processed. Please check your email to complete your password change.',
      );
    } catch (err) {
      console.log('Error processing password change request', err);
      toast.error('Something went wrong, please try again.');
    }
  }

  // Redirect back to the main profile page after request is sent
  if (data?.changePassword) {
    return <Redirect to={profileRoute()} />;
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>Change Password</Card.Title>
        <p>
          If you'd like to change your password, we'll need to do a quick
          verification to ensure you're the account owner. Click the button
          below and we'll send you an email containing a link to update your
          password.
        </p>

        <div className="text-center">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleClick}
            disabled={loading}
          >
            <FontAwesomeIcon icon="key" /> Change Password
          </button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default SecurityTab;
