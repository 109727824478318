import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Model, ModelShare } from 'apollo/schema/types';
import { modelRoute } from 'routes';
import { useAuth } from 'contexts/auth';
import Tooltip from 'components/common/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PrivateModelIcon from 'components/modules/model/PrivateModelIcon';
import SelectedFilters from '../SelectedFilters';

const ModelLink = styled(Link)`
  width: 100%;
  height: 100%;
  color: #222222;
  border-left: 3px solid transparent;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    border-left: 3px solid #ff9800;
  }
`;

type Props = {
  models: Model[];
};

const ModelSearchList: React.FC<Props> = ({ models }: Props) => {
  const { authority } = useAuth();

  /** Returns true if the model is shared with the current user */
  function renderIfShared(
    model: Model,
    cb: (share: ModelShare) => React.ReactNode,
  ) {
    if (!authority) return false;

    const share = model.shares.find(
      share => share.targetUserId === authority.userId,
    );

    if (share) {
      return cb(share);
    }
  }

  return (
    <>
      <SelectedFilters />

      <div className="text-center text-muted" style={{ paddingBottom: '14px' }}>
        Displaying <b>{models.length}</b> results
      </div>

      {models.map(model => (
        <div key={model.id} className="card mb-2">
          <ModelLink
            to={modelRoute(model.id)}
            style={{ width: '100%', height: '100%', color: '#222' }}
          >
            <div className="card-body">
              <div className="float-right">
                <span style={{ color: '#bbbbbb' }}>
                  {model.copyrightLicense}
                  {renderIfShared(model, share => (
                    <Tooltip
                      overlay={<>Shared with you by {share.sourceUser.name}</>}
                    >
                      <FontAwesomeIcon
                        icon="user-friends"
                        className="ml-2"
                        style={{ color: '#888888' }}
                      />
                    </Tooltip>
                  ))}
                  {model.isPrivate && (
                    <Tooltip overlay="Model is private">
                      <PrivateModelIcon className="ml-2" />
                    </Tooltip>
                  )}
                </span>
              </div>

              <h4 className="card-title">
                {model.name}
                <br />
                <small style={{ color: '#888' }}>{model.country}</small>
              </h4>
              {model.description && (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {model.description}
                </div>
              )}
            </div>
          </ModelLink>
        </div>
      ))}
    </>
  );
};

export default ModelSearchList;
