import React from 'react';
import { contributeRoute, modelSearchMapRoute } from 'routes';
import { Link } from 'react-router-dom';
import './Hero.css';
import styled from 'styled-components';

const heroStyles = {
  background:
    // "linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.15)), url('/assets/images/header-bg.png')",
    "url('/assets/images/header-bg.png')",
  backgroundSize: 'cover',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
};

const HeroButton = styled(Link)`
  box-shadow: 0px 0px 10px #444;
`;

const Hero: React.FC = () => {
  return (
    <div className="hero text-center" style={heroStyles}>
      <div className="hero-text-container">
        <div className="hero-text">
          <div className="hero-heading">
            <div className="hero-subheading">Virtual 3D Geoscience</div>
          </div>

          <HeroButton to={modelSearchMapRoute()} className="btn btn-warning">
            Find Models
          </HeroButton>

          <HeroButton to={contributeRoute()} className="btn btn-warning ml-2">
            Contribute
          </HeroButton>
        </div>
      </div>
    </div>
  );
};

export default Hero;
