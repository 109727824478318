import React from 'react';
import { Card } from 'react-bootstrap';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as fragments from 'apollo/fragments';
import { Organization, RefetchQueries, Role } from 'apollo/schema/types';
import { useSortFilter } from 'hooks/data';
import SortTrigger from 'components/common/SortTrigger';
import Spinner from 'components/common/Spinner';
import ManageOrganizationUserRoles from './ManageOrganizationUserRoles';

export const ORG_USER_LIST = gql`
  query OrgUserList($orgId: Int!) {
    organizationList(organizationId: $orgId) {
      ...orgParts
      users {
        ...userParts
      }
    }
  }

  ${fragments.orgParts}
  ${fragments.userParts}
`;
export type OrgUserListData = { organizationList: Organization[] };
export type OrgUserListVars = { orgId: number };

type Props = {
  orgId: number;
};

const OrganizationUserList: React.FC<Props> = ({ orgId }: Props) => {
  const { data, loading } = useQuery<OrgUserListData, OrgUserListVars>(
    ORG_USER_LIST,
    { variables: { orgId } },
  );

  const refetchQueries: RefetchQueries = [
    { query: ORG_USER_LIST, variables: { orgId } },
  ];

  const users =
    data?.organizationList.find(org => org.id === orgId)?.users || [];

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    users,
    'name',
    'name',
  );

  const isOrgAdmin = (roles: Role[]) => roles.includes('organizationAdmin');
  const isOrgEditor = (roles: Role[]) => roles.includes('organizationEditor');
  const isOrgSharer = (roles: Role[]) => roles.includes('organizationSharer');

  return (
    <Card>
      <Card.Header>Organization Users</Card.Header>
      <Card.Body>
        <p>
          <b>Admin</b>: can invite users to organization, accept requests to
          join the organization, and manage users' roles within the
          organization.
          <br />
          <b>Editor</b>: can edit models that belong to the organization.
          <br />
          <b>Sharer</b>: can share models that belong to the organization to
          other organizations or individual users.
        </p>

        <Spinner show={loading} />

        <table className="table">
          <thead>
            <tr>
              <th>
                <SortTrigger colName="name" sortIndicatorProps={siProps}>
                  Name
                </SortTrigger>
              </th>
              <th>
                <SortTrigger colName="email" sortIndicatorProps={siProps}>
                  Email
                </SortTrigger>
              </th>
              <th className="text-center">
                <SortTrigger
                  colName="isOrganizationAdmin"
                  sortIndicatorProps={siProps}
                >
                  Admin
                </SortTrigger>
              </th>
              <th className="text-center">
                <SortTrigger
                  colName="isOrganizationEditor"
                  sortIndicatorProps={siProps}
                >
                  Editor
                </SortTrigger>
              </th>
              <th className="text-center">
                <SortTrigger
                  colName="isOrganizationSharer"
                  sortIndicatorProps={siProps}
                >
                  Sharer
                </SortTrigger>
              </th>
              <th />
            </tr>
          </thead>

          <tbody>
            {items.map(user => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td className="text-center">
                  {isOrgAdmin(user.roles) && (
                    <FontAwesomeIcon icon="user-tie" />
                  )}
                </td>
                <td className="text-center">
                  {isOrgEditor(user.roles) && <FontAwesomeIcon icon="tools" />}
                </td>
                <td className="text-center">
                  {isOrgSharer(user.roles) && <FontAwesomeIcon icon="share" />}
                </td>
                <td className="text-right">
                  <ManageOrganizationUserRoles
                    user={user}
                    isOrgAdmin={isOrgAdmin(user.roles)}
                    isOrgEditor={isOrgEditor(user.roles)}
                    isOrgSharer={isOrgSharer(user.roles)}
                    refetchQueries={refetchQueries}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
};

export default OrganizationUserList;
