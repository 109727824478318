import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';

import FormikField from 'components/common/FormikField';
import { FormikTinyMceField } from 'components/common/FormikField/FormikTinyMceField';
import { PageFormValues } from 'utils/modules/page';
import { CLIENT_URL } from 'config/constants';

function PageForm(): JSX.Element {
  const { values } = useFormikContext<PageFormValues>();

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Field
            name="page.slug"
            label="Slug"
            component={FormikField}
            required
            helpTextPlacement="bottom"
            helpText={
              <div style={{ fontSize: '11pt', padding: '10px' }}>
                <p>
                  The slug is the URL that this page will be publicly available
                  at.
                </p>
                <p>
                  If multiple words, the preferred format for readability and
                  search engine optimization is "kebab-case", for example{' '}
                  <b>"my-fancy-page"</b> .
                </p>
                <p className="mb-0">
                  Note that these user-defined page routes will <b>not</b>{' '}
                  override the main app routes, so if a duplicate name is
                  picked, this page will not be accessible.
                </p>
              </div>
            }
          />
          {values.page.slug && (
            <div style={{ marginTop: '-10px' }}>
              {CLIENT_URL}/<b>{values.page.slug}</b>
            </div>
          )}
        </Col>
        <Col>
          <Field
            name="page.title"
            label="Title"
            component={FormikField}
            required
          />
        </Col>
      </Row>

      <Field
        name="page.content"
        label="Content"
        component={FormikField}
        type={FormikTinyMceField}
        config={{
          height: 500,
        }}
        required
      />
      <Field
        name="page.isSecured"
        label="Secured"
        component={FormikField}
        type="checkbox"
      />
    </>
  );
}

export default PageForm;
