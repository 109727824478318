import React from 'react';
import { gql, useMutation } from '@apollo/client';

import * as fragments from 'apollo/fragments';
import Confirm from 'components/common/Confirm';
import { Model } from 'apollo/schema/types';
import { toast } from 'react-toastify';

const SET_MODEL_PUBLISHED = gql`
  mutation SetModelPublished($modelId: Int!, $isPublished: Boolean!) {
    setModelPublished(modelId: $modelId, isPublished: $isPublished) {
      ...modelParts
    }
  }

  ${fragments.modelParts}
`;
type MutationData = {
  setModelPublished: Model;
};
type MutationVars = {
  modelId: number;
  isPublished: boolean;
};

type Props = {
  children: (onConfirm: () => void) => React.ReactChild;
  modelId: number;
  isPublished: boolean;
};

const PublishModelButton: React.FC<Props> = ({
  children,
  modelId,
  isPublished,
}: Props) => {
  const nextPublished = !isPublished;

  const [setModelPublished] = useMutation<MutationData, MutationVars>(
    SET_MODEL_PUBLISHED,
    { variables: { modelId, isPublished: nextPublished } },
  );

  async function handleSetPublish() {
    try {
      await setModelPublished();

      if (nextPublished) {
        toast.success('The model has been successfully published.');
      } else {
        toast.success('The model was successfully unpublished.');
      }
    } catch (err) {
      console.log('Error changing isPublished', err);
      toast.error(
        `There was a problem ${
          nextPublished ? '' : 'un'
        }publishing the model. Please try again.`,
      );
    }
  }

  const confirmPublishText = 'Model will be published.';

  const confirmUnpublishText = (
    <>
      <p>
        The Model will be <b className="text-danger">unpublished</b> and{' '}
        <b className="text-danger">no longer visible</b> on V3Geo.
      </p>
      <p>
        You will be able to make changes to the model and then resubmit it for
        review again if desired.
      </p>
    </>
  );

  return (
    <>
      <Confirm
        onConfirm={handleSetPublish}
        text={nextPublished ? confirmPublishText : confirmUnpublishText}
        submitText={nextPublished ? 'Publish Model' : 'Unpublish Model'}
        submitButtonClass={nextPublished ? undefined : 'btn btn-danger'}
      >
        {onConfirm => children(onConfirm)}
      </Confirm>
    </>
  );
};

export default PublishModelButton;
