import * as R from 'ramda';
import { FormikHelpers, yupToFormErrors } from 'formik';

/**
 * Sets errors in a formik form if the request resulted in a validation_error
 * @param errors - The result of the errors object from the Apollo request
 * @param formikHelpers - Formik actions object passed by onSubmit
 */
export function setValidationErrors<T>(
  error: any,
  formikHelpers: FormikHelpers<T>,
) {
  const exception = R.path(['graphQLErrors', 0, 'extensions'], error);
  const response = R.path(['response'], exception);
  const statusCode = R.path(['statusCode'], response);
  const errorsObject = R.path(['error'], response);

  const isValidationError = statusCode === 422;

  console.group('setValidationErrors.error arg inspection');
  console.log('Raw error:', JSON.parse(JSON.stringify(error)));
  console.log('exception:', exception);
  console.log('response:', response);
  console.log('isValidationError:', isValidationError);
  console.log('errorsObject:', errorsObject);
  console.groupEnd();

  // const errorType = R.path(['graphQLErrors', 0, 'message'], error);
  // const errorsObject = R.path(
  //   ['graphQLErrors', 0, 'extensions', 'errors'],
  //   error,
  // );
  // const isValidationError = R.equals('validation_error', errorType);

  if (!isValidationError) return;

  formikHelpers.setErrors(yupToFormErrors(errorsObject));
}
