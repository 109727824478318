import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { RefetchQueries } from 'apollo/schema/types';

const DELETE_MODEL = gql`
  mutation DeleteModel($modelId: Int!) {
    deleteModel(modelId: $modelId)
  }
`;
type MutationData = {
  deleteModel: boolean;
};
type MutationVars = {
  modelId: number;
};

type Props = {
  children: (handleDelete: () => void) => JSX.Element;
  modelId: number;
  onDeleteSuccess?: () => any;
  refetchQueries?: RefetchQueries;
};

const DeleteModel: React.FC<Props> = ({
  children,
  modelId,
  onDeleteSuccess,
  refetchQueries,
}: Props) => {
  const [deleteModel] = useMutation<MutationData, MutationVars>(DELETE_MODEL, {
    variables: { modelId },
    refetchQueries,
  });

  async function handleDelete(): Promise<void> {
    try {
      await deleteModel();
      toast.success('The model was deleted successfully.');

      if (onDeleteSuccess) onDeleteSuccess();
    } catch (err) {
      console.log('Error deleting model', err);
      let addtlInfo: string = '';
      if (err.message === 'model_not_deleted') {
        addtlInfo =
          ' The model may have already been deleted. Please refresh the page and try again.';
      }

      toast.error(`There was a problem deleting the model.${addtlInfo}`);
    }
  }

  return children(handleDelete);
};

export default DeleteModel;
