import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import UploadMUFMultipart from 'components/modules/model/UploadMUFMultipart';
// import UploadMUFDirect from 'components/modules/model/UploadMUFDirect';

type RouteParams = {
  modelId: string;
};

function Upload3DModelFilesPage() {
  const params = useParams<RouteParams>();
  const modelId = parseInt(params.modelId, 10);

  return (
    <div className="container-fluid">
      <Row style={{ padding: '20px' }} className="justify-content-center">
        <Col xs={12} md={6}>
          {/* <UploadMUFDirect modelId={modelId} /> */}
          <UploadMUFMultipart modelId={modelId} />
        </Col>
      </Row>
    </div>
  );
}

export default Upload3DModelFilesPage;
