import React from 'react';
import { Card, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { AuthorityOrganization } from 'apollo/schema/types';
import RoleSecured from 'components/common/RoleSecured';
import EditNameModal from 'components/modules/organization/EditNameModal';
import LeaveOrganizationButton from 'components/modules/organization/LeaveOrganizationButton';

const RoleBlock = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  font-size: 10pt;
  text-align: center;
  cursor: default;
  height: 100%;
  /* border: 2px solid rgba(0, 0, 0, 0.02); */
  border-bottom: 3px solid rgb(255, 152, 0);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);

  p {
    text-align: left;
    margin: 0;
  }
`;
const RoleIcon = styled(FontAwesomeIcon)`
  font-size: 38pt;
`;
const RoleName = styled.div`
  font-size: 12pt;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
`;

type Props = {
  organization: AuthorityOrganization;
};

const MyOrganizationTab: React.FC<Props> = ({ organization }: Props) => {
  return (
    <>
      <Card>
        <Card.Body>
          <div className="float-right">
            <DropdownButton
              title={<FontAwesomeIcon icon="cog" className="mr-1" />}
              size="sm"
              variant="secondary"
              style={{ marginTop: '-5px' }}
            >
              <RoleSecured roles={['admin', 'organizationAdmin']}>
                <EditNameModal org={organization}>
                  {showModal => (
                    <Dropdown.Item onClick={showModal}>
                      Edit Organization
                    </Dropdown.Item>
                  )}
                </EditNameModal>
              </RoleSecured>

              <LeaveOrganizationButton>
                {(onClick, loading) => (
                  <Dropdown.Item onClick={onClick} disabled={loading}>
                    Leave Organization
                  </Dropdown.Item>
                )}
              </LeaveOrganizationButton>
            </DropdownButton>
          </div>

          <div className="text-center">
            <h3>{organization.name}</h3>
          </div>

          <Row className="justify-content-md-center mt-3">
            <Col sm={3}>
              <RoleBlock>
                <RoleIcon icon="user" style={{ textAlign: 'center' }} />
                <RoleName>Organization Member</RoleName>
                <p>May create and view models within the organization</p>
              </RoleBlock>
            </Col>

            <RoleSecured roles={['organizationAdmin']}>
              <Col sm={3}>
                <RoleBlock>
                  <RoleIcon icon="user-tie" />
                  <RoleName>Administrator</RoleName>
                  <p>Manages the organization's settings and users</p>
                </RoleBlock>
              </Col>
            </RoleSecured>

            <RoleSecured roles={['organizationEditor']}>
              <Col sm={3}>
                <RoleBlock>
                  <RoleIcon icon="tools" />
                  <RoleName>Editor</RoleName>
                  <p>May edit models that belong to the organization</p>
                </RoleBlock>
              </Col>
            </RoleSecured>

            <RoleSecured roles={['organizationSharer']}>
              <Col sm={3}>
                <RoleBlock>
                  <RoleIcon icon="share" />
                  <RoleName>Sharer</RoleName>
                  <p>
                    Sets visibility and sharing of the organization's models
                  </p>
                </RoleBlock>
              </Col>
            </RoleSecured>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default MyOrganizationTab;
