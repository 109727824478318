import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { Field, Form, Formik, FormikHelpers } from 'formik';

import { Model, RefetchQueries } from 'apollo/schema/types';
import * as fragments from 'apollo/fragments';
import { useModalState } from 'hooks/modal';
import FormikField from 'components/common/FormikField';

const CREATE_MODEL_PUBLIC_LINK = gql`
  mutation CreateModelPublicLink(
    $modelId: Int!
    $duration: Int
    $note: String
  ) {
    createModelPublicLink(modelId: $modelId, duration: $duration, note: $note) {
      ...modelPublicLinkParts
    }
  }
  ${fragments.modelPublicLinkParts}
`;
type MutData = {
  generateModelPublicKey: Model;
};
type MutVars = {
  modelId: number;
  duration?: number | null;
  note?: string | null;
};

type FormValues = {
  duration: string;
  note: '';
};

const initialValues = (): FormValues => ({ duration: '0', note: '' });

type Props = {
  children: (showModal: () => void) => JSX.Element;
  modelId: number;
  refetchQueries: RefetchQueries;
};

const CreateModelPublicLinkModal: React.FC<Props> = ({
  children,
  modelId,
  refetchQueries,
}: Props) => {
  const { show, showModal, hideModal } = useModalState();
  const [createLink, { loading }] = useMutation<MutData, MutVars>(
    CREATE_MODEL_PUBLIC_LINK,
    { refetchQueries },
  );

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    const variables: MutVars = { modelId };
    if (values.duration !== '0') {
      variables.duration = parseInt(values.duration);
    }
    if (values.note) {
      variables.note = values.note;
    }

    try {
      await createLink({ variables });
      toast.success(
        'Public link generated successfully, you may now use the link to share your model.',
      );
      helpers.resetForm();
      hideModal();
    } catch (err) {
      console.log('Error generating key', err);
      toast.error(
        'There was a problem generating a link for your model. Please try again.',
      );
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Shareable Link</Modal.Title>
        </Modal.Header>

        <Formik onSubmit={handleSubmit} initialValues={initialValues()}>
          <Form>
            <Modal.Body>
              <Field
                name="duration"
                label="How long should the link be valid for?"
                helpText="How long the link will be valid for. The link will automatically stop working after the time limit expires."
                component={FormikField}
                type="select"
                options={[
                  { value: '0', label: 'Never expires' },
                  // { value: '1', label: '1 minute' },
                  { value: '60', label: '1 hour' },
                  { value: '720', label: '12 hours' },
                  { value: '1440', label: '1 day' },
                  { value: '10080', label: '1 week' },
                  { value: '43800', label: '1 month' },
                  { value: '131400', label: '3 months' },
                  { value: '262800', label: '6 months' },
                  { value: '525601', label: '1 year' },
                ]}
                required
              />
              <Field
                name="note"
                label="Optional note"
                component={FormikField}
                placeholder={`E.g. "Link for today's meeting"`}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                disabled={loading}
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default CreateModelPublicLinkModal;
