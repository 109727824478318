import { useAuth } from 'contexts/auth';
import { Link } from 'react-router-dom';
import { profileRoute } from 'routes';

/**
 * If a user is authenticated and their profile has not been completed,
 * show a warning and a link to the profile page.
 * Until their profile is completed, all operations that require auth from the
 * API will fail.
 */
const ProfileNotCompeltedNotice: React.FC = () => {
  const { authority } = useAuth();

  if (!authority || authority.isProfileCompleted) {
    return null;
  }

  return (
    <div className="alert alert-warning mb-3">
      <h4>Finish setting up your V3Geo profile</h4>

      <p>
        We need a little more info to finish setting up your profile. Please
        complete your profile for full access to V3Geo features.
      </p>

      <Link to={profileRoute()}>My Profile &raquo;</Link>
    </div>
  );
};

export default ProfileNotCompeltedNotice;
