import React from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

import { CANCEL_ORG_REQUEST } from 'apollo/operations/organization';
import Confirm from 'components/common/Confirm';
import {
  CancelOrgRequestData,
  CancelOrgRequestVars,
} from 'apollo/schema/operations';

type Props = {
  requestId: number;
};

const CancelOrganizationRequest: React.FC<Props> = ({ requestId }: Props) => {
  const [cancelRequest] = useMutation<
    CancelOrgRequestData,
    CancelOrgRequestVars
  >(CANCEL_ORG_REQUEST, { variables: { requestId } });

  async function handleCancel() {
    try {
      await cancelRequest();
      toast.success('Your request to join the organization has been canceled.');
    } catch (err) {
      console.log('Error canceling org request', err);
      toast.error(
        'there was a problem canceling the request to join the organization. Please try again.',
      );
    }
  }

  return (
    <Confirm
      onConfirm={handleCancel}
      text="Your request to join the organization will be canceled."
    >
      {onConfirm => (
        <button type="button" className="btn btn-secondary" onClick={onConfirm}>
          Cancel Request
        </button>
      )}
    </Confirm>
  );
};

export default CancelOrganizationRequest;
