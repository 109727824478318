import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, useFormikContext } from 'formik';

import FormikField from 'components/common/FormikField';
import { Georeference, GeoreferenceDataType } from 'apollo/schema/types';
import {
  GeoreferenceFormValues,
  GeoreferenceLimits,
} from 'utils/modules/georeference';

type ToolImageProps = {
  filename: string;
};
const ToolImage: React.FC<ToolImageProps> = ({ filename }: ToolImageProps) => (
  <img
    src={`/assets/images/${filename}`}
    style={{ height: '20px', width: 'auto', marginTop: '-5px' }}
    alt="marker icon"
  />
);

function renderToolImage(dataType: GeoreferenceDataType) {
  switch (dataType) {
    case 'POINT':
    case 'CENTER':
      return <ToolImage filename="marker_tool.png" />;

    case 'OUTLINE':
    case 'POLYGON':
      return <ToolImage filename="polygon_tool.png" />;

    case 'POLYLINE':
      return <ToolImage filename="polyline_tool.png" />;

    default:
      return null;
  }
}

type NCSMProps = {
  dataType: GeoreferenceDataType | null;
};
const NoCoordinatesSetMessage: React.FC<NCSMProps> = ({
  dataType = null,
}: NCSMProps) => {
  if (!dataType) return <div>Select a georeference type to continue.</div>;

  return (
    <div>
      {/* <FontAwesomeIcon icon="times-circle" className="text-danger" /> No
      coordinates selected. */}
      {/* <div className="text-muted"> */}
      Choose the map's <b>{dataType.toLowerCase()} tool</b>{' '}
      {renderToolImage(dataType)} to select the coordinates.
      {/* </div> */}
    </div>
  );
};

type CSMProps = {
  onReset: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
const CoordinatesSetMessage: React.FC<CSMProps> = ({ onReset }: CSMProps) => (
  <div className="text-center mb-4">
    <div>
      <FontAwesomeIcon icon="check" className="text-success" /> Coordinates set
    </div>
    <button
      type="button"
      className="btn btn-secondary btn-sm"
      onClick={onReset}
    >
      Reset
    </button>
  </div>
);

// Uncomment if more data types are used than center

// const dataTypeLimit = (dataType, limits) =>
//   R.pipe(R.prop(dataType), R.defaultTo(-1))(limits);

// const numGeorefsDataType = (dataType, georefs) =>
//   R.pipe(R.filter(R.whereEq({ dataType })), R.length)(georefs);

// function isDataTypeDisabled(dataType, georefs, limits) {
//   const numAllowed = dataTypeLimit(dataType, limits);
//   const numGeorefs = numGeorefsDataType(dataType, georefs);

//   if (numAllowed < 0) return false;
//   if (numAllowed === 0) return true;
//   return numGeorefs >= numAllowed;
// }

// const optionLabel = (dataType, georefs, limits) => {
//   const numAllowed = dataTypeLimit(dataType, limits);
//   const numGeorefs = numGeorefsDataType(dataType, georefs);

//   const baseText = R.pipe(str => str.toLowerCase(), ucwords)(dataType);
//   if (numAllowed === -1) return baseText;

//   return `${baseText} (${numGeorefs}/${numAllowed})`;
// };

type Props = {
  georeferences: Georeference[];
  dataTypes: GeoreferenceDataType[];
  limits: GeoreferenceLimits;
};
const GeoreferenceFormFields: React.FC<Props> = () => {
  const { values, setFieldValue } = useFormikContext<GeoreferenceFormValues>();

  function handleResetCoords(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setFieldValue('data', null);
  }

  return (
    <>
      {/*
      <Field
        name="dataType"
        label="Georeference type"
        component={FormikField}
        type="select"
        options={dataTypes.map(dataType => ({
          value: dataType,
          label: optionLabel(dataType, georeferences, limits),
          disabled: isDataTypeDisabled(dataType, georeferences, limits),
        }))}
        required
      />
      */}

      {/* <Field name="name" label="Name" component={FormikField} required /> */}

      {/*
      <Field
        name="description"
        label="Description"
        component={FormikField}
        required
      />
      */}

      <Field
        name="data"
        // label="Coordinates"
        component={FormikField}
        type="text"
        required
        renderInput={() =>
          values.data ? (
            <CoordinatesSetMessage onReset={handleResetCoords} />
          ) : (
            <NoCoordinatesSetMessage dataType={values.dataType} />
          )
        }
      />
    </>
  );
};

export default GeoreferenceFormFields;
