import React from 'react';
import { toast } from 'react-toastify';
import { Formik, Form, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';

import { News } from 'apollo/schema/types';
import { setValidationErrors } from 'utils/forms';
import { UPDATE_NEWS } from 'apollo/operations/news';
import { UpdateNewsData, UpdateNewsVars } from 'apollo/schema/operations';
import {
  defaultNews,
  NewsFormValues,
  validationSchema,
} from 'utils/modules/news';
import NewsFormFields from 'components/modules/news/NewsFormFields';
import FormikErrors from 'components/common/FormikErrors';

type Props = {
  news: News;
};

const UpdateNewsForm: React.FC<Props> = ({ news }: Props) => {
  const [updateNews, { loading, error }] = useMutation<
    UpdateNewsData,
    UpdateNewsVars
  >(UPDATE_NEWS);

  async function handleSubmit(
    values: NewsFormValues,
    helpers: FormikHelpers<NewsFormValues>,
  ) {
    try {
      const res = await updateNews({
        variables: { id: news.id, news: values },
      });
      const updatedNews = res.data?.updateNews;
      if (!updatedNews) throw new Error('Error parsing updateNews response');
      toast.success('News updated successfully.');
      helpers.resetForm({ values: defaultNews(updatedNews) });
    } catch (err) {
      console.log('Error creating news', err);
      setValidationErrors(err, helpers);
      toast.error('There was a problem creating the news.');
    }
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={defaultNews(news)}
        validationSchema={validationSchema}
      >
        <Form>
          <NewsFormFields />

          <FormikErrors graphQLError={error} />

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default UpdateNewsForm;
