import { gql } from '@apollo/client';

import * as fragments from 'apollo/fragments';

export const CREATE_ORG = gql`
  mutation CreateOrg($organization: OrganizationInput!) {
    createOrganization(organization: $organization) {
      ...orgParts
    }
  }

  ${fragments.orgParts}
`;

export const UPDATE_ORG = gql`
  mutation UpdateOrg($orgId: Int!, $org: OrganizationInput!) {
    updateOrganization(organizationId: $orgId, organization: $org) {
      ...orgParts
    }
  }
  ${fragments.orgParts}
`;

export const LEAVE_ORG = gql`
  mutation LeaveOrg {
    leaveOrganization {
      ...userParts
    }
  }

  ${fragments.userParts}
`;

export const ORG_INVITE_LIST = gql`
  query OrgInviteList($orgId: Int!) {
    organizationInviteList(organizationId: $orgId) {
      ...orgInviteParts
      inviter {
        ...userParts
      }
    }
  }

  ${fragments.orgInviteParts}
  ${fragments.userParts}
`;

export const CREATE_ORG_INVITE = gql`
  mutation CreateOrgInvite($email: String!) {
    createOrganizationInvite(email: $email) {
      ...orgInviteParts
      inviter {
        ...userParts
      }
    }
  }

  ${fragments.orgInviteParts}
  ${fragments.userParts}
`;

export const ORG_INVITES_BY_USER = gql`
  query OrgInvitesByUser($userId: Int!) {
    organizationInvitesByUser(userId: $userId) {
      ...orgInviteParts
      organizationPublicProfile {
        ...orgPublicProfileParts
      }
      inviter {
        ...userParts
      }
    }
  }

  ${fragments.orgInviteParts}
  ${fragments.orgPublicProfileParts}
  ${fragments.userParts}
`;

export const ACCEPT_ORG_INVITE = gql`
  mutation AcceptOrgInvite($inviteId: Int!) {
    acceptOrganizationInvite(inviteId: $inviteId) {
      ...orgInviteParts
    }
  }

  ${fragments.orgInviteParts}
`;

export const DECLINE_ORG_INVITE = gql`
  mutation DeclineOrgInvite($inviteId: Int!) {
    declineOrganizationInvite(inviteId: $inviteId) {
      ...orgInviteParts
      organization {
        ...orgParts
      }
      inviter {
        ...userParts
      }
    }
  }

  ${fragments.orgInviteParts}
  ${fragments.orgParts}
  ${fragments.userParts}
`;

export const GRANT_ORG_ADMIN = gql`
  mutation GrantOrgAdmin($userId: Int!) {
    grantOrganizationAdmin(userId: $userId) {
      ...userParts
    }
  }
  ${fragments.userParts}
`;

export const REVOKE_ORG_ADMIN = gql`
  mutation RevokeOrgAdmin($userId: Int!) {
    revokeOrganizationAdmin(userId: $userId) {
      ...userParts
    }
  }
  ${fragments.userParts}
`;

export const GRANT_ORG_EDITOR = gql`
  mutation GrantOrgEditor($userId: Int!) {
    grantOrganizationEditor(userId: $userId) {
      ...userParts
    }
  }
  ${fragments.userParts}
`;

export const REVOKE_ORG_EDITOR = gql`
  mutation RevokeOrgEditor($userId: Int!) {
    revokeOrganizationEditor(userId: $userId) {
      ...userParts
    }
  }
  ${fragments.userParts}
`;

export const GRANT_ORG_SHARER = gql`
  mutation GrantOrgSharer($userId: Int!) {
    grantOrganizationSharer(userId: $userId) {
      ...userParts
    }
  }
  ${fragments.userParts}
`;

export const REVOKE_ORG_SHARER = gql`
  mutation RevokeOrgSharer($userId: Int!) {
    revokeOrganizationSharer(userId: $userId) {
      ...userParts
    }
  }
  ${fragments.userParts}
`;

export const REMOVE_USER_FROM_ORG = gql`
  mutation RemoveUserFromOrg($userId: Int!) {
    removeUserFromOrganization(userId: $userId)
  }
`;

export const ORG_REQUEST_LIST = gql`
  query OrgRequestList($orgId: Int!) {
    organizationRequestList(organizationId: $orgId) {
      ...orgRequestParts
      requester {
        ...userParts
      }
      accepter {
        ...userParts
      }
    }
  }

  ${fragments.orgRequestParts}
  ${fragments.userParts}
`;

export const PUBLIC_ORG_LIST = gql`
  query PublicOrgList {
    publicOrganizationList {
      ...orgPublicProfileParts
    }
  }

  ${fragments.orgPublicProfileParts}
`;

export const CREATE_ORG_REQUEST = gql`
  mutation CreateOrgRequest($orgId: Int!) {
    createOrganizationRequest(organizationId: $orgId) {
      ...orgRequestParts
    }
  }

  ${fragments.orgRequestParts}
`;

export const MY_ORG_REQUESTS = gql`
  query MyOrgRequests {
    myOrganizationRequests {
      ...orgRequestParts
      organizationPublicProfile {
        ...orgPublicProfileParts
      }
    }
  }

  ${fragments.orgRequestParts}
  ${fragments.orgPublicProfileParts}
`;

export const CANCEL_ORG_REQUEST = gql`
  mutation CancelOrgRequest($requestId: Int!) {
    cancelOrganizationRequest(organizationRequestId: $requestId) {
      ...orgRequestParts
      organizationPublicProfile {
        ...orgPublicProfileParts
      }
    }
  }

  ${fragments.orgRequestParts}
  ${fragments.orgPublicProfileParts}
`;

export const ACCEPT_ORG_REQUEST = gql`
  mutation AcceptOrgRequest($requestId: Int!) {
    acceptOrganizationRequest(organizationRequestId: $requestId) {
      ...orgRequestParts
      requester {
        ...userParts
      }
    }
  }

  ${fragments.orgRequestParts}
  ${fragments.userParts}
`;

export const DECLINE_ORG_REQUEST = gql`
  mutation DeclineOrgRequest($requestId: Int!, $declineReason: String!) {
    declineOrganizationRequest(
      organizationRequestId: $requestId
      declineReason: $declineReason
    ) {
      ...orgRequestParts
      requester {
        ...userPublicProfileParts
      }
    }
  }

  ${fragments.orgRequestParts}
  ${fragments.userPublicProfileParts}
`;
