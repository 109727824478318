import {
  Authority,
  Model,
  ModelRequirementsMet,
  ModelReview,
} from 'apollo/schema/types';
import { ModelInput } from 'apollo/schema/operations';
import { yup } from 'utils/validation';
import { CLIENT_URL } from 'config/constants';

export interface ModelFormValues extends ModelInput {}

export const defaultModel = (model?: Model): ModelFormValues => ({
  name: model?.name || '',
  description: model?.description || null,
  modelSize: model?.modelSize || '',
  smallestVisibleFeatureSize: model?.smallestVisibleFeatureSize || '',
  author: model?.author || model?.author || '',
  copyrightLicense: model?.copyrightLicense || null,
  fundingSource: model?.fundingSource || '',
  source: model?.source || '',
  isPrivate: model?.isPrivate || false,
  // isDownloadable: model?.isDownloadable || false,
  country: model?.country || '',
  region: model?.region || '',
  location: model?.location || '',
  literature: model?.literature || '',
});

export const publicationTypes = [
  'peer reviewed literature',
  'phd thesis',
  'master thesis',
  'published technical reports',
  'internal company reports',
  'internet',
  'unpublished',
  'unknown',
];

export const copyrightOptions = ['CC BY', 'CC BY-NC', 'CC0'];

export function modelValidationSchema(
  authority: Authority,
): yup.AnyObjectSchema {
  const schema: Record<keyof ModelFormValues, yup.AnySchema> = {
    name: yup
      .string()
      .label('Name')
      .required(),
    description: yup
      .string()
      .label('Description')
      .when(['isPrivate'], (isPrivate: boolean, schema: yup.StringSchema) => {
        if (isPrivate) return schema.nullable();
        return schema.required();
      }),
    modelSize: yup
      .string()
      .label('Model size')
      .required(),
    smallestVisibleFeatureSize: yup
      .string()
      .label('Smallest visible feature')
      .required(),
    author: yup
      .string()
      .label('Author of model')
      .required(),
    copyrightLicense: yup
      .string()
      .label('Copyright license')
      .when(['isPrivate'], (isPrivate: boolean, schema: yup.StringSchema) => {
        if (isPrivate) return schema.nullable();
        return schema.oneOf(copyrightOptions).required();
      }),
    fundingSource: yup
      .string()
      .label('Funding source')
      .nullable(),
    source: yup
      .string()
      .label('Source')
      .nullable(),
    isPrivate: yup.boolean().label('Private'), // defaults to false
    // isDownloadable: yup.boolean().label('Downloadable'), // defaults to false
    country: yup
      .string()
      .label('Country')
      .required(),
    region: yup
      .string()
      .label('Region')
      .nullable(),
    location: yup
      .string()
      .label('Location')
      .nullable(),
    literature: yup
      .string()
      .label('Literature')
      .nullable(),
  };

  if (
    !authority.metadata.isSubscriptionActive &&
    !(authority.roles.includes('admin') || authority.roles.includes('reviewer'))
  ) {
    schema.isPrivate = yup
      .boolean()
      .label('Privacy')
      .isFalse('Only members with a paid subscription can have private models');
  }

  return yup.object(schema);
}

export const hasPendingReview = (reviews: ModelReview[]) =>
  typeof reviews.find(r => r.isApproved === null) !== 'undefined';

export const publishingRequirementsMet = (
  requirements: ModelRequirementsMet,
): boolean =>
  Object.values(requirements || {}).filter(val => val === false).length === 0;

export const viewerUrl = (modelId: number) =>
  `${CLIENT_URL}/viewer/index.html#/${modelId}`;

// Model offsets
export type ModelOffsetFormValues = {
  offsetX: string | number | null;
  offsetY: string | number | null;
  offsetZ: string | number | null;
};

export const defaultModelOffset = (
  offsetX?: number | null,
  offsetY?: number | null,
  offsetZ?: number | null,
): ModelOffsetFormValues => ({
  offsetX: offsetX ?? null,
  offsetY: offsetY ?? null,
  offsetZ: offsetZ ?? null,
});

export const modelOffsetValidator = (): yup.SchemaOf<ModelOffsetFormValues> =>
  yup.object({
    offsetX: yup
      .number()
      .default(null)
      .nullable(),
    offsetY: yup
      .number()
      .default(null)
      .nullable(),
    offsetZ: yup
      .number()
      .default(null)
      .nullable(),
  });
