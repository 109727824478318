import React from 'react';
import * as R from 'ramda';
import { useMutation } from '@apollo/client';
import { Formik, Form, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { Card } from 'react-bootstrap';

import { Model } from 'apollo/schema/types';
import {
  defaultModel,
  ModelFormValues,
  modelValidationSchema,
} from 'utils/modules/model';
import { UPDATE_MODEL } from 'apollo/operations/model';
import { UpdateModelData, UpdateModelVars } from 'apollo/schema/operations';
import { setValidationErrors } from 'utils/forms';
import { useAuth } from 'contexts/auth';
import ModelFormFields from 'components/modules/model/ModelFormFields';
import FormikErrors from 'components/common/FormikErrors';

type Props = {
  model: Model;
  locked: boolean;
};

const UpdateModelForm: React.FC<Props> = ({ model, locked }: Props) => {
  const { authority, hasAnyRole } = useAuth();
  if (!authority) throw new Error('no authority present');

  const [updateModel, { loading, error }] = useMutation<
    UpdateModelData,
    UpdateModelVars
  >(UPDATE_MODEL);

  async function handleSubmit(
    values: ModelFormValues,
    formikBag: FormikHelpers<ModelFormValues>,
  ) {
    const fields = R.keys(defaultModel());
    const modelInput = R.pick(fields, values);

    try {
      await updateModel({
        variables: { id: model.id, model: modelInput },
      });
      toast.success('Model updated successfully.');
    } catch (err) {
      setValidationErrors(err, formikBag);
      toast.error('There was a problem updating the model.');
      console.log('Error updating model', err);
    }
  }

  return (
    <Card>
      <Formik
        onSubmit={handleSubmit}
        initialValues={defaultModel(model)}
        validationSchema={modelValidationSchema(authority)}
      >
        <Form>
          <Card.Body>
            <ModelFormFields
              model={model}
              disabled={locked}
              isSubscriptionActive={
                hasAnyRole(['admin']) ||
                (authority.metadata.isSubscriptionActive &&
                  authority.userId === model.userId)
              }
            />
            <div className="mt-3" />
            <FormikErrors graphQLError={error} />

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Save
              </button>
            </div>
          </Card.Body>
        </Form>
      </Formik>
    </Card>
  );
};

export default UpdateModelForm;
