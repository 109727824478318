import React from 'react';
import { Switch, Route, useRouteMatch, Link, Redirect } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Badge, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as routes from 'routes';
import * as fragments from 'apollo/fragments';
import { useAuth } from 'contexts/auth';
import { ModelData, ModelVars } from 'apollo/schema/operations';
import { hasPendingReview } from 'utils/modules/model';
import excludedModels from 'components/modules/modelSearch/ModelSearchForm/excludedModels';
import StandardLayout, {
  BreadcrumbItem,
} from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import NotFound from 'components/errors/NotFound';
import SecuredRoute from 'components/common/SecuredRoute';
import Tooltip from 'components/common/Tooltip';
import ModelInfoTab from './ModelInfoTab';
import TechnicalReviewTab from './TechnicalReviewTab';
import ModelPublishingTab from './ModelPublishingTab';
import ModelSharingTab from './ModelSharingTab';
import ModelOwnershipTab from './ModelOwnershipTab';
import Model3DFilesTab from './Model3DFilesTab';
import ModelEditPageNavigation from './Navigation';
import ModelFilesTab from './ModelFilesTab';

export const MODEL_EDIT_PAGE = gql`
  query ModelEditPage($id: Int!) {
    model(id: $id) {
      ...modelParts
      reviews {
        ...modelReviewParts
      }
    }
  }

  ${fragments.modelParts}
  ${fragments.modelReviewParts}
`;

type RouteParams = {
  modelId: string;
};

const ModelEditPage: React.FC = () => {
  const { authority, hasAnyRole } = useAuth();
  const match = useRouteMatch<RouteParams>();
  const modelId = parseInt(match.params.modelId);

  const { data, loading } = useQuery<ModelData, ModelVars>(MODEL_EDIT_PAGE, {
    variables: { id: modelId },
  });

  const model = data?.model;

  const isAdmin = hasAnyRole(['admin']);
  const isOwner = model?.userId === authority!.userId;
  const isOwnerOrAdmin = isAdmin || isOwner;
  const isReviewer = hasAnyRole(['reviewer']);

  const canViewModel = isOwnerOrAdmin || hasAnyRole(['reviewer']);

  const isExcluded = model && excludedModels.includes(model.id);

  const isReviewPending = hasPendingReview(model?.reviews ?? []);
  /** Lock the model for editing when it is published or has a pending review */
  const locked = !isAdmin && (model?.isPublished || isReviewPending);

  if (!model && !loading && !canViewModel) {
    return <Redirect to={routes.notFoundRoute()} push />;
  }

  const middleBreadcrumb: BreadcrumbItem[] = isOwner
    ? [[routes.myModelsMyModelsTabRoute(), 'My Models']]
    : isAdmin || isReviewer
    ? [[routes.modelReviewTasksRoute(), 'Review Tasks']]
    : [];

  return (
    <StandardLayout
      breadcrumb={[
        ...middleBreadcrumb,
        [match.path, model?.name ?? 'Model Detail'],
      ]}
    >
      <Spinner show={loading} />

      {model && (
        <>
          <div className="alert alert-info text-info">
            <Row>
              <Col
                md={2}
                xl={1}
                className="d-sm-none d-md-block col-md-2 col-xl-1 text-center my-auto"
              >
                <FontAwesomeIcon
                  icon={model.isPublished ? 'eye' : 'eye-slash'}
                  style={{ fontSize: '24pt', color: '#1f9bcf' }}
                />
              </Col>

              <Col md={10} xl={11} className="my-auto">
                <h4 className="my-0 text-info">
                  Model {model.isPublished ? 'is' : 'not'} published
                </h4>
                {!model.isPublished && (
                  <p className="mb-0">
                    This model is currently in draft mode and is only visible to
                    you.
                  </p>
                )}
                {model.isPublished && (
                  <p className="mb-0">
                    This model is currently published and only the key details
                    can be edited. Please unpublish the model first to allow
                    modifying its other data.
                  </p>
                )}
              </Col>
            </Row>
          </div>

          <div style={{ position: 'relative' }}>
            <Link
              to={routes.modelRoute(model.id)}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-secondary float-right"
              style={{ marginTop: '-5px' }}
            >
              {model.isPublished ? 'View Model Page' : 'Preview Model Page'}
            </Link>

            <h1>
              {model.name}

              {isExcluded && (
                <Tooltip overlay="This model has been manually excluded and will not appear in search results.">
                  <Badge
                    variant="danger"
                    className="ml-2"
                    style={{
                      display: 'inline-block',
                      position: 'absolute',
                      top: '8px',
                      fontSize: '12pt',
                    }}
                  >
                    <FontAwesomeIcon
                      icon="minus-circle"
                      style={{
                        fontSize: '10pt',
                        paddingBottom: '1px',
                        paddingRight: '5px',
                      }}
                    />
                    Excluded
                  </Badge>
                </Tooltip>
              )}
            </h1>
          </div>
          <div className="clearfix" />
          <Row className="mt-3">
            <Col md={3}>
              <ModelEditPageNavigation
                model={model}
                isOwnerOrAdmin={isOwnerOrAdmin}
              />
            </Col>

            <Col md={9}>
              <Switch>
                <Redirect from={match.path} to={`${match.path}/info`} exact />

                <Route path={`${match.path}/info`}>
                  <ModelInfoTab modelId={model.id} locked={locked} />
                </Route>

                <Route path={`${match.path}/3d-model-files`}>
                  <Model3DFilesTab modelId={model.id} locked={locked} />
                </Route>

                <Route path={`${match.path}/technical-review`}>
                  <TechnicalReviewTab modelId={model.id} />
                </Route>

                {isOwnerOrAdmin && (
                  <Route path={`${match.path}/publishing`}>
                    <ModelPublishingTab modelId={model.id} />
                  </Route>
                )}
                {isOwnerOrAdmin && (
                  <Route path={`${match.path}/sharing`}>
                    <ModelSharingTab modelId={model.id} />
                  </Route>
                )}

                <SecuredRoute
                  path={`${match.path}/ownership`}
                  roles={['admin']}
                >
                  <ModelOwnershipTab modelId={model.id} userId={model.userId} />
                </SecuredRoute>

                <SecuredRoute
                  path={`${match.path}/model-files`}
                  roles={['admin']}
                >
                  <ModelFilesTab
                    modelId={model.id}
                    isPublished={model.isPublished}
                  />
                </SecuredRoute>

                <Redirect
                  from={match.path}
                  exact
                  to={routes.modelEditInfoTabRoute(model.id)}
                />

                <Route component={NotFound} />
              </Switch>
            </Col>
          </Row>
        </>
      )}
    </StandardLayout>
  );
};

export default ModelEditPage;
