import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import * as fragments from 'apollo/fragments';
import { User } from 'apollo/schema/types';
import { adminDashboardRoute, adminOrgRoute } from 'routes';
import { useSortFilter } from 'hooks/data';
import { fullDateTime, prettyDate } from 'utils/text';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import SortTrigger from 'components/common/SortTrigger';
import Tooltip from 'components/common/Tooltip';
import Confirm from 'components/common/Confirm';
import SetIsSubscriptionActive from 'components/modules/user/SetIsSubscriptionActive';
import ToggleReviewer from 'components/modules/user/ToggleReviewer';

const ADMIN_USER_LIST_PAGE = gql`
  query AdminUserListPage {
    userList {
      ...userParts
      organization {
        ...orgParts
      }
    }
  }
  ${fragments.userParts}
  ${fragments.orgParts}
`;
export type AdminUserListPageData = {
  userList: User[];
};

const YesIcon: React.FC = () => (
  <FontAwesomeIcon icon="check" className="text-success" />
);
const NoIcon: React.FC = () => (
  <FontAwesomeIcon icon="times-circle" className="text-danger" />
);

function AdminUserPage() {
  const match = useRouteMatch();
  const { loading, data } = useQuery<AdminUserListPageData>(
    ADMIN_USER_LIST_PAGE,
  );

  const { items: userList, sortIndicatorProps: siProps } = useSortFilter(
    data?.userList || [],
    'name',
    'name',
  );

  if (loading) return <Spinner />;

  return (
    <StandardLayout
      breadcrumb={[
        [adminDashboardRoute(), 'Dashboard'],
        [match.path, 'Users'],
      ]}
    >
      <h1>Users</h1>

      <table className="table">
        <thead>
          <tr>
            <th>
              <SortTrigger colName="id" sortIndicatorProps={siProps}>
                ID
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="name" sortIndicatorProps={siProps}>
                Name
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="email" sortIndicatorProps={siProps}>
                Email
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="organization.name"
                sortIndicatorProps={siProps}
                filterable
              >
                Org
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="createdAt" sortIndicatorProps={siProps}>
                Registered
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="isEnabled"
                sortIndicatorProps={siProps}
                filterable
              >
                Enabled
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="isEmailVerified"
                sortIndicatorProps={siProps}
                filterable
              >
                <Tooltip overlay="Whether the user has verified their email address">
                  <span>Verified</span>
                </Tooltip>
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="isSubscriptionActive"
                sortIndicatorProps={siProps}
                filterable
                renderFilterOption={option =>
                  option === 'true' ? 'Subscription Active' : 'Nonsubscriber'
                }
              >
                <Tooltip overlay="Whether the user has an active subscription">
                  <span>Sub</span>
                </Tooltip>
              </SortTrigger>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {userList.map(user => (
            <tr key={user.id}>
              <td className="align-middle">{user.id}</td>
              <td className="align-middle">
                {user.name}
                <RolesBadge user={user} />
              </td>
              <td className="align-middle">{user.email}</td>
              <td className="align-middle">
                {user.organization && (
                  <Link
                    to={adminOrgRoute(user.organization.id)}
                    target="_blank"
                  >
                    {user.organization.name}
                  </Link>
                )}
              </td>
              <td className="align-middle">
                <Tooltip overlay={fullDateTime(user.createdAt)}>
                  <span>{prettyDate(user.createdAt)}</span>
                </Tooltip>
              </td>
              <td className="align-middle text-center">
                {user.isEnabled ? <YesIcon /> : <NoIcon />}
              </td>
              <td className="align-middle text-center">
                {user.isEmailVerified && <YesIcon />}
              </td>
              <td className="align-middle text-center">
                {user.isSubscriptionActive && (
                  <Tooltip overlay="Subscription is active">
                    <span
                      className="badge badge-success text-light"
                      style={{ fontSize: '10pt' }}
                    >
                      <FontAwesomeIcon
                        icon="dollar-sign"
                        // className="text-success"
                      />
                    </span>
                  </Tooltip>
                )}
              </td>

              <td className="text-right">
                <DropdownButton
                  id={`adminUserManagement--${user.id}`}
                  title={<FontAwesomeIcon icon="cog" />}
                  size="sm"
                  variant="secondary"
                >
                  <SetIsSubscriptionActive
                    userId={user.id}
                    nextSubscriptionState={!user.isSubscriptionActive}
                  >
                    {toggleSubActive => (
                      <Confirm
                        onConfirm={toggleSubActive}
                        submitText={
                          user.isSubscriptionActive
                            ? 'Remove Subscription'
                            : 'Activate Subscription'
                        }
                        submitButtonClass={
                          user.isSubscriptionActive
                            ? 'btn btn-danger'
                            : 'btn btn-primary'
                        }
                        text={
                          user.isSubscriptionActive ? (
                            <>
                              The user's subscription will be marked as{' '}
                              <b>INACTIVE</b>. Ensure that their subscription
                              has been canceled in FastSpring.
                            </>
                          ) : (
                            <>
                              The user's subscription within V3Geo will be
                              marked as <b>ACTIVE</b>.
                            </>
                          )
                        }
                      >
                        {confirmToggle => (
                          <Dropdown.Item onClick={confirmToggle}>
                            {user.isSubscriptionActive
                              ? 'Remove subscription'
                              : 'Activate subscription'}
                          </Dropdown.Item>
                        )}
                      </Confirm>
                    )}
                  </SetIsSubscriptionActive>

                  <ToggleReviewer userId={user.id} isReviewer={user.isReviewer}>
                    {(toggleReviewer, loading) => (
                      <Confirm
                        onConfirm={toggleReviewer}
                        submitButtonClass={
                          user.isReviewer ? 'btn btn-danger' : 'btn btn-primary'
                        }
                        submitText={user.isReviewer ? 'Revoke' : 'Grant'}
                        text={`The reviewer role will be ${
                          user.isReviewer ? 'REVOKED from' : 'GRANTED to'
                        } this user.`}
                      >
                        {confirmToggle => (
                          <Dropdown.Item
                            onClick={confirmToggle}
                            disabled={loading}
                          >
                            {user.isReviewer
                              ? 'Revoke reviewer role'
                              : 'Grant reviewer role'}
                          </Dropdown.Item>
                        )}
                      </Confirm>
                    )}
                  </ToggleReviewer>
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StandardLayout>
  );
}

function RolesBadge({ user }: { user: User }) {
  if (!user.isAdmin && !user.isReviewer) return null;

  return (
    <span
      className="badge badge-warning text-light ml-2"
      style={{ fontSize: '10pt' }}
    >
      {user.isAdmin && (
        <Tooltip overlay="Admin">
          <FontAwesomeIcon icon="crown" className="mx-1" />
        </Tooltip>
      )}
      {user.isReviewer && (
        <Tooltip overlay="Reviewer">
          <FontAwesomeIcon icon="clipboard-check" className="mx-1" />
        </Tooltip>
      )}
    </span>
  );
}

export default AdminUserPage;
