import React, { useEffect } from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { Field, useFormikContext } from 'formik';
import { Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormikField from 'components/common/FormikField';
import {
  ModelEnumOptionsData,
  MODEL_ENUM_OPTIONS,
} from 'apollo/operations/model';
import { copyrightOptions, ModelFormValues } from 'utils/modules/model';
import ModelImagesCarousel from '../ModelImagesCarousel';
import { Model } from 'apollo/schema/types';
import { modelEditImagesTabRoute } from 'routes';
import CopyrightInfoOverlay from 'components/common/CopyrightInfoOverlay';
import PrivateModelIcon from '../PrivateModelIcon';
import { useModalState } from 'hooks/modal';

type SelectOption = {
  value: string;
  label: string;
};

const mapOption = (value: string): SelectOption => ({ value, label: value });

type Props = {
  model?: Model;
  disabled?: boolean;
  isSubscriptionActive: boolean;
};

const ModelFormFields: React.FC<Props> = ({
  model,
  disabled = false,
  isSubscriptionActive,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<ModelFormValues>();
  const { data, loading } = useQuery<ModelEnumOptionsData>(MODEL_ENUM_OPTIONS);
  const {
    show: isShowingPrivacyWarning,
    showModal: showPrivacyWarning,
    hideModal: hidePrivacyWarning,
  } = useModalState();

  // When a model becomes private, automatically unset the copyright license
  useEffect(() => {
    if (values.isPrivate) {
      setFieldValue('copyrightLicense', null);
    } else if (!values.copyrightLicense) {
      setFieldValue('copyrightLicense', model?.copyrightLicense ?? null);
    }
  }, [values.isPrivate]);

  const countryOptions = R.pipe(
    R.pathOr([], ['modelEnumOptions', 'country']),
    R.map(mapOption),
  )(data);

  const modelSizeOptions = R.pipe(
    R.pathOr([], ['modelEnumOptions', 'modelSize']),
    R.map(mapOption),
  )(data);

  const smallestVisibleFeatureOptions = R.pipe(
    R.pathOr([], ['modelEnumOptions', 'smallestVisibleFeature']),
    R.map(mapOption),
  )(data);

  function copyrightOpts() {
    const curVal = values.copyrightLicense;

    // Prepend the current value to the option list if it's not already there.
    // The validator will catch it if it's no longer valid.
    if (curVal && !copyrightOptions.includes(curVal)) {
      return [curVal, ...copyrightOptions].map(mapOption);
    }

    return copyrightOptions.map(mapOption);
  }

  const publicText =
    'This model will be public on V3Geo. It will appear in search results, and anyone will be able to view it.';
  const privateText =
    'This model will be be hidden from search results and will not be accessible by anyone who has not been granted access to it through the Sharing tab.';

  return (
    <>
      <Row>
        <Col md={model ? 8 : 12}>
          <Field
            name="name"
            label="Model name"
            component={FormikField}
            required
            disabled={disabled}
          />

          <Field
            name="isPrivate"
            label="Model privacy"
            component={FormikField}
            type="radio"
            disabled={disabled}
            inline
            required
            onChange={(
              nextValue: boolean,
              defaultChangeFn: (value: any) => void,
            ) => {
              // If the model is currently published and private, and the privacy is being
              // set to public, show a warning that the model will be unpublished
              if (model?.isPublished && model.isPrivate && !nextValue) {
                showPrivacyWarning();
              }
              defaultChangeFn(nextValue);
            }}
            options={[
              {
                value: false,
                text: (
                  <>
                    Public
                    <FontAwesomeIcon
                      icon="globe-americas"
                      className="ml-1 text-muted"
                    />
                  </>
                ),
              },
              {
                value: true,
                text: (
                  <>
                    <span className={!isSubscriptionActive ? 'text-muted' : ''}>
                      Private
                    </span>
                    <PrivateModelIcon className="ml-1" />
                  </>
                ),
                disabled: !isSubscriptionActive,
              },
            ]}
          />
          {!isSubscriptionActive && (
            <div className="mb-2">
              <b>Privacy settings are available to V3Geo subscribers.</b>
            </div>
          )}
          <div className="ml-3 text-muted">
            {values.isPrivate ? privateText : publicText}
          </div>
        </Col>

        {model && (
          <Col md={4} className="my-auto">
            <div
              className="figure text-center img-thumbnail mt-4"
              style={{ width: '100%' }}
            >
              <ModelImagesCarousel
                images={model.images}
                href={modelEditImagesTabRoute(model.id)}
                imageContainerStyle={{
                  height: '150px',
                  backgroundSize: 'contain',
                }}
              />
            </div>
          </Col>
        )}
      </Row>

      <div className="mt-3" />

      <Field
        name="description"
        label="Description"
        component={FormikField}
        type="textarea"
        required={!values.isPrivate}
        disabled={disabled}
        style={{ height: '200px' }}
      />
      <Row>
        <Col>
          <Field
            name="country"
            label="Country"
            component={FormikField}
            type="select"
            options={countryOptions}
            disabled={loading || disabled}
            required
          />
        </Col>
        <Col>
          <Field
            name="region"
            label="Region"
            component={FormikField}
            disabled={disabled}
          />
        </Col>
        <Col>
          <Field
            name="location"
            label="Location"
            component={FormikField}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            name="modelSize"
            label="Model size"
            component={FormikField}
            type="select"
            options={modelSizeOptions}
            disabled={loading || disabled}
            required
          />
        </Col>
        <Col>
          <Field
            name="smallestVisibleFeatureSize"
            label="Smallest visible feature"
            component={FormikField}
            type="select"
            options={smallestVisibleFeatureOptions}
            required
            disabled={disabled}
          />
        </Col>
      </Row>

      <Field
        name="literature"
        label="Literature"
        component={FormikField}
        type="textarea"
        style={{ height: '200px' }}
        disabled={disabled}
      />

      <Field
        name="author"
        label="Author"
        component={FormikField}
        required
        disabled={disabled}
      />
      <Field
        name="fundingSource"
        label="Funding source"
        component={FormikField}
        disabled={disabled}
      />
      <Field
        name="source"
        label="Source"
        component={FormikField}
        disabled={disabled}
      />
      <Field
        name="copyrightLicense"
        label="License"
        component={FormikField}
        type="select"
        options={copyrightOpts()}
        required={!values.isPrivate}
        disabled={disabled || values.isPrivate}
        blankOptionText={values.isPrivate === true ? 'Private' : undefined}
        helpText={<CopyrightInfoOverlay />}
      />

      {/* Hidden until functionality is implemented
      <Field
        name="isDownloadable"
        label="Allow downloads"
        component={FormikField}
        type="checkbox"
        disabled={disabled}
      />
      */}

      <Modal show={isShowingPrivacyWarning} onHide={hidePrivacyWarning}>
        <Modal.Header closeButton>
          <Modal.Title>Model will be unpublished</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please note that public models must be approved before they can be
            published.
          </p>
          <p className="mb-0">
            Since this private model is currently published,{' '}
            <b>it will be automatically unpublished</b> if the privacy is
            changed to public.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={hidePrivacyWarning}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModelFormFields;
