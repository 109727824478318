import React from 'react';
import classnames from 'classnames';
import { ErrorMessage, FieldProps } from 'formik';
import { PopoverProps } from 'react-bootstrap';

import FormLabel from './FormLabel';

type Props = FieldProps & {
  name: string;
  type: 'text' | 'number' | 'email' | 'password' | 'file' | 'textarea';
  className?: string;
  isInvalid: boolean;
  label?: React.ReactNode;
  renderInput?: (input: JSX.Element) => React.ReactNode;
  value?: string | number | null;
  helpText?: React.ReactNode;
  helpTextPlacement?: PopoverProps['placement'];
  required?: boolean;
};

const FormikTextField: React.FC<Props> = ({
  field,
  form,
  meta,
  type = 'text',
  className,
  isInvalid,
  label = null,
  renderInput,
  helpText = null,
  helpTextPlacement,
  required = false,
  ...props
}: Props) => {
  const fieldProps = {
    ...field,
    ...props,
    type,
    value: field.value ?? '',
    className: classnames(className, 'form-control'),
  };

  let fieldCmp: JSX.Element;
  if (type === 'textarea') {
    fieldCmp = <textarea {...fieldProps} />;
  } else {
    fieldCmp = <input {...fieldProps} />;
  }

  return (
    <div
      className={classnames('form-group', {
        'has-error': isInvalid,
        'text-danger': isInvalid,
      })}
    >
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        helpTextPlacement={helpTextPlacement}
        required={required}
      />
      {renderInput ? renderInput(fieldCmp) : fieldCmp}
      <ErrorMessage name={field.name} />
    </div>
  );
};

export default FormikTextField;
