import React from 'react';
import * as R from 'ramda';
import { useRouteMatch } from 'react-router-dom';

import PageLoader from 'components/modules/page/PageLoader';
import StandardLayout from 'components/layouts/StandardLayout';

function CmsPage() {
  const match = useRouteMatch();
  const slug = R.path(['params', 'slug'], match);

  return (
    <PageLoader
      slug={slug}
      render={page => (
        <StandardLayout breadcrumb={[[match.path, page.title]]}>
          <h1>{page.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </StandardLayout>
      )}
    />
  );
}

export default CmsPage;
