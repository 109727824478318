import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { User } from 'apollo/schema/types';
import * as fragments from 'apollo/fragments';

const SET_SUBSCRIPTION_ACTIVE = gql`
  mutation SetSubscriptionActive(
    $userId: Int!
    $isSubscriptionActive: Boolean!
  ) {
    setSubscriptionActive(
      userId: $userId
      isSubscriptionActive: $isSubscriptionActive
    ) {
      ...userParts
    }
  }

  ${fragments.userParts}
`;
type MutationData = {
  setSubscriptionActive: User;
};
type MutationVars = {
  userId: number;
  isSubscriptionActive: boolean;
};

type Props = {
  children: (fire: () => Promise<void>) => JSX.Element;
  userId: number;
  nextSubscriptionState: boolean;
};

const SetIsSubscriptionActive: React.FC<Props> = ({
  children,
  userId,
  nextSubscriptionState,
}: Props) => {
  const [setSub] = useMutation<MutationData, MutationVars>(
    SET_SUBSCRIPTION_ACTIVE,
    {
      variables: {
        userId,
        isSubscriptionActive: nextSubscriptionState,
      },
    },
  );

  async function handleClick(): Promise<void> {
    try {
      const res = await setSub();
      if (!res.data) throw new Error('No response from server.');
      const isSubActive = res.data.setSubscriptionActive.isSubscriptionActive;
      toast.success(
        <>
          The user's subscription is now marked as{' '}
          <b>{isSubActive ? 'ACTIVE' : 'INACTIVE'}</b>.
        </>,
      );
    } catch (err) {
      console.log('Error setting subscription state', err);
      toast.error('There was a problem updating the subscription state.');
    }
  }

  return children(handleClick);
};

export default SetIsSubscriptionActive;
