import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'querystring';
import moment from 'moment-timezone';

import { ModelPublicLink, RefetchQueries } from 'apollo/schema/types';
import { CLIENT_URL } from 'config/constants';
import { modelRoute } from 'routes';
import Confirm from 'components/common/Confirm';
import Tooltip from 'components/common/Tooltip';
import DeletePublicLink from './DeletePublicLink';
import { toast } from 'react-toastify';

type Props = {
  link: ModelPublicLink;
  refetchQueries: RefetchQueries;
};

const PublicLinkItem: React.FC<Props> = ({ link, refetchQueries }: Props) => {
  const pathname = `${CLIENT_URL}${modelRoute(link.modelId)}`;
  const search = qs.encode({ s: link.key });
  const publicUrl = `${pathname}?${search}`;

  const isExpired = link.expiresAt && moment() >= moment(link.expiresAt);

  function copyToClipboard(e: React.MouseEvent) {
    e.preventDefault();

    const inp = document.createElement('input');
    inp.style.cssText = 'position: absolute; left: -1000px; top: -1000px';
    inp.value = publicUrl;
    document.body.appendChild(inp);
    inp.select();

    document.execCommand('copy');
    document.body.removeChild(inp);

    (e.target as HTMLButtonElement).focus();

    toast.info('URL copied to clipboard.');
  }

  return (
    <Card className="mt-2">
      <Card.Body>
        {link.note && (
          <div className="mb-2">
            <FontAwesomeIcon icon={['far', 'sticky-note']} />
            <span className="ml-2 mb-2">{link.note}</span>
          </div>
        )}

        <div className="input-group">
          <input
            type="text"
            className="form-control"
            onChange={() => {}}
            value={publicUrl}
          />
          <span className="input-group-append">
            <span className="input-group-text">
              <Tooltip overlay="Copy URL to clipboard">
                <FontAwesomeIcon
                  icon="copy"
                  onClick={copyToClipboard}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </span>
          </span>
        </div>

        {link.expiresAt && (
          <Tooltip overlay={moment(link.expiresAt).format('LLL Z')}>
            <span className="text-muted">
              Expire{isExpired ? 'd' : 's'}{' '}
              <b>{moment(link.expiresAt).fromNow()}</b>
            </span>
          </Tooltip>
        )}

        <div className="mt-2 text-center">
          <DeletePublicLink linkId={link.id} refetchQueries={refetchQueries}>
            {deleteLink => (
              <Confirm
                onConfirm={deleteLink}
                text="This link will be removed; anyone you've shared it with will no longer be able to access this model."
              >
                {confirmDeleteLink => (
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary text-danger"
                    onClick={confirmDeleteLink}
                  >
                    <FontAwesomeIcon icon="trash" className="mr-2" />
                    {isExpired ? 'Delete Link' : 'Deactivate Link'}
                  </button>
                )}
              </Confirm>
            )}
          </DeletePublicLink>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PublicLinkItem;
