import React from 'react';
import { Field } from 'formik';

import FormikField from 'components/common/FormikField';
import { FormikTinyMceField } from 'components/common/FormikField/FormikTinyMceField';

const NewsFormFields: React.FC = () => {
  return (
    <>
      <div className="row">
        <div className="col-6">
          <Field
            name="title"
            label="Title"
            component={FormikField}
            placeholder="Example Title"
            required
          />
        </div>
        <div className="col-6">
          <Field
            name="slug"
            label="Slug"
            component={FormikField}
            placeholder="example-slug"
            helpText={
              <>
                Text that will be used in the article's URL. The convention for
                URLs is <em>kebab-case</em>
              </>
            }
            required
          />
        </div>
      </div>

      <Field
        name="introduction"
        label="Introduction"
        component={FormikField}
        type={FormikTinyMceField}
        helpText={`Content "above the fold", used in places where only the first portion of the article is shown.`}
        required
      />

      <Field
        name="content"
        label="Content"
        component={FormikField}
        type={FormikTinyMceField}
        config={{
          height: 500,
        }}
      />
    </>
  );
};

export default NewsFormFields;
