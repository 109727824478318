import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classnames from 'classnames';

// Link element version:

export type LinkBoxProps = LinkProps & {
  children?: React.ReactNode;
  header?: React.ReactNode;
};

function LinkBox({ children, header, ...props }: LinkBoxProps) {
  return (
    <Link {...props} className={classnames(props.className, 'link-box')}>
      {header && <span className="link-box-header">{header}</span>}
      {children}
    </Link>
  );
}

// Anchor element version:

export type LinkBoxAProps = React.HTMLProps<HTMLAnchorElement> & {
  children?: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
};

export function LinkBoxA({ children, header, className, ...props }: LinkBoxAProps) {
  return (
    <a {...props} className={classnames(className, 'link-box')}>
      {header && <span className="link-box-header">{header}</span>}
      {children}
    </a>
  );
}

export default LinkBox;
