import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ModelUserFile, RefetchQueries } from 'apollo/schema/types';
import { modelUpload3DModelFilesRoute } from 'routes';
import { CLIENT_URL } from 'config/constants';
import HelpBox from 'components/common/HelpBox';
import CurrentFiles from './CurrentFiles';

type Props = {
  modelId: number;
  mufs: ModelUserFile[];
  locked: boolean;
  isPrivate: boolean;
  reloadFiles: () => Promise<void>;
  refetchQueries: RefetchQueries;
};

function UserFileManager({
  modelId,
  mufs,
  locked,
  isPrivate,
  reloadFiles,
  refetchQueries,
}: Props): JSX.Element {
  return (
    <>
      <HelpBox>
        <h5 className="heading">Important Note</h5>
        <p className="mb-0">
          {isPrivate
            ? 'At present, model files are converted into the optimised web streaming format by the V3Geo team. You will be notified when this process is complete.'
            : 'Following upload and submission, a basic technical review is performed by the V3Geo team. You will contacted by the team once this has been completed. Following this stage, your model files will be converted into the optimised web streaming format and added to the database. You will get to approve the final publication.'}
        </p>
        <p className="mb-0 mt-1">
          For questions, you may contact us through:{' '}
          <a href="mailto:support@v3geo.com">support@v3geo.com</a>
        </p>
      </HelpBox>

      {!locked && (
        <div className="text-center my-3">
          <a
            href={`${CLIENT_URL}${modelUpload3DModelFilesRoute(modelId)}`}
            target="_blank"
            className="btn btn-warning"
          >
            Upload 3D Model Sections
            <FontAwesomeIcon icon="external-link-alt" className="ml-2" />
          </a>
        </div>
      )}

      <CurrentFiles
        modelId={modelId}
        mufs={mufs}
        locked={locked}
        reloadFiles={reloadFiles}
        refetchQueries={refetchQueries}
      />
    </>
  );
}

export default UserFileManager;
