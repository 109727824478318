import React, { HTMLProps } from 'react';
import classnames from 'classnames';

import './index.css';

type Props = HTMLProps<HTMLDivElement> & {
  show?: boolean;
};

const Spinner: React.FC<Props> = ({
  show = true,
  className,
  ...props
}: Props) => {
  if (!show) return null;
  return <div className={classnames('spinner', className)} {...props} />;
};

export default Spinner;
