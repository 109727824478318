import React from 'react';
import { useMutation, gql } from '@apollo/client';
import classnames from 'classnames';

import { ModelInterpretation } from 'apollo/schema/types';
import * as fragments from 'apollo/fragments';
import { toast } from 'react-toastify';
import Confirm from 'components/common/Confirm';

const SET_INTERPRETATION_PUBLISHED = gql`
  mutation SetInterpretationPublished(
    $interpretationId: Int!
    $isPublished: Boolean!
  ) {
    setModelInterpretationPublished(
      interpretationId: $interpretationId
      isPublished: $isPublished
    ) {
      ...interpretationParts
    }
  }

  ${fragments.interpretationParts}
`;
type MutData = {
  setModelInterpretationPublished: ModelInterpretation;
};
type MutVars = {
  interpretationId: number;
  isPublished: boolean;
};

type Props = {
  interpretation: ModelInterpretation;
};

const SetInterpretationPublished: React.FC<Props> = ({
  interpretation,
}: Props) => {
  const [setPublished, { loading }] = useMutation<MutData, MutVars>(
    SET_INTERPRETATION_PUBLISHED,
  );

  async function handleClick() {
    const isPublished = !interpretation.isPublished;
    const pubSuccessVerb = isPublished ? 'published' : 'unpublished';
    const pubErrorVerb = isPublished ? 'publishing' : 'unpublishing';

    try {
      await setPublished({
        variables: {
          interpretationId: interpretation.id,
          isPublished,
        },
      });
      toast.success(
        <span>
          Interpretation successfully <b>{pubSuccessVerb}</b>.
        </span>,
      );
    } catch (err) {
      console.log('Error changing publish state', err);
      toast.error(
        `There was a problem ${pubErrorVerb} the interpretation. Please try again.`,
      );
    }
  }

  return (
    <Confirm onConfirm={handleClick}>
      {handleConfirm => (
        <button
          type="button"
          className={classnames('btn btn-sm', {
            'btn-danger': interpretation.isPublished,
            'btn-success': !interpretation.isPublished,
          })}
          onClick={handleConfirm}
          disabled={loading}
        >
          {interpretation.isPublished ? 'Unpublish' : 'Publish'}
        </button>
      )}
    </Confirm>
  );
};

export default SetInterpretationPublished;
