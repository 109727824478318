import React from 'react';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import {
  adminModelsRoute,
  modelEditTechnicalReviewTabRoute,
  modelReviewTasksRoute,
  myModelsMyModelsTabRoute,
} from 'routes';
import * as fragments from 'apollo/fragments';
import { ModelData, ModelRequirementsMetData } from 'apollo/schema/operations';
import { publishingRequirementsMet } from 'utils/modules/model';
import { useAuth } from 'contexts/auth';
import Spinner from 'components/common/Spinner';
import RoleSecured from 'components/common/RoleSecured';
import Confirm from 'components/common/Confirm';
import AdminIcon from 'components/common/AdminIcon';
import HelpBox from 'components/common/HelpBox';
import DeleteModel from 'components/modules/model/DeleteModel';
import PublishModelButton from 'components/modules/model/PublishModelButton';
import RequirementsChecklist from 'components/modules/model/RequirementsChecklist';

const MODEL_PUBLISHING_TAB = gql`
  query ModelPublishingTab($modelId: Int!) {
    model(id: $modelId) {
      ...modelParts
      reviews {
        ...modelReviewParts
        reviewedBy {
          ...userParts
        }
      }
    }

    modelRequirementsMet(modelId: $modelId) {
      ...modelRequirementsMetParts
    }
  }

  ${fragments.modelParts}
  ${fragments.modelReviewParts}
  ${fragments.userParts}
  ${fragments.modelRequirementsMetParts}
`;
type QueryData = ModelData & ModelRequirementsMetData;
type QueryVars = {
  modelId: number;
};

type Props = {
  modelId: number;
};

const ModelPublishingTab: React.FC<Props> = ({ modelId }: Props) => {
  const { authority, hasAnyRole } = useAuth();
  if (!authority) throw new Error('not authenticated');

  const history = useHistory();
  const { data, loading } = useQuery<QueryData, QueryVars>(
    MODEL_PUBLISHING_TAB,
    { variables: { modelId } },
  );

  if (loading) return <Spinner />;
  if (!data) return null;

  const { model, modelRequirementsMet } = data;
  const reviews = R.sortWith([R.descend(R.prop('updatedAt'))], model.reviews);
  const pendingReview = reviews.filter(r => r.isApproved === null)[0];

  const isAdmin = hasAnyRole(['admin']);
  const isReviewer = hasAnyRole(['reviewer']);

  const canPublish = publishingRequirementsMet(modelRequirementsMet);

  function handleDeleteSuccess() {
    if (isAdmin) {
      history.push(adminModelsRoute());
    } else if (isReviewer) {
      history.push(modelReviewTasksRoute());
    } else {
      history.push(myModelsMyModelsTabRoute());
    }
  }

  return (
    <>
      {model.isPrivate && !model.isPublished && (
        <Row>
          <Col md={8}>
            <Card>
              <Card.Body>
                <div className="text-center">
                  <h3>Model NOT Published</h3>
                </div>
                <p>
                  This model is not currently published and is only visible to
                  you.
                </p>
                <p>
                  When you're finished making changes, you can publish using the
                  button below. The model will appear in search results and be
                  viewable only to those you have granted access.
                </p>

                <div className="text-center">
                  <PublishModelButton
                    modelId={modelId}
                    isPublished={model.isPublished}
                  >
                    {publishModel => (
                      <button
                        type="button"
                        className="btn btn-success mr-2"
                        onClick={publishModel}
                        disabled={!canPublish}
                      >
                        Publish Model
                      </button>
                    )}
                  </PublishModelButton>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <RequirementsChecklist requirements={modelRequirementsMet} />
          </Col>
        </Row>
      )}

      {model.isPublished && (
        <Card bg="light" text="dark" border="success">
          <Card.Body className="text-center">
            <h3 className="text-success">Model Published</h3>
            <p>This model is live on V3Geo!</p>

            <PublishModelButton
              modelId={modelId}
              isPublished={model.isPublished}
            >
              {handleUnpublish => (
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  onClick={handleUnpublish}
                >
                  Unpublish Model
                </button>
              )}
            </PublishModelButton>
          </Card.Body>
        </Card>
      )}

      {!model.isPublished && !model.isPrivate && (
        <>
          <Row className="justify-content-center">
            <Col md={10}>
              <HelpBox>
                <div>
                  To be published on V3Geo, your model must be reviewed by the
                  V3Geo team.
                </div>
                <div className="text-center">
                  <Link to={modelEditTechnicalReviewTabRoute(modelId)}>
                    Technical Review
                  </Link>
                </div>
              </HelpBox>
            </Col>
          </Row>

          <RoleSecured roles={['admin']}>
            <Card className="mt-3">
              <Card.Header>
                <AdminIcon className="mr-2" />
                Administrator Actions
              </Card.Header>
              <Card.Body className="text-center">
                <PublishModelButton
                  modelId={modelId}
                  isPublished={model.isPublished}
                >
                  {publishModel => (
                    <button
                      type="button"
                      className="btn btn-primary mr-2"
                      onClick={publishModel}
                      disabled={!canPublish}
                    >
                      Publish Model Directly
                    </button>
                  )}
                </PublishModelButton>
              </Card.Body>
            </Card>
          </RoleSecured>
        </>
      )}

      {(!model.isPublished || isAdmin) && !pendingReview && (
        <Row className="mt-3 justify-content-center">
          <Col md={8}>
            <Card>
              <Card.Body>
                <Card.Title>Delete Model</Card.Title>

                <p>
                  Deleting this model will remove it from all areas of V3Geo.
                  Proceed only if you are sure you will never use this model
                  again.
                </p>

                <div className="text-center">
                  <DeleteModel
                    modelId={model.id}
                    onDeleteSuccess={handleDeleteSuccess}
                  >
                    {handleDeleteModel => (
                      <Confirm
                        onConfirm={handleDeleteModel}
                        title="Confirm Model Deletion"
                        submitText="Delete Model"
                        submitButtonClass="btn btn-danger"
                        text={
                          <>
                            This model will be{' '}
                            <b className="text-danger">PERMANENTLY DELETED</b>.
                            Are you sure you want to proceed?
                          </>
                        }
                      >
                        {confirmDelete => (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={confirmDelete}
                          >
                            <FontAwesomeIcon icon="trash" /> Delete Model
                          </button>
                        )}
                      </Confirm>
                    )}
                  </DeleteModel>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ModelPublishingTab;
