import { gql } from '@apollo/client';

import * as fragments from 'apollo/fragments';

export const NEWS_LIST = gql`
  query NewsList($year: Int, $month: Int, $skip: Int, $take: Int) {
    newsList(year: $year, month: $month, skip: $skip, take: $take) {
      ...newsParts
    }
  }

  ${fragments.newsParts}
`;

export const GET_NEWS = gql`
  query GetNews($id: Int, $slug: String) {
    news(id: $id, slug: $slug) {
      ...newsParts
    }
  }

  ${fragments.newsParts}
`;

export const CREATE_NEWS = gql`
  mutation CreateNews($news: NewsInput!) {
    createNews(news: $news) {
      ...newsParts
    }
  }

  ${fragments.newsParts}
`;

export const UPDATE_NEWS = gql`
  mutation UpdateNews($id: Int!, $news: NewsInput!) {
    updateNews(id: $id, news: $news) {
      ...newsParts
    }
  }

  ${fragments.newsParts}
`;

export const DELETE_NEWS = gql`
  mutation DeleteNews($id: Int!) {
    deleteNews(id: $id)
  }
`;

export const NEWS_OVERVIEW = gql`
  query NewsOverview {
    newsOverview
  }
`;
