import React from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ModelReview, RefetchQueries } from 'apollo/schema/types';
import { modelRoute } from 'routes';
import { useModalState } from 'hooks/modal';
import * as fragments from 'apollo/fragments';
import FormikField from 'components/common/FormikField';
import { setValidationErrors } from 'utils/forms';

const CREATE_MODEL_REVIEW = gql`
  mutation CreateModelReview($modelId: Int!, $comments: String) {
    createModelReview(modelId: $modelId, comments: $comments) {
      ...modelReviewParts
    }
  }
  ${fragments.modelReviewParts}
`;
type MutData = {
  createModelReview: ModelReview;
};
type MutVars = {
  modelId: number;
  comments?: string | null;
};

type FormValues = {
  comments: string;
};
function initialValues(): FormValues {
  return { comments: '' };
}

type Props = {
  children: (onClick: () => void, loading: boolean) => React.ReactNode;
  modelId: number;
  refetchQueries: RefetchQueries;
};

const CreateModelReviewButton: React.FC<Props> = ({
  children,
  modelId,
  refetchQueries,
}: Props) => {
  const { show, showModal, hideModal } = useModalState();
  const [createModelReview, { loading }] = useMutation<MutData, MutVars>(
    CREATE_MODEL_REVIEW,
    { refetchQueries },
  );

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    const variables: MutVars = {
      modelId,
    };
    if (values.comments) {
      variables.comments = values.comments;
    }

    try {
      await createModelReview({ variables });
    } catch (err) {
      console.log('Error creating model review', err);

      let errMsg =
        'There was a problem submitting the model for review. Please try again.';

      if (err.message === 'model_private_review_not_needed') {
        errMsg =
          'This model is private and does not need to be reviewed. You may publish it directly on the Publishing tab of the model page.';
      }

      toast.error(errMsg);
      setValidationErrors(err, helpers);
    }
  }

  return (
    <>
      {children(showModal, loading)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Submit Model For Review</Modal.Title>
        </Modal.Header>

        <Formik onSubmit={handleSubmit} initialValues={initialValues()}>
          <Form>
            <Modal.Body>
              <p>
                You are about to submit your model to be published on V3Geo.
              </p>
              <p>
                Once submitted, you won't be able to make further changes
                without canceling the review or unpublishing the model and
                resubmitting it for another review.
              </p>
              <p>
                You may use the link below to preview your model to ensure it's
                ready to be reviewed.
              </p>

              <div className="text-center my-3">
                <Link
                  to={modelRoute(modelId)}
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Preview Model <FontAwesomeIcon icon="external-link-alt" />
                </Link>
              </div>

              <Field
                name="comments"
                label="Additional comments to reviewers (optional)"
                component={FormikField}
                type="textarea"
              />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Submit
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default CreateModelReviewButton;
