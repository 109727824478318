import { gql, useMutation } from '@apollo/client';

import * as fragments from 'apollo/fragments';
import {
  GrantReviewerData,
  GrantReviewerVars,
  RevokeReviewerData,
  RevokeReviewerVars,
} from 'apollo/schema/operations';
import { toast } from 'react-toastify';

const GRANT_REVIEWER = gql`
  mutation GrantReviewer($userId: Int!) {
    grantReviewer(userId: $userId) {
      ...userParts
    }
  }

  ${fragments.userParts}
`;
const REVOKE_REVIEWER = gql`
  mutation RevokeReviewer($userId: Int!) {
    revokeReviewer(userId: $userId) {
      ...userParts
    }
  }

  ${fragments.userParts}
`;

type Props = {
  children: (
    toggleReviewer: () => Promise<void>,
    loading: boolean,
  ) => JSX.Element;
  userId: number;
  isReviewer: boolean;
};

function ToggleReviewer({ children, userId, isReviewer }: Props) {
  const [grantReviewer, { loading: loadingGrant }] = useMutation<
    GrantReviewerData,
    GrantReviewerVars
  >(GRANT_REVIEWER, { variables: { userId } });

  const [revokeReviewer, { loading: loadingRevoke }] = useMutation<
    RevokeReviewerData,
    RevokeReviewerVars
  >(REVOKE_REVIEWER, { variables: { userId } });

  async function handleGrant() {
    try {
      await grantReviewer();
      toast.success('Reviewer role granted successfully.');
    } catch (err) {
      console.log('Error granting reviewer role', err);
      toast.error('There was a problem granting the reviewer role.');
    }
  }

  async function handleRevoke() {
    try {
      await revokeReviewer();
      toast.success('Reviewer role revoked successfully.');
    } catch (err) {
      console.log('Error revoking reviewer role', err);
      toast.error('There was a problem revoking the reviewer role.');
    }
  }

  const handler = isReviewer ? handleRevoke : handleGrant;
  const loading = loadingGrant || loadingRevoke;

  return children(handler, loading);
}

export default ToggleReviewer;
