import { gql } from '@apollo/client';

import * as fragments from 'apollo/fragments';

export const MODEL_IMAGE_LIST = gql`
  query ModelImageList($modelId: Int!) {
    model(id: $modelId) {
      ...modelParts
      images {
        ...modelImageParts
        file {
          ...fileParts
        }
      }
    }
  }
  ${fragments.modelParts}
  ${fragments.modelImageParts}
  ${fragments.fileParts}
`;

export const UPLOAD_MODEL_IMAGE = gql`
  mutation UploadModelImage(
    $id: Int!
    $file: Upload!
    $modelImage: ModelImageInput!
  ) {
    uploadModelImage(id: $id, file: $file, modelImage: $modelImage) {
      file {
        ...fileParts
      }
    }
  }
  ${fragments.fileParts}
`;

export const DELETE_MODEL_IMAGE = gql`
  mutation DeleteModelImage($modelId: Int!, $fileId: Int!) {
    deleteModelImage(modelId: $modelId, fileId: $fileId)
  }
`;
