import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Model } from 'apollo/schema/types';
import UpdateModelForm from 'components/modules/model/UpdateModelForm';
import Tooltip from 'components/common/Tooltip';
import { modelEditImagesTabRoute } from 'routes';
import { useAuth } from 'contexts/auth';

type Props = {
  model: Model;
};

function ModelDetailTab({ model }: Props): JSX.Element {
  const auth = useAuth();

  const canEdit =
    model.userId === auth.authority?.userId || auth.hasAnyRole(['admin']);

  return (
    <>
      <UpdateModelForm model={model} locked={!canEdit} />

      {!model.isPublished && (
        <div className="mt-3 text-right">
          <Tooltip overlay="Continue to images">
            <Link
              to={modelEditImagesTabRoute(model.id)}
              className="btn btn-primary"
            >
              Next
              <FontAwesomeIcon icon="chevron-right" className="ml-2" />
            </Link>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default ModelDetailTab;
