import React from 'react';
import * as R from 'ramda';
import { useRouteMatch, Redirect, Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Card, Col, Row } from 'react-bootstrap';

import { AgeTag, GeologyTag } from 'apollo/schema/types';
import { CLIENT_URL } from 'config/constants';
import { notFoundRoute, modelEditRoute, modelRoute } from 'routes';
import { useQueryString } from 'hooks/routing';
import * as fragments from 'apollo/fragments';
import { useAuth } from 'contexts/auth';
import { viewerUrl } from 'utils/modules/model';
import { ModelData, ModelVars } from 'apollo/schema/operations';
import NotFoundPage from 'pages/NotFoundPage';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import RoleSecured from 'components/common/RoleSecured';
import ModelImagesCarousel from 'components/modules/model/ModelImagesCarousel';
import ModelTags from 'components/modules/model/ModelTags';
import ModelInfo from 'components/modules/model/ModelInfo';
import PrivateModelIcon from 'components/modules/model/PrivateModelIcon';
import Tooltip from 'components/common/Tooltip';
import CopyModelLink from 'components/modules/model/CopyModelLink';
import CopyIframeLink from 'components/modules/model/CopyIframeLink';
import ModelCoordinatesPopover from 'components/modules/model/ModelCoordinatesPopover';

const MODEL_PAGE = gql`
  query ModelPage($id: Int!, $publicKey: String, $setCookie: Boolean) {
    model(id: $id, publicKey: $publicKey, setCookie: $setCookie) {
      ...modelParts
      georeferences {
        ...georeferenceParts
      }
      ageTags {
        ...ageTagParts
      }
      geologyTags {
        ...geologyTagParts
      }
      images {
        ...modelImageParts
        file {
          ...fileParts
        }
      }
      shares {
        ...modelShareParts
      }
      publicLinks(active: true) {
        ...modelPublicLinkParts
      }
    }
  }

  ${fragments.modelParts}
  ${fragments.georeferenceParts}
  ${fragments.ageTagParts}
  ${fragments.geologyTagParts}
  ${fragments.fileParts}
  ${fragments.modelImageParts}
  ${fragments.modelShareParts}
  ${fragments.modelPublicLinkParts}
`;

const isNilOrEmpty: (item: any) => boolean = R.anyPass([R.isNil, R.isEmpty]);

type RouteParams = {
  modelId: string;
};
type SearchString = {
  s?: string;
};

type QueryData = ModelData;
type QueryVars = ModelVars;

const ModelPage: React.FC = () => {
  const { authority, hasAnyRole } = useAuth();
  const match = useRouteMatch<RouteParams>();
  const modelId = parseInt(match.params.modelId, 10);

  const { query: searchQuery } = useQueryString<SearchString>();

  const queryVariables: QueryVars = { id: modelId };
  if (searchQuery.s) {
    // When "s(ecret key)" is in the querystring, add it to the query along with
    // the option to set the viewer cookie on load. If the user is not authenticated,
    // this is what will give them access to the viewer does not support keys
    queryVariables.publicKey = decodeURIComponent(searchQuery.s);
    queryVariables.setCookie = true;
  }

  const { data, loading, error } = useQuery<QueryData, QueryVars>(MODEL_PAGE, {
    variables: queryVariables,
  });

  const model = data?.model;
  const images = model?.images || [];

  const isAdmin = hasAnyRole(['admin']);
  const isModelOwner =
    !!authority && !!model && authority.userId === model.userId;
  const isOwnerOrAdmin = isAdmin || isModelOwner;

  const categories = R.pipe(
    R.propOr<GeologyTag[]>([], 'geologyTags'),
    R.map((gt: GeologyTag) => gt.category),
    R.uniq,
  )(model);
  const subcategories = R.pipe(
    R.propOr<GeologyTag[]>([], 'geologyTags'),
    R.map((gt: GeologyTag) => gt.subcategory),
    R.uniq,
  )(model);
  const systems = R.pipe(
    R.propOr<AgeTag[]>([], 'ageTags'),
    R.map((at: AgeTag) => at.system),
    R.uniq,
  )(model);

  const hasAnyTags = R.any(R.complement(R.isEmpty), [
    categories,
    subcategories,
    systems,
  ]);

  if (modelId === 222 && !hasAnyRole(['admin'])) {
    console.log('Model 222 is inaccessible.');
    return <Redirect to={notFoundRoute()} />;
  }

  if (error) {
    console.log('Error loading model:', error);
    // return <Redirect to={notFoundRoute()} push />;
    return <NotFoundPage />;
  }

  const regionLocation =
    model &&
    [model?.region, model?.location]
      .filter((text): text is string => !!text)
      .join(', ');

  const center = model?.georeferences.find(g => g.dataType === 'CENTER');
  const centerCoords = center?.data.coordinates[0];

  return (
    <StandardLayout
      breadcrumb={[[match.path, model?.name ?? 'Model Detail']]}
      hideBreadcrumb
    >
      {loading && <Spinner />}
      {!loading && model && (
        <>
          {!model.isPublished && (
            <Alert variant="info">
              <Row>
                <Col md={1} className="text-center">
                  <FontAwesomeIcon
                    icon="eye-slash"
                    style={{ fontSize: '28pt', marginTop: '8px' }}
                  />
                </Col>
                <Col md={11}>
                  <h4 style={{ marginTop: 0 }}>Model not published</h4>
                  <p style={{ marginBottom: 0 }}>
                    This model isn't published, so it's only visible to you.
                  </p>
                </Col>
              </Row>
            </Alert>
          )}

          <RoleSecured roles={['admin']}>
            <Link
              to={modelEditRoute(modelId)}
              className="btn btn-sm btn-primary float-right"
            >
              <FontAwesomeIcon icon="pencil-alt" className="mr-2" />
              Edit Model
            </Link>
          </RoleSecured>

          <h1>
            {model.name} <small className="text-muted">{model.country}</small>
            {model.isPrivate && (
              <Tooltip overlay="Private Model">
                <PrivateModelIcon
                  style={{ marginLeft: '10px', fontSize: '16pt' }}
                />
              </Tooltip>
            )}
          </h1>

          <Row>
            <Col md={8} style={{ wordBreak: 'break-word' }}>
              <div
                className="figure text-center img-thumbnail"
                style={{ width: '100%' }}
              >
                <ModelImagesCarousel
                  images={images}
                  href={viewerUrl(modelId)}
                  imageContainerStyle={
                    !images.length ? { backgroundSize: 'contain' } : {}
                  }
                />

                <div className="figure-caption mt-2">
                  <a
                    href={viewerUrl(modelId)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click the image to start the fullscreen 3D webviewer
                  </a>
                </div>
              </div>

              {/* <h2 className="mt-4">Model Description</h2>

              {!isNilOrEmpty(model.literature) && (
                <div className="mt-4">
                  <h3>Literature</h3>
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    {model.literature}
                  </div>
                </div>
              )} */}
              {!isNilOrEmpty(model.description) && (
                <>
                  <div className="mt-2" />

                  {(regionLocation || centerCoords) && (
                    <div style={{ fontSize: '14pt' }}>
                      {regionLocation}
                      {centerCoords && (
                        <ModelCoordinatesPopover
                          latitude={centerCoords.latitude}
                          longitude={centerCoords.longitude}
                        />
                      )}
                    </div>
                  )}
                  <h2 className="mt-2">Model Description</h2>
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    {model.description}
                  </div>
                </>
              )}

              {!isNilOrEmpty(model.literature) && (
                <div className="mt-4">
                  <h3>Literature</h3>
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    {model.literature}
                  </div>
                </div>
              )}
            </Col>

            <Col md={4}>
              <ModelInfo model={model} isOwnerOrAdmin={isOwnerOrAdmin} />

              {hasAnyTags && (
                <Card className="mt-3">
                  <Card.Body>
                    <Card.Title>Tags</Card.Title>
                    <ModelTags title="Categories" tags={categories} />
                    <ModelTags title="Subcategories" tags={subcategories} />
                    <ModelTags title="Geological Age" tags={systems} />
                  </Card.Body>
                </Card>
              )}

              <Card className="mt-3">
                <Card.Body>
                  <Card.Title>Model Properties</Card.Title>
                  <Row>
                    <Col sm={5}>
                      <strong>Model Size</strong>
                    </Col>
                    <Col sm={7}>{model.modelSize}</Col>
                  </Row>

                  {model.smallestVisibleFeatureSize && (
                    <Row className="mt-2">
                      <Col sm={5}>
                        <strong>Smallest Visible Feature</strong>
                      </Col>
                      <Col sm={7}>{model.smallestVisibleFeatureSize}</Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>

              {!model.isPrivate && (
                <>
                  <Card className="mt-3">
                    <Card.Body>
                      <Card.Title>How to reference this model</Card.Title>
                      {[
                        model.name,
                        model.author,
                        `${CLIENT_URL}${modelRoute(model.id)}`,
                      ].join(', ')}
                    </Card.Body>
                  </Card>

                  <Card className="mt-3">
                    <Card.Body>
                      <Card.Title>
                        Sharing <FontAwesomeIcon icon="share-alt" />
                      </Card.Title>

                      <Row className="mt-2">
                        <Col sm={6} className="pt-2">
                          Via Link
                        </Col>
                        <Col sm={6}>
                          <CopyModelLink modelId={model.id} />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={6} className="pt-2">
                          Embed 3D Viewer
                        </Col>
                        <Col sm={6}>
                          <CopyIframeLink
                            modelId={model.id}
                            modelName={model.name}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
    </StandardLayout>
  );
};

export default ModelPage;
