import React from 'react';
import { useMutation } from '@apollo/client';
import { Formik, Form, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

import { adminNewsRoute } from 'routes';
import { RefetchQueries } from 'apollo/schema/types';
import { CREATE_NEWS } from 'apollo/operations/news';
import { CreateNewsData, CreateNewsVars } from 'apollo/schema/operations';
import { setValidationErrors } from 'utils/forms';
import {
  defaultNews,
  NewsFormValues,
  validationSchema,
} from 'utils/modules/news';
import FormikErrors from 'components/common/FormikErrors';
import NewsFormFields from 'components/modules/news/NewsFormFields';

type Props = {
  refetchQueries: RefetchQueries;
};

const CreateNews: React.FC<Props> = ({ refetchQueries }: Props) => {
  const [createNews, { loading, error, data }] = useMutation<
    CreateNewsData,
    CreateNewsVars
  >(CREATE_NEWS, {
    refetchQueries,
  });

  async function handleSubmit(
    values: NewsFormValues,
    helpers: FormikHelpers<NewsFormValues>,
  ) {
    try {
      await createNews({ variables: { news: values } });
      toast.success('News created successfully.');
    } catch (err) {
      console.log('Error creating news', err);
      setValidationErrors(err, helpers);
      toast.error('There was a problem creating the news.');
    }
  }

  if (data) {
    return <Redirect to={adminNewsRoute(data.createNews.id)} />;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultNews()}
      validationSchema={validationSchema}
    >
      <Form>
        <NewsFormFields />
        <FormikErrors graphQLError={error} />

        <div className="text-center">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            Save
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default CreateNews;
