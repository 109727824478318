import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import StandardLayout from 'components/layouts/StandardLayout';
import ContributeWorkflow from 'components/modules/contribute/ContributeWorkflow';
import { cmsPage, myModelsCreateRoute } from 'routes';
import PageLoader from 'components/modules/page/PageLoader';
import LinkBox from 'components/common/LinkBox';

function ContributePage(): JSX.Element {
  const match = useRouteMatch();

  return (
    <StandardLayout breadcrumb={[[match.path, 'Contribute']]} hideBreadcrumb>
      <PageLoader
        slug="contribute"
        render={page => (
          <>
            <h1>{page.title}</h1>

            <Row>
              <Col md={8}>
                <div dangerouslySetInnerHTML={{ __html: page.content }} />
                <div className="text-center mt-5 mb-5">
                  <Link
                    to={myModelsCreateRoute()}
                    className="btn btn-warning btn"
                  >
                    Get Started
                  </Link>
                </div>

                <LinkBox
                  to={cmsPage('create-model')}
                  header="How to create a model entry in V3Geo"
                />
                <LinkBox
                  to={cmsPage('3dmodel')}
                  header="How to prepare a 3D model for V3Geo"
                />
                <LinkBox to={cmsPage('review')} header="Technical review" />

                <div className="mb-4 d-xs-block d-md-none" />
              </Col>

              <Col md={4}>
                <h4>
                  Workflow for
                  <br />
                  3D model contribution
                </h4>
                <ContributeWorkflow />
              </Col>
            </Row>
          </>
        )}
      />
    </StandardLayout>
  );
}

export default ContributePage;
