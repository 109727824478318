import React, { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import {
  GoogleMap,
  DrawingManager,
  Marker,
  Polygon,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';

import { markerTypes, polygonTypes } from 'utils/map';

const { google } = window;
const { ControlPosition, drawing } = google.maps;

const blue = '#4d58c8';
const red = '#bf3638';
const iconNormal = '/assets/images/marker-default.png';
const iconNormalDot = '/assets/images/marker-default-dot.png';
const iconSelected = '/assets/images/marker-blue.png';
const iconSelectedDot = '/assets/images/marker-blue-dot.png';

function georefIcon(georef) {
  if (georef.dataType === 'CENTER') {
    if (georef.isSelected) return iconSelectedDot;
    return iconNormalDot;
  }

  if (georef.isSelected) return iconSelected;
  return iconNormal;
}

// Extract marker components from georeferences array
function getMapMarkers(georeferences, clickable) {
  return georeferences
    .filter(georef => markerTypes.includes(georef.dataType))
    .map(georef => (
      <Marker
        key={georef.id || uuid()}
        position={georef.data.coordinates[0]}
        options={{ clickable }}
        icon={georefIcon(georef)}
        onMouseOver={() => georef.onSelect(true)}
        onMouseOut={() => georef.onSelect(false)}
      />
    ));
}

// Extract polygon components from georeferences array
function getMapPolygons(georeferences, clickable) {
  const polygonOptions = {
    fillColor: 'red',
    fillOpacity: 0.1,
    strokeColor: 'red',
    strokeWeight: 4,
    clickable,
  };

  return georeferences
    .filter(item => polygonTypes.includes(item.dataType))
    .map(item => {
      const options = {
        ...polygonOptions,
        strokeColor: item.isSelected ? blue : red,
        fillColor: item.isSelected ? blue : red,
      };

      return (
        <Polygon
          key={item.id || uuid()}
          path={item.data.coordinates}
          options={options}
          onMouseOver={() => item.onSelect(true)}
          onMouseOut={() => item.onSelect(false)}
        />
      );
    });
}

function getDefaultDrawingMode(drawingModes) {
  // Note: returning null sets the map drawing mode to nothing, i.e. "the hand tool"
  // Otherwise, automatically set it to whatever drawing tool is first available
  if (!drawingModes.length) {
    return null;
  }
  return drawing.OverlayType[drawingModes[0]] || null;
}

function MapEditor({
  georeferences,
  drawingModes,
  onDrawingComplete,
  selectedGeoreference,
  onSelect,
}) {
  function handlePolygonComplete(polygon) {
    // Remove element from the drawing map; it will be displayed as it is passed down through georeferences array
    polygon.setMap(null);

    const data = [];
    polygon.getPaths().forEach(path => {
      path.forEach(coords => {
        data.push({
          latitude: coords.lat(),
          longitude: coords.lng(),
        });
      });
    });

    if (onDrawingComplete) {
      onDrawingComplete(data);
    }
  }

  function handleMarkerComplete(marker) {
    // Remove element from the drawing map; it will be displayed as it is passed down through georeferences array
    marker.setMap(null);

    const data = {
      latitude: marker.getPosition().lat(),
      longitude: marker.getPosition().lng(),
    };

    if (onDrawingComplete) {
      onDrawingComplete(data);
    }
  }

  // When not in a drawing mode, allow clicking the georef to view the infowindow
  const clickable = drawingModes.length === 0;

  let markers, polygons;
  if (georeferences) {
    markers = getMapMarkers(georeferences, clickable);
    polygons = getMapPolygons(georeferences, clickable);
  }

  const handleLoad = useCallback(
    map => {
      if (!georeferences.length) {
        map.setCenter({ lat: 0, lng: 0 });
        map.setZoom(1);
        return;
      }

      if (georeferences.length === 1) {
        const center = georeferences[0].data.coordinates[0];
        map.setCenter(center);
        map.setZoom(11);
        return;
      }

      const bounds = new window.google.maps.LatLngBounds();
      georeferences.forEach(georef => {
        georef.data.coordinates.forEach(coords => bounds.extend(coords));
      });
      map.fitBounds(bounds);
    },
    [georeferences],
  );

  return (
    <div>
      <h3 style={{ marginTop: 0 }}>Preview</h3>

      <GoogleMap
        onLoad={handleLoad}
        mapContainerStyle={{ width: '100%', height: '400px' }}
        mapTypeId={google.maps.MapTypeId.HYBRID}
      >
        <DrawingManager
          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: ControlPosition.TOP_CENTER,
              drawingModes: drawingModes.map(dm => drawing.OverlayType[dm]),
            },
          }}
          drawingMode={getDefaultDrawingMode(drawingModes)}
          onMarkerComplete={handleMarkerComplete}
          onPolygonComplete={handlePolygonComplete}
        />

        {markers}
        {polygons}
      </GoogleMap>
    </div>
  );
}

MapEditor.propTypes = {
  georeferences: PropTypes.arrayOf(PropTypes.object).isRequired,
  drawingModes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDrawingComplete: PropTypes.func,
  selectedGeoreference: PropTypes.object,
  onSelect: PropTypes.func,
};

export default MapEditor;
