import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from 'components/common/Tooltip';

function defaultValueRenderer(value: string): string {
  if (value === 'true') return 'Yes';
  if (value === 'false') return 'No';
  if (value === null) return 'No value';
  return value;
}

type Props = {
  options: string[];
  values: string[];
  onChange: (value: string) => void;
  renderFilterOption?: (value: string) => React.ReactNode;
};

const FilterButton: React.FC<Props> = ({
  options,
  values,
  onChange,
  renderFilterOption = defaultValueRenderer,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const hasFilters = values.length > 0;

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <Tooltip
      popover
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <div className="px-3 pt-2 py-1">
          <div className="mb-2">
            <strong>Filter</strong>
          </div>
          {options.map(opt => (
            <div key={opt} className="checkbox">
              <label>
                <input
                  type="checkbox"
                  value={opt}
                  onChange={e => onChange(e.target.value)}
                  checked={values.includes(opt)}
                />{' '}
                {renderFilterOption(opt)}
              </label>
            </div>
          ))}
        </div>
      }
    >
      <button
        type="button"
        className="btn btn-link ml-1"
        onClick={handleToggle}
        style={{ padding: 0 }}
      >
        <FontAwesomeIcon
          icon="filter"
          className={hasFilters ? 'text-danger' : ''}
          style={{ fontSize: '8pt' }}
        />
      </button>
    </Tooltip>
  );
};

export default FilterButton;
