import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { RefetchQueries } from 'apollo/schema/types';

const DELETE_MODEL_PUBLIC_LINK = gql`
  mutation DeleteModelPublicLink($linkId: Int!) {
    deleteModelPublicLink(linkId: $linkId)
  }
`;
type MutData = { deleteModelPublicLink: boolean };
type MutVars = { linkId: number };

type Props = {
  children: (handleDelete: () => void) => JSX.Element;
  linkId: number;
  refetchQueries: RefetchQueries;
};

const DeletePublicLink: React.FC<Props> = ({
  children,
  linkId,
  refetchQueries,
}: Props) => {
  const [deleteLink] = useMutation<MutData, MutVars>(DELETE_MODEL_PUBLIC_LINK, {
    variables: { linkId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteLink();
      toast.success('The public link was successfully removed.');
    } catch (err) {
      console.log('Error deleting link', err);
      toast.error(
        'There was a problem deleting the public link, please try again.',
      );
    }
  }

  return children(handleDelete);
};

export default DeletePublicLink;
