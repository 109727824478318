import { useState, useCallback } from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { FilterSearchProps } from 'components/common/FilterSearch';
import { SortIndicatorProps } from 'components/common/SortIndicator';

type SortDirection = 'asc' | 'desc';

const directionFn = (
  dirStr: SortDirection,
): typeof R.ascend | typeof R.descend => {
  if (dirStr === 'asc') return R.ascend;
  if (dirStr === 'desc') return R.descend;
  throw new Error('Unknown sort direction');
};

const flipDirection = (dirStr: SortDirection): SortDirection => {
  return dirStr === 'asc' ? 'desc' : 'asc';
};

type SortHistoryItem = [string, SortDirection]; // [property, direction]
type SortHistory = SortHistoryItem[];

type CategoryFilters = Record<string, string[]>; // { colName: ["value1", "value2"] }

export type SortChangeFn = (
  property: string,
  overrideReverse?: boolean,
) => void;

type UseSortFilterReturn<T> = {
  items: T[];
  onSortChange: SortChangeFn;
  filterSearchProps: FilterSearchProps;
  sortIndicatorProps: SortIndicatorProps;
};

export function useSortFilter<T>(
  unsortedItems: T[],
  defaultSortProp: string,
  searchProp: string,
): UseSortFilterReturn<T> {
  const history = useHistory();

  const [sortHistory, setSortHistory] = useState<SortHistory>([
    [defaultSortProp, 'asc'],
  ]);
  const [includesFilter, setIncludesFilter] = useState('');
  const [startsWithFilter, setStartsWithFilter] = useState('');
  const [categoryFilters, setCategoryFilters] = useState<CategoryFilters>({});

  const [currentSort, sortDirection] = sortHistory[0];

  // Push the given property to the top of the sort history
  // The final sort list is filtered so any given property can only exist once
  const handleSortChange = useCallback(
    (property: string, overrideReverse?: boolean) => {
      let nextDirection =
        currentSort === property ? flipDirection(sortDirection) : 'asc';
      if (overrideReverse) {
        nextDirection = 'asc';
      }
      const nextHistoryItem: SortHistoryItem = [property, nextDirection];

      const nextSortHistory = R.pipe(
        R.prepend(nextHistoryItem),
        R.uniqBy(R.head), // Make sure the column names are unique in sort history
      )(sortHistory) as SortHistory;

      setSortHistory(nextSortHistory);
    },
    [currentSort, history, sortDirection, sortHistory],
  );

  function handleStartsWithChange(value: string) {
    const nextVal = typeof value === 'string' ? value.toLowerCase() : value;
    setStartsWithFilter(startsWithFilter === nextVal ? '' : nextVal);
  }

  function handleIncludesChange(value: string) {
    setIncludesFilter(value);
  }

  const filterByCategory: (filters: CategoryFilters) => (items: T[]) => T[] = (
    filters: CategoryFilters,
  ) =>
    R.filter(item =>
      R.pipe(
        R.keys,
        R.reduce((acc, key) => {
          if (!acc) return acc;
          const vals = R.prop(key, filters);
          if (R.either(R.isEmpty, R.isNil)(vals)) return true;
          return R.pipe(
            R.pathOr<any>(null, R.split('.', key)),
            String,
            R.trim,
            R.contains(R.__, vals),
          )(item);
        }, true),
        R.equals(true),
      )(filters),
    );
  const categoryFilteredItems = filterByCategory(categoryFilters)(
    unsortedItems,
  );

  const filteredItems = R.isEmpty(searchProp)
    ? // Skip filtering if the search property is not set
      categoryFilteredItems
    : categoryFilteredItems.filter(item => {
        const propPath = R.split('.', searchProp);
        const value = R.pipe(
          R.path(propPath),
          R.defaultTo(''),
          String, // Sometimes ids get sorted, next 2 fns will blow up if you pass them a number
          R.toLower,
          R.trim,
        )(item);
        if (!R.isEmpty(startsWithFilter) && !value.startsWith(startsWithFilter))
          return false;
        if (
          !R.isEmpty(includesFilter) &&
          !value.includes(includesFilter.toLowerCase())
        )
          return false;
        return true;
      });

  /** Returns the property specified by dot.path in an object, lowercased */
  const valueProp = (property: string): any =>
    R.pipe(
      R.path(R.split('.', property)),
      R.defaultTo(''),
      // If the value is a string, trim and lowercase it for comparison purposes
      val => (typeof val === 'string' ? R.toLower(val) : val),
      val => (typeof val === 'string' ? R.trim(val) : val),
    );
  /** Create a sort function for the given property and direction */
  const sortFn = (property: string) =>
    directionFn(sortDirection)(valueProp(property));
  /** Takes a given array of sort-tuples [property, reverse] and returns a list of sort functions  */
  const mapSortFns = R.map(R.apply(sortFn));

  const sortedItems = R.sortWith(mapSortFns(sortHistory))(filteredItems) as T[];

  /** Build list of unique items for a given category **/
  const categoryFilterOptions = (propertyPath: string) =>
    R.pipe(
      R.map(R.pathOr<any>(null, propertyPath.split('.'))),
      R.reject(R.isNil),
      R.map(R.pipe(String, R.trim)),
      R.uniq,
      R.sortBy(R.identity),
    )(unsortedItems);

  function getFiltersForCategory(category: string) {
    return R.pipe(
      R.pathOr<string[]>([], [category]),
      R.defaultTo<string[]>([]),
    )(categoryFilters);
  }

  const handleCategoryFilterChange = (category: string) => (value: string) => {
    const curFilters = getFiltersForCategory(category);

    let nextFilters: string[];
    if (R.contains(value, curFilters)) {
      nextFilters = R.without([value], curFilters);
    } else {
      nextFilters = R.append(value, curFilters);
    }

    setCategoryFilters(R.assoc(category, nextFilters));
  };

  return {
    items: sortedItems,
    onSortChange: handleSortChange,
    filterSearchProps: {
      includesValue: includesFilter,
      startsWithValue: startsWithFilter,
      onIncludesChange: handleIncludesChange,
      onStartsWithChange: handleStartsWithChange,
    },
    sortIndicatorProps: {
      sort: currentSort,
      sortReverse: sortDirection === 'asc',
      onSortChange: handleSortChange,
      categoryFilterOptions,
      getFiltersForCategory,
      onCategoryFilterChange: handleCategoryFilterChange,
    },
  };
}
