import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import classnames from 'classnames';
import { Card } from 'react-bootstrap';

import { Georeference, GeoreferenceDataType } from 'apollo/schema/types';
import {
  GeoreferenceFormValues,
  GeoreferenceLimits,
} from 'utils/modules/georeference';
import GeoreferenceFormFields from './GeoreferenceFormFields';

type ToggleButtonProps = {
  expanded: boolean;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  expanded,
  handleClick,
  disabled = false,
}: ToggleButtonProps) => (
  <button
    type="button"
    className={classnames('btn btn-sm', {
      'btn-primary': !expanded,
      'btn-secondary': expanded,
    })}
    onClick={handleClick}
    disabled={disabled}
  >
    {!expanded ? 'Create' : 'Cancel'}
  </button>
);

type Props = {
  georeferences: Georeference[];
  dataTypes: GeoreferenceDataType[];
  limits?: GeoreferenceLimits;
  disabled?: boolean;
};

const CreateGeoreference: React.FC<Props> = ({
  georeferences,
  dataTypes,
  limits = {},
  disabled = false,
}: Props) => {
  const { setFieldValue, resetForm } = useFormikContext<
    GeoreferenceFormValues
  >();
  const [expanded, setExpanded] = useState(false);

  function toggleExpanded() {
    const nextExpanded = !expanded;

    if (nextExpanded) {
      setFieldValue('dataType', 'CENTER');
    } else {
      resetForm();
    }

    setExpanded(nextExpanded);
  }

  return (
    <>
      {!expanded && georeferences.length === 0 && (
        <Card>
          <Card.Body>
            <p>A georeference has not been created yet on this model.</p>
            {!disabled && (
              <p>Click the Create button to place a model center on the map.</p>
            )}

            <div className="text-center mt-3">
              <ToggleButton
                expanded={expanded}
                handleClick={toggleExpanded}
                disabled={disabled}
              />
            </div>
          </Card.Body>
        </Card>
      )}
      {!expanded && georeferences.length > 0 && (
        <div className="text-right">
          <ToggleButton
            expanded={expanded}
            handleClick={toggleExpanded}
            disabled={disabled}
          />
        </div>
      )}

      {expanded && (
        <Card>
          <Card.Header>Create a georeference</Card.Header>
          <Card.Body>
            <GeoreferenceFormFields
              georeferences={georeferences}
              dataTypes={dataTypes}
              limits={limits}
            />

            <div className="text-center">
              <ToggleButton
                expanded={expanded}
                handleClick={toggleExpanded}
                disabled={disabled}
              />

              <button
                type="submit"
                className="btn btn-primary btn-sm ml-2"
                disabled={disabled}
              >
                Save
              </button>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CreateGeoreference;
