import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';

import { RefetchQueries } from 'apollo/schema/types';
import { UPLOAD_CMS_FILE } from 'apollo/operations/cmsFile';
import FormikField from 'components/common/FormikField';

type FormValues = {
  file: File | null; // Native file type
};

const initialValues = (): FormValues => ({ file: null });

type Props = {
  refetchQueries: RefetchQueries;
};

function UploadFile({ refetchQueries }: Props) {
  const [uploadCmsFile, { loading }] = useMutation(UPLOAD_CMS_FILE, {
    refetchQueries,
  });

  async function handleSubmit(
    { file }: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    try {
      const result = await uploadCmsFile({ variables: { file } });
      console.log('File upload result:', result);
      helpers.resetForm();
    } catch (err) {
      console.log('Error uploading file', err);
    }
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues()}>
      <Form>
        <Field name="file" label="File" component={FormikField} type="file" />

        <div className="text-center">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            Upload
          </button>
        </div>
      </Form>
    </Formik>
  );
}

export default UploadFile;
