import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { LEAVE_ORG } from 'apollo/operations/organization';
import { LeaveOrgData } from 'apollo/schema/operations';
import { useAuth } from 'contexts/auth';
import Confirm from 'components/common/Confirm';

type Props = {
  children: (handleClick: () => void, loading: boolean) => React.ReactNode;
};

const LeaveOrganizationButton: React.FC<Props> = ({ children }: Props) => {
  const [leaveOrganization, { loading }] = useMutation<LeaveOrgData>(LEAVE_ORG);
  const auth = useAuth();

  async function handleLeave() {
    try {
      await leaveOrganization();
      auth.reloadAuthority();
    } catch (err) {
      console.log('Error leaving organization', err);
      toast.error('There was a problem leaving the organization.');
    }
  }

  return (
    <Confirm onConfirm={handleLeave}>
      {handleConfirm => children(handleConfirm, loading)}
    </Confirm>
  );
};

export default LeaveOrganizationButton;
