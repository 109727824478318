export type ModelSearchFormValues = {
  search?: string;
  country?: string[];
  category?: string[];
  subcategory?: string[];
  system?: string[];
};

export const defaultModelSearch = (): ModelSearchFormValues => ({
  search: '',
  country: [],
  category: [],
  subcategory: [],
  system: [],
});
