import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import { cmsPage } from 'routes';

const Footer: React.FC = () => {
  const footerEl = document.getElementById('footerRoot');
  if (!footerEl) return null;

  const footer = (
    <div className="py-3" style={{ backgroundColor: '#f5f5f5' }}>
      <div className="container">
        <Row>
          <Col>
            <span className="text-muted">
              Copyright &copy;{' '}
              <a
                href="http://virtualoutcrop.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                VOG Group
              </a>
              . All rights reserved.
            </span>
          </Col>

          <Col className="text-center">
            <Link to={cmsPage('terms')}>Terms of Use</Link>
          </Col>

          <Col className="text-right">
            <a href="mailto:contact@v3geo.com">Contact Us</a>
          </Col>
        </Row>
      </div>
    </div>
  );

  return ReactDOM.createPortal(footer, footerEl);
};

export default Footer;
