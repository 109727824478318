import { gql } from '@apollo/client';

import * as fragments from 'apollo/fragments';

export const USER_LIST = gql`
  query UserList {
    userList {
      ...userParts
    }
  }
  ${fragments.userParts}
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($user: UpdateProfileInput!) {
    updateProfile(user: $user) {
      ...authorityParts
    }
  }
  ${fragments.authorityParts}
`;

export const USER_INVITE_LIST = gql`
  query UserInviteList {
    userInviteList {
      ...userInviteParts
    }
  }

  ${fragments.userInviteParts}
`;

export const INVITE_USER = gql`
  mutation InviteUser($email: String!) {
    inviteUser(email: $email)
  }
`;
