import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import * as fragments from 'apollo/fragments';
import { Model, ModelShare, RefetchQueries } from 'apollo/schema/types';
import { CLIENT_URL } from 'config/constants';
import { modelRoute } from 'routes';
import Spinner from 'components/common/Spinner';
import CreateModelShare from 'components/modules/modelShare/CreateModelShare';
import DeleteModelShare from 'components/modules/modelShare/DeleteModelShare';
import Confirm from 'components/common/Confirm';
import Tooltip from 'components/common/Tooltip';
import CreateModelPublicLinkModal from 'components/modules/modelShare/CreateModelPublicLinkModal';
import PublicLinkItem from 'components/modules/modelShare/PublicLinkItem';
import HelpBox from 'components/common/HelpBox';

const MODEL_SHARING_TAB = gql`
  query ModelSharingTab($modelId: Int!) {
    model(id: $modelId) {
      ...modelParts
      shares {
        ...modelShareParts
        targetUser {
          ...userParts
        }
        targetUserInvite {
          ...userInviteParts
        }
      }
      publicLinks {
        ...modelPublicLinkParts
      }
    }
  }
  ${fragments.modelParts}
  ${fragments.modelShareParts}
  ${fragments.userParts}
  ${fragments.userInviteParts}
  ${fragments.modelPublicLinkParts}
`;
type QueryData = { model: Model };
type QueryVars = { modelId: number };

type Props = {
  modelId: number;
};

const ModelSharingTab: React.FC<Props> = ({ modelId }: Props) => {
  const [showExpiredLinks, setShowExpiredLinks] = useState<boolean>(false);
  const { data, loading } = useQuery<QueryData, QueryVars>(MODEL_SHARING_TAB, {
    variables: { modelId },
  });

  const refetchQueries: RefetchQueries = [
    { query: MODEL_SHARING_TAB, variables: { modelId } },
  ];

  const model = data?.model;

  function targetEmail(share: ModelShare): string {
    if (share.targetUser) return share.targetUser.email;
    if (share.targetUserInvite) return share.targetUserInvite.targetUserEmail;

    const errMsg =
      'Model share has neither a linked user nor an invite attached.';
    console.log(errMsg, share);
    return '';
  }

  const toggleShowExpiredLinks = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowExpiredLinks(!showExpiredLinks);
  };

  if (loading) return <Spinner />;
  if (!model) return null;

  const shares = model.shares || [];
  const hasShares = shares.length > 0;

  const activeLinks = model.publicLinks.filter(
    link => link.expiresAt === null || moment() < moment(link.expiresAt),
  );
  const expiredLinks = model.publicLinks.filter(
    link => link.expiresAt && moment() >= moment(link.expiresAt),
  );

  if (!model.isPublished || !model.isPrivate) {
    const modelLink = `${CLIENT_URL}${modelRoute(model.id)}`;

    return (
      <Row className="justify-content-center">
        <Col md={10}>
          <HelpBox>
            {!model.isPublished && (
              <>
                Once your model is published, you can use this page to grant
                access to view your model.
              </>
            )}

            {model.isPublished && !model.isPrivate && (
              <>
                <div>
                  This model is public, so it will automatically appear in
                  search results or by using the direct link:
                </div>
                <div className="text-center">
                  <a
                    href={modelLink}
                    target="_blank"
                    className="btn btn-sm btn-secondary"
                  >
                    {model.name} <FontAwesomeIcon icon="external-link-alt" />
                  </a>
                </div>
              </>
            )}
          </HelpBox>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col md={8}>
          <Card border={hasShares ? 'info' : ''}>
            <Card.Header className={hasShares ? 'bg-info text-light' : ''}>
              <div className="float-right">
                <CreateModelShare
                  modelId={model.id}
                  refetchQueries={refetchQueries}
                >
                  {onClick => (
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      onClick={onClick}
                      disabled={!model.isPrivate}
                    >
                      <FontAwesomeIcon icon="user-friends" /> Share Model
                    </button>
                  )}
                </CreateModelShare>
              </div>
              Shared With
            </Card.Header>
            <Card.Body>
              {hasShares && (
                <p>
                  Users with the following email addresses will be able to view
                  this model:
                </p>
              )}

              <table className="table">
                <thead>
                  <tr>
                    <td>Email</td>
                    <td className="text-center">
                      <Tooltip overlay="Indicates whether the user is allowed to create interpretations on this model">
                        <span>Interpretations</span>
                      </Tooltip>
                    </td>
                    <td />
                  </tr>
                </thead>

                <tbody>
                  {!hasShares && (
                    <tr>
                      <td colSpan={2} className="text-center text-muted">
                        <i>This model has not been shared with any users.</i>
                      </td>
                    </tr>
                  )}

                  {shares.map(share => (
                    <tr key={share.id}>
                      <td className="align-middle">{targetEmail(share)}</td>
                      <td className="align-middle text-center">
                        {share.allowInterpretationCreate ? (
                          <>
                            <FontAwesomeIcon icon="american-sign-language-interpreting" />{' '}
                            Allowed
                          </>
                        ) : (
                          '--'
                        )}
                      </td>
                      <td className="text-right">
                        <DeleteModelShare
                          shareId={share.id}
                          refetchQueries={refetchQueries}
                        >
                          {deleteShare => (
                            <Confirm
                              onConfirm={deleteShare}
                              text="This user's access to this model will be revoked."
                            >
                              {confirmDelete => (
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm"
                                  onClick={confirmDelete}
                                >
                                  <FontAwesomeIcon icon="trash" /> Revoke
                                </button>
                              )}
                            </Confirm>
                          )}
                        </DeleteModelShare>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card border={activeLinks.length > 0 ? 'info' : ''}>
            <Card.Header
              className={activeLinks.length > 0 ? 'bg-info text-light' : ''}
            >
              Shareable Links
              <CreateModelPublicLinkModal
                modelId={model.id}
                refetchQueries={refetchQueries}
              >
                {showCreateLinkModal => (
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm float-right"
                    disabled={!model.isPublished || !model.isPrivate}
                    onClick={showCreateLinkModal}
                  >
                    <FontAwesomeIcon icon="link" /> Create
                  </button>
                )}
              </CreateModelPublicLinkModal>
            </Card.Header>
            <Card.Body className="text-wrap">
              <p>
                You can use shareable links to share your model with others,
                even those who do not have a V3Geo account.
              </p>

              <div className="text-center mt-2"></div>

              {activeLinks.length > 0 && <h4 className="mt-3">Active Links</h4>}
              {activeLinks.map(link => (
                <PublicLinkItem
                  key={link.id}
                  link={link}
                  refetchQueries={refetchQueries}
                />
              ))}

              {expiredLinks.length > 0 && (
                <>
                  <h4 className="mt-3">
                    <a href="#toggle" onClick={toggleShowExpiredLinks}>
                      Expired Links
                      <FontAwesomeIcon
                        icon={
                          showExpiredLinks ? 'chevron-down' : 'chevron-right'
                        }
                        className="ml-2 text-muted"
                        style={{ fontSize: '12pt' }}
                      />
                    </a>
                  </h4>

                  {showExpiredLinks &&
                    expiredLinks.map(link => (
                      <PublicLinkItem
                        key={link.id}
                        link={link}
                        refetchQueries={refetchQueries}
                      />
                    ))}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ModelSharingTab;
