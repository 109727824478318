import React from 'react';
import { ToastContainer as ToastifyContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContainer: React.FC = () => (
  <ToastifyContainer
    position={toast.POSITION.TOP_CENTER}
    autoClose={5000}
    draggable={false}
  />
);

export default ToastContainer;
