import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import StandardLayout from 'components/layouts/StandardLayout';
import NotFound from 'components/errors/NotFound';

function NotFoundPage() {
  const match = useRouteMatch();

  return (
    <StandardLayout breadcrumb={[[match.path, 'Not Found']]} hideBreadcrumb>
      <NotFound />
    </StandardLayout>
  );
}

export default NotFoundPage;
