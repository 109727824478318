import React, { HTMLProps } from 'react';
import * as R from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { CmsFile } from 'apollo/schema/types';
import { copyToClipboard } from 'utils/clipboard';
import { APP_ENV, CLIENT_URL } from 'config/constants';

const isImage = (file: CmsFile): boolean =>
  R.pipe(
    R.pathOr<string>('', ['key']),
    R.split('.'),
    R.last,
    R.toLower,
    R.contains(R.__, ['jpg', 'jpeg', 'png', 'gif', 'svg']),
  )(file);

const baseUrl = APP_ENV === 'production' ? CLIENT_URL : 'https://dev.v3geo.com';
const downloadLink = (file: CmsFile): string => `${baseUrl}/${file.key}`;

const ThumbnailContainer = styled.div`
  margin: 0 auto;
  width: 80px;
  height: 80px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

type DownloadLinkProps = HTMLProps<HTMLAnchorElement> & {
  file: CmsFile;
  children: React.ReactNode;
};

const DownloadLink: React.FC<DownloadLinkProps> = ({
  file,
  children,
  ...props
}: DownloadLinkProps) => {
  const url = downloadLink(file);

  return (
    <a {...props} href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

type Props = {
  file: CmsFile;
};

const FilePreview: React.FC<Props> = ({ file }: Props) => {
  function handleCopy(event: React.MouseEvent<HTMLButtonElement>) {
    const target = event.target as HTMLButtonElement;
    copyToClipboard(downloadLink(file));
    target.focus();

    toast.info('URL copied to clipboard.');
  }

  return (
    <>
      <DownloadLink file={file}>
        {isImage(file) ? (
          <ThumbnailContainer>
            <img
              src={downloadLink(file)}
              className="img-thumbnail"
              alt="file preview"
            />
          </ThumbnailContainer>
        ) : (
          <FontAwesomeIcon
            icon="file-download"
            className="img-thumbnail text-secondary"
            style={{ fontSize: '64pt' }}
          />
        )}
      </DownloadLink>
      <div>
        <button type="button" className="btn btn-link" onClick={handleCopy}>
          Copy URL
        </button>
      </div>
    </>
  );
};

export default FilePreview;
