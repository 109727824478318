import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import classnames from 'classnames';

const NavLink = ({
  'aria-current': ariaCurrent = 'page',
  activeClassName = 'active',
  activeStyle = null,
  className: classNameProp = null,
  exact = false,
  isActive: isActiveProp = null,
  location = null,
  strict = false,
  style: styleProp = null,
  to,
  children,
  disabled = false,
  ...rest
}) => {
  if (disabled) {
    const isActive = isActiveProp !== null ? isActiveProp() : false;
    return (
      <span
        className={classnames(classNameProp, 'disabled', {
          [activeClassName]: isActive,
        })}
      >
        <a href={to} style={styleProp} onClick={e => e.preventDefault()}>
          {children}
        </a>
      </span>
    );
  }

  return (
    <Route
      path={typeof to === 'object' ? to.pathname : to}
      exact={exact}
      strict={strict}
      location={location}
    >
      {({ location, match }) => {
        const isActive = !!(isActiveProp !== null
          ? isActiveProp(match, location)
          : match);

        const className = isActive
          ? classnames(classNameProp, activeClassName)
          : classNameProp;
        const style = isActive ? { ...styleProp, ...activeStyle } : styleProp;

        return (
          <Link
            aria-current={(isActive && ariaCurrent) || null}
            className={className}
            style={style}
            to={to}
            {...rest}
          >
            {children}
          </Link>
        );
      }}
    </Route>
  );
};

const ariaCurrentType = PropTypes.oneOf([
  'page',
  'step',
  'location',
  'date',
  'time',
  'true',
]);

NavLink.propTypes = {
  'aria-current': ariaCurrentType,
  activeClassName: PropTypes.string,
  activeStyle: PropTypes.object,
  className: PropTypes.string,
  exact: PropTypes.bool,
  isActive: PropTypes.func,
  location: PropTypes.object,
  strict: PropTypes.bool,
  style: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default NavLink;
