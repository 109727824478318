import React from 'react';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';

import StandardLayout from 'components/layouts/StandardLayout';
import RecentNewsPage from './RecentNewsPage';
import NewsArticlePage from './NewsArticlePage';
import { newsRoute } from 'routes';
import NewsOverview from 'components/modules/news/NewsOverview';

const NewsPage: React.FC = () => {
  const match = useRouteMatch();

  return (
    <StandardLayout breadcrumb={[[match.path, 'News']]} hideBreadcrumb>
      <div className="row">
        <div className="col-sm-3">
          <h1>News</h1>
          <Link to={newsRoute()}>Recent News</Link>

          <NewsOverview />
        </div>

        <div className="col-sm-9">
          <Switch>
            <Route path={match.path} exact component={RecentNewsPage} />
            <Route
              path={`${match.path}/:slug`}
              exact
              component={NewsArticlePage}
            />
          </Switch>
        </div>
      </div>
    </StandardLayout>
  );
};

export default NewsPage;
