import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { cmsPage } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import PageLoader, {
  PageContentContainer,
} from 'components/modules/page/PageLoader';
import LinkBox, { LinkBoxA } from 'components/common/LinkBox';

function GuidelinesPage() {
  const match = useRouteMatch();

  return (
    <PageLoader
      slug="3D-modelling-how-to"
      render={page => (
        <StandardLayout breadcrumb={[[match.path, page.title]]} hideBreadcrumb>
          <h1>{page.title}</h1>
          <Row>
            <Col md={9}>
              <PageContentContainer
                dangerouslySetInnerHTML={{ __html: page.content }}
              />
            </Col>

            <Col md={3}>
              <LinkBoxA
                header="V3Geo paper"
                href="https://gc.copernicus.org/preprints/gc-2021-30/"
                target="_blank"
              />

              <LinkBoxA
                header="Acquisition guidelines"
                href="https://doi.org/10.31223/X54914"
                target="_blank"
              >
                (Howell et al. on EarthArXiv)
              </LinkBoxA>

              <LinkBox
                header="Preparing and formatting a 3D model for V3Geo"
                to={cmsPage('3dmodel')}
              />
            </Col>
          </Row>
        </StandardLayout>
      )}
    />
  );
}

export default GuidelinesPage;
