import * as R from 'ramda';
import { useFormikContext } from 'formik';

import PropTypes from 'prop-types';
import { ucwords } from 'utils/text';

/**
 * Convert an error code to a nice error message. Falls back to just swapping underscores for spaces.
 * @param {String} error The error message to be formatted
 * @returns {String} Formatted message
 */
export function formatError(error) {
  switch (error) {
    case 'email_suffix_not_in_company_list':
      return 'The provided email domain is not authorized by your company.';

    default:
      return R.pipe(
        R.defaultTo('Value is invalid.'),
        R.replace('_', ' '),
      )(error);
  }
}

/**
 * Recursively walk the errors object until an array of messages is found. The last key will be used for the field name.
 * @param {Object} errors Errors object to be parsed
 * @param {Array<String>} parentFields Not set by initial caller - array containing the accumulated path of field names
 * @returns {JSX} Renderable markup
 */
export const formatErrors = (errors, parentFields = []) => {
  return Object.keys(errors).map(field => {
    if (errors[field] instanceof Array || typeof errors[field] === 'string') {
      return (
        <li key={field}>
          <b>
            {parentFields.length
              ? // Just use the fieldname and ignore the rest of the path. Uncomment to add the whole path to the string.
                '' // ? parentFields.map(f => formatFieldName(f)).join(' ') + ' - '
              : ''}
            {ucwords(field)}:
          </b>{' '}
          {errors[field] instanceof Array &&
            errors[field].map(formatError).join(' ')}
          {typeof errors[field] === 'string' && <>{errors[field]}</>}
        </li>
      );
    } else {
      return formatErrors(errors[field], [...parentFields, field]);
    }
  });
};

function FormikErrors({ graphQLError, containerProps = {} }) {
  const isEmptyOrNil = R.anyPass([R.isEmpty, R.isNil]);
  const { submitCount, errors } = useFormikContext();

  const hasBeenSubmitted = submitCount > 0;
  const hasErrors = Object.keys(errors).length > 0;

  if (!hasBeenSubmitted || (!hasErrors && isEmptyOrNil(graphQLError))) {
    return null;
  }

  const statusCode = R.path(['networkError', 'statusCode'], errors);
  const errorType = R.path(['graphQLErrors', 0, 'message'], errors);
  const graphQLErrors = R.path(['graphQLErrors', 0, 'errors'], errors);
  // If all of these are nil, it's probably not an error from graphql
  // This check allows for a formik error object to be passed in in addition to
  // graphql errors from the API.
  const isGraphQLLikeError = R.any(R.complement(R.isNil), [
    statusCode,
    errorType,
    graphQLErrors,
  ]);
  const isValidationError =
    R.equals('validation_error', errorType) ||
    (!isGraphQLLikeError && !isEmptyOrNil(errors));

  let errorMessage;
  if (isValidationError) {
    errorMessage = 'There was a problem in the data submitted.';
  } else if (statusCode === 400) {
    errorMessage =
      'One or more required fields is missing data. Please try again.';
  } else {
    errorMessage = R.pathOr(
      'There was a problem processing your request.',
      ['networkError', 'result', 'errors', 0, 'message'],
      errors,
    );
  }

  // This can probably be de-duped with some of the logic above, this is getting messy
  const exception = R.path(['graphQLErrors', 0, 'extensions'], graphQLError);

  console.group('FORMIK_ERRORS');
  console.log('Exception:', exception);
  console.groupEnd();
  // Check for unique violation constraints
  if (exception?.response?.message === 'unique_constraint_violation') {
    errorMessage =
      exception?.response?.error ??
      'The required unique fields must contain a value that does not already exist.';
  }

  // console.log('graphqlErrors:', JSON.parse(JSON.stringify(graphQLError)));
  // console.log('isValidationError:', isValidationError);
  // console.log('Formik errors obj:', errors);

  return (
    <div className="alert alert-danger" {...containerProps}>
      <h4>Error</h4>
      <p>{errorMessage}</p>
      <ul>{formatErrors(errors)}</ul>
    </div>
  );
}

FormikErrors.propTypes = {
  graphQLError: PropTypes.object,
  containerProps: PropTypes.object,
};

FormikErrors.defaultProps = {
  graphQLError: null,
};

export default FormikErrors;
