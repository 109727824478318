import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import {
  DeleteModelFilesData,
  DeleteModelFilesVars,
} from 'apollo/schema/operations';
import { RefetchQueries } from 'apollo/schema/types';

const DELETE_MODEL_FILES = gql`
  mutation DeleteModelFiles($modelId: Int!) {
    deleteModelFiles(modelId: $modelId) {
      filesDeleted
    }
  }
`;

type Props = {
  children: (
    handleDelete: () => Promise<void>,
    loading: boolean,
  ) => JSX.Element;
  modelId: number;
  refetchQueries: RefetchQueries;
};

function DeleteModelFiles({
  children,
  modelId,
  refetchQueries,
}: Props): JSX.Element {
  const [deleteFiles, { loading }] = useMutation<
    DeleteModelFilesData,
    DeleteModelFilesVars
  >(DELETE_MODEL_FILES, { variables: { modelId }, refetchQueries });

  async function handleDelete() {
    try {
      const res = await deleteFiles();
      if (res.data) {
        toast.success(
          `${res.data.deleteModelFiles.filesDeleted} files successfully deleted.`,
        );
      }
    } catch (err) {
      console.log('Error deleting model files', err);
      toast.error(
        'There was a problem deleting the files. Check the console for more information.',
      );
    }
  }

  return children(handleDelete, loading);
}

export default DeleteModelFiles;
