import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import StandardLayout from 'components/layouts/StandardLayout';

function ForbiddenPage() {
  const match = useRouteMatch();

  return (
    <StandardLayout breadcrumb={[[match.path, 'Not Allowed']]} hideBreadcrumb>
      <h1>Not Allowed</h1>
      <p>Your account does not have permission to view this page.</p>
    </StandardLayout>
  );
}

export default ForbiddenPage;
