import { gql } from '@apollo/client';

export const CMS_FILE_LIST = gql`
  query CmsFileList {
    cmsFileList {
      contents {
        key
        lastModified
        size
      }
      keyCount
      isTruncated
      nextContinuationToken
    }
  }
`;

export const UPLOAD_CMS_FILE = gql`
  mutation UploadCmsFile($file: Upload!) {
    uploadCmsFile(file: $file)
  }
`;

export const DELETE_CMS_FILE = gql`
  mutation DeleteCmsFile($filename: String!) {
    deleteCmsFile(filename: $filename)
  }
`;
