import styled from 'styled-components';

const GreyBox = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  border-left: 5px solid #dddddd;
  padding: 14px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  .greybox-title {
    padding: 0;
    font-size: 18pt;
    margin-bottom: 8px;
  }
`;

export default GreyBox;
