import { useSafeState } from './state';

export function useModalState() {
  const [show, setShow] = useSafeState(false);

  const showModal = (event?: React.MouseEvent) => {
    if (event) event.preventDefault();
    setShow(true);
  };

  const hideModal = (event?: React.MouseEvent) => {
    if (event) event.preventDefault();
    setShow(false);
  };

  return { show, showModal, hideModal };
}
