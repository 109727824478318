import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { UPLOAD_MODEL_IMAGE } from 'apollo/operations/modelImage';
import { RefetchQueries } from 'apollo/schema/types';
import {
  UploadModelImageData,
  UploadModelImageVars,
} from 'apollo/schema/operations';

type Props = {
  modelId: number;
  refetchQueries: RefetchQueries;
  disabled: boolean;
};

function ModelImagesTab({ modelId, refetchQueries, disabled }: Props) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const [uploadModelImage, { loading }] = useMutation<
    UploadModelImageData,
    UploadModelImageVars
  >(UPLOAD_MODEL_IMAGE, {
    refetchQueries,
  });

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (!file) {
      window.alert('Please select a file to upload.');
      return;
    }

    // Note: We have access to some metadata set by the file that could be sent
    // to the API to check against upload quotas. At the moment the user is
    // only limited by a set number of images per model.

    // const fileProp = p => R.prop(p, file);
    // const fileMeta = {
    //   fileName: fileProp('name'),
    //   fileSize: fileProp('size'),
    //   fileType: fileProp('type'),
    //   fileLastModifiedDate: fileProp('lastModifiedDate'),
    // };

    try {
      await uploadModelImage({
        variables: {
          id: modelId,
          file,
          modelImage: {}, // TODO caption/order; contents can be empty for now
        },
      });
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      toast.success('Image uploaded successfully.');
    } catch (err) {
      let errorMessage = 'There was a problem uploading the image.';
      if (err.message === 'image_limit_reached') {
        errorMessage =
          'You have already uploaded the maximum number of images for this model.';
      }

      console.log('Error initializing image upload', err);
      toast.error(errorMessage);
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files?.[0]) {
      setFile(files[0]);
    } else {
      setFile(null);
    }
  }

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <div className="form-group">
        <label htmlFor="file">Select a file:</label>
        <input
          type="file"
          name="file"
          id="file"
          ref={fileInputRef}
          onChange={handleChange}
          className="form-control-file"
          disabled={disabled}
        />
      </div>

      <div className="text-center">
        <button
          type="submit"
          disabled={disabled || loading}
          className="btn btn-primary btn-sm"
        >
          Upload
        </button>
      </div>

      {loading && <p>Initializing file upload...</p>}
    </form>
  );
}

export default ModelImagesTab;
