import { News } from 'apollo/schema/types';
import { yup } from 'utils/validation';

export type NewsFormValues = {
  slug: string;
  title: string;
  introduction: string;
  content: string;
};

export function defaultNews(news?: News): NewsFormValues {
  return {
    slug: news?.slug || '',
    title: news?.title || '',
    introduction: news?.introduction || '',
    content: news?.content || '',
  };
}

export const validationSchema = yup.object({
  slug: yup
    .string()
    .required()
    .matches(/^[a-zA-Z\d-]+[a-zA-Z\d]$/),
  title: yup.string().required(),
  introduction: yup.string().required(),
  content: yup.string().nullable(),
});
