import React from 'react';
import { useQuery, gql } from '@apollo/client';
import * as R from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Model, ModelInterpretation } from 'apollo/schema/types';
import * as fragments from 'apollo/fragments';
import Spinner from 'components/common/Spinner';
import SetInterpretationPublished from 'components/modules/model/SetInterpretationPublished';

const MODEL_INTERPRETATIONS_TAB = gql`
  query ModelInterpretationsTab($modelId: Int!) {
    model(id: $modelId) {
      ...modelParts
      interpretations {
        ...interpretationParts
        createdByPublicProfile {
          ...userPublicProfileParts
        }
      }
    }
  }

  ${fragments.modelParts}
  ${fragments.interpretationParts}
  ${fragments.userPublicProfileParts}
`;
type QueryData = { model: Model };
type QueryVars = { modelId: number };

type Props = {
  modelId: number;
};

const ModelInterpretationsTab: React.FC<Props> = ({ modelId }: Props) => {
  const { data, loading } = useQuery<QueryData, QueryVars>(
    MODEL_INTERPRETATIONS_TAB,
    { variables: { modelId } },
  );

  const interpretations = R.pipe(
    R.pathOr<Model | null>(null, ['model']),
    R.pathOr<ModelInterpretation[]>([], ['interpretations']),
  )(data);

  if (loading) return <Spinner />;

  return (
    <>
      <Spinner show={loading} />

      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Created By</th>
            <th className="text-center">Published</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {!interpretations.length && (
            <tr>
              <td colSpan={5} className="text-center text-muted">
                <i>No interpretations created yet.</i>
              </td>
            </tr>
          )}

          {interpretations.map(interp => (
            <tr key={interp.id}>
              <td>{interp.id}</td>
              <td>{interp.name}</td>
              <td>{interp.createdByPublicProfile.name}</td>
              <td className="text-center">
                {interp.isPublished ? (
                  <span className="text-success">
                    <FontAwesomeIcon icon="check" /> Published
                  </span>
                ) : (
                  '--'
                )}
              </td>
              <td className="text-right">
                <SetInterpretationPublished interpretation={interp} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ModelInterpretationsTab;
