import { gql } from '@apollo/client';

import * as fragments from 'apollo/fragments';

export const MODEL_SEARCH = gql`
  query ModelSearch(
    $search: String
    $country: [String]
    $category: [String]
    $subcategory: [String]
    $system: [String]
  ) {
    modelSearch(
      search: $search
      country: $country
      category: $category
      subcategory: $subcategory
      system: $system
    ) {
      model {
        ...modelParts
        georeferences {
          ...georeferenceParts
        }
        shares {
          ...modelShareParts
          sourceUser {
            ...userParts
          }
        }
      }
      country {
        name
        count
      }
      category {
        name
        count
      }
      subcategory {
        name
        count
      }
      system {
        name
        count
      }
    }
  }

  ${fragments.modelParts}
  ${fragments.georeferenceParts}
  ${fragments.modelShareParts}
  ${fragments.userParts}
`;
