import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { Field, useFormikContext } from 'formik';
import FormikField from 'components/common/FormikField';
import { GEOLOGY_TAG_OPTIONS } from 'apollo/operations/geologyTag';
import {
  categories,
  GeologyTagFormValues,
  subcategories,
} from 'utils/modules/geologyTag';
import { sortByArray } from 'utils/data';
import {
  GeologyTagOptionsData,
  GeologyTagOptionsVars,
} from 'apollo/schema/operations';

type Props = {
  disabled: boolean;
};

const GeologyTagFormFields: React.FC<Props> = ({ disabled }: Props) => {
  const { values, setFieldValue } = useFormikContext<GeologyTagFormValues>();
  const { category, subcategory } = values;

  const { data, loading } = useQuery<
    GeologyTagOptionsData,
    GeologyTagOptionsVars
  >(GEOLOGY_TAG_OPTIONS, {
    variables: {
      category,
      // Subcategory is irrelevant to the options; not using it prevents unnecessary querying
      // subcategory
    },
    onCompleted(loadedData) {
      const subcategoryOptions = getOptions('subcategory', loadedData);
      if (!subcategory || !subcategoryOptions.includes(subcategory)) {
        setFieldValue('subcategory', null);
      }
    },
  });

  function sortOptions(field: string, options: string[]): string[] {
    if (field === 'category') {
      return sortByArray(options, categories, 'name');
    }

    if (field === 'subcategory') {
      return sortByArray(options, subcategories, 'name');
    }

    return options;
  }

  function getOptions(field: string, d = data): string[] {
    return R.pipe(
      R.pathOr<string[]>([], ['geologyTagOptions', field]),
      R.defaultTo([]),
    )(d);
  }

  const getSelectOptions = (field: string) =>
    R.pipe(
      getOptions,
      options => sortOptions(field, options),
      R.map(opt => ({ value: opt, label: opt })),
    )(field);

  return (
    <>
      <Field
        name="category"
        label="Category"
        component={FormikField}
        type="select"
        options={getSelectOptions('category')}
        disabled={disabled || loading || !getOptions('category').length}
        required
      />

      <Field
        name="subcategory"
        label="Subcategory"
        component={FormikField}
        type="select"
        options={getSelectOptions('subcategory')}
        disabled={disabled || loading || !getOptions('subcategory').length}
      />
    </>
  );
};

export default GeologyTagFormFields;
