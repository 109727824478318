import { useMutation } from '@apollo/client';
import {
  ACCEPT_ORG_INVITE,
  DECLINE_ORG_INVITE,
} from 'apollo/operations/organization';
import {
  AcceptOrgInviteData,
  AcceptOrgInviteVars,
  DeclineOrgInviteData,
  DeclineOrgInviteVars,
} from 'apollo/schema/operations';
import Confirm from 'components/common/Confirm';
import { useAuth } from 'contexts/auth';
import React from 'react';
import { toast } from 'react-toastify';

type Props = {
  inviteId: number;
  orgName: string;
};

const AcceptRejectOrgInviteButtons: React.FC<Props> = ({
  inviteId,
  orgName,
}: Props) => {
  const auth = useAuth();
  const [acceptOrgInvite, { loading: loadingAccept }] = useMutation<
    AcceptOrgInviteData,
    AcceptOrgInviteVars
  >(ACCEPT_ORG_INVITE, { variables: { inviteId } });
  const [declineOrgInvite, { loading: loadingDecline }] = useMutation<
    DeclineOrgInviteData,
    DeclineOrgInviteVars
  >(DECLINE_ORG_INVITE, { variables: { inviteId } });

  async function handleAccept() {
    try {
      await acceptOrgInvite();
      console.log('Org invitation accepted successfully');
    } catch (err) {
      console.log('Error accepting org invitation', err);
      toast.error(
        'There was a problem accepting the invitation. Please try again.',
      );
      return;
    }

    try {
      console.log('Reloading authority to update UI');
      await auth.reloadAuthority();
    } catch (err) {
      // Something went wrong reloading authority, just log out and back in
      toast.error(
        `You have successfully joined ${orgName}. Please log out and in again for all account changes to take effect.`,
      );
      return;
    }

    toast.success(`You have successfully joined ${orgName}.`);
  }

  async function handleDecline() {
    try {
      await declineOrgInvite();
    } catch (err) {
      console.log('Error declining org invite', err);
      toast.error(
        'There was a problem declining the invitation. Please try again.',
      );
    }
  }

  const loading = loadingAccept || loadingDecline;

  return (
    <>
      <Confirm
        onConfirm={handleAccept}
        title="Accept Organization Invite"
        text={
          <>
            By accepting this invite, you will become a member of{' '}
            <b>{orgName}</b>. You may only be a member of <b>one</b>{' '}
            organization at a time.
          </>
        }
      >
        {onConfirm => (
          <button
            type="button"
            className="btn btn-success btn-sm"
            onClick={onConfirm}
            disabled={loading}
          >
            Accept
          </button>
        )}
      </Confirm>

      <span className="mr-2" />

      <Confirm
        onConfirm={handleDecline}
        title="Decline Organization Invite"
        text={
          <>
            The invitation to join <b>{orgName}</b> will be <b>declined</b>.
          </>
        }
      >
        {onConfirm => (
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={onConfirm}
            disabled={loading}
          >
            Decline
          </button>
        )}
      </Confirm>
    </>
  );
};

export default AcceptRejectOrgInviteButtons;
