import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';

const SpacerEl = styled.div``;

type Props = {
  /** Vertical spacing */
  v?: number | null;
  /** Horizontal spacing */
  h?: number | null;
  unit?: 'px' | 'em' | 'rem';
  style?: React.CSSProperties;
};

const Spacer: React.FC<Props> = ({
  v = null,
  h = null,
  unit = 'px',
  style = {},
  ...props
}: Props) => {
  const spacingStyles: React.CSSProperties = {};
  if (!R.isNil(v)) {
    spacingStyles.marginTop = `${v}${unit}`;
  }

  if (!R.isNil(h)) {
    spacingStyles.marginLeft = `${h}${unit}`;
    spacingStyles.display = 'inline-block';
  }

  return <SpacerEl {...props} style={{ ...style, ...spacingStyles }} />;
};

export default Spacer;
