import { gql } from '@apollo/client';

import * as fragments from 'apollo/fragments';

export const PAGE_LIST = gql`
  query LoadPageList($id: Int, $slugs: [String!]) {
    pageList(id: $id, slugs: $slugs) {
      ...pageParts
    }
  }
  ${fragments.pageParts}
`;

export const CREATE_PAGE = gql`
  mutation CreatePage($page: PageInput!) {
    createPage(page: $page) {
      ...pageParts
    }
  }
  ${fragments.pageParts}
`;

export const UPDATE_PAGE = gql`
  mutation UpdatePage($id: Int!, $page: PageInput!) {
    updatePage(id: $id, page: $page) {
      ...pageParts
    }
  }
  ${fragments.pageParts}
`;
