import React from 'react';
import { useRouteMatch } from 'react-router';
import { gql, useQuery } from '@apollo/client';

import { Organization, Role, User } from 'apollo/schema/types';
import * as routes from 'routes';
import * as fragments from 'apollo/fragments';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';

type HasRoleProps = {
  user: User;
  role: Role;
};

const HasRole: React.FC<HasRoleProps> = ({ user, role }: HasRoleProps) => {
  const hasRole = user.roles.includes(role);
  if (hasRole) {
    return <>✅</>;
  }
  return <>❌</>;
};

const ADMIN_ORG_PAGE = gql`
  query AdminOrgPage($orgId: Int!) {
    organizationList(organizationId: $orgId) {
      ...orgParts
      users {
        ...userParts
      }
    }
  }

  ${fragments.orgParts}
  ${fragments.userParts}
`;
type AdminOrgPageData = { organizationList: Organization[] };
type AdminOrgPageVars = { orgId: number };

type RouteParams = {
  orgId: string;
};

const AdminOrganizationPage: React.FC = () => {
  const match = useRouteMatch<RouteParams>();
  const orgId = parseInt(match.params.orgId);
  const { data, loading } = useQuery<AdminOrgPageData, AdminOrgPageVars>(
    ADMIN_ORG_PAGE,
    { variables: { orgId } },
  );

  const org = (data?.organizationList || []).find(org => org.id === orgId);

  return (
    <StandardLayout
      breadcrumb={[
        [routes.adminRoute(), 'Dashboard'],
        [routes.adminOrgListRoute(), 'Organizations'],
        [match.path, org ? org.name : `Organization ${orgId}`],
      ]}
    >
      <Spinner show={loading} />
      {org && (
        <>
          <h1>{org.name}</h1>
          <p>Created {org.createdAt}</p>

          <h2>Users</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th className="text-center">Admin</th>
                <th className="text-center">Editor</th>
                <th className="text-center">Sharer</th>
              </tr>
            </thead>

            <tbody>
              {!org.users.length && (
                <tr>
                  <td colSpan={5} className="text-center text-muted">
                    No users found.
                  </td>
                </tr>
              )}

              {org.users.map(user => (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td className="text-center">
                    <HasRole user={user} role="organizationAdmin" />
                  </td>
                  <td className="text-center">
                    <HasRole user={user} role="organizationEditor" />
                  </td>
                  <td className="text-center">
                    <HasRole user={user} role="organizationSharer" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </StandardLayout>
  );
};

export default AdminOrganizationPage;
