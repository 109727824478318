import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { getDrawingModes, isMarkerType } from 'utils/map';
import MapEditor from './MapEditor';
import { Georeference, GeoreferenceCoordinate } from 'apollo/schema/types';
import { GeoreferenceFormValues } from 'utils/modules/georeference';

/** Formats a string-coordinate in a format compatible with google maps */
function formatCoordinates(latLngStringPairs: GeoreferenceCoordinate[]) {
  return latLngStringPairs.map(pair => ({
    lat: parseFloat(pair.latitude),
    lng: parseFloat(pair.longitude),
  }));
}

function formatObjectForMap(
  georefs: Georeference[],
  newGeoref: GeoreferenceFormValues,
) {
  const elements = [...georefs];

  if (newGeoref && newGeoref.data) {
    elements.push(newGeoref as Georeference);
  }

  return elements
    .filter(el => el.data.coordinates)
    .map(el => ({
      ...el,
      data: {
        coordinates: formatCoordinates(el.data.coordinates),
      },
    }));
}

type Props = {
  georeferences: Georeference[];
};

function MapEditorContainer({ georeferences }: Props) {
  const { values, setFieldValue } = useFormikContext<GeoreferenceFormValues>();
  const { dataType } = values;

  useEffect(() => {
    // When data type changes, remove any coordinates that have been set
    setFieldValue('data', null);
  }, [dataType, setFieldValue]);

  function handleDrawingComplete(data: GeoreferenceFormValues['data']) {
    console.log('Drawing complete, data:', data);
    if (data && dataType) {
      // Wrap coordinates in an array if it is a single point
      const coordinates = isMarkerType(dataType) ? [data] : data;

      setFieldValue('data', { coordinates });
    } else {
      setFieldValue('data', null);
    }
  }

  const mapFormattedGis = formatObjectForMap(georeferences, values);

  return (
    <MapEditor
      georeferences={mapFormattedGis}
      drawingModes={getDrawingModes(values.dataType)}
      onDrawingComplete={handleDrawingComplete}
    />
  );
}

export default MapEditorContainer;
