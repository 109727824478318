import { library } from '@fortawesome/fontawesome-svg-core';
import { faStickyNote as farStickyNote } from '@fortawesome/free-regular-svg-icons';
import {
  faAmericanSignLanguageInterpreting,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClipboardCheck,
  faCloudUploadAlt,
  faCode,
  faCog,
  faCompressAlt,
  faCopy,
  faCrosshairs,
  faCrown,
  faDollarSign,
  faDownload,
  faEnvelope,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileDownload,
  faFilter,
  faGlobeAmericas,
  faGlobeEurope,
  faKey,
  faLink,
  faLock,
  faLongArrowAltDown,
  faMapMarkerAlt,
  faMinusCircle,
  faPencilAlt,
  faQuestionCircle,
  faShare,
  faShareAlt,
  faSync,
  faTimesCircle,
  faTools,
  faTrash,
  faUser,
  faUserFriends,
  faUserTie,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(
  faUser,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faLongArrowAltDown,
  faPencilAlt,
  faDownload,
  faQuestionCircle,
  faFileDownload,
  faCopy,
  faEnvelope,
  faFilter,
  faCircleNotch,
  faUserTie,
  faTools,
  faShare,
  faCog,
  faCheck,
  faCheckCircle,
  faTimesCircle,
  faCrown,
  faUserFriends,
  faCloudUploadAlt,
  faEyeSlash,
  faTrash,
  faLock,
  faEye,
  faAmericanSignLanguageInterpreting,
  faLink,
  faDollarSign,
  faCopy,
  faExternalLinkAlt,
  faGlobeEurope,
  faGlobeAmericas,
  faMinusCircle,
  faWrench,
  faClipboardCheck,
  faCrosshairs,
  faMapMarkerAlt,
  faCode,
  farStickyNote,
  faExclamationTriangle,
  faShareAlt,
  faCompressAlt,
  faSync,
  faKey,
);
