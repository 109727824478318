import React from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { orgCreateRoute, orgRoute, profileRoute } from 'routes';
import { CREATE_ORG } from 'apollo/operations/organization';
import { CreateOrgData, CreateOrgVars } from 'apollo/schema/operations';
import { yup } from 'utils/validation';
import { useAuth } from 'contexts/auth';
import { setValidationErrors } from 'utils/forms';
import StandardLayout from 'components/layouts/StandardLayout';
import FormikField from 'components/common/FormikField';
import FormikErrors from 'components/common/FormikErrors';

type FormValues = {
  name: string;
};

const initialValues = () => ({ name: '' });
const validationSchema = () => yup.object({ name: yup.string().required() });

const OrganizationCreatePage: React.FC = () => {
  const history = useHistory();
  const { reloadAuthority } = useAuth();
  const [createOrg, { loading, error }] = useMutation<
    CreateOrgData,
    CreateOrgVars
  >(CREATE_ORG);

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    try {
      await createOrg({ variables: { organization: values } });
      toast.success('Organization created successfully');

      // Reload user since they now have an org attached
      await reloadAuthority();
      history.replace(orgRoute());
    } catch (err) {
      setValidationErrors(err, helpers);

      console.log('Error creating org', err);
      if (err?.message === 'user_in_org') {
        toast.error(
          `It looks like you're already in an organization. You'll need to leave it before you can create a new one.`,
        );
      } else {
        toast.error(
          'There was a problem creating the organization. Please try again.',
        );
      }
    }
  }

  return (
    <StandardLayout
      breadcrumb={[
        [profileRoute(), 'My Profile'],
        [orgRoute(), 'Organization'],
        [orgCreateRoute(), 'Create Organization'],
      ]}
    >
      <h1>Create Organization</h1>

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues()}
        validationSchema={validationSchema()}
      >
        <Form>
          <Field
            name="name"
            label="Organization name"
            component={FormikField}
            required
          />

          <FormikErrors graphQLError={error} />

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              Create
            </button>
          </div>
        </Form>
      </Formik>
    </StandardLayout>
  );
};

export default OrganizationCreatePage;
