import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Form, Formik, FormikHelpers } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import * as fragments from 'apollo/fragments';
import {
  ModelOffsetInput,
  UpdateModelOffsetData,
  UpdateModelOffsetVars,
} from 'apollo/schema/operations';
import {
  defaultModelOffset,
  ModelOffsetFormValues,
  modelOffsetValidator,
} from 'utils/modules/model';
import { setValidationErrors } from 'utils/forms';
import FormikErrors from 'components/common/FormikErrors';
import ModelOffsetFormFields from './ModelOffsetFormFields';

const UPDATE_MODEL_OFFSET = gql`
  mutation UpdateModelOffset($modelId: Int!, $offset: ModelOffsetInput!) {
    updateModelOffset(modelId: $modelId, offset: $offset) {
      ...modelParts
      ...modelOffsetParts
    }
  }

  ${fragments.modelParts}
  ${fragments.modelOffsetParts}
`;

type Props = {
  modelId: number;
  offsetX: number | null;
  offsetY: number | null;
  offsetZ: number | null;
  locked: boolean;
};

function UpdateModelOffsetForm({
  modelId,
  offsetX,
  offsetY,
  offsetZ,
  locked,
}: Props) {
  const [updateModelOffset, { loading, error }] = useMutation<
    UpdateModelOffsetData,
    UpdateModelOffsetVars
  >(UPDATE_MODEL_OFFSET);

  async function handleSubmit(
    values: ModelOffsetFormValues,
    helpers: FormikHelpers<ModelOffsetFormValues>,
  ) {
    function floatOrNull(value: string | number | null) {
      if (value === '') return null;
      if (value === null) return null;
      if (typeof value === 'number') return value;
      return parseFloat(value);
    }

    const offset: ModelOffsetInput = {
      offsetX: floatOrNull(values.offsetX),
      offsetY: floatOrNull(values.offsetY),
      offsetZ: floatOrNull(values.offsetZ),
    };

    try {
      await updateModelOffset({ variables: { modelId, offset } });
      toast.success('3D model offsets saved successfully.');
    } catch (err) {
      console.log('Error updating offset', err);
      toast.error(
        'There was a problem saving your offset values. Please check the data entered and try again.',
      );
      setValidationErrors(err, helpers);
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultModelOffset(offsetX, offsetY, offsetZ)}
      validationSchema={modelOffsetValidator()}
    >
      <Form>
        <Row>
          <ModelOffsetFormFields disabled={locked || loading} />
          {!locked && (
            <Col>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={locked || loading}
                style={{ marginTop: '26px' }}
              >
                Save
              </button>
            </Col>
          )}
        </Row>

        <FormikErrors graphQLError={error} />
      </Form>
    </Formik>
  );
}

export default UpdateModelOffsetForm;
