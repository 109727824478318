export const systems = [
  'Quaternary',
  'Neogene',
  'Paleogene',
  'Cretaceous',
  'Jurassic',
  'Triassic',
  'Permian',
  'Carboniferous',
  'Devonian',
  'Silurian',
  'Ordovician',
  'Cambrian',
  'Precambrian',
];
