import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { modelSearchMapRoute, modelSearchListRoute } from 'routes';
import { defaultModelSearch } from 'utils/modules/modelSearch';
import StandardLayout from 'components/layouts/StandardLayout';
import NavLink from 'components/common/NavLink';
import ModelSearchForm from 'components/modules/modelSearch/ModelSearchForm';
import ModelSearchFilters from 'components/modules/modelSearch/ModelSearchForm/SearchFilters';
import ModelSearchList from 'components/modules/modelSearch/ModelSearchList';
import ModelSearchMap from 'components/modules/modelSearch/ModelSearchMap';

function ModelSearchPage() {
  const match = useRouteMatch();

  function handleSubmit() {}

  return (
    <StandardLayout
      breadcrumb={[[match.path, 'Find a 3D Model']]}
      hideBreadcrumb
    >
      <Formik onSubmit={handleSubmit} initialValues={defaultModelSearch()}>
        <Form>
          <ModelSearchForm>
            {({ options, models, loading }) => (
              <>
                <h1 className="float-left">Find a 3D Model</h1>

                <div className="float-right">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <NavLink
                        to={modelSearchMapRoute()}
                        exact
                        className="nav-link"
                      >
                        Map
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={modelSearchListRoute()}
                        exact
                        className="nav-link"
                      >
                        List
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="clearfix" />

                <div className="row">
                  <div className="col-md-3">
                    <ModelSearchFilters options={options} loading={loading} />
                  </div>

                  <div className="col-md-9">
                    {/* <SelectedFilters /> */}

                    <Switch>
                      <Route path={match.path} exact>
                        <ModelSearchMap models={models} />
                      </Route>
                      <Route path={`${match.path}/list`} exact>
                        <ModelSearchList models={models} />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </>
            )}
          </ModelSearchForm>
        </Form>
      </Formik>
    </StandardLayout>
  );
}

export default ModelSearchPage;
