function searchPropDeep(propName: string, obj: any) {
  let props = propName.split('.');

  for (let i = 0; i < props.length; i++) {
    if (!obj) {
      return null;
    }

    if (typeof obj === 'object') {
      obj = obj[props[i]];
    }
  }

  return obj;
}

export function sortByArray<T>(
  unsorted: T[],
  order: string[],
  propertyName: string,
) {
  let sorted = unsorted.slice(0);
  let priorityOrder = order.map(item => item.toLowerCase());

  sorted.sort((a, b) => {
    const aProp =
      propertyName && typeof a === 'object'
        ? searchPropDeep(propertyName, a)
        : a;
    const bProp =
      propertyName && typeof b === 'object'
        ? searchPropDeep(propertyName, b)
        : b;

    const priorityA = priorityOrder.indexOf(aProp.toLowerCase());
    const priorityB = priorityOrder.indexOf(bProp.toLowerCase());

    // If the item is not found in order array, put it at end of list
    if (priorityA === -1) return 1;
    if (priorityB === -1) return -1;

    if (priorityA > priorityB) return 1;
    if (priorityA === priorityB) return 0;
    // if (priorityA < priorityB)
    return -1;
  });

  return sorted;
}
