import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

type Props = Partial<FontAwesomeIconProps>;

const PrivateModelIcon = (props: Props) => (
  <FontAwesomeIcon
    {...props}
    className={`${props.className ?? ''} text-warning`}
    icon="lock"
  />
);

export default PrivateModelIcon;
