import React from 'react';
import { Modal } from 'react-bootstrap';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { Organization, OrganizationPublicProfile } from 'apollo/schema/types';
import { useModalState } from 'hooks/modal';
import {
  OrgFormValues,
  orgInitialFormValues,
  orgValidationSchema,
} from 'utils/modules/organization';
import { UPDATE_ORG } from 'apollo/operations/organization';
import { UpdateOrgData, UpdateOrgVars } from 'apollo/schema/operations';
import { setValidationErrors } from 'utils/forms';
import FormikField from 'components/common/FormikField';
import FormikErrors from 'components/common/FormikErrors';
import { useAuth } from 'contexts/auth';

type Props = {
  org: Organization | OrganizationPublicProfile;
  children: (showModal: () => void) => React.ReactNode;
};

const EditNameModal: React.FC<Props> = ({ org, children }: Props) => {
  const { authority, reloadAuthority } = useAuth();
  const { show, showModal, hideModal } = useModalState();
  const [updateOrg, { loading, error }] = useMutation<
    UpdateOrgData,
    UpdateOrgVars
  >(UPDATE_ORG);

  async function handleSubmit(
    values: OrgFormValues,
    helpers: FormikHelpers<OrgFormValues>,
  ) {
    try {
      await updateOrg({
        variables: {
          orgId: org.id,
          org: values,
        },
      });
      // If user is editing their own org, reload authority to get updated name
      if (authority?.metadata.organization?.id === org.id) {
        await reloadAuthority();
      }
      toast.success('Organization saved successfully.');
      hideModal();
    } catch (err) {
      setValidationErrors(err, helpers);
      toast.error(
        'There was a problem saving the organization. Please try again.',
      );
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Organization</Modal.Title>
        </Modal.Header>

        <Formik
          onSubmit={handleSubmit}
          initialValues={orgInitialFormValues(org)}
          validationSchema={orgValidationSchema}
        >
          <Form>
            <Modal.Body>
              <Field
                name="name"
                label="Name"
                component={FormikField}
                required
              />

              <FormikErrors graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default EditNameModal;
