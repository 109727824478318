import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { ACCEPT_ORG_REQUEST } from 'apollo/operations/organization';
import {
  AcceptOrgRequestData,
  AcceptOrgRequestVars,
} from 'apollo/schema/operations';
import Confirm from 'components/common/Confirm';

type Props = {
  children: (acceptRequest: () => void) => React.ReactNode;
  requestId: number;
};

const AcceptRequest: React.FC<Props> = ({ children, requestId }: Props) => {
  const [acceptRequest] = useMutation<
    AcceptOrgRequestData,
    AcceptOrgRequestVars
  >(ACCEPT_ORG_REQUEST, { variables: { requestId } });

  async function handleAccept() {
    try {
      await acceptRequest();
      toast.success('Request accepted.');
    } catch (err) {
      console.log('Error accepting org request', err);
      toast.error(
        'There was a problem accepting the organization request. Please try again.',
      );
    }
  }

  return (
    <Confirm
      onConfirm={handleAccept}
      text="This user will be added to your organization."
    >
      {onConfirm => children(onConfirm)}
    </Confirm>
  );
};

export default AcceptRequest;
