import React from 'react';
import styled from 'styled-components';

import { Page } from 'apollo/schema/types';
import { adminPageRoute } from 'routes';
import NavLink from 'components/common/NavLink';

const PageNameLabel = styled.div`
  font-size: 12pt;
  font-weight: bold;
`;

const PageTitleLabel = styled.div`
  font-size: 9pt;
  font-weight: normal;
  color: #999999;
`;

type Props = {
  pageList: Page[];
};

const PageList: React.FC<Props> = ({ pageList }: Props) => {
  return (
    <ul className="nav nav-pills">
      {pageList.map(page => (
        <li key={page.id} className="nav-item" style={{ width: '100%' }}>
          <NavLink to={adminPageRoute(page.id)} className="nav-link">
            <PageNameLabel>{page.slug}</PageNameLabel>
            <PageTitleLabel>{page.title}</PageTitleLabel>
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default PageList;
