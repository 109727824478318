import React from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import { ErrorMessage, FieldProps } from 'formik';

import FormLabel from './FormLabel';
import { SelectOption } from './FieldTypes';

type OptionWrapperProps = { inline?: boolean; children: React.ReactNode };
const OptionWrapper = ({ inline = false, children }: OptionWrapperProps) =>
  inline ? <>{children}</> : <div className="radio">{children}</div>;

type Props = FieldProps &
  React.HTMLProps<HTMLInputElement> & {
    helpText?: React.ReactNode;
    options: SelectOption[];
    inline?: boolean;
    /** Allows the default onChange behavior to be intercepted.
     * The default change handler is passed as the second argument where
     * a new (or the original) value can be passed in */
    onChange?: (value: any, defaultOnChange?: (newValue: any) => void) => void;
  };

function FormikRadioField({
  form,
  field,
  className,
  label,
  helpText,
  options,
  inline = false,
  required = false,
  disabled = false,
  onChange,
  ...props
}: Props) {
  const handleChange = (value: any) => () => {
    const defaultOnChange = (newVal: any) => {
      form.setFieldValue(field.name, newVal);
    };

    if (onChange) {
      onChange(value, defaultOnChange);
    } else {
      defaultOnChange(value);
    }
  };

  const fieldPath = field.name.split('.');
  const currentValue = R.path(fieldPath, form.values);

  return (
    <>
      <div>
        <FormLabel
          name={field.name}
          label={label}
          helpText={helpText}
          required={required}
        />
      </div>

      {options.map((option, i) => (
        <OptionWrapper key={i} inline={inline}>
          <label className={classnames({ 'radio-inline mr-3': inline })}>
            <input
              {...field}
              {...props}
              type="radio"
              value={option.value}
              onChange={handleChange(option.value)}
              checked={option.value === currentValue}
              disabled={(disabled || option.disabled) ?? false}
            />{' '}
            {option.text}
          </label>
        </OptionWrapper>
      ))}

      <div className="text-danger">
        <ErrorMessage name={field.name} />
      </div>
    </>
  );
}

export default FormikRadioField;
