import React, { useCallback, useEffect } from 'react';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';

import * as fragments from 'apollo/fragments';
import { ModelData, ModelVars } from 'apollo/schema/operations';
import { RefetchQueries } from 'apollo/schema/types';
import { cmsPage, modelEditTechnicalReviewTabRoute } from 'routes';
import { useImperativeQuery } from 'hooks/apollo';
import Spinner from 'components/common/Spinner';
import NotFound from 'components/errors/NotFound';
import UserFileManager from 'components/modules/model/UserFileManager';
import Tooltip from 'components/common/Tooltip';
import UpdateModelOffsetForm from 'components/modules/model/UpdateModelOffsetForm';
import HelpBox from 'components/common/HelpBox';

const MODEL_FILES_TAB = gql`
  query ModelFilesTab($id: Int!) {
    model(id: $id) {
      ...modelParts
      ...modelOffsetParts
      userFiles {
        ...modelUserFileParts
        file {
          ...fileParts
        }
      }
    }
  }

  ${fragments.modelParts}
  ${fragments.modelOffsetParts}
  ${fragments.modelUserFileParts}
  ${fragments.fileParts}
`;

type Props = {
  modelId: number;
  locked: boolean;
};

function Model3DFilesTab({ modelId, locked }: Props) {
  const [loadModelFiles, { data, loading }] = useImperativeQuery<
    ModelData,
    ModelVars
  >(MODEL_FILES_TAB, { variables: { id: modelId } });
  const refetchQueries: RefetchQueries = [
    { query: MODEL_FILES_TAB, variables: { id: modelId } },
  ];

  const loadFiles = useCallback(async () => {
    loadModelFiles();
  }, [modelId]);

  useEffect(() => {
    loadFiles();
  }, []);

  const model = data?.model;
  const mufs = model?.userFiles ?? [];

  if (loading) return <Spinner />;
  if (!model) return <NotFound />;

  return (
    <>
      <Card>
        <Card.Body>
          <UserFileManager
            modelId={model.id}
            mufs={mufs}
            locked={locked}
            isPrivate={model.isPrivate}
            reloadFiles={loadFiles}
            refetchQueries={refetchQueries}
          />
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Card.Title>Model Offsets</Card.Title>

          <HelpBox className="mb-3">
            Please specify UTM coordinate offsets for your georeferenced model
            (leave blank if your model is not topographic, i.e. represents a
            hand sample, fossil, etc.). Full details can be found in{' '}
            <Link to={`${cmsPage('3dmodel')}#location`} target="_blank">
              the guidelines
            </Link>
            .
          </HelpBox>

          <UpdateModelOffsetForm
            modelId={model.id}
            offsetX={model.offsetX}
            offsetY={model.offsetY}
            offsetZ={model.offsetZ}
            locked={locked}
          />
        </Card.Body>
      </Card>

      {!model.isPublished && (
        <div className="mt-3 text-right">
          <Tooltip overlay="Continue to technical review">
            <Link
              to={modelEditTechnicalReviewTabRoute(model.id)}
              className="btn btn-primary"
            >
              Next
              <FontAwesomeIcon icon="chevron-right" className="ml-2" />
            </Link>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default Model3DFilesTab;
