import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Card, Modal } from 'react-bootstrap';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import { ModelShare, RefetchQueries } from 'apollo/schema/types';
import * as fragments from 'apollo/fragments';
import { useModalState } from 'hooks/modal';
import { yup } from 'utils/validation';
import FormikField from 'components/common/FormikField';
import FormikErrors from 'components/common/FormikErrors';
import RoleSecured from 'components/common/RoleSecured';

const CREATE_SHARE = gql`
  mutation CreateModelShare(
    $modelId: Int!
    $email: String!
    $allowInterpretationCreate: Boolean!
  ) {
    createModelShare(
      modelId: $modelId
      email: $email
      allowInterpretationCreate: $allowInterpretationCreate
    ) {
      ...modelShareParts
    }
  }

  ${fragments.modelShareParts}
`;
type MutationData = {
  createModelShare: ModelShare;
};
type MutationVars = {
  modelId: number;
  email: string;
  allowInterpretationCreate: boolean;
};

type FormValues = {
  email: string;
  allowInterpretationCreate: boolean | null;
};

const initialValues = (): FormValues => ({
  email: '',
  allowInterpretationCreate: false,
});

const validationSchema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  allowInterpretationCreate: yup.boolean().required(),
});

type Props = {
  children: (onClick: () => void) => React.ReactChild;
  modelId: number;
  refetchQueries: RefetchQueries;
};

const CreateModelShare: React.FC<Props> = ({
  children,
  modelId,
  refetchQueries,
}: Props) => {
  const { show, showModal, hideModal } = useModalState();
  const [createShare, { loading, error }] = useMutation<
    MutationData,
    MutationVars
  >(CREATE_SHARE, { refetchQueries });

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    try {
      await createShare({
        variables: {
          modelId,
          email: values.email,
          allowInterpretationCreate: values.allowInterpretationCreate || false,
        },
      });

      hideModal();
      helpers.resetForm();
      toast.success('The model was shared successfully.');
    } catch (err) {
      console.log('Error creating model share', err);
      let toastMsg = 'There was a problem sharing the model.';
      if (err.message === 'model_already_shared_with_user') {
        toastMsg = `You've already shared this model with that email address.`;
      }
      toast.error(toastMsg);
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Share Model</Modal.Title>
        </Modal.Header>

        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues()}
          validationSchema={validationSchema}
        >
          <Form>
            <Modal.Body>
              <p>
                Enter the email address of the user you would like to share this
                model with.
              </p>
              <p>
                If they don't already have a V3Geo account, they will receive
                instructions on how to create one.
              </p>

              <Field
                name="email"
                label="Email address"
                component={FormikField}
                type="email"
                required
                renderInput={(input: JSX.Element) => (
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon="envelope" />
                      </span>
                    </div>
                    {input}
                  </div>
                )}
              />

              <RoleSecured roles={['admin']}>
                <Card className="mb-2">
                  <Card.Body>
                    <Field
                      name="allowInterpretationCreate"
                      label="Interpretations"
                      component={FormikField}
                      type="radio"
                      required
                      options={[
                        {
                          value: true,
                          text: (
                            <>
                              Allowed
                              <div className="ml-3 text-muted">
                                This user will be allowed to create
                                interpretations on this model. You'll still be
                                able to decide which interpretations are
                                published.
                              </div>
                            </>
                          ),
                        },
                        {
                          value: false,
                          text: (
                            <>
                              Not Allowed
                              <div className="ml-3 text-muted">
                                This user will <b>not</b> be allowed to create
                                interpretations on this model.
                              </div>
                            </>
                          ),
                        },
                      ]}
                    />
                  </Card.Body>
                </Card>
              </RoleSecured>

              <FormikErrors graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                disabled={loading}
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default CreateModelShare;
