import React from 'react';
import * as R from 'ramda';
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const filters = ['category', 'subcategory', 'system', 'country'];

function SelectedFilters() {
  const { values, setFieldValue } = useFormikContext();

  const getFilterValues = filter =>
    R.pipe(R.propOr(null, filter), R.defaultTo([]))(values);

  const handleClick = filter => value => () => {
    const values = getFilterValues(filter);
    const nextValues = R.without([value], values);
    setFieldValue(filter, nextValues);
  };

  const clearSearch = () => setFieldValue('search', '');

  const hasSearch = R.allPass([R.complement(R.isEmpty), R.complement(R.isNil)])(
    values.search,
  );
  const hasAnyFilters = R.pipe(
    R.map(getFilterValues),
    R.any(R.complement(R.isEmpty)),
  )(filters);

  if (!hasSearch && !hasAnyFilters) return null;

  return (
    <div className="mb-2">
      {values.search && (
        <button
          key="search"
          type="button"
          className="btn btn-light mr-2"
          onClick={clearSearch}
        >
          <FontAwesomeIcon icon="times-circle" className="text-muted mr-2" />
          Search: {values.search}
        </button>
      )}

      {filters.map(filter =>
        getFilterValues(filter).map(value => (
          <button
            key={`${filter}-${value}`}
            type="button"
            className="btn btn-light mr-2"
            onClick={handleClick(filter)(value)}
          >
            <FontAwesomeIcon icon="times-circle" className="text-muted mr-2" />
            {value}
          </button>
        )),
      )}
    </div>
  );
}

SelectedFilters.propTypes = {};

export default SelectedFilters;
