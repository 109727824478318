import { useMutation } from '@apollo/client';
import { DELETE_MODEL_IMAGE } from 'apollo/operations/modelImage';
import { toast } from 'react-toastify';
import { RefetchQueries } from 'apollo/schema/types';

type Props = {
  modelId: number;
  fileId: number;
  refetchQueries: RefetchQueries;
};

function DeleteModelImageButton({
  modelId,
  fileId,
  refetchQueries,
}: Props): JSX.Element {
  const [deleteImage, { loading }] = useMutation(DELETE_MODEL_IMAGE, {
    variables: { modelId, fileId },
    refetchQueries,
  });

  async function handleDelete() {
    const text = 'The file will be permanently deleted.';
    if (!window.confirm(text)) return;

    try {
      await deleteImage();
      toast.info('Image deleted.');
    } catch (err) {
      console.log('Error deleting image', err);
      toast.error(
        'There was a problem deleting the selected image. It may have already been deleted.',
      );
    }
  }

  return (
    <button
      type="button"
      className="btn btn-link text-danger"
      onClick={handleDelete}
      disabled={loading}
    >
      Delete Image
    </button>
  );
}

export default DeleteModelImageButton;
