import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { Role } from 'apollo/schema/types';
import * as routes from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import RoleSecured from 'components/common/RoleSecured';
import Notifications from 'components/modules/notifications/Notifications';
import LinkBox from 'components/common/LinkBox';

function ReviewTasksIcon() {
  return (
    <Notifications>
      {numTasks =>
        numTasks > 0 ? (
          <div className="badge badge-danger ml-2">{numTasks}</div>
        ) : null
      }
    </Notifications>
  );
}

type DashboardItemProps = {
  children: React.ReactNode;
  header: React.ReactNode;
  url: string;
  roles: Role[];
};

function DashboardItem({ children, header, url, roles }: DashboardItemProps) {
  return (
    <RoleSecured roles={roles}>
      <Col md={6}>
        <LinkBox to={url} className="mb-3" header={header}>
          {children}
        </LinkBox>
      </Col>
    </RoleSecured>
  );
}

const AdminDashboardPage: React.FC = () => {
  const match = useRouteMatch();

  return (
    <StandardLayout breadcrumb={[[match.path, 'Dashboard']]}>
      <h1>Dashboard</h1>

      <Row>
        <DashboardItem
          header={
            <>
              Review Tasks
              <ReviewTasksIcon />
            </>
          }
          roles={['admin', 'reviewer']}
          url={routes.modelReviewTasksRoute()}
        >
          Overview of all models awaiting technical review
        </DashboardItem>

        <DashboardItem
          header="My Models"
          roles={['admin', 'reviewer']}
          url={routes.myModelsRoute()}
        >
          Listing of my models
        </DashboardItem>

        <DashboardItem
          header="Model Management"
          roles={['admin']}
          url={routes.adminModelsRoute()}
        >
          Overview of all models created by all users within V3Geo.
        </DashboardItem>

        <DashboardItem
          header="User Management"
          roles={['admin']}
          url={routes.adminUserRoute()}
        >
          Manage users that are currently registered in the V3Geo system
        </DashboardItem>

        <DashboardItem
          header="News"
          roles={['admin']}
          url={routes.adminNewsRoute()}
        >
          Manage V3Geo news articles
        </DashboardItem>

        <DashboardItem
          header="Organization Management"
          roles={['admin']}
          url={routes.adminOrgListRoute()}
        >
          Manage users' organizations in the V3Geo system
        </DashboardItem>

        <DashboardItem
          header="Page Content"
          roles={['admin']}
          url={routes.adminPageRoute()}
        >
          Manage page content published throughout the V3Geo website
        </DashboardItem>

        <DashboardItem
          header="File Manager"
          roles={['admin']}
          url={routes.adminFileManagerRoute()}
        >
          Manage files uploaded to V3Geo's CMS system
        </DashboardItem>
      </Row>
    </StandardLayout>
  );
};

export default AdminDashboardPage;
