import React from 'react';
import ReactSelect from 'react-select';
import classnames from 'classnames';
import { ErrorMessage, FieldProps } from 'formik';

import FormLabel from './FormLabel';

type SelectOption = {
  value: any;
  label: string;
  disabled?: boolean;
};

type ReactSelectOption = {
  value: any;
  label: string;
  isDisabled: boolean;
};

type Props = FieldProps & {
  options: SelectOption[];
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  isInvalid?: boolean;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  renderInput?: (input: JSX.Element) => React.ReactNode;
};

function FormikReactSelectField({
  field,
  form,
  options,
  loading = false,
  disabled = false,
  required = false,
  isInvalid = false,
  label = null,
  helpText = null,
  renderInput,
}: Props) {
  function handleChange(option: SelectOption | null) {
    const value = option?.value || null;
    form.setFieldValue(field.name, value);
  }

  const selectedValue = options.find(opt => {
    // Cast to strings for comparison if a number is given for one of the values
    // Needed for select boxes for picking an ID
    const optValue =
      typeof opt.value === 'number' ? String(opt.value) : opt.value;
    const fieldValue =
      typeof field.value === 'number' ? String(field.value) : field.value;
    return optValue === fieldValue;
  });

  // Convert generic `disabled` used everywhere else into react-select's `isDisabled` prop
  const selectOptions: ReactSelectOption[] = options.map(opt => {
    const nextOpt: any = { ...opt };
    if (opt.disabled) {
      nextOpt.isDisabled = true;
      delete nextOpt.disabled;
    }
    return nextOpt;
  });

  const input = (
    <ReactSelect
      value={selectedValue}
      isLoading={loading}
      isDisabled={disabled}
      onChange={handleChange}
      options={selectOptions}
      isSearchable
      isClearable
    />
  );

  return (
    <div
      className={classnames('form-group', {
        'has-error': isInvalid,
        'text-danger': isInvalid,
      })}
    >
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
      />
      {renderInput ? renderInput(input) : input}
      <ErrorMessage name={field.name} />
    </div>
  );
}

export default FormikReactSelectField;
