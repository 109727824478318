import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OrganizationInvite } from 'apollo/schema/types';
import { ORG_INVITE_LIST } from 'apollo/operations/organization';
import { OrgInviteListData, OrgInviteListVars } from 'apollo/schema/operations';
import Spinner from 'components/common/Spinner';
import CreateOrganizationInvite from './CreateOrganizationInvite';

type TableProps = {
  invites: OrganizationInvite[];
};

const InvitationTable: React.FC<TableProps> = ({ invites }: TableProps) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Email</th>
          <th>Status</th>
          <th>Invited By</th>
          <th>Sent At</th>
        </tr>
      </thead>
      <tbody>
        {invites.map(invite => (
          <tr key={invite.id}>
            <td>{invite.inviteeEmail}</td>
            <td>{invite.status}</td>
            <td>{invite.inviter?.name}</td>
            <td>{invite.createdAt}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

type Props = {
  orgId: number;
};

const OrganizationInviteList: React.FC<Props> = ({ orgId }: Props) => {
  const [showPast, setShowPast] = useState(false);
  const { data, loading } = useQuery<OrgInviteListData, OrgInviteListVars>(
    ORG_INVITE_LIST,
    { variables: { orgId } },
  );

  const invites = R.pipe(
    R.pathOr<OrganizationInvite[]>([], ['organizationInviteList']),
    R.sortWith([R.descend(R.prop('createdAt'))]),
  )(data);
  const pendingInvites = invites.filter(inv => inv.status === 'PENDING');
  const pastInvites = invites.filter(inv => inv.status !== 'PENDING');

  const toggleShowPast = () => setShowPast(!showPast);

  return (
    <Card>
      <Card.Header>
        Organization Invitations
        <div className="float-right">
          <CreateOrganizationInvite>
            {showModal => (
              <button
                type="button"
                onClick={showModal}
                className="btn btn-secondary btn-sm"
              >
                Invite a user
              </button>
            )}
          </CreateOrganizationInvite>
        </div>
      </Card.Header>
      <Card.Body>
        <Spinner show={loading} />

        {!loading && !pendingInvites.length && (
          <div className="text-center text-muted">
            <i>No invites pending.</i>
          </div>
        )}
        {pendingInvites.length > 0 && (
          <InvitationTable invites={pendingInvites} />
        )}

        {pastInvites.length > 0 && (
          <>
            <button
              type="button"
              onClick={toggleShowPast}
              className="btn btn-link mb-2"
              style={{ padding: 0 }}
            >
              {showPast ? 'Hide' : 'Show'} past invitations
              <FontAwesomeIcon
                icon={showPast ? 'chevron-down' : 'chevron-right'}
                className="ml-2"
              />
            </button>

            {showPast && <InvitationTable invites={pastInvites} />}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default OrganizationInviteList;
