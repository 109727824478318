import { useMutation, gql } from '@apollo/client';
import { toast } from 'react-toastify';

import {
  DeleteModelUserFileData,
  DeleteModelUserFileVars,
} from 'apollo/schema/operations';
import { RefetchQueries } from 'apollo/schema/types';

const DELETE_MUF = gql`
  mutation DeleteModelUserFile($modelUserFileId: Int!) {
    deleteModelUserFile(modelUserFileId: $modelUserFileId)
  }
`;

type Props = {
  children: (deleteMuf: () => Promise<void>) => JSX.Element;
  modelUserFileId: number;
  refetchQueries: RefetchQueries;
};

function DeleteMUF({
  children,
  modelUserFileId,
  refetchQueries,
}: Props): JSX.Element {
  const [deleteMUF] = useMutation<
    DeleteModelUserFileData,
    DeleteModelUserFileVars
  >(DELETE_MUF, { variables: { modelUserFileId }, refetchQueries });

  async function handleDelete() {
    try {
      await deleteMUF();
      toast.success('File deleted successfully.');
    } catch (err) {
      console.log('Error deleting MUF', err);
      toast.error('There was a problem deleting the file. Please try again.');
    }
  }

  return children(handleDelete);
}

export default DeleteMUF;
