import React from 'react';
import {
  OverlayTrigger as BSOverlayTrigger,
  OverlayTriggerProps,
  Popover as BSPopover,
  PopoverProps,
  Tooltip as BSTooltip,
  TooltipProps,
} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

type Props = Omit<Partial<OverlayTriggerProps>, 'overlay'> &
  (Partial<TooltipProps> | Partial<PopoverProps>) & {
    overlay: React.ReactNode;
    children: JSX.Element;
    title?: string;
    popover?: boolean;
    disabled?: boolean;
  };

function Tooltip({
  trigger = ['focus', 'hover'],
  placement = 'top',
  popover = false,
  rootClose = false,
  title,
  overlay,
  children,
  disabled = false,
}: Props): JSX.Element {
  if (disabled) return children;

  const instanceId = uuid();

  // If 'popover' is set, render a Popover component instead of a Tooltip
  const tooltipOverlay = popover ? (
    <BSPopover id={instanceId} title={title}>
      {overlay}
    </BSPopover>
  ) : (
    <BSTooltip id={instanceId}>{overlay}</BSTooltip>
  );

  return (
    <BSOverlayTrigger
      trigger={trigger}
      placement={placement}
      overlay={tooltipOverlay}
      rootClose={rootClose}
    >
      {children}
    </BSOverlayTrigger>
  );
}

export default Tooltip;
