import React from 'react';
import { useQuery } from '@apollo/client';
import { Card } from 'react-bootstrap';

import { RefetchQueries } from 'apollo/schema/types';
import { USER_INVITE_LIST } from 'apollo/operations/user';
import { UserInviteListData } from 'apollo/schema/operations';
import CreateUserInvite from 'components/modules/userInvite/CreateUserInvite';
import MyInvitesTable from 'components/modules/userInvite/MyInvitesTable';

const InviteTab: React.FC = () => {
  const { data } = useQuery<UserInviteListData>(USER_INVITE_LIST);

  const invites = data?.userInviteList || [];

  const refetchQueries: RefetchQueries = [{ query: USER_INVITE_LIST }];

  return (
    <>
      <CreateUserInvite refetchQueries={refetchQueries} />

      {invites.length > 0 && (
        <Card className="mt-3">
          <Card.Body>
            <Card.Title>My Invites</Card.Title>
            <MyInvitesTable userInvites={invites} />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default InviteTab;
