import React from 'react';
import PropTypes from 'prop-types';

function ModelTags({ title, tags }) {
  if (!tags.length) return null;

  return (
    <div className="row mb-2">
      <div className="col-sm-5">
        <strong>{title}</strong>
      </div>
      <div className="col-sm-7">
        {tags.map(tag => (
          <div key={tag}>{tag}</div>
        ))}
      </div>
    </div>
  );
}

ModelTags.propTypes = {
  title: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ModelTags;
