import React, { useCallback, useContext, useState } from 'react';
import * as R from 'ramda';

import { Authority, Role } from 'apollo/schema/types';
import { CURRENT_AUTHORITY } from 'apollo/operations/auth';
import { useImperativeQuery } from 'hooks/apollo';
import { CurrentAuthorityData } from 'apollo/schema/operations';

export type AuthContextValue = {
  authority: Authority | null;
  setAuthority: (authority: Authority | null) => void;
  isAuthenticated: boolean;
  hasAnyRole: (roles: Role[]) => boolean;
  reloadAuthority: () => Promise<Authority | null>;
};

// @ts-ignore An argument is not supposed to be required by react? Is this a bug?
export const AuthContext = React.createContext<AuthContextValue>();

type Props = { children: React.ReactNode };

export const AuthContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [authority, setAuthority] = useState<Authority | null>(null);
  const [loadCurrentAuthority] = useImperativeQuery<CurrentAuthorityData>(
    CURRENT_AUTHORITY,
  );

  const isAuthenticated = authority?.anonymous === false;

  function hasAnyRole(roles: Role[]) {
    if (!isAuthenticated || !authority) return false;
    if (!roles.length) return true;

    return R.pipe(
      R.intersection(authority.roles),
      R.complement(R.isEmpty),
    )(roles);
  }

  const reloadAuthority = useCallback(async () => {
    const result = await loadCurrentAuthority();
    const nextAuthority = result?.data?.currentAuthority || null;
    setAuthority(nextAuthority);
    return nextAuthority;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authority,
        setAuthority,
        isAuthenticated,
        hasAnyRole,
        reloadAuthority,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext)!;
