export const APP_ENV = process.env.NODE_ENV || 'development';

/** The URL the frontend is accessed at */
export const CLIENT_URL =
  process.env.REACT_APP_DEPLOY_URL || 'http://localhost:3000';

/** The backend GraphQL URL */
export const GRAPHQL_URL =
  process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:3000/graphql';

// TODO: Get new API key for V3Geo
export const GOOGLE_MAPS_API_KEY = 'AIzaSyB9d7mveyN6IWzGGzXkcuC8ycxFqrzmceQ';

// Auth0 Configuration

export const AUTH0_DOMAIN =
  process.env.REACT_APP_AUTH0_DOMAIN || 'v3geo.eu.auth0.com';
export const AUTH0_CLIENT_ID =
  process.env.REACT_APP_AUTH0_CLIENT_ID || 'r0Fbo9DV3jx1BHyuEXoLmg3DECUE6yI4';
export const AUTH0_CLIENT_AUD =
  process.env.REACT_APP_AUTH0_CLIENT_AUD || 'v3geo-local';
