import React, { HTMLProps } from 'react';
import classnames from 'classnames';
import { ErrorMessage, FieldProps } from 'formik';
import DateTime, { ViewMode } from 'react-datetime';
import { Moment } from 'moment';

import FormLabel from './FormLabel';

type Props = FieldProps & {
  inputProps?: HTMLProps<HTMLInputElement>;
  isInvalid: boolean;
  label?: React.ReactNode;
  renderInput?: (input: React.ReactNode) => React.ReactNode;
  helpText?: React.ReactNode;
  mode?: ViewMode;
  dateFormat?: string | boolean;
  timeFormat?: string | boolean;
  required?: boolean;
};

const FormikTextField: React.FC<Props> = ({
  form,
  field,
  isInvalid,
  label,
  renderInput,
  helpText,
  inputProps,
  mode = 'days',
  dateFormat = 'YYYY-MM-DD',
  timeFormat = false,
  required = false,
}: Props) => {
  function handleChange(value: Moment | string) {
    let date: string;
    if (typeof value === 'string') date = value;
    else date = value.format('YYYY-MM-DD');

    form.setFieldValue(field.name, date);
  }

  const input = (
    <DateTime
      viewMode={mode}
      dateFormat={dateFormat}
      timeFormat={timeFormat}
      value={field.value}
      onChange={handleChange}
      inputProps={{ placeholder: 'yyyy-mm-dd', ...inputProps }}
      closeOnSelect
    />
  );

  return (
    <div
      className={classnames('form-group', {
        'has-error': isInvalid,
        'text-danger': isInvalid,
      })}
    >
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
      />
      {renderInput ? renderInput(input) : input}
      <ErrorMessage name={field.name} />
    </div>
  );
};

export default FormikTextField;
