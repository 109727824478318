import React, { useRef, useEffect, HTMLProps } from 'react';
import classnames from 'classnames';
import { ErrorMessage, FieldProps } from 'formik';

import FormLabel from './FormLabel';

type Props = FieldProps &
  HTMLProps<HTMLInputElement> & {
    className?: string;
    isInvalid?: boolean;
    label?: React.ReactNode;
    renderInput?: (input: JSX.Element) => JSX.Element;
    helpText?: React.ReactNode;
    required?: boolean;
  };

function FormikFileField({
  form,
  field,
  className,
  isInvalid = false,
  label,
  renderInput,
  helpText,
  required = false,
  ...props
}: Props) {
  const fileRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(field.name, event.currentTarget.files?.[0]);
  };

  useEffect(() => {
    if (!field.value && fileRef.current) {
      fileRef.current.value = '';
    }
  }, [field.value]);

  const input = (
    <input
      {...props}
      ref={fileRef}
      type="file"
      id={field.name}
      onChange={handleChange}
      className={classnames(className, 'form-control')}
    />
  );

  return (
    <div
      className={classnames('form-group', {
        'has-error': isInvalid,
        'text-danger': isInvalid,
      })}
    >
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
      />
      {renderInput ? renderInput(input) : input}
      <ErrorMessage name={field.name} />
    </div>
  );
}

export default FormikFileField;
