import React from 'react';

import SortIndicator, {
  SortIndicatorProps,
} from 'components/common/SortIndicator';
import FilterButton from './FilterButton';

interface Props {
  /** The property name of the column to be sorted */
  colName: string;
  /** JSX rendered as text */
  children: React.ReactNode;
  sortIndicatorProps: SortIndicatorProps;
  filterable?: boolean;
  renderFilterOption?: (value: string) => React.ReactNode;
}

const SortTrigger: React.FC<Props> = ({
  colName,
  sortIndicatorProps,
  children,
  filterable = false,
  renderFilterOption,
}: Props) => {
  function handleClick() {
    sortIndicatorProps.onSortChange(colName);
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-link"
        onClick={handleClick}
        style={{ margin: 0, padding: 0 }}
      >
        {children} <SortIndicator {...sortIndicatorProps} colName={colName} />
      </button>

      {filterable && (
        <FilterButton
          options={sortIndicatorProps.categoryFilterOptions(colName)}
          values={sortIndicatorProps.getFiltersForCategory(colName)}
          onChange={sortIndicatorProps.onCategoryFilterChange(colName)}
          renderFilterOption={renderFilterOption}
        />
      )}
    </>
  );
};

export default SortTrigger;
