import React, { useState } from 'react';
import * as R from 'ramda';
import { Link, useRouteMatch } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Col, Nav, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Model } from 'apollo/schema/types';
import * as fragments from 'apollo/fragments';
import { modelRoute } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import FixModelHtml from 'components/modules/model/FixModelHtml';

type StatusTextProps = {
  field: string;
  isHtml: boolean;
};

const StatusText: React.FC<StatusTextProps> = ({
  field,
  isHtml,
}: StatusTextProps) => {
  if (isHtml) {
    return (
      <div className="text-danger">
        <b>
          <FontAwesomeIcon icon="times-circle" /> {field}
        </b>
      </div>
    );
  }

  return (
    <div className="text-success">
      <FontAwesomeIcon icon="check" /> {field}
    </div>
  );
};

const MODEL_LIST = gql`
  query ModelList {
    modelList {
      ...modelParts
    }
  }
  ${fragments.modelParts}
`;
type QueryData = { modelList: Model[] };

type ModelWithHtml = {
  model: Model;
  isDescriptionHtml: boolean;
  isLiteratureHtml: boolean;
};

const AdminModelsHtmlReview: React.FC = () => {
  const match = useRouteMatch();
  const [active, setActive] = useState<Model | null>(null);
  const { data, loading } = useQuery<QueryData>(MODEL_LIST);

  function containsHtml(text?: string | null) {
    if (!text) return false;
    const results = text.match(/<[^>]*>/g);
    return results !== null && results.length > 0;
  }

  const modelsWithHtml: ModelWithHtml[] = R.pipe(
    R.propOr<Model[]>([], 'modelList'),
    R.reduce((acc: ModelWithHtml[], cur: Model) => {
      const isDescriptionHtml = containsHtml(cur.description);
      const isLiteratureHtml = containsHtml(cur.literature);

      const modelWithMeta: ModelWithHtml = {
        model: cur,
        isDescriptionHtml,
        isLiteratureHtml,
      };

      if (isDescriptionHtml || isLiteratureHtml) {
        return [...acc, modelWithMeta];
      }
      return acc;
    }, [] as ModelWithHtml[]),
    R.sortBy(R.pathOr('', ['model', 'name'])),
  )(data);

  return (
    <StandardLayout breadcrumb={[[match.path, 'Model HTML']]}>
      <h1>Models with HTML</h1>
      <p>
        HTML will no longer be supported once model creation is allowed by
        public users. Use this tool to reformat the text content.
      </p>
      <Spinner show={loading} />
      Total Models: <b>{data?.modelList.length}</b> &mdash; Models containing
      HTML: <b>{modelsWithHtml.length}</b>
      <div className="mt-3" />
      <Row>
        <Col md={4}>
          <Nav
            variant="pills"
            style={{ maxHeight: '80vh', overflowY: 'scroll' }}
          >
            {modelsWithHtml.map(mwh => (
              <Nav.Item key={mwh.model.id} style={{ width: '100%' }}>
                <Nav.Link
                  onClick={() => setActive(mwh.model)}
                  className={active?.id === mwh.model.id ? 'active' : ''}
                >
                  <>
                    <strong>
                      {mwh.model.name}
                      <div className="badge badge-primary ml-2">
                        {mwh.model.id}
                      </div>
                    </strong>
                    <StatusText
                      field="Description"
                      isHtml={mwh.isDescriptionHtml}
                    />
                    <StatusText
                      field="Literature"
                      isHtml={mwh.isLiteratureHtml}
                    />
                  </>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>

        <Col md={8}>
          {!active && (
            <div className="text-center">Choose a model to get started.</div>
          )}
          {active && (
            <>
              <div className="float-right">
                <Link
                  to={modelRoute(active.id)}
                  target="_blank"
                  className="btn btn-secondary btn-sm"
                >
                  View Model Page <FontAwesomeIcon icon="external-link-alt" />
                </Link>
              </div>
              <FixModelHtml model={active} />
            </>
          )}
        </Col>
      </Row>
    </StandardLayout>
  );
};

export default AdminModelsHtmlReview;
