import { gql, useQuery } from '@apollo/client';
import { PendingReviewsData } from 'apollo/schema/operations';

export const NOTIFICATIONS_QUERY = gql`
  query Notifications {
    pendingReviews {
      id
    }
  }
`;
type QueryData = PendingReviewsData;

type Props = {
  children: (numTasks: number, loading: boolean) => JSX.Element | null;
};

function Notifications({ children }: Props) {
  const { data, loading } = useQuery<QueryData>(NOTIFICATIONS_QUERY);
  const pendingReviews = data?.pendingReviews ?? [];

  const numTasks = pendingReviews.length;

  return children(numTasks, loading);
}

export default Notifications;
