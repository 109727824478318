import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import * as routes from 'routes';
import RoleSecured from 'components/common/RoleSecured';
import NavLink from 'components/common/NavLink';
import Tooltip from 'components/common/Tooltip';
import Notifications from 'components/modules/notifications/Notifications';
import CurrentUser from './CurrentUser';

const NotificationIcon = styled.span`
  display: inline-block;
  margin-bottom: 5px;
  background-color: hsl(36, 100%, 50%);
  width: 8px;
  height: 8px;
  border-radius: 4px;
`;

type HamburgerLayout = 'normal' | 'mobile';

function Navbar() {
  type HamburgerDictionaryItem = {
    to: string;
    label: string;
    subItems?: HamburgerDictionaryItem[];
  };
  const hamburgerItems: HamburgerDictionaryItem[] = [
    { label: 'About', to: routes.cmsPage('about') },
    { label: 'News', to: routes.newsRoute() },
    {
      label: 'Contribute',
      to: routes.contributeRoute(),
      subItems: [
        {
          label: 'Creating a model entry',
          to: routes.cmsPage('create-model'),
        },
        {
          label: 'Preparing a 3D model',
          to: routes.cmsPage('3dmodel'),
        },
        {
          label: 'Technical review',
          to: routes.cmsPage('review'),
        },
      ],
    },
    {
      label: 'Resources',
      to: routes.cmsPage('resources'),
      subItems: [
        {
          label: 'Making a high-quality 3D model',
          to: routes.cmsPage('3D-modelling-how-to'),
        },
        { label: 'Tutorials', to: routes.cmsPage('create-model-long') },
        { label: 'FAQ', to: routes.cmsPage('faq') },
      ],
    },
    { label: 'Plans', to: routes.cmsPage('plans') },
    { label: 'Contact', to: routes.cmsPage('contact') },
  ];

  const renderHamburgerItem = (
    item: HamburgerDictionaryItem,
    layout: HamburgerLayout,
    isSubItem = false,
  ): JSX.Element => {
    const UnorderedList = (props: any) => <ul {...props} />;
    const WrapperCmp =
      layout === 'normal'
        ? React.Fragment
        : isSubItem
        ? UnorderedList
        : React.Fragment;

    return (
      <WrapperCmp key={item.to}>
        <HamburgerItem to={item.to} subItem={isSubItem} layout={layout}>
          {item.label}
        </HamburgerItem>
        {item.subItems &&
          item.subItems.map(subItem =>
            renderHamburgerItem(subItem, layout, true),
          )}
      </WrapperCmp>
    );
  };

  return (
    <div
      className="navbar navbar-dark bg-dark navbar-expand-md"
      style={{ minHeight: '80px', borderBottom: '4px solid #ff9800' }}
    >
      <div className="container">
        <Link to={routes.homeRoute()} className="navbar-brand">
          {/*
          PNG Logo
          <img
            src="/assets/images/v3geo-logo-inverted.png"
            style={{ height: '30px', width: 'auto' }}
            alt="V3Geo Logo"
          /> */}
          {/* SVG Logo (white) */}
          <img
            src="/assets/images/V3GeoLogo.svg"
            style={{ height: '30px', width: 'auto' }}
            alt="V3Geo Logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink
                to={routes.modelSearchRoute()}
                exact
                className="nav-link"
              >
                Find Models
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact to={routes.contributeRoute()} className="nav-link">
                Contribute
              </NavLink>
            </li>
          </ul>

          <ul className="navbar-nav">
            {/* <Hamburger menu - small layouts> */}
            {hamburgerItems.map(item => renderHamburgerItem(item, 'mobile'))}
            {/* <Hamburger menu - small layouts> */}

            <RoleSecured roles={['user']}>
              <li className="nav-item">
                <NavLink to={routes.myModelsRoute()} className="nav-link">
                  My Models
                </NavLink>
              </li>
            </RoleSecured>

            <RoleSecured roles={['admin', 'reviewer']}>
              <li className="nav-item">
                <NavLink to={routes.adminDashboardRoute()} className="nav-link">
                  Dashboard
                  <Notifications>
                    {numTasks =>
                      numTasks > 0 ? (
                        <Tooltip
                          overlay={
                            numTasks === 1
                              ? 'There is 1 pending task'
                              : `There are ${numTasks} pending tasks`
                          }
                          placement="bottom"
                        >
                          <NotificationIcon className="ml-1" />
                        </Tooltip>
                      ) : null
                    }
                  </Notifications>
                </NavLink>
              </li>
            </RoleSecured>

            <CurrentUser />

            {/* <Hamburger Menu - regular layouts (button)> */}
            <li className="nav-item dropdown d-none d-md-block">
              <a
                href="#hamburger-menu"
                className="nav-link"
                role="button"
                data-toggle="dropdown"
              >
                <span className="navbar-toggler-icon" />
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                id="hamburgerMenu"
              >
                {hamburgerItems.map(item =>
                  renderHamburgerItem(item, 'normal'),
                )}
              </div>
            </li>
            {/* </Hamburger Menu> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

type HamburgerItemProps = {
  children: React.ReactNode;
  to: string;
  layout?: HamburgerLayout;
  subItem?: boolean;
};
function HamburgerItem({
  children,
  to,
  layout = 'normal',
  subItem: subRoute = false,
}: HamburgerItemProps): JSX.Element {
  if (layout === 'mobile') {
    return (
      <li className="nav-item d-block d-md-none">
        <NavLink to={to} className="nav-link">
          {children}
        </NavLink>
      </li>
    );
  }

  return (
    <NavLink
      to={to}
      className="dropdown-item"
      style={subRoute ? { paddingLeft: '50px' } : {}}
    >
      {children}
    </NavLink>
  );
}

export default Navbar;
