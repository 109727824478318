import React from 'react';

import Tooltip from 'components/common/Tooltip';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { toLatLng } from 'utils/map';

type Props = {
  latitude: string;
  longitude: string;
};

function ModelCoordinatesPopover({ latitude, longitude }: Props) {
  const coordDisplay = (coordinatePart: string) =>
    parseFloat(coordinatePart).toPrecision(6);

  return (
    <Tooltip
      popover
      rootClose
      trigger={['click']}
      placement="right"
      overlay={<MapOverlay longitude={longitude} latitude={latitude} />}
    >
      <span
        className="text-muted ml-2"
        style={{
          fontSize: '10pt',
          fontFamily: 'SF Mono, Cascadia Mono, Consolas, monospace',
          cursor: 'pointer',
        }}
      >
        ({coordDisplay(latitude)}, {coordDisplay(longitude)})
      </span>
    </Tooltip>
  );
}

function MapOverlay({ latitude, longitude }: Props) {
  const center = toLatLng({ latitude, longitude });

  return (
    <div style={{ padding: '12px', fontSize: '10pt' }}>
      <h5>Model Center</h5>
      <div
        className="mb-2"
        style={{
          fontFamily: 'SF Mono, Cascadia Mono, Consolas, monospace',
        }}
      >
        {latitude}, {longitude}
      </div>

      <GoogleMap
        mapContainerStyle={{ width: '250px', height: '200px' }}
        center={center}
        zoom={8}
        options={{
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          mapTypeId: google.maps.MapTypeId.HYBRID,
        }}
        // onLoad={onLoad}
        // onUnmount={onUnmount}
      >
        <Marker clickable={false} position={center} />
      </GoogleMap>
    </div>
  );
}

export default ModelCoordinatesPopover;
