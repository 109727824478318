export interface GeologyTagFormValues {
  category: string;
  subcategory: string | null;
}

export function defaultGeologyTag(): GeologyTagFormValues {
  return {
    category: '',
    subcategory: null,
  };
}

export const categories = [
  'Clastic Sedimentology',
  'Carbonate and Evaporite Sedimentology',
  'Metamorphic',
  'Extrusive Igneous',
  'Intrusive Igneous',
  'Structure',
  'Fossils',
  'Hand Specimen',
  'Microscopic',
  'Quaternary/Geomorphology',
];

export const subcategories = [
  'Fluvial/Alluvial',
  'Aeolian',
  'Lacustrine',
  'Shallow Marine/Paralic',
  'Shelf',
  'Slope',
  'Basin Floor',
  'Lacustrine',
  'Marine',
  'Ramp',
  'Reef',
  'Evaporite Playa',
  'Slate',
  'Phyllite',
  'Schist',
  'Gneiss',
  'Marble',
  'Quartzite',
  'Pyroclastic Flow Deposit',
  'Lava Flow Deposit',
  'Dyke',
  'Sill',
  'Lacolith',
  'Folds',
  'Reserve/ Thrust Faults',
  'Extensional Faults',
  'Strike Slip Faults',
  'Inversion Structures',
  'Joints',
  'Veins',
];
