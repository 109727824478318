import React from 'react';

type Props = {
  message?: React.ReactNode;
};

const NotFound: React.FC<Props> = ({
  message = 'The resource you are looking for could not be found.',
}: Props) => {
  return (
    <>
      <h1>Not Found</h1>
      <p>{message}</p>
    </>
  );
};

export default NotFound;
