import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import FormikTextField from './FormikTextField';
import FormikCheckboxField from './FormikCheckboxField';
import FormikSelectField from './FormikSelectField';
import FormikFileField from './FormikFileField';
import FormikDateField from './FormikDateField';
import FormikRadioField from './FormikRadioField';

const isTextType = fieldType =>
  R.contains(fieldType, ['text', 'number', 'email', 'password', 'textarea']);
const isCheckboxType = R.equals('checkbox');
const isRadioType = R.equals('radio');
const isFileType = R.equals('file');
const isSelectType = R.equals('select');
const isDateType = R.equals('date');

function FormikField({ form, field, type, ...props }) {
  const fieldPath = R.split('.', field.name);
  const isFieldTouched = R.pipe(
    R.path(fieldPath),
    R.complement(R.isNil),
  )(form.touched);
  const hasError = R.pipe(
    R.path(fieldPath),
    R.complement(R.isNil),
  )(form.errors);
  const isInvalid = isFieldTouched && hasError;

  if (typeof type === 'function') {
    return React.createElement(type, { form, field, isInvalid, ...props });
  }

  if (isTextType(type)) {
    return (
      <FormikTextField
        {...props}
        field={field}
        form={form}
        type={type}
        isInvalid={isInvalid}
      />
    );
  }

  if (isFileType(type)) {
    return (
      <FormikFileField
        {...props}
        field={field}
        form={form}
        type={type}
        isInvalid={isInvalid}
      />
    );
  }

  if (isDateType(type)) {
    return (
      <FormikDateField
        {...props}
        field={field}
        form={form}
        isInvalid={isInvalid}
      />
    );
  }

  if (isCheckboxType(type)) {
    return <FormikCheckboxField {...props} form={form} field={field} />;
  }

  if (isRadioType(type)) {
    return <FormikRadioField {...props} form={form} field={field} />;
  }

  if (isSelectType(type)) {
    return (
      <FormikSelectField
        {...props}
        form={form}
        field={field}
        isInvalid={isInvalid}
      />
    );
  }

  return null;
}

FormikField.propTypes = {
  /** The input type that will be rendered. Can either be a string (text, password, ...) or a custom component */
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

FormikField.defaultProps = {
  type: 'text',
  className: null,
  required: false,
};

export default FormikField;
