import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Dropdown, SplitButton } from 'react-bootstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Model } from 'apollo/schema/types';
import { Link } from 'react-router-dom';
import * as fragments from 'apollo/fragments';
import { useAuth } from 'contexts/auth';
import { useSortFilter } from 'hooks/data';
import { modelEditRoute, modelRoute, myModelsCreateRoute } from 'routes';
import SortTrigger from 'components/common/SortTrigger';
import Spinner from 'components/common/Spinner';
import Tooltip from 'components/common/Tooltip';
import DateTime from 'components/common/DateTime';
import PrivateModelIcon from 'components/modules/model/PrivateModelIcon';
import PublicModelIcon from 'components/modules/model/PublicModelIcon';

const MY_MODELS_TAB = gql`
  query MyModelsTab($userId: Int!) {
    modelsByUser(userId: $userId) {
      ...modelParts
      reviews {
        ...modelReviewParts
      }
      shares {
        ...modelShareParts
      }
    }
  }
  ${fragments.modelParts}
  ${fragments.modelReviewParts}
  ${fragments.modelShareParts}
`;
type QueryData = {
  modelsByUser: Model[];
};
type QueryVars = {
  userId: number;
};

type ModelStatus = 'Published' | 'Under Review' | 'Draft';
type ModelWithMeta = Model & {
  status: ModelStatus;
  isShared: boolean;
};

const MyModelsTab: React.FC = () => {
  const { authority } = useAuth();
  if (!authority) throw new Error('Must be logged in');

  const { data, loading } = useQuery<QueryData, QueryVars>(MY_MODELS_TAB, {
    variables: { userId: authority.userId },
  });
  const modelsWithMeta = (data?.modelsByUser || []).map(model => {
    let status: ModelStatus = 'Draft';
    if (model.isPublished) {
      status = 'Published';
    } else {
      const pendingReviews = model.reviews.filter(
        review => review.isApproved === null,
      );
      if (pendingReviews.length > 0) {
        status = 'Under Review';
      }
    }

    const isShared = model.shares.length > 0;

    const mwm: ModelWithMeta = {
      ...model,
      status,
      isShared,
    };
    return mwm;
  });

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    modelsWithMeta,
    'name',
    'name',
  );

  if (loading) return <Spinner />;

  return (
    <>
      <div className="float-right my-2">
        <Link to={myModelsCreateRoute()} className="btn btn-sm btn-primary">
          Create a Model
        </Link>
      </div>

      <h1>My Models</h1>

      <table className="table">
        <thead>
          <tr>
            <th>
              <SortTrigger colName="name" sortIndicatorProps={siProps}>
                Name
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="status"
                sortIndicatorProps={siProps}
                filterable
              >
                Status
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="isPrivate"
                sortIndicatorProps={siProps}
                filterable
                renderFilterOption={(value: string) => {
                  if (value === 'true') return 'Private';
                  return 'Public';
                }}
              >
                Privacy
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="isShared"
                sortIndicatorProps={siProps}
                filterable
              >
                Shared
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="createdAt" sortIndicatorProps={siProps}>
                Created
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="updatedAt" sortIndicatorProps={siProps}>
                Last Updated
              </SortTrigger>
            </th>
            <th />
          </tr>
        </thead>

        <tbody>
          {!items.length && (
            <tr>
              <td colSpan={7} className="text-center text-muted">
                <i>
                  You haven't created any models yet.
                  <br />
                  Click the Create a Model button to get started.
                </i>
              </td>
            </tr>
          )}

          {items.map(model => (
            <tr key={model.id}>
              <td className="align-middle">
                <Link to={modelEditRoute(model.id)}>{model.name}</Link>
              </td>
              <td className="align-middle">
                <span
                  className={classnames({
                    'text-muted': model.status === 'Draft',
                    'text-success': model.status === 'Published',
                    'text-info': model.status === 'Under Review',
                  })}
                  style={{ fontWeight: 'bold' }}
                >
                  {model.status}
                </span>
              </td>
              <td className="align-middle text-center">
                {model.isPrivate && (
                  <Tooltip overlay="Private model">
                    <PrivateModelIcon />
                  </Tooltip>
                )}
                {!model.isPrivate && model.isPublished && (
                  <Tooltip overlay="Public model">
                    <PublicModelIcon />
                  </Tooltip>
                )}
              </td>
              <td className="align-middle text-center">
                {model.isShared && (
                  <Tooltip overlay="This model is being shared with another user">
                    <FontAwesomeIcon icon="user-friends" />
                  </Tooltip>
                )}
              </td>
              <td className="align-middle">
                <DateTime date={model.createdAt} format="LL" tooltip />
              </td>
              <td className="align-middle">
                <DateTime date={model.updatedAt} format="LL" tooltip />
              </td>

              <td className="text-right">
                <SplitButton
                  id={`model-actions--${model.id}`}
                  variant="secondary"
                  size="sm"
                  title={model.isPublished ? 'View' : 'Preview'}
                  href={modelRoute(model.id)}
                  target="_blank"
                  alignRight
                >
                  <Dropdown.Item
                    href={modelEditRoute(model.id)}
                    target="_blank"
                  >
                    Edit Model
                  </Dropdown.Item>
                </SplitButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default MyModelsTab;
