import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ModelUserFile, RefetchQueries } from 'apollo/schema/types';
import Confirm from 'components/common/Confirm';
import DateTime from 'components/common/DateTime';
import RoleSecured from 'components/common/RoleSecured';
import SortTrigger from 'components/common/SortTrigger';
import Tooltip from 'components/common/Tooltip';
import { useSortFilter } from 'hooks/data';
import DeleteMUF from './DeleteMUF';

type Props = {
  modelId: number;
  mufs: ModelUserFile[];
  locked: boolean;
  reloadFiles: () => Promise<void>;
  refetchQueries: RefetchQueries;
};

function CurrentFiles({
  modelId,
  mufs,
  locked,
  reloadFiles,
  refetchQueries,
}: Props): JSX.Element {
  type SortableMUF = ModelUserFile & {
    completionStatus: 'complete' | 'incomplete';
  };
  const sortableMufs: SortableMUF[] = mufs.map(muf => ({
    ...muf,
    completionStatus: muf.completedAt ? 'complete' : 'incomplete',
  }));

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    sortableMufs,
    'file.completedAt',
    'file.filename',
  );

  return (
    <table className="table">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="file.filename" sortIndicatorProps={siProps}>
              Filename
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="completionStatus"
              sortIndicatorProps={siProps}
            >
              Upload status
            </SortTrigger>
          </th>
          <th>
            <SortTrigger colName="completedAt" sortIndicatorProps={siProps}>
              Uploaded At
            </SortTrigger>
          </th>
          {!locked && (
            <th className="text-right">
              <Tooltip overlay="Reload file list">
                <button
                  type="button"
                  className="btn btn-link"
                  style={{ padding: 0 }}
                  onClick={reloadFiles}
                >
                  <FontAwesomeIcon icon="sync" />
                </button>
              </Tooltip>
            </th>
          )}
        </tr>
      </thead>

      <tbody>
        {!mufs.length && (
          <tr>
            <td colSpan={locked ? 2 : 3} className="text-center text-muted">
              <em>No files uploaded.</em>
            </td>
          </tr>
        )}
        {items.map(muf => (
          <tr key={muf.id}>
            <td>{muf.file.filename}</td>
            <td>{muf.completionStatus}</td>
            <td>{muf.completedAt && <DateTime date={muf.completedAt} />}</td>
            <td className="text-right">
              <RoleSecured roles={['admin', 'reviewer']}>
                <Tooltip overlay="Download file">
                  <a
                    href={muf.file.signedUrl}
                    target="_blank"
                    className="btn btn-link text-dark mr-2"
                    style={{ padding: 0 }}
                  >
                    <FontAwesomeIcon icon="download" />
                  </a>
                </Tooltip>
              </RoleSecured>

              {!locked && (
                <Tooltip overlay="Delete this file">
                  <span>
                    <DeleteMUF
                      modelUserFileId={muf.id}
                      refetchQueries={refetchQueries}
                    >
                      {handleDeleteMUF => (
                        <Confirm
                          onConfirm={handleDeleteMUF}
                          text="The file will be permanently deleted."
                          submitButtonClass="btn btn-danger"
                          submitText="Permanently Delete File"
                        >
                          {confirmDelete => (
                            <button
                              type="button"
                              className="btn btn-link text-dark"
                              style={{ padding: 0 }}
                              onClick={confirmDelete}
                            >
                              <FontAwesomeIcon icon="trash" />
                            </button>
                          )}
                        </Confirm>
                      )}
                    </DeleteMUF>
                  </span>
                </Tooltip>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CurrentFiles;
