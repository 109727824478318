import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { RefetchQueries } from 'apollo/schema/types';
import { DELETE_CMS_FILE } from 'apollo/operations/cmsFile';

type Props = {
  filename: string;
  refetchQueries: RefetchQueries;
};

function DeleteCmsFile({ filename, refetchQueries }: Props) {
  const [deleteCmsFile, { loading }] = useMutation(DELETE_CMS_FILE, {
    variables: { filename },
    refetchQueries,
  });

  async function handleDelete() {
    const confirmText = 'The file will be permanently deleted.';
    if (!window.confirm(confirmText)) return;

    try {
      await deleteCmsFile();
      toast.info('File deleted.');
    } catch (err) {
      console.log('Error deleting cms file', err);
      toast.error(
        'There was a problem deleting the file. It may have already been deleted.',
      );
    }
  }

  return (
    <button
      type="button"
      className="btn btn-sm btn-link text-danger"
      onClick={handleDelete}
      disabled={loading}
    >
      delete
    </button>
  );
}

export default DeleteCmsFile;
