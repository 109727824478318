// Yup uses template string format sometimes for interpolating values internally
/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: "${path} can't be empty",

    notType(field: any) {
      switch (field.type) {
        case 'number':
          return `${field.path} must be a number`;
        case 'string':
          return `${field.path} can't be empty`;
        default:
          return `${field.path} is invalid`;
      }
    },
  },
});

export { yup };

export const zipMimes = [
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip',
];
