import React from 'react';
import * as R from 'ramda';
import { Card } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { User } from 'apollo/schema/types';
import { USER_LIST } from 'apollo/operations/user';
import {
  TransferModelOwnershipData,
  TransferModelOwnershipVars,
  UserListData,
} from 'apollo/schema/operations';
import { yup } from 'utils/validation';
import { TRANSFER_MODEL_OWNERSHIP } from 'apollo/operations/model';
import { setValidationErrors } from 'utils/forms';
import FormikField from 'components/common/FormikField';
import FormikErrors from 'components/common/FormikErrors';
import FormikReactSelectField from 'components/common/FormikField/FormikReactSelectField';

type FormValues = {
  userId: string;
};

const validationSchema = yup.object({ userId: yup.number().required() });

type Props = {
  modelId: number;
  userId: number;
};

const ModelOwnershipTab: React.FC<Props> = ({ modelId, userId }: Props) => {
  const { data: userListData, loading: loadingUserList } = useQuery<
    UserListData
  >(USER_LIST);

  const [transferOwnership, { loading: loadingTransfer, error }] = useMutation<
    TransferModelOwnershipData,
    TransferModelOwnershipVars
  >(TRANSFER_MODEL_OWNERSHIP);

  async function handleTransfer(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    const newUserId = parseInt(values.userId);
    try {
      await transferOwnership({
        variables: {
          modelId,
          newUserId,
        },
      });
      toast.success('Model transferred successfully.');
    } catch (err) {
      console.log('Error transferring ownership', err);
      setValidationErrors(err, helpers);
      toast.error('There was a problem transferring ownership.');
    }
  }

  const userOptions = R.pipe(
    R.pathOr<User[]>([], ['userList']),
    R.sortBy(R.prop('name')),
    R.map(user => ({
      value: user.id,
      label: `${user.name} <${user.email}>`,
    })),
  )(userListData);

  const loading = loadingUserList || loadingTransfer;

  return (
    <Formik
      onSubmit={handleTransfer}
      initialValues={{ userId: String(userId) }}
      validationSchema={validationSchema}
    >
      <Form>
        <Card>
          <Card.Body>
            <Card.Title>Transfer Model Ownership</Card.Title>
            <p>
              Select a name from the list to transfer ownership of this model to
              a new user.
            </p>

            <Field
              name="userId"
              label="User"
              component={FormikField}
              type={FormikReactSelectField}
              options={userOptions}
              required
            />

            <FormikErrors graphQLError={error} />
          </Card.Body>

          <Card.Footer className="text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              Transfer
            </button>
          </Card.Footer>
        </Card>
      </Form>
    </Formik>
  );
};

export default ModelOwnershipTab;
