import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useModalState } from 'hooks/modal';
import { NOTIFICATIONS_QUERY } from 'components/modules/notifications/Notifications';
import * as fragments from 'apollo/fragments';
import { yup } from 'utils/validation';
import { ModelReview } from 'apollo/schema/types';
import { useAuth } from 'contexts/auth';
import { modelReviewTasksRoute } from 'routes';
import FormikField from 'components/common/FormikField';

const REJECT_MODEL_REVIEW = gql`
  mutation RejectModelReview($modelReviewId: Int!, $rejectionReason: String!) {
    rejectModelReview(
      modelReviewId: $modelReviewId
      rejectionReason: $rejectionReason
    ) {
      ...modelReviewParts
    }
  }
  ${fragments.modelReviewParts}
`;
type MutData = {
  rejectModelReview: ModelReview;
};
type MutVars = {
  modelReviewId: number;
  rejectionReason: string;
};

type FormValues = {
  rejectionReason: string;
};
const initialValues = (): FormValues => ({ rejectionReason: '' });
const validationSchema = yup.object({
  rejectionReason: yup
    .string()
    .required()
    .label('rejection reason'),
});

type Props = {
  children: (showModal: () => void) => React.ReactNode;
  modelReviewId: number;
};

const RejectModelReview: React.FC<Props> = ({
  children,
  modelReviewId,
}: Props) => {
  const history = useHistory();
  const { hasAnyRole } = useAuth();
  const { show, showModal, hideModal } = useModalState();
  const [rejectReview, { loading }] = useMutation<MutData, MutVars>(
    REJECT_MODEL_REVIEW,
    {
      refetchQueries: [{ query: NOTIFICATIONS_QUERY }],
    },
  );

  async function handleSubmit(values: FormValues) {
    const confirmText = 'Are you sure you want to reject the review?';
    if (!window.confirm(confirmText)) {
      return;
    }

    try {
      await rejectReview({
        variables: {
          modelReviewId,
          rejectionReason: values.rejectionReason,
        },
      });
      toast.success('The model review has been rejected.');
      hideModal();

      // Reviewers may not be able to see the model any more
      if (hasAnyRole(['reviewer'])) {
        history.replace(modelReviewTasksRoute());
      }
    } catch (err) {
      console.log('Error rejecting review', err);
      toast.error('There was a problem rejecting the review.');
    }
  }

  const handleCancel = (reason: string) => (e: React.MouseEvent) => {
    e.preventDefault();

    const confirmText =
      'Are you sure you would like to cancel? You will lose any data entered into this form.';
    if (reason.length > 0 && !window.confirm(confirmText)) {
      return;
    }

    hideModal();
  };

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal} backdrop="static" keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Request Changes</Modal.Title>
        </Modal.Header>

        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues()}
          validationSchema={validationSchema}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <p>
                  The review request will be rejected for the following reason.
                  The model will <b>not</b> be published, and the contributor
                  will receive an email with the given reason and instructions
                  on how to resubmit.
                </p>

                <Field
                  name="rejectionReason"
                  label="Rejection reason"
                  component={FormikField}
                  type="textarea"
                  style={{ height: '150px' }}
                  required
                />
              </Modal.Body>

              <Modal.Footer className="text-right">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancel(values.rejectionReason)}
                  disabled={loading}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn btn-danger"
                  disabled={loading}
                >
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default RejectModelReview;
