import React, { useEffect, useRef } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Card } from 'react-bootstrap';

import { useAuth } from 'contexts/auth';
import { Authority } from 'apollo/schema/types';
import { yup } from 'utils/validation';
import { UPDATE_PROFILE } from 'apollo/operations/user';
import { setValidationErrors } from 'utils/forms';
import FormikField from 'components/common/FormikField';
import FormikErrors from 'components/common/FormikErrors';
import Spacer from 'components/common/Spacer';
import { UpdateProfileData, UpdateProfileVars } from 'apollo/schema/operations';

type FormValues = {
  email: string;
  name: string;
  affiliation: string;
};

const initialValues = (authority: Authority): FormValues => {
  // New auth0 accounts will have their name set to the email field
  let name = authority.metadata.name;
  if (name === authority.metadata.email) {
    name = '';
  }

  return {
    name,
    email: authority.metadata.email,
    affiliation: authority.metadata.affiliation || '',
  };
};

function validationSchema(isLocal: boolean) {
  const name = isLocal
    ? yup
        .string()
        .trim()
        .required()
        .test(
          'not-email',
          `can't be an email address`,
          (value: string | null | undefined) => {
            if (!value) return true; // 'required' catches this
            const match = value.match(/@/g);
            return match === null || match.length === 0;
          },
        )
    : yup.string();

  return yup.object({
    name,
    affiliation: yup
      .string()
      .trim()
      .required(),
  });
}

const UpdateUser: React.FC = () => {
  const { authority, setAuthority } = useAuth();
  const [updateProfile, { loading, error }] = useMutation<
    UpdateProfileData,
    UpdateProfileVars
  >(UPDATE_PROFILE);

  if (!authority) throw new Error('not authenticated');

  const isProfileCompleted = authority!.isProfileCompleted;
  const isProfileCompletedRef = useRef(isProfileCompleted);

  // Show a toast when profile is completed
  useEffect(() => {
    const wasProfileCompleted = isProfileCompletedRef.current;

    if (!wasProfileCompleted && isProfileCompleted) {
      toast.success('Your profile is now complete.');
    }

    isProfileCompletedRef.current = isProfileCompleted;
  }, [isProfileCompleted]);

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    const userInput = {
      name: values.name.trim(),
      affiliation: values.affiliation.trim(),
    };

    try {
      const result = await updateProfile({ variables: { user: userInput } });

      const nextAuthority = result.data?.updateProfile;
      if (nextAuthority) {
        console.log('Update successful, setting new authority', nextAuthority);
        setAuthority(nextAuthority);
      } else {
        throw new Error('Error updating profile, see server logs');
      }
      toast.success('Profile updated successfully.');
    } catch (err) {
      console.log('Error updating profile', err);
      toast.error(
        'There was a problem updating your profile. Please try again.',
      );
      setValidationErrors(err, helpers);
      return;
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues(authority)}
      validationSchema={validationSchema(authority.metadata.isLocalUser)}
    >
      <Form>
        <Card>
          <Card.Body>
            <Card.Title>Update Profile</Card.Title>
            <Field
              name="email"
              label="Email"
              component={FormikField}
              disabled
            />

            <Field name="name" label="Name" component={FormikField} required />

            <Field
              name="affiliation"
              label="Affiliation"
              component={FormikField}
              required
            />
            <div style={{ marginTop: '-10px' }}>
              The name of the company, school, or institute with which you are
              affiliated
            </div>

            <Spacer v={10} />
            <FormikErrors graphQLError={error} />
          </Card.Body>

          <Card.Footer className="text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              Save
            </button>
          </Card.Footer>
        </Card>
      </Form>
    </Formik>
  );
};

export default UpdateUser;
