import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Modal } from 'react-bootstrap';

import { DECLINE_ORG_REQUEST } from 'apollo/operations/organization';
import {
  DeclineOrgRequestData,
  DeclineOrgRequestVars,
} from 'apollo/schema/operations';
import { useModalState } from 'hooks/modal';
import { yup } from 'utils/validation';
import { setValidationErrors } from 'utils/forms';
import FormikErrors from 'components/common/FormikErrors';
import FormikField from 'components/common/FormikField';

type FormValues = {
  declineReason: string;
};

type Props = {
  children: (showModal: () => void) => React.ReactNode;
  requestId: number;
};

const DeclineRequest: React.FC<Props> = ({ children, requestId }: Props) => {
  const { show, showModal, hideModal } = useModalState();
  const [declineRequest, { loading, error }] = useMutation<
    DeclineOrgRequestData,
    DeclineOrgRequestVars
  >(DECLINE_ORG_REQUEST);

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    try {
      await declineRequest({
        variables: {
          requestId,
          declineReason: values.declineReason,
        },
      });
      toast.success('Request declined successfully.');
    } catch (err) {
      console.log('Error declining org request', err);
      toast.error(
        'There was a problem accepting the organization request. Please try again.',
      );
      setValidationErrors(err, helpers);
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Decline Request</Modal.Title>
        </Modal.Header>

        <Formik
          onSubmit={handleSubmit}
          initialValues={{ declineReason: '' }}
          validationSchema={yup.object({
            declineReason: yup
              .string()
              .required()
              .label('decline reason'),
          })}
        >
          <Form>
            <Modal.Body>
              <p>
                This user's request to join your organization will be declined.
                Please enter a reason so that the user can be notified.
              </p>

              <Field
                name="declineReason"
                label="Decline reason"
                component={FormikField}
                required
              />

              <FormikErrors graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={hideModal}
                disabled={loading}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Confirm
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default DeclineRequest;
