import React, { useEffect, useRef } from 'react';
import * as R from 'ramda';
import { Field, useFormikContext } from 'formik';
import { Card } from 'react-bootstrap';

import { systems } from 'utils/modules/ageTag';
import { sortByArray } from 'utils/data';
import { categories, subcategories } from 'utils/modules/geologyTag';
import { SearchResult } from 'apollo/schema/operations';
import { ModelSearchFormValues } from 'utils/modules/modelSearch';
import FormikField from 'components/common/FormikField';
import { OptionableFilter } from './index';

type Props = {
  name: OptionableFilter;
  title: React.ReactNode;
  options: SearchResult[];
};

const FilterOptions: React.FC<Props> = ({ name, title, options }: Props) => {
  const { values, setFieldValue } = useFormikContext<ModelSearchFormValues>();

  const currentValues = values[name] || [];
  const validOptions = options.map(R.prop('name'));
  const validOptionsRef = useRef(validOptions);

  const invalidValues = R.filter(
    R.complement(R.contains(R.__, validOptions)),
    currentValues,
  );

  // If an option is set that is no longer valid, unset it
  useEffect(() => {
    const isOptionsUpdated =
      validOptions.length !== validOptionsRef.current.length ||
      R.isEmpty(R.symmetricDifference(validOptions, validOptionsRef.current));

    if (isOptionsUpdated && invalidValues.length) {
      setFieldValue(name, R.without(invalidValues, currentValues));
      validOptionsRef.current = validOptions;
    }
  }, [currentValues, invalidValues, name, setFieldValue, validOptions]);

  let sortedOptions = options;
  if (name === 'system') {
    sortedOptions = sortByArray(options, systems, 'name');
  } else if (name === 'category') {
    sortedOptions = sortByArray(options, categories, 'name');
  } else if (name === 'subcategory') {
    sortedOptions = sortByArray(options, subcategories, 'name');
  }

  return (
    <Card className="mb-3">
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        {sortedOptions.map(opt => (
          <Field
            key={opt.name}
            name={name}
            label={
              <>
                <span className="mr-2">{opt.name.replace(/\//g, ' / ')}</span>
                <span className="badge badge-primary">{opt.count}</span>
              </>
            }
            value={opt.name}
            component={FormikField}
            type="checkbox"
          />
        ))}
        {!sortedOptions.length && (
          <em className="text-muted">No options exist for this filter</em>
        )}
      </Card.Body>
    </Card>
  );
};

export default FilterOptions;
