import React from 'react';
import { Modal } from 'react-bootstrap';

import { useModalState } from 'hooks/modal';
import { useSafeState } from 'hooks/state';

type Props = {
  onConfirm: () => void | Promise<void>;
  title?: React.ReactNode;
  text?: React.ReactNode;
  submitText?: React.ReactNode;
  cancelText?: React.ReactNode;
  showCancel?: boolean;
  submitButtonClass?: string;
  children: (onConfirm: () => void) => React.ReactNode;
};

const Confirm: React.FC<Props> = ({
  onConfirm,
  title = 'Confirm',
  text = 'Are you sure you want to perform this action?',
  submitText = 'Confirm',
  cancelText = 'Cancel',
  showCancel = true,
  submitButtonClass = 'btn btn-primary',
  children,
}: Props) => {
  const { show, showModal, hideModal } = useModalState();
  const [disabled, setDisabled] = useSafeState(false);

  async function handleConfirm() {
    setDisabled(true);
    await onConfirm();
    hideModal();
    setDisabled(false);
  }

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{text}</Modal.Body>

        <Modal.Footer>
          {showCancel && (
            <button
              type="button"
              className="btn btn-default"
              onClick={hideModal}
              disabled={disabled}
              data-testid="confirmModal--cancel"
            >
              {cancelText}
            </button>
          )}

          <button
            type="button"
            className={submitButtonClass}
            onClick={handleConfirm}
            disabled={disabled}
            data-testid="confirmModal--submit"
          >
            {submitText}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Confirm;
