import React from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as he from 'he';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { useAuth } from 'contexts/auth';
import { Model } from 'apollo/schema/types';
import {
  defaultModel,
  ModelFormValues,
  modelValidationSchema,
} from 'utils/modules/model';
import { UPDATE_MODEL } from 'apollo/operations/model';
import FormikField from 'components/common/FormikField';
import FormikErrors from 'components/common/FormikErrors';
import { setValidationErrors } from 'utils/forms';
import { UpdateModelData, UpdateModelVars } from 'apollo/schema/operations';

function stripHtml(text: string): string {
  const strippedTags = text.replaceAll(/<[^>]*>/g, '');
  return he.decode(strippedTags);
}

function strippedDefaultModel(model: Model): ModelFormValues {
  const defaultVals = defaultModel(model);
  defaultVals.description = model.description
    ? stripHtml(model.description)
    : null;
  if (defaultVals.literature) {
    defaultVals.literature = stripHtml(defaultVals.literature);
  }
  return defaultVals;
}

type Props = {
  model: Model;
};

const FixModelHtml: React.FC<Props> = ({ model }: Props) => {
  const { authority } = useAuth();
  if (!authority) throw new Error('Must be logged in');

  const [updateModel, { loading, error }] = useMutation<
    UpdateModelData,
    UpdateModelVars
  >(UPDATE_MODEL);

  async function handleSubmit(
    values: ModelFormValues,
    helpers: FormikHelpers<ModelFormValues>,
  ) {
    try {
      await updateModel({
        variables: {
          id: model.id,
          model: values,
        },
      });
      toast.success('Model updated successfully.');
    } catch (err) {
      console.log('Error saving model', err);
      setValidationErrors(err, helpers);
      toast.error('There was a problem updating the model.');
    }
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={strippedDefaultModel(model)}
        validationSchema={modelValidationSchema(authority)}
        enableReinitialize
      >
        <Form>
          <h3>{model.name}</h3>

          <Field
            name="description"
            label="Description"
            component={FormikField}
            type="textarea"
            style={{ height: '200px' }}
            required
          />
          <Field
            name="literature"
            label="Literature"
            component={FormikField}
            style={{ height: '200px' }}
            type="textarea"
          />

          <FormikErrors graphQLError={error} />

          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default FixModelHtml;
