import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import qs from 'qs';

import { NEWS_OVERVIEW } from 'apollo/operations/news';
import { newsRoute } from 'routes';
import { Link } from 'react-router-dom';

function NewsOverview() {
  const { data } = useQuery(NEWS_OVERVIEW);

  const overview = R.pipe(
    R.propOr([], 'newsOverview'),
    R.defaultTo([]),
    R.reduce((acc, cur) => {
      const dateParts = R.split('-', cur);
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]);

      if (acc[year]) acc[year].push(month);
      else acc[year] = [month];

      return acc;
    }, {}),
  )(data);

  const diff = (a, b) => a - b;

  const years = R.pipe(
    R.keys,
    R.sort(diff),
    R.reverse,
    //
  )(overview);

  const months = year =>
    R.pipe(
      R.prop(year),
      R.defaultTo([]),
      R.sort(diff),
      //
    )(overview);

  const monthName = monthNumber =>
    moment()
      .month(monthNumber - 1)
      .format('MMMM');

  return (
    <ol className="list-unstyled">
      {years.map(year => (
        <li key={year} className="mt-2">
          <Link
            to={{
              pathname: newsRoute(),
              search: qs.stringify({ year }),
            }}
          >
            {year}
          </Link>
          <ol className="list-unstyled ml-3">
            {months(year).map(month => (
              <li key={month}>
                <Link
                  to={{
                    pathname: newsRoute(),
                    search: qs.stringify({ year, month }),
                  }}
                >
                  {monthName(month)}
                </Link>
              </li>
            ))}
          </ol>
        </li>
      ))}
    </ol>
  );
}

export default NewsOverview;
