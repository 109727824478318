import { Page } from 'apollo/schema/types';
import { PageInput } from 'apollo/schema/operations';
import { yup } from 'utils/validation';

export type PageFormValues = {
  page: PageInput;
};

export const defaultPage = (page?: Page): PageFormValues => ({
  page: {
    slug: page?.slug || '',
    title: page?.title || '',
    content: page?.content || '',
    isSecured: page?.isSecured || false,
  },
});

export const validationSchema = yup.object({
  page: yup.object({
    slug: yup
      .string()
      .required()
      .matches(/^[a-zA-Z\d-]+[a-zA-Z\d]$/)
      .label('slug'),
    title: yup
      .string()
      .required()
      .label('title'),
    content: yup
      .string()
      .required()
      .label('content'),
  }),
});
