import React from 'react';
import { useRouteMatch } from 'react-router';
import { useQuery, gql } from '@apollo/client';

import * as routes from 'routes';
import * as fragments from 'apollo/fragments';
import StandardLayout from 'components/layouts/StandardLayout';
import { Organization } from 'apollo/schema/types';
import Spinner from 'components/common/Spinner';
import { useSortFilter } from 'hooks/data';
import SortTrigger from 'components/common/SortTrigger';
import { Link } from 'react-router-dom';

const ADMIN_ORG_LIST_PAGE = gql`
  query AdminOrgListPage {
    organizationList {
      ...orgParts
      users {
        ...userParts
      }
    }
  }

  ${fragments.orgParts}
  ${fragments.userParts}
`;
type AdminOrgListPageData = {
  organizationList: Organization[];
};

type OrgWithMeta = Organization & {
  userCount: number;
};

function addMetaToOrgs(orgList: Organization[]): OrgWithMeta[] {
  return orgList.map(org => ({
    ...org,
    userCount: org.users.length,
  }));
}

type UserCountProps = {
  count: number;
};

const UserCount: React.FC<UserCountProps> = ({ count }: UserCountProps) => {
  if (count > 0) return <>{count}</>;
  return (
    <span className="text-danger">
      <b>{count}</b>
    </span>
  );
};

const AdminOrganizationListPage: React.FC = () => {
  const match = useRouteMatch();
  const { data, loading } = useQuery<AdminOrgListPageData>(ADMIN_ORG_LIST_PAGE);
  const orgList = addMetaToOrgs(data?.organizationList || []);
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    orgList,
    'name',
    'name',
  );

  return (
    <StandardLayout
      breadcrumb={[
        [routes.adminRoute(), 'Dashboard'],
        [match.path, 'Organizations'],
      ]}
    >
      <h1>Organizations</h1>

      <Spinner show={loading} />

      <table className="table">
        <thead>
          <tr>
            <th>
              <SortTrigger colName="id" sortIndicatorProps={siProps}>
                ID
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="name" sortIndicatorProps={siProps}>
                Name
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger colName="userCount" sortIndicatorProps={siProps}>
                Users
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="createdAt" sortIndicatorProps={siProps}>
                Created
              </SortTrigger>
            </th>
            <th />
          </tr>
        </thead>

        <tbody>
          {!items.length && !loading && (
            <tr>
              <td colSpan={4} className="text-center text-muted">
                No organizations found.
              </td>
            </tr>
          )}
          {items.map(org => (
            <tr key={org.id}>
              <td>{org.id}</td>
              <td>{org.name}</td>
              <td className="text-center">
                <UserCount count={org.userCount} />
              </td>
              <td>{org.createdAt}</td>
              <td className="text-right">
                <Link to={routes.adminOrgRoute(org.id)}>View</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StandardLayout>
  );
};

export default AdminOrganizationListPage;
