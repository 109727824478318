import React, { useRef, useState, useEffect } from 'react';

import { useDebounce } from 'hooks/useDebounce';
import { FieldProps } from 'formik';

type Props = FieldProps & {
  placeholder: string;
};

const NameSearchField: React.FC<Props> = ({
  form,
  field,
  placeholder,
  ...props
}: Props) => {
  const { setFieldValue } = form;
  const { value: formValue, name: fieldName } = field;
  const formValueRef = useRef(formValue);

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 250);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  useEffect(() => {
    setFieldValue(fieldName, debouncedValue);
  }, [fieldName, setFieldValue, debouncedValue]);

  // This form's values are controlled inside this component's state and then
  // debounced up to the formik form. This effect watches the formik form value,
  // since it may be cleared by another means. If it detects the value has been cleared,
  // this effect will reset the local form field value.
  useEffect(() => {
    if (formValueRef.current && !formValue) {
      setValue('');
    }

    formValueRef.current = formValue;
  }, [formValue]);

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      className="form-control"
      placeholder={placeholder}
    />
  );
};

export default NameSearchField;
