import React from 'react';
import * as R from 'ramda';
import { Formik, Form, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { Page } from 'apollo/schema/types';
import { UPDATE_PAGE } from 'apollo/operations/page';
import { UpdatePageData, UpdatePageVars } from 'apollo/schema/operations';
import { setValidationErrors } from 'utils/forms';
import PageForm from 'components/modules/page/PageForm';
import {
  defaultPage,
  PageFormValues,
  validationSchema,
} from 'utils/modules/page';
import FormikErrors from 'components/common/FormikErrors';

type Props = {
  page: Page;
};

const UpdatePage: React.FC<Props> = ({ page }: Props) => {
  const [updatePage, { loading, error }] = useMutation<
    UpdatePageData,
    UpdatePageVars
  >(UPDATE_PAGE);

  async function handleSubmit(
    values: PageFormValues,
    helpers: FormikHelpers<PageFormValues>,
  ) {
    const updatedPage = R.pipe(
      R.prop('page'),
      R.pick(['slug', 'title', 'content', 'isSecured']),
    )(values);

    try {
      await updatePage({
        variables: {
          id: page.id,
          page: updatedPage,
        },
      });
      toast.success('Page saved successfully.');
    } catch (err) {
      setValidationErrors(err, helpers);
      console.log('Error updating page:', err);
      toast.error('There was a problem updating the page.');
    }
  }

  return (
    <Formik
      initialValues={defaultPage(page)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <Form>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Update Page</h5>

            <PageForm />
            <FormikErrors graphQLError={error} />
          </div>
          <div className="card-footer text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              Save
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default UpdatePage;
