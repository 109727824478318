import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Nav } from 'react-bootstrap';
import { Model, RefetchQueries } from 'apollo/schema/types';
import * as routes from 'routes';
import * as fragments from 'apollo/fragments';
import NavLink from 'components/common/NavLink';
import Spinner from 'components/common/Spinner';
import NotFound from 'components/errors/NotFound';
import RoleSecured from 'components/common/RoleSecured';
import SecuredRoute from 'components/common/SecuredRoute';
import ModelAgeTagsTab from './ModelAgeTagsTab';
import ModelDetailTab from './ModelDetailTab';
import ModelImagesTab from './ModelImagesTab';
import ModelGeoreferencesTab from './ModelGeoreferencesTab';
import ModelGeologyTagsTab from './ModelGeologyTagsTab';
import ModelInterpretationsTab from './ModelInterpretationsTab';

export const MODEL_DETAIL = gql`
  query ModelDetail($id: Int!) {
    model(id: $id) {
      ...modelParts
      images {
        ...modelImageParts
        file {
          ...fileParts
        }
      }
      georeferences {
        ...georeferenceParts
      }
      ageTags {
        ...ageTagParts
      }
      geologyTags {
        ...geologyTagParts
      }
      reviews {
        ...modelReviewParts
      }
      publicLinks {
        ...modelPublicLinkParts
      }
    }
  }
  ${fragments.modelParts}
  ${fragments.modelImageParts}
  ${fragments.fileParts}
  ${fragments.georeferenceParts}
  ${fragments.ageTagParts}
  ${fragments.geologyTagParts}
  ${fragments.modelReviewParts}
  ${fragments.modelPublicLinkParts}
`;
export type ModelDetailData = {
  model: Model;
};
export type ModelDetailVars = {
  id: number;
};

type Props = {
  modelId: number;
  locked: boolean;
};

function ModelEditPage({ modelId, locked }: Props): JSX.Element | null {
  const match = useRouteMatch();
  const { data, loading } = useQuery<ModelDetailData, ModelDetailVars>(
    MODEL_DETAIL,
    { variables: { id: modelId } },
  );

  const refetchQueries: RefetchQueries = [
    { query: MODEL_DETAIL, variables: { id: modelId } },
  ];

  const model = data?.model;

  if (loading) return <Spinner />;
  if (!model) return null;

  return (
    <>
      <Nav variant="tabs" className="mb-2">
        <Nav.Item>
          <NavLink
            exact
            to={routes.modelEditInfoTabRoute(modelId)}
            className="nav-link"
          >
            Key Details
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            to={routes.modelEditImagesTabRoute(modelId)}
            className="nav-link"
          >
            Images
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            to={routes.modelEditGeoreferencesTabRoute(modelId)}
            className="nav-link"
          >
            Georeference
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            to={routes.modelEditGeologyTagsTabRoute(modelId)}
            className="nav-link"
          >
            Geology Tags
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            to={routes.modelEditAgeTagsTabRoute(modelId)}
            className="nav-link"
          >
            Geological Age
          </NavLink>
        </Nav.Item>

        <RoleSecured roles={['admin']}>
          <Nav.Item>
            <NavLink
              to={routes.modelEditInterpretationsTabRoute(modelId)}
              className="nav-link"
            >
              Interpretations
            </NavLink>
          </Nav.Item>
        </RoleSecured>
      </Nav>

      <Switch>
        <Route path={match.path} exact>
          <ModelDetailTab model={model} />
        </Route>

        <Route path={`${match.path}/images`}>
          <ModelImagesTab model={model} locked={locked} />
        </Route>

        <Route path={`${match.path}/georeferences`}>
          <ModelGeoreferencesTab
            model={model}
            refetchQueries={refetchQueries}
            locked={locked}
          />
        </Route>

        <Route path={`${match.path}/age-tags`}>
          <ModelAgeTagsTab
            model={model}
            refetchQueries={refetchQueries}
            locked={locked}
          />
        </Route>

        <Route path={`${match.path}/geology-tags`}>
          <ModelGeologyTagsTab
            model={model}
            refetchQueries={refetchQueries}
            locked={locked}
          />
        </Route>

        <SecuredRoute path={`${match.path}/interpretations`} roles={['admin']}>
          <ModelInterpretationsTab modelId={model.id} />
        </SecuredRoute>

        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default ModelEditPage;
