import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as R from 'ramda';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from '@react-google-maps/api';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { modelRoute } from 'routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Georeference, Model } from 'apollo/schema/types';
import { createBounds, toLatLng } from 'utils/map';
import { styles } from 'utils/mapStyles';
import ModelMapList from 'components/modules/modelSearch/ModelSearchMap/ModelMapList';
import SelectedFilters from '../SelectedFilters';

const MapModelLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 5px 5px 5px;
  padding-right: 30px;
  color: #333333;
  font-weight: normal;
  text-decoration: none;

  & div.model-name {
    font-size: 12pt;
    font-weight: bold;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: hsl(36, 100%, 50%);
  }
`;

export class ModelWithCenter {
  model: Model;
  center: Georeference;

  constructor(model: Model, center: Georeference) {
    this.model = model;
    this.center = center;
  }
}

type Props = {
  models: Model[];
};

const ModelSearchMap: React.FC<Props> = ({ models }: Props) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selected, setSelected] = useState<ModelWithCenter[]>([]);

  let modelsWithCenter = models
    .map(model => {
      const modelGeorefs = model.georeferences;
      const center = modelGeorefs.find(g => g.dataType === 'CENTER');
      if (!center) return null;
      return new ModelWithCenter(model, center);
    })
    .filter(mwc => mwc !== null) as ModelWithCenter[];

  const getCoords = (mwc: ModelWithCenter) => {
    const coords = mwc.center.data.coordinates[0];
    return toLatLng(coords);
  };

  const currentGeorefs = modelsWithCenter.map(mwc => mwc.model.id);
  const currentGeorefsRef = useRef<number[]>(currentGeorefs);
  const mapRef = useRef<google.maps.Map | null>(map);

  function fitBounds() {
    if (!map) return;
    if (!modelsWithCenter.length) {
      map.setZoom(1);
      map.setCenter({ lat: 0, lng: 0 });
      return;
    }

    const bounds = createBounds(modelsWithCenter.map(mwc => mwc.center));
    map.fitBounds(bounds);
  }

  useEffect(() => {
    const difference = R.symmetricDifference(
      currentGeorefs,
      currentGeorefsRef.current,
    );
    const isGeorefsUpdated = difference.length > 0;

    if (isGeorefsUpdated) {
      // Call fitBounds when georefs list changes
      fitBounds();

      // Hide any open info windows in case the model was filtered out
      setSelected([]);
    }

    // Call fitBounds when map is done loading to ensure a center/zoom is set even if no georefs
    if (!mapRef.current && map) {
      fitBounds();
    }

    currentGeorefsRef.current = currentGeorefs;
    mapRef.current = map;
  }, [currentGeorefs]);

  function toggleSelected(mwc: ModelWithCenter) {
    let nextSelected: ModelWithCenter[];

    const currentModelIds = selected.map(s => s.model.id);
    if (currentModelIds.includes(mwc.model.id)) {
      nextSelected = selected.filter(s => s.model.id !== mwc.model.id);
    } else {
      nextSelected = [...selected, mwc];
    }

    setSelected(nextSelected);
  }

  const onLoad = useCallback(setMap, [modelsWithCenter]);

  function handleBoundsChanged() {
    const bounds = map?.getBounds();
    if (!bounds) {
      return;
    }

    // Create list of visible georeferences within given bounds
    const visibleModels = modelsWithCenter.filter(mwc => {
      const position = getCoords(mwc);
      return bounds.contains(position);
    });

    // If there are 3 or fewer visible models, automatically display all infowindows
    // (as long as there aren't any infowindows already open)
    if (visibleModels.length <= 3) {
      const nextSelected = R.pipe(
        R.concat(visibleModels),
        R.uniqBy(R.path(['model', 'id'])),
      )(selected);
      setSelected(nextSelected);
    }
  }

  function handleListSelect(mwc: ModelWithCenter) {
    toggleSelected(mwc);

    // Move the map to the model center
    const isSelected = selected.map(s => s.model.id).includes(mwc.model.id);
    if (!isSelected) {
      map?.setCenter(getCoords(mwc));
      map?.setZoom(10);
    }
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <button
          type="button"
          className="btn btn-secondary float-right ml-3"
          onClick={fitBounds}
          style={{ position: 'absolute', bottom: 0, right: 0 }}
        >
          <FontAwesomeIcon icon="crosshairs" /> Recenter map
        </button>

        <SelectedFilters />

        <div
          className="text-center text-muted"
          style={{ paddingBottom: '14px' }}
        >
          Displaying <b>{models.length}</b> results
        </div>
      </div>

      <div className="clearfix" />

      <GoogleMap
        id="georeferences"
        mapContainerStyle={{
          height: '400px',
          width: '100%',
        }}
        options={{
          styles,
          mapTypeId: 'terrain',
          streetViewControl: false,
          fullscreenControl: true,
        }}
        onLoad={onLoad}
        onIdle={handleBoundsChanged}
      >
        <MarkerClusterer options={{ maxZoom: 8 }}>
          {clusterer =>
            modelsWithCenter.map(mwc => (
              <Marker
                key={mwc.center.id}
                position={getCoords(mwc)}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  fillColor: '#FF9800',
                  fillOpacity: 0.9,
                  strokeColor: '#cc7a00',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                  scale: 6,
                }}
                onClick={() => toggleSelected(mwc)}
                clusterer={clusterer}
              />
            ))
          }
        </MarkerClusterer>

        {selected.map(mwc => (
          <InfoWindow
            key={mwc.model.id}
            position={getCoords(mwc)}
            onCloseClick={() => toggleSelected(mwc)}
            // This can be disabled if the map interrupts panning to reposition
            // to display the info windows. Using onIdle instead of onBoundsChanged
            // as the trigger seems to work well without disabling auto-pan.
            // options={{ disableAutoPan: true }}
          >
            <MapModelLink
              to={modelRoute(mwc.model.id)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="model-name">{mwc.model.name}</div>
              View model &raquo;
            </MapModelLink>
          </InfoWindow>
        ))}
      </GoogleMap>

      <ModelMapList
        models={models}
        selectedModels={selected}
        onToggleSelected={handleListSelect}
      />
    </>
  );
};

export default ModelSearchMap;
