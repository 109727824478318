import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Card } from 'react-bootstrap';

import {
  ListModelFilesData,
  ListModelFilesVars,
} from 'apollo/schema/operations';
import HelpBox from 'components/common/HelpBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Confirm from 'components/common/Confirm';
import DeleteModelFiles from 'components/modules/model/DeleteModelFiles';
import { useSortFilter } from 'hooks/data';
import { RefetchQueries } from 'apollo/schema/types';
import SortTrigger from 'components/common/SortTrigger';
import DateTime from 'components/common/DateTime';

// This page manages the FINAL model files uploaded by admins

const MODEL_FILES = gql`
  query ModelFiles($modelId: Int!) {
    listModelFiles(modelId: $modelId) {
      count
      results {
        key
        size
        lastModified
      }
    }
  }
`;

type Props = {
  modelId: number;
  isPublished: boolean;
};

function ModelFilesTab({ modelId, isPublished }: Props): JSX.Element {
  const { data, loading } = useQuery<ListModelFilesData, ListModelFilesVars>(
    MODEL_FILES,
    { variables: { modelId } },
  );

  const refetchQueries: RefetchQueries = [
    { query: MODEL_FILES, variables: { modelId } },
  ];

  const currentCount = data?.listModelFiles?.count;
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    data?.listModelFiles?.results ?? [],
    'key',
    'key',
  );

  console.log('model files:', data);

  return (
    <>
      <HelpBox>
        This page manages the final model files uploaded by V3Geo Administrators
      </HelpBox>

      <Card className="mt-3">
        <Card.Header>
          <div className="float-right">
            <DeleteModelFiles modelId={modelId} refetchQueries={refetchQueries}>
              {(deleteModelFiles, loadingDeleteAll) => (
                <Confirm
                  onConfirm={deleteModelFiles}
                  text={
                    <>
                      <b className="text-danger">
                        WARNING: All model files are about to be deleted. This
                        cannot be undone.
                      </b>{' '}
                      Are you sure you want to proceed?
                    </>
                  }
                >
                  {confirmDeleteAll => (
                    <button
                      type="button"
                      className="btn btn-link text-danger"
                      style={{ padding: 0 }}
                      onClick={confirmDeleteAll}
                      disabled={
                        isPublished ||
                        loading ||
                        loadingDeleteAll ||
                        !items.length
                      }
                    >
                      <FontAwesomeIcon icon="trash" /> Delete All Files
                    </button>
                  )}
                </Confirm>
              )}
            </DeleteModelFiles>
          </div>
          Uploaded Files{!!currentCount && ` (${currentCount})`}
          <div className="clearfix" />
        </Card.Header>
        <Card.Body>
          {!loading && !items.length && (
            <div className="text-center text-muted">
              <i>No files have been uploaded to this model.</i>
            </div>
          )}

          {items.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <SortTrigger colName="key" sortIndicatorProps={siProps}>
                      Filename
                    </SortTrigger>
                  </th>
                  <th>
                    <SortTrigger
                      colName="lastModified"
                      sortIndicatorProps={siProps}
                    >
                      Last Modified
                    </SortTrigger>
                  </th>
                  <td>
                    <SortTrigger colName="size" sortIndicatorProps={siProps}>
                      Size
                    </SortTrigger>
                  </td>
                </tr>
              </thead>

              <tbody>
                {items.map(f => (
                  <tr key={f.key}>
                    <td>{f.key}</td>
                    <td>
                      <DateTime format="LLL" date={f.lastModified} />
                    </td>
                    <td>{f.size}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default ModelFilesTab;
