import React from 'react';

import { Role } from 'apollo/schema/types';
import { useAuth } from 'contexts/auth';

type Props = {
  children: React.ReactNode;
  roles?: Role[];
  guest?: boolean;
};

const RoleSecured: React.FC<Props> = ({
  children,
  roles = [],
  guest = false,
}: Props) => {
  const { isAuthenticated, hasAnyRole } = useAuth();

  // Only render when anonymous if guest prop is set
  if (guest) {
    return isAuthenticated ? null : <>{children}</>;
  }

  // Render nothing if the user is not authenticated or does not have one of the provided roles
  // Maybe add a fallback render prop for content to render instead of nothing
  if (!isAuthenticated || !hasAnyRole(roles)) {
    return null;
  }

  return <>{children}</>;
};

export default RoleSecured;
