import React from 'react';
import { Switch, Route, useRouteMatch, NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

import * as routes from 'routes';
import { useAuth } from 'contexts/auth';
import StandardLayout from 'components/layouts/StandardLayout';
import RoleSecured from 'components/common/RoleSecured';
import SecuredRoute from 'components/common/SecuredRoute';
import MyOrganizationTab from './MyOrganizationTab';
import OrganizationRequestList from 'components/modules/organization/OrganizationRequestList';
import OrganizationUserList from 'components/modules/organization/OrganizationUserList';
import CreateOrJoinOrganization from 'components/modules/organization/CreateOrJoinOrganization';
import MyOrganizationInvites from 'components/modules/organization/MyOrganizationInvites';
import OrganizationInviteList from 'components/modules/organization/OrganizationInviteList';

const OrganizationPage: React.FC = () => {
  const match = useRouteMatch();
  const { authority } = useAuth();
  if (!authority) throw new Error('Must be logged in');

  const org = authority.metadata.organization;

  return (
    <StandardLayout breadcrumb={[[match.path, 'Organization']]}>
      {!org && (
        <>
          <CreateOrJoinOrganization />
          <div className="mt-3" />
          <MyOrganizationInvites />
        </>
      )}

      {org && (
        <>
          <Nav variant="pills" className="mb-2">
            <Nav.Item>
              <NavLink to={routes.orgRoute()} exact className="nav-link">
                My Organization
              </NavLink>
            </Nav.Item>

            <RoleSecured roles={['admin', 'organizationAdmin']}>
              <Nav.Item>
                <NavLink to={routes.orgUsersTabRoute()} className="nav-link">
                  Members
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink to={routes.orgInvitesTabRoute()} className="nav-link">
                  Invitations
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink to={routes.orgRequestsTabRoute()} className="nav-link">
                  Requests
                </NavLink>
              </Nav.Item>
            </RoleSecured>
          </Nav>

          <Switch>
            <Route path={match.path} exact>
              <MyOrganizationTab organization={org} />
            </Route>

            <SecuredRoute
              path={`${match.path}/members`}
              roles={['admin', 'organizationAdmin']}
            >
              <OrganizationUserList orgId={org.id} />
            </SecuredRoute>

            <SecuredRoute
              path={`${match.path}/invites`}
              roles={['admin', 'organizationAdmin']}
            >
              <OrganizationInviteList orgId={org.id} />
            </SecuredRoute>

            <SecuredRoute
              path={`${match.path}/requests`}
              roles={['admin', 'organizationAdmin']}
            >
              <OrganizationRequestList orgId={org.id} />
            </SecuredRoute>
          </Switch>
        </>
      )}
    </StandardLayout>
  );
};

export default OrganizationPage;
