import React from 'react';
import * as R from 'ramda';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { RefetchQueries, User } from 'apollo/schema/types';
import {
  GRANT_ORG_ADMIN,
  REVOKE_ORG_ADMIN,
  GRANT_ORG_EDITOR,
  REVOKE_ORG_EDITOR,
  GRANT_ORG_SHARER,
  REVOKE_ORG_SHARER,
  REMOVE_USER_FROM_ORG,
} from 'apollo/operations/organization';
import {
  GrantOrgAdminData,
  GrantOrgEditorData,
  GrantOrgSharerData,
  OrgRoleMutVars,
  RemoveUserFromOrgData,
  RemoveUserFromOrgVars,
  RevokeOrgAdminData,
  RevokeOrgEditorData,
  RevokeOrgSharerData,
} from 'apollo/schema/operations';
import { useAuth } from 'contexts/auth';
import Confirm from 'components/common/Confirm';

type Props = {
  user: User;
  isOrgAdmin: boolean;
  isOrgEditor: boolean;
  isOrgSharer: boolean;
  refetchQueries: RefetchQueries;
};

const ManageOrganizationUserRoles: React.FC<Props> = ({
  user,
  isOrgAdmin,
  isOrgEditor,
  isOrgSharer,
  refetchQueries,
}: Props) => {
  const { authority } = useAuth();
  if (!authority) throw new Error('User should be logged in');
  const userId = user.id;

  const [removeUserFromOrg, { loading: lrmufo }] = useMutation<
    RemoveUserFromOrgData,
    RemoveUserFromOrgVars
  >(REMOVE_USER_FROM_ORG, { variables: { userId: user.id }, refetchQueries });
  const [grantOrgAdmin, { loading: lga }] = useMutation<
    GrantOrgAdminData,
    OrgRoleMutVars
  >(GRANT_ORG_ADMIN, { variables: { userId } });
  const [revokeOrgAdmin, { loading: lra }] = useMutation<
    RevokeOrgAdminData,
    OrgRoleMutVars
  >(REVOKE_ORG_ADMIN, { variables: { userId } });
  const [grantOrgEditor, { loading: lge }] = useMutation<
    GrantOrgEditorData,
    OrgRoleMutVars
  >(GRANT_ORG_EDITOR, { variables: { userId } });
  const [revokeOrgEditor, { loading: lre }] = useMutation<
    RevokeOrgEditorData,
    OrgRoleMutVars
  >(REVOKE_ORG_EDITOR, { variables: { userId } });
  const [grantOrgSharer, { loading: lgs }] = useMutation<
    GrantOrgSharerData,
    OrgRoleMutVars
  >(GRANT_ORG_SHARER, { variables: { userId } });
  const [revokeOrgSharer, { loading: lrs }] = useMutation<
    RevokeOrgSharerData,
    OrgRoleMutVars
  >(REVOKE_ORG_SHARER, { variables: { userId } });

  const loading = R.any(R.equals(true), [lrmufo, lga, lra, lge, lre, lgs, lrs]);

  async function handleRemove() {
    try {
      await removeUserFromOrg();
      toast.success('User removed from organization.');
    } catch (err) {
      console.log('Error removing user from organization', err);
      toast.error(
        'There was a problem removing the user from the organization. Please try again.',
      );
    }
  }

  async function handleOrgAdmin() {
    const mutFn = isOrgAdmin ? revokeOrgAdmin : grantOrgAdmin;

    try {
      await mutFn();
    } catch (err) {
      toast.error(
        `There was a problem ${
          isOrgAdmin ? 'revoking' : 'granting'
        } the role, please try again.`,
      );
    }
  }

  async function handleOrgEditor() {
    const mutFn = isOrgEditor ? revokeOrgEditor : grantOrgEditor;

    try {
      await mutFn();
    } catch (err) {
      toast.error(
        `There was a problem ${
          isOrgEditor ? 'revoking' : 'granting'
        } the role, please try again.`,
      );
    }
  }

  async function handleOrgSharer() {
    const mutFn = isOrgSharer ? revokeOrgSharer : grantOrgSharer;

    try {
      await mutFn();
    } catch (err) {
      toast.error(
        `There was a problem ${
          isOrgSharer ? 'revoking' : 'granting'
        } the role, please try again.`,
      );
    }
  }

  return (
    <>
      <DropdownButton
        id={`orgUserMgmtDropdown--${user.id}`}
        title="Manage"
        size="sm"
        variant="secondary"
        disabled={loading}
      >
        {/* User can't remove themself from org */}
        {authority.userId !== user.id && (
          <Confirm
            onConfirm={handleRemove}
            text="This user will be removed from the organization."
          >
            {onConfirm => (
              <Dropdown.Item onClick={onConfirm}>Remove User</Dropdown.Item>
            )}
          </Confirm>
        )}

        <Confirm
          onConfirm={handleOrgAdmin}
          text="You are about to remove the organization admin role from yourself. Are you sure you want to continue?"
        >
          {confirm => (
            <Dropdown.Item
              onClick={
                isOrgAdmin && user.id === authority.userId
                  ? confirm
                  : handleOrgAdmin
              }
              disabled={loading}
            >
              {isOrgAdmin ? 'Revoke' : 'Grant'} Organization Admin
            </Dropdown.Item>
          )}
        </Confirm>
        <Dropdown.Item onClick={handleOrgEditor} disabled={loading}>
          {isOrgEditor ? 'Revoke' : 'Grant'} Organization Editor
        </Dropdown.Item>
        <Dropdown.Item onClick={handleOrgSharer} disabled={loading}>
          {isOrgSharer ? 'Revoke' : 'Grant'} Organization Sharer
        </Dropdown.Item>
      </DropdownButton>
    </>
  );
};

export default ManageOrganizationUserRoles;
