import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { AgeTag, RefetchQueries } from 'apollo/schema/types';
import { DELETE_AGE_TAG } from 'apollo/operations/ageTag';
import Confirm from 'components/common/Confirm';

type Props = {
  ageTag: AgeTag;
  refetchQueries: RefetchQueries;
  disabled: boolean;
};

const AgeTagRow: React.FC<Props> = ({
  ageTag,
  refetchQueries,
  disabled,
}: Props) => {
  const [deleteAgeTag, { loading }] = useMutation(DELETE_AGE_TAG, {
    variables: {
      modelId: ageTag.modelId,
      ageTagId: ageTag.id,
    },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteAgeTag();
      toast.info('Age tag deleted.');
    } catch (err) {
      console.log('Error deleting age tag', err);
      toast.error(
        'There was a problem deleting the age tag. It may have already been deleted.',
      );
    }
  }

  return (
    <tr>
      <td className="align-middle">{ageTag.system}</td>
      <td className="align-middle text-right">
        <Confirm
          onConfirm={handleDelete}
          text="The age tag will be removed from the model."
        >
          {onConfirm => (
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={onConfirm}
              disabled={disabled || loading}
            >
              Remove
            </button>
          )}
        </Confirm>
      </td>
    </tr>
  );
};

export default AgeTagRow;
