import { GeoreferenceDataType } from 'apollo/schema/types';
import { yup } from 'utils/validation';

export const georeferenceDataTypes: GeoreferenceDataType[] = [
  'CENTER',
  // 'POINT',
  // 'OUTLINE',
  // 'POLYGON',
  // 'POLYLINE',
];

export interface GeoreferenceFormValuesCoordinate {
  latitude: number | string;
  longitude: number | string;
}
export interface GeoreferenceDataValue {
  coordinates: GeoreferenceFormValuesCoordinate[];
}
export interface GeoreferenceFormValues {
  name: string | null;
  description: string | null;
  dataType: GeoreferenceDataType | null;
  data: null | GeoreferenceDataValue;
}

export const defaultGeoreference = (): GeoreferenceFormValues => ({
  name: null,
  description: null,
  dataType: null,
  data: null,
});

export const validationSchema = yup.object({
  name: yup.string().nullable(),
  description: yup.string().nullable(),
  dataType: yup
    .string()
    .label('georeference type')
    .oneOf(georeferenceDataTypes)
    .required(),
  data: yup
    .object({
      coordinates: yup.array().of(
        yup.object({
          latitude: yup.string().required(),
          longitude: yup.string().required(),
        }),
      ),
    })
    .label('coordinates')
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} can't be empty")
    .required(),
});

/** Limits object for the georeference types.
 *  Specify 0 to disable, -1 for infinite (or leave empty), or a number to set a limit */
export type GeoreferenceLimits = Partial<Record<GeoreferenceDataType, number>>;
