import React from 'react';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';

import { modelEditPublishingTabRoute } from 'routes';
import { RefetchQueries } from 'apollo/schema/types';
import * as fragments from 'apollo/fragments';
import { prettyDate } from 'utils/text';
import { useAuth } from 'contexts/auth';
import { ModelData, ModelRequirementsMetData } from 'apollo/schema/operations';
import { publishingRequirementsMet } from 'utils/modules/model';
import Spinner from 'components/common/Spinner';
import CreateModelReviewButton from 'components/modules/modelReview/CreateModelReviewButton';
import RoleSecured from 'components/common/RoleSecured';
import Confirm from 'components/common/Confirm';
import ApproveModelReview from 'components/modules/modelReview/ApproveModelReview';
import RejectModelReview from 'components/modules/modelReview/RejectModelReview';
import CancelReview from 'components/modules/modelReview/CancelReview';
import HelpBox from 'components/common/HelpBox';
import GreyBox from 'components/common/GreyBox';
import RequirementsChecklist from 'components/modules/model/RequirementsChecklist';
import Tooltip from 'components/common/Tooltip';

const MODEL_PUBLISHING_TAB = gql`
  query ModelPublishingTab($modelId: Int!) {
    model(id: $modelId) {
      ...modelParts
      reviews {
        ...modelReviewParts
        reviewedBy {
          ...userParts
        }
      }
    }

    modelRequirementsMet(modelId: $modelId) {
      ...modelRequirementsMetParts
    }
  }

  ${fragments.modelParts}
  ${fragments.modelReviewParts}
  ${fragments.userParts}
  ${fragments.modelRequirementsMetParts}
`;
type QueryData = ModelData & ModelRequirementsMetData;
type QueryVars = {
  modelId: number;
};

type ReasonFormatterProps = { reason: string | null };
const ReasonFormatter = ({ reason }: ReasonFormatterProps) =>
  reason ? (
    <div style={{ whiteSpace: 'pre-line', overflowWrap: 'anywhere' }}>
      {reason}
    </div>
  ) : null;

type Props = {
  modelId: number;
};

const TechnicalReviewTab: React.FC<Props> = ({ modelId }: Props) => {
  const { authority } = useAuth();
  if (!authority) throw new Error('not authenticated');

  const { data, loading } = useQuery<QueryData, QueryVars>(
    MODEL_PUBLISHING_TAB,
    { variables: { modelId } },
  );

  const refetchQueries: RefetchQueries = [
    { query: MODEL_PUBLISHING_TAB, variables: { modelId } },
  ];

  if (loading) return <Spinner />;
  if (!data) return null;

  const { model, modelRequirementsMet } = data;

  const reviews = R.sortWith([R.descend(R.prop('updatedAt'))], model.reviews);
  const pendingReview = reviews.filter(r => r.isApproved === null)[0];
  const pastReviews = reviews.filter(r => r.isApproved !== null);
  const firstRejectedReview =
    !pendingReview && reviews[0]?.isApproved === false ? reviews[0] : null;

  const isModelOwner = model?.userId === authority.userId;

  const canSubmit = publishingRequirementsMet(modelRequirementsMet);

  return (
    <>
      {model.isPrivate && (
        <HelpBox className="mb-3">
          Since this model is private, it doesn't need to be reviewed. If you
          would like to publish the model, you may do so directly on the{' '}
          <Link to={modelEditPublishingTabRoute(model.id)}>Publishing</Link>{' '}
          tab.
        </HelpBox>
      )}
      {!model.isPublished && !pendingReview && !model.isPrivate && (
        <Row>
          <Col md={8}>
            <Card className="mb-3">
              <Card.Body>
                {!firstRejectedReview ? (
                  <>
                    <h3>Submit model for review</h3>
                    <p>
                      Once you are finished making any changes to your model, it
                      can be submitted to be published to V3Geo.
                    </p>
                  </>
                ) : (
                  <>
                    <h3>Resubmit model for review</h3>

                    <GreyBox className="my-2 p-3 text-center">
                      <div className="text-danger greybox-title">
                        Your model was not approved
                      </div>

                      <ReasonFormatter
                        reason={firstRejectedReview.rejectionReason}
                      />
                    </GreyBox>
                    <p>
                      You may now make changes to the model. Once finished, the
                      model can be resubmitted and reviewed by the V3Geo team
                      for publishing.
                    </p>
                  </>
                )}

                <p>
                  The V3Geo team will review the model details for quality, and
                  you will receive an email when the model is published or needs
                  further changes.
                </p>
                <p>
                  <b>
                    Once your model is published, further changes cannot be made
                    without unpublishing and re-submitting for approval.
                  </b>
                </p>
              </Card.Body>

              <Card.Footer className="text-center">
                <CreateModelReviewButton
                  modelId={modelId}
                  refetchQueries={refetchQueries}
                >
                  {(handleClick, loadingCreateReview) => (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleClick}
                      disabled={loadingCreateReview || !canSubmit}
                    >
                      Submit for Review
                    </button>
                  )}
                </CreateModelReviewButton>
              </Card.Footer>
            </Card>
          </Col>

          <Col md={4}>
            <RequirementsChecklist requirements={modelRequirementsMet} />
          </Col>
        </Row>
      )}
      {pendingReview && (
        <Card className="mb-3">
          <Card.Body>
            <h3>Under Review</h3>
            <p className="mb-0">
              Your model is currently under review by the V3Geo team. You will
              receive an email when it is approved.
            </p>

            {pendingReview.comments && (
              <GreyBox className="text-left my-3">
                <b>Comments</b>
                <div>{pendingReview.comments}</div>
              </GreyBox>
            )}

            {isModelOwner && (
              <div className="mt-3 text-center">
                <CancelReview reviewId={pendingReview.id}>
                  {handleCancelReview => (
                    <Confirm
                      onConfirm={handleCancelReview}
                      submitText="Cancel Review Request"
                      cancelText="Close"
                    >
                      {handleConfirmCancelReview => (
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary"
                          onClick={handleConfirmCancelReview}
                        >
                          Cancel Review Request
                        </button>
                      )}
                    </Confirm>
                  )}
                </CancelReview>
              </div>
            )}

            <RoleSecured roles={['admin', 'reviewer']}>
              <div className="mt-3 text-center">
                <RejectModelReview modelReviewId={pendingReview.id}>
                  {handleRejectClick => (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleRejectClick}
                    >
                      Reject Model...
                    </button>
                  )}
                </RejectModelReview>

                <span className="ml-2" />

                <ApproveModelReview modelReviewId={pendingReview.id}>
                  {confirmApprove => (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={confirmApprove}
                    >
                      Approve Model...
                    </button>
                  )}
                </ApproveModelReview>
              </div>
            </RoleSecured>
          </Card.Body>
        </Card>
      )}

      <h4>Review history</h4>
      <Card className="mb-2">
        <Card.Body>
          {prettyDate(model.createdAt)}
          {' → '}
          <strong>Model Entry Created</strong>
        </Card.Body>
      </Card>

      {pastReviews.map(review => (
        <Card key={review.id} className="mb-2">
          <Card.Body>
            {prettyDate(review.updatedAt)}
            {' → '}
            <strong
              className={review.isApproved ? 'text-success' : 'text-danger'}
            >
              {review.isApproved ? 'Approved' : 'Not Approved'}
            </strong>
            {review.comments && (
              <GreyBox className="my-2">
                <strong>Submission comments</strong>
                <div>{review.comments}</div>
              </GreyBox>
            )}
            {review.isApproved === false && (
              <GreyBox className="my-2">
                <strong>Reason not approved</strong>
                <ReasonFormatter reason={review.rejectionReason} />
              </GreyBox>
            )}
          </Card.Body>
        </Card>
      ))}

      {/* Models created before the review system was implemented may already be published.
      Create a placeholder that shows some info on the page so it isn't blank. */}
      {!reviews.length && model.isPublished && (
        <Card className="mb-2">
          <Card.Body>
            <Tooltip overlay="Model entry created">
              <span>{prettyDate(model.createdAt)}</span>
            </Tooltip>
            {' → '}
            <strong className="text-success">Approved</strong>
            <GreyBox className="my-2">
              The technical review for this model was performed in an earlier
              version of V3Geo. No formal review history is available.
            </GreyBox>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default TechnicalReviewTab;
