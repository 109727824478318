import { gql } from '@apollo/client';

import * as fragments from 'apollo/fragments';

export const CREATE_AGE_TAG = gql`
  mutation CreateAgeTag($modelId: Int!, $system: String!) {
    createAgeTag(modelId: $modelId, system: $system) {
      ...ageTagParts
    }
  }
  ${fragments.ageTagParts}
`;

export const DELETE_AGE_TAG = gql`
  mutation DeleteAgeTag($modelId: Int!, $ageTagId: Int!) {
    deleteAgeTag(modelId: $modelId, ageTagId: $ageTagId)
  }
`;

export const AGE_TAG_OPTIONS = gql`
  query AgeTagOptions {
    ageTagOptions
  }
`;
