import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import { orgBrowseRoute, orgCreateRoute } from 'routes';
import { MY_ORG_REQUESTS } from 'apollo/operations/organization';
import { MyOrgRequestsData } from 'apollo/schema/operations';
import { OrganizationRequest } from 'apollo/schema/types';
import Spinner from 'components/common/Spinner';
import Tooltip from 'components/common/Tooltip';
import CancelOrganizationRequest from '../CancelOrganizationRequest';

const CreateOrJoinOrganization: React.FC = () => {
  const { data, loading } = useQuery<MyOrgRequestsData>(MY_ORG_REQUESTS);

  const pendingRequest = R.pipe(
    R.pathOr<OrganizationRequest[]>([], ['myOrganizationRequests']),
    R.filter(R.propEq('status', 'PENDING')),
    R.head, // There should only be one pending request
  )(data) as OrganizationRequest | undefined;

  const pastRequests = R.pipe(
    R.pathOr<OrganizationRequest[]>([], ['myOrganizationRequests']),
    R.filter(R.complement(R.propEq('status', 'PENDING'))),
    R.sortWith([R.descend(R.prop('createdAt'))]),
  )(data);

  if (loading) return <Spinner />;

  // If there is a request pending, hide all other UI
  if (pendingRequest) {
    return (
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Body className="text-center">
              <Card.Title as="h3">Organization Request Pending</Card.Title>
              Request to join{' '}
              <b>{pendingRequest.organizationPublicProfile.name}</b> is{' '}
              <b>pending</b>. You'll receive an email automatically when your
              request is approved or denied.
              <div className="mt-3">
                <CancelOrganizationRequest requestId={pendingRequest.id} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row style={{ marginTop: '60px' }}>
        <Col md={6} className="text-center">
          <Card>
            <Card.Body>
              <h3>Create an organization</h3>
              <p>Create your own organization within V3Geo</p>
              <Link to={orgCreateRoute()} className="btn btn-primary">
                Create Organization
              </Link>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} className="text-center">
          <Card>
            <Card.Body>
              <h3>Join an organization</h3>
              <p>Request to join an existing V3Geo organization</p>
              <Link to={orgBrowseRoute()} className="btn btn-primary">
                Browse Organizations
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {pastRequests.length > 0 && (
        <Card className="mt-3">
          <Card.Body>
            <Card.Title as="h3">Past organization requests</Card.Title>

            <table className="table">
              <thead>
                <tr>
                  <th>Organization</th>
                  <th>Status</th>
                  <th>Last updated</th>
                </tr>
              </thead>

              <tbody>
                {pastRequests.map(orgReq => (
                  <tr key={orgReq.id}>
                    <td>{orgReq.organizationPublicProfile.name}</td>
                    <td>
                      {orgReq.status === 'DECLINED' && (
                        <Tooltip
                          overlay={
                            <>
                              <b>Decline reason:</b>
                              <br />
                              {orgReq.declineReason}
                            </>
                          }
                        >
                          <span>{orgReq.status}</span>
                        </Tooltip>
                      )}
                      {orgReq.status !== 'DECLINED' && <>{orgReq.status}</>}
                    </td>
                    <td>{orgReq.updatedAt}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CreateOrJoinOrganization;
