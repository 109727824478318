import React from 'react';
import { useQuery } from '@apollo/client';

import { RefetchQueries } from 'apollo/schema/types';
import NotFound from 'components/errors/NotFound';
import { GET_NEWS } from 'apollo/operations/news';
import { GetNewsData, GetNewsVars } from 'apollo/schema/operations';
import Spinner from 'components/common/Spinner';
import UpdateNewsForm from './UpdateNewsForm';
import DeleteNewsButton from './DeleteNewsButton';

type Props = {
  newsId: number;
  refetchQueries: RefetchQueries;
};

const UpdateNews: React.FC<Props> = ({ newsId, refetchQueries }: Props) => {
  const { data, loading } = useQuery<GetNewsData, GetNewsVars>(GET_NEWS, {
    variables: { id: newsId },
  });

  if (loading) return <Spinner />;
  const news = data?.news;

  if (!news) return <NotFound />;

  return (
    <>
      <UpdateNewsForm news={news} />

      <div className="text-center mt-3">
        <DeleteNewsButton newsId={newsId} refetchQueries={refetchQueries} />
      </div>
    </>
  );
};

export default UpdateNews;
