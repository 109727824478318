import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

import { myModelsMyModelsTabRoute, myModelsSharedWithMeTabRoute } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import NavLink from 'components/common/NavLink';
import MyModelsTab from './MyModelsTab';
import ModelsSharedWithMeTab from './ModelsSharedWithMeTab';
import CreateModelTab from './CreateModelTab';

const MyModelsPage: React.FC = () => {
  const match = useRouteMatch();

  return (
    <StandardLayout breadcrumb={[[match.path, 'My Models']]}>
      <Nav variant="tabs" className="mb-3">
        <Nav.Item>
          <NavLink to={myModelsMyModelsTabRoute()} exact className="nav-link">
            My Models
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink to={myModelsSharedWithMeTabRoute()} className="nav-link">
            Shared with Me
          </NavLink>
        </Nav.Item>
      </Nav>

      <Switch>
        <Route path={match.path} exact component={MyModelsTab} />
        <Route
          path={`${match.path}/shared-with-me`}
          component={ModelsSharedWithMeTab}
        />
        <Route path={`${match.path}/create`} component={CreateModelTab} />
      </Switch>
    </StandardLayout>
  );
};

export default MyModelsPage;
