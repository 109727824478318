import React from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { Formik, Form, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  defaultModel,
  ModelFormValues,
  modelValidationSchema,
} from 'utils/modules/model';
import { CREATE_MODEL } from 'apollo/operations/model';
import { CreateModelData, CreateModelVars } from 'apollo/schema/operations';
import { modelEditImagesTabRoute } from 'routes';
import { useAuth } from 'contexts/auth';
import { setValidationErrors } from 'utils/forms';
import FormikErrors from 'components/common/FormikErrors';
import ModelFormFields from 'components/modules/model/ModelFormFields';

const CreateModelTab: React.FC = () => {
  const history = useHistory();
  const { authority, hasAnyRole } = useAuth();
  if (!authority) throw new Error('no authority present');

  const [createModel, { loading, error }] = useMutation<
    CreateModelData,
    CreateModelVars
  >(CREATE_MODEL);

  async function handleSubmit(
    values: ModelFormValues,
    helpers: FormikHelpers<ModelFormValues>,
  ) {
    try {
      const res = await createModel({ variables: { model: values } });
      const modelId = res.data?.createModel.id;
      if (!modelId) throw new Error('Error parsing createModel response');

      toast.success('Model created successfully.');
      history.push(modelEditImagesTabRoute(modelId));
    } catch (err) {
      setValidationErrors(err, helpers);
      toast.error('There was a problem creating the model.');
      console.log('Error creating model', err);
    }
  }

  return (
    <Card>
      <Card.Body>
        <h1>Create New Model</h1>
        <p>
          To get started creating a model, fill in the following basic
          information.
        </p>

        <Formik
          onSubmit={handleSubmit}
          initialValues={defaultModel()}
          validationSchema={modelValidationSchema(authority)}
        >
          <Form>
            <Modal.Body>
              <ModelFormFields
                isSubscriptionActive={
                  hasAnyRole(['admin']) ||
                  authority.metadata.isSubscriptionActive
                }
              />
              <div className="mt-3" />
              <FormikErrors graphQLError={error} />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Save &amp; Continue
                <FontAwesomeIcon icon="chevron-right" className="ml-2" />
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default CreateModelTab;
