import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import { newsRoute } from 'routes';
import { NEWS_LIST } from 'apollo/operations/news';
import { NewsListData, NewsListVars } from 'apollo/schema/operations';
import { useQueryString } from 'hooks/routing';
import Spinner from 'components/common/Spinner';
import PageLoader from 'components/modules/page/PageLoader';

type QSVars = {
  year?: string;
  month?: string;
};

const RecentNewsPage: React.FC = () => {
  const { query } = useQueryString<QSVars>();

  const variables: NewsListVars = {};
  if (query.year) variables.year = parseInt(query.year);
  if (query.month) variables.month = parseInt(query.month);
  if (!query.year && !query.month) variables.take = 10;

  const { data, loading } = useQuery<NewsListData, NewsListVars>(NEWS_LIST, {
    variables,
  });
  const news = R.sortWith(
    [R.ascend(R.prop('createdAt'))],
    data?.newsList || [],
  );

  return (
    <>
      <h1>Recent news</h1>
      <PageLoader showTitle={false} slug="recent-news" fallback={null} />

      {loading && <Spinner />}
      {!loading &&
        news.map((n, i) => (
          <div className="card mb-3" key={n.id}>
            <div className="card-body">
              <h3 className="card-title">{n.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: n.introduction }} />
            </div>
            <div className="card-footer  text-right">
              <Link to={newsRoute(n.slug)}>read more...</Link>
            </div>
          </div>
        ))}
    </>
  );
};

export default RecentNewsPage;
