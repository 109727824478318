import { gql } from '@apollo/client';

import { authorityParts } from 'apollo/fragments';

export const SYNC_USER = gql`
  mutation SyncUser($idToken: String!) {
    syncUser(idToken: $idToken) {
      ...authorityParts
    }
  }

  ${authorityParts}
`;

export const CURRENT_AUTHORITY = gql`
  query CurrentAuthority {
    currentAuthority {
      ...authorityParts
    }
  }

  ${authorityParts}
`;
