import * as env from 'config/constants';

const auth0LogoutUrl =
  `https://${env.AUTH0_DOMAIN}/v2/logout?` +
  `client_id=${env.AUTH0_CLIENT_ID}&` +
  `returnTo=${encodeURI(`${env.CLIENT_URL}/api/oauth/logout`)}`; // returnTo, not return_to 🤷‍♀️

export function LogoutButton() {
  return (
    <a href={auth0LogoutUrl} className="dropdown-item">
      Log out
    </a>
  );
}
