import { gql, useQuery } from '@apollo/client';
import { Link, useRouteMatch } from 'react-router-dom';

import { adminDashboardRoute, modelEditTechnicalReviewTabRoute } from 'routes';
import * as fragments from 'apollo/fragments';
import { useSortFilter } from 'hooks/data';
import { prettyDate } from 'utils/text';
import Tooltip from 'components/common/Tooltip';
import Spinner from 'components/common/Spinner';
import SortTrigger from 'components/common/SortTrigger';
import StandardLayout from 'components/layouts/StandardLayout';
import { PendingReviewsData } from 'apollo/schema/operations';

const MODELS_NEEDING_REVIEW_PAGE = gql`
  query ModelsNeedingReviewTab {
    pendingReviews {
      ...modelReviewParts
      model {
        ...modelParts
        user {
          ...userParts
        }
      }
    }
  }
  ${fragments.modelReviewParts}
  ${fragments.modelParts}
  ${fragments.userParts}
`;
type QueryData = PendingReviewsData;

function ModelReviewTasksPage() {
  const match = useRouteMatch();
  const { data, loading } = useQuery<QueryData>(MODELS_NEEDING_REVIEW_PAGE);

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    data?.pendingReviews || [],
    'createdAt',
    'model.name',
  );

  if (loading) return <Spinner />;

  return (
    <StandardLayout
      breadcrumb={[
        [adminDashboardRoute(), 'Dashboard'],
        [match.path, 'Review Tasks'],
      ]}
    >
      <h1>Review Tasks</h1>

      <table className="table">
        <thead>
          <tr>
            <th>
              <SortTrigger colName="createdAt" sortIndicatorProps={siProps}>
                <Tooltip overlay="Date the review was requested">
                  <span>Submitted</span>
                </Tooltip>
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="model.name" sortIndicatorProps={siProps}>
                Model
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="model.user.name"
                sortIndicatorProps={siProps}
                filterable
              >
                Author
              </SortTrigger>
            </th>
            <th />
          </tr>
        </thead>

        <tbody>
          {!items.length && (
            <tr>
              <td colSpan={3} className="text-center text-muted">
                <em>No models pending review</em>
              </td>
            </tr>
          )}
          {items.map(review => (
            <tr key={review.id}>
              <td>{prettyDate(review.createdAt)}</td>
              <td>{review.model.name}</td>
              <td>{review.model.user?.name}</td>
              <td className="text-right">
                <Link
                  to={modelEditTechnicalReviewTabRoute(review.model.id)}
                  target="_blank"
                  className="btn btn-secondary btn-sm"
                >
                  Review
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StandardLayout>
  );
}

export default ModelReviewTasksPage;
