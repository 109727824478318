import React from 'react';
import { Modal } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';

import { OrganizationInvite } from 'apollo/schema/types';
import { useModalState } from 'hooks/modal';
import { orgInviteParts } from 'apollo/fragments';
import { yup } from 'utils/validation';
import { CREATE_ORG_INVITE } from 'apollo/operations/organization';
import {
  CreateOrgInviteData,
  CreateOrgInviteVars,
} from 'apollo/schema/operations';
import FormikField from 'components/common/FormikField';

type FormValues = { email: string };
const initialValues = (): FormValues => ({ email: '' });
const validationSchema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
});

type Props = {
  children: (showModal: () => void) => React.ReactNode;
};

const CreateOrganizationInvite: React.FC<Props> = ({ children }: Props) => {
  const { show, showModal, hideModal } = useModalState();
  const [createOrgInvite, { loading }] = useMutation<
    CreateOrgInviteData,
    CreateOrgInviteVars
  >(CREATE_ORG_INVITE, {
    update(cache, result) {
      cache.modify({
        fields: {
          organizationInviteList(cachedInvites: OrganizationInvite[] = []) {
            return [
              ...cachedInvites,
              cache.writeFragment({
                data: result.data?.createOrganizationInvite,
                fragment: orgInviteParts,
              }),
            ];
          },
        },
      });
    },
  });

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    try {
      await createOrgInvite({ variables: { email: values.email } });
      toast.success(
        'The user has been successfully invited to join your organization.',
      );
      helpers.resetForm();
      hideModal();
    } catch (err) {
      const { message } = err;
      console.log('Error creating org invite', err);

      let toastText = 'There was a problem inviting the user.';

      // User already has an invite pending
      if (message === 'pending_invite_exists') {
        toastText =
          'This user already has a pending invite to join your organization. They can accept or discard this invite from their profile page.';
      } else if (message === 'user_in_org') {
        toastText = `This user already belongs to an organization. They'll need to leave it before they can be invited to join this organization.`;
      }

      toast.error(toastText);
    }
  }

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Invite a user to join this organization</Modal.Title>
        </Modal.Header>

        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues()}
          validationSchema={validationSchema}
        >
          <Form>
            <Modal.Body>
              <p>
                Use this form to invite someone to join your organization. If
                they don't already have an account with V3Geo, they'll be sent
                information on how to create one.
              </p>

              <Field
                name="email"
                label="Email address"
                component={FormikField}
                required
              />
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-default"
                disabled={loading}
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Send
              </button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default CreateOrganizationInvite;
