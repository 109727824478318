import { gql } from '@apollo/client';

import * as fragments from 'apollo/fragments';

export const MODEL_DETAIL = gql`
  query ModelDetail($id: Int!) {
    model(id: $id) {
      ...modelParts
      georeferences {
        ...georeferenceParts
      }
      ageTags {
        ...ageTagParts
      }
      geologyTags {
        ...geologyTagParts
      }
    }
  }
  ${fragments.modelParts}
  ${fragments.georeferenceParts}
  ${fragments.ageTagParts}
  ${fragments.geologyTagParts}
`;

export const CREATE_MODEL = gql`
  mutation CreateModel($model: ModelInput!) {
    createModel(model: $model) {
      id
    }
  }
`;

export const UPDATE_MODEL = gql`
  mutation UpdateModel($id: Int!, $model: ModelInput!) {
    updateModel(id: $id, model: $model) {
      ...modelParts
    }
  }
  ${fragments.modelParts}
`;

export const MODEL_ENUM_OPTIONS = gql`
  query ModelEnumOptions {
    modelEnumOptions {
      country
      modelSize
      smallestVisibleFeature
    }
  }
`;
export type ModelEnumOptionsData = {
  country: string[];
  modelSize: string[];
  smallestVisibleFeature: string[];
};

export const CREATE_MODEL_GEOREFERENCE = gql`
  mutation CreateModelGeoreference(
    $modelId: Int!
    $georeference: GeoreferenceInput!
  ) {
    createModelGeoreference(id: $modelId, georeference: $georeference) {
      ...georeferenceParts
    }
  }
  ${fragments.georeferenceParts}
`;

export const DELETE_MODEL_GEOREFERENCE = gql`
  mutation DeleteModelGeoreference($modelId: Int!, $georeferenceId: Int!) {
    deleteModelGeoreference(id: $modelId, georeferenceId: $georeferenceId)
  }
`;

export const TRANSFER_MODEL_OWNERSHIP = gql`
  mutation TransferModelOwnership($modelId: Int!, $newUserId: Int!) {
    transferModelOwnership(modelId: $modelId, newUserId: $newUserId) {
      ...modelParts
    }
  }
  ${fragments.modelParts}
`;
