import React from 'react';
import { Field } from 'formik';

import FormikField from 'components/common/FormikField';
import FilterOptions from './FilterOptions';
import SearchField from './SearchField';
import { ModelSearchOptions } from './index';

type Props = {
  options: ModelSearchOptions;
  loading?: boolean;
};

const ModelSearchFilters: React.FC<Props> = ({
  options,
  loading = false,
}: Props) => {
  return (
    <>
      <Field
        name="search"
        component={FormikField}
        type={SearchField}
        placeholder="Search"
      />
      <div className="mb-2" />

      <FilterOptions
        name="category"
        title="Category"
        options={options.category}
      />
      <FilterOptions
        name="subcategory"
        title="Subcategory"
        options={options.subcategory}
      />
      <FilterOptions
        name="system"
        title="Geological Age"
        options={options.system}
      />
      <FilterOptions name="country" title="Country" options={options.country} />
    </>
  );
};

export default ModelSearchFilters;
