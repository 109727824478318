import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { FormikHelpers } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { modelEditGeologyTagsTabRoute } from 'routes';
import {
  GeoreferenceDataType,
  Model,
  RefetchQueries,
} from 'apollo/schema/types';
import GeoreferenceManager from 'components/modules/georeference/GeoreferenceManager';
import {
  CREATE_MODEL_GEOREFERENCE,
  DELETE_MODEL_GEOREFERENCE,
} from 'apollo/operations/model';
import {
  CreateModelGeoreferenceData,
  CreateModelGeoreferenceVars,
  DeleteModelGeoreferenceData,
  DeleteModelGeoreferenceVars,
  GeoreferenceInput,
} from 'apollo/schema/operations';
import { setValidationErrors } from 'utils/forms';
import { GeoreferenceFormValues } from 'utils/modules/georeference';
import HelpBox from 'components/common/HelpBox';
import Tooltip from 'components/common/Tooltip';

type Props = {
  model: Model;
  refetchQueries: RefetchQueries;
  locked: boolean;
};

function ModelGeoreferencesTab({
  model,
  refetchQueries,
  locked,
}: Props): JSX.Element {
  const [createGeoref, { loading: loadingCreate }] = useMutation<
    CreateModelGeoreferenceData,
    CreateModelGeoreferenceVars
  >(CREATE_MODEL_GEOREFERENCE, { refetchQueries });

  const [deleteGeoref, { loading: loadingDelete }] = useMutation<
    DeleteModelGeoreferenceData,
    DeleteModelGeoreferenceVars
  >(DELETE_MODEL_GEOREFERENCE, { refetchQueries });

  const disabled = loadingCreate || loadingDelete;

  const georeferences = model.georeferences || [];

  async function handleCreate(
    values: GeoreferenceFormValues,
    helpers: FormikHelpers<GeoreferenceFormValues>,
  ) {
    if (!values.data) {
      throw new Error(
        'Missing georeference data, should have been caught in validation.',
      );
    }
    const georeference: GeoreferenceInput = {
      name: values.name || null,
      description: values.description || null,
      dataType: values.dataType as GeoreferenceDataType,
      data: {
        coordinates: values.data.coordinates.map(coord => ({
          latitude: coord.latitude.toString(),
          longitude: coord.longitude.toString(),
        })),
      },
    };

    try {
      await createGeoref({
        variables: {
          modelId: model.id,
          georeference,
        },
      });
      toast.success('Georeference created successfully.');
      helpers.resetForm();
    } catch (err) {
      console.log('Error creating georeference', err);
      toast.error(
        'There was a problem creating the georeference. Please try again.',
      );
      setValidationErrors(err, helpers);
    }
  }

  async function handleDelete(georeferenceId: number) {
    try {
      await deleteGeoref({
        variables: {
          modelId: model.id,
          georeferenceId,
        },
      });
      toast.success('Georeference deleted successfully.');
    } catch (err) {
      console.log('Error deleting georeference', err);
      toast.error(
        'There was a problem deleting the georeference. Refresh the page and try again.',
      );
    }
  }

  return (
    <>
      {!model.isPublished && (
        <Row className="justify-content-center">
          <Col md={10}>
            <HelpBox className="mb-3">
              A <em>georeference</em> ties your model to a physical location,
              and will help give context to the area your 3D model represents.
              The location will be visible as a pin in the model search tool,
              allowing others to find your model when browsing the map.
            </HelpBox>
          </Col>
        </Row>
      )}

      <GeoreferenceManager
        georeferences={georeferences}
        dataTypes={['CENTER']}
        limits={{ CENTER: 1 }}
        onCreate={handleCreate}
        onDelete={handleDelete}
        disabled={disabled || locked}
      />

      {!model.isPublished && (
        <div className="mt-3 text-right">
          <Tooltip overlay="Continue to geology tags">
            <Link
              to={modelEditGeologyTagsTabRoute(model.id)}
              className="btn btn-primary"
            >
              Next
              <FontAwesomeIcon icon="chevron-right" className="ml-2" />
            </Link>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default ModelGeoreferencesTab;
