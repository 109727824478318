import * as R from 'ramda';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';

import { Model } from 'apollo/schema/types';
import { MODEL_SEARCH } from 'apollo/operations/modelSearch';
import excludedModels from './excludedModels';
import { ModelSearchFormValues } from 'utils/modules/modelSearch';
import {
  ModelSearchData,
  ModelSearchResult,
  ModelSearchVars,
  SearchResult,
} from 'apollo/schema/operations';

export type OptionableFilter = keyof Omit<ModelSearchResult, 'model'>;

export type ModelSearchOptions = Record<OptionableFilter, SearchResult[]>;

type ChildArgObj = {
  options: ModelSearchOptions;
  models: Model[];
  loading: boolean;
};

type Props = {
  children: (arg: ChildArgObj) => JSX.Element;
};

const ModelSearchForm: React.FC<Props> = ({ children }: Props) => {
  const { values } = useFormikContext<ModelSearchFormValues>();

  const { data, loading, previousData } = useQuery<
    ModelSearchData,
    ModelSearchVars
  >(MODEL_SEARCH, { variables: values });

  function filterOptions(filterName: OptionableFilter): SearchResult[] {
    const filterData = (data || previousData)?.modelSearch[filterName] || [];
    return R.sortBy(d => d.name, filterData);
  }

  const filters: OptionableFilter[] = [
    'country',
    'category',
    'subcategory',
    'system',
  ];

  const options = filters.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: filterOptions(cur),
    }),
    {},
  ) as ModelSearchOptions;

  const models = (data?.modelSearch.model || []).filter(
    model => !excludedModels.includes(model.id),
  );

  return children({ options, models, loading });
};

export default ModelSearchForm;
