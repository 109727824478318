import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import Tooltip from '../Tooltip';

function AdminIcon(props: Omit<FontAwesomeIconProps, 'icon'>) {
  return (
    <Tooltip overlay="Only visible to administrators">
      <FontAwesomeIcon icon="wrench" {...props} />
    </Tooltip>
  );
}

export default AdminIcon;
