import { Organization, OrganizationPublicProfile } from 'apollo/schema/types';
import { yup } from 'utils/validation';

export type OrgFormValues = {
  name: string;
};

export function orgInitialFormValues(
  org?: Organization | OrganizationPublicProfile,
): OrgFormValues {
  return {
    name: org?.name || '',
  };
}

export const orgValidationSchema = yup.object({
  name: yup
    .string()
    .required()
    .label('name'),
});
