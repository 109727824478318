import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SelectableGeoref } from './GeoreferenceManager';
import { capitalize } from 'utils/text';
import Tooltip from 'components/common/Tooltip';
import Confirm from 'components/common/Confirm';

type ContainerProps = {
  selected: boolean;
};
const GeorefItemContainer = styled.div<ContainerProps>`
  padding: 10px;
  border-left: 4px solid;
  border-color: ${props => (props.selected ? '#4d58c8' : '#eeeeee')};
  background-color: rgba(0, 0, 0, 0.025);
  cursor: default;
`;

type Props = {
  georeference: SelectableGeoref;
  onDelete: (georeferenceId: number) => void;
  disabled?: boolean;
};

const GeoreferenceListItem: React.FC<Props> = ({
  georeference,
  onDelete,
  disabled,
}: Props) => {
  const handleSelect = (value: boolean) => () => georeference.onSelect(value);
  const handleDelete = () => onDelete(georeference.id);

  return (
    <GeorefItemContainer
      onMouseEnter={handleSelect(true)}
      onMouseLeave={handleSelect(false)}
      selected={georeference.isSelected}
      className="mb-3 p-3"
    >
      <Confirm
        onConfirm={handleDelete}
        text="The selected georeference will be permanently deleted."
      >
        {handleConfirmDelete => (
          <Tooltip overlay="Remove this georeference">
            <button
              type="button"
              className="btn btn-sm btn-secondary float-right"
              style={{ marginTop: '-5px' }}
              onClick={handleConfirmDelete}
              disabled={disabled}
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          </Tooltip>
        )}
      </Confirm>

      <div className="my-auto">
        <strong>{capitalize(georeference.dataType)}</strong>
      </div>

      {!georeference.data.coordinates && (
        <span className="text-danger">
          <span
            className="glyphicon glyphicon-exclamation-sign"
            title="No coordinates set"
          />
        </span>
      )}

      {/* <div>{georeference.name}</div>
      <div>
        <small className="text-muted text-xs">{georeference.description}</small>
      </div> */}
      {/* <div className="clearfix" /> */}
    </GeorefItemContainer>
  );
};

export default GeoreferenceListItem;
