/** Models excluded from search results */
const excludedModels: number[] = [
  149,
  160,
  161,
  162,
  163,
  164,
  165,
  166,
  167,
  168,
  169,
  170,
  171,
  172,
  173,
  174,
  175,
  176,
  177,
  178,
  179,
  180,
  181,
  182,
  183,
  184,
  185,
  186,
  187,
  188,

  // Per #171
  257,
  258,
  259,
  260,
  261,
  262,
  263,
  264,
  265,
  266,
];

export default excludedModels;
