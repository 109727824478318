import React from 'react';
import * as R from 'ramda';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { CMS_FILE_LIST } from 'apollo/operations/cmsFile';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import UploadFile from 'components/modules/fileManager/UploadFile';
import CmsFileList from 'components/modules/fileManager/CmsFileList';
import { CmsFileListData } from 'apollo/schema/operations';

function AdminFileManagerPage() {
  const match = useRouteMatch();
  const { data, loading } = useQuery<CmsFileListData>(CMS_FILE_LIST);
  const refetchQueries = [{ query: CMS_FILE_LIST }];

  const cmsFiles = R.pathOr([], ['cmsFileList', 'contents'], data);

  // todo: pagination when isTruncated is true

  return (
    <StandardLayout breadcrumb={[[match.path, 'File Manager']]}>
      <h1>File Manager</h1>

      <div className="row">
        <div className="col-md-9">
          <div className="card">
            <div className="card-header">Files</div>
            <div className="card-body">
              {loading && <Spinner />}
              {data && (
                <CmsFileList files={cmsFiles} refetchQueries={refetchQueries} />
              )}
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card">
            <div className="card-header">Upload a file</div>
            <div className="card-body">
              <UploadFile refetchQueries={refetchQueries} />
            </div>
          </div>
        </div>
      </div>
    </StandardLayout>
  );
}

export default AdminFileManagerPage;
