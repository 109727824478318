import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { adminNewsRoute } from 'routes';
import { News } from 'apollo/schema/types';
import NavLink from 'components/common/NavLink';

const NewsTitleLabel = styled.div`
  font-size: 14pt;
  font-weight: bold;
`;

const NewsSlugLabel = styled.div`
  font-size: 10pt;
  font-weight: normal;
`;

type Props = {
  newsList: News[];
};

function AdminNewsList({ newsList }: Props) {
  return (
    <>
      <div className="text-center mt-2">
        <Link to={adminNewsRoute()} className="btn btn-primary btn-sm">
          Create News
        </Link>
      </div>

      <hr />

      <ul className="nav nav-pills flex-column">
        {newsList.map(article => (
          <li key={article.id} className="nav-item">
            <NavLink to={adminNewsRoute(article.id)} className="nav-link">
              <NewsTitleLabel>{article.title}</NewsTitleLabel>
              <NewsSlugLabel>{article.slug}</NewsSlugLabel>
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
}

export default AdminNewsList;
