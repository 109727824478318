import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Col, Nav, Row } from 'react-bootstrap';

import { inviteRoute, profileRoute, securityRoute } from 'routes';
import { useAuth } from 'contexts/auth';
import StandardLayout from 'components/layouts/StandardLayout';
import UpdateUser from 'components/modules/user/UpdateUser';
import NavLink from 'components/common/NavLink';
import InviteTab from './InviteTab';
import SecurityTab from './SecurityTab';

function ProfilePage() {
  const match = useRouteMatch();
  const { authority } = useAuth();

  return (
    <StandardLayout breadcrumb={[[profileRoute(), 'My Profile']]}>
      <Row>
        <Col md={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <NavLink to={profileRoute()} className="nav-link" exact>
                My Profile
              </NavLink>
            </Nav.Item>

            {authority?.metadata.isLocalUser && (
              <Nav.Item>
                <NavLink to={securityRoute()} className="nav-link">
                  Account Security
                </NavLink>
              </Nav.Item>
            )}

            {authority?.isProfileCompleted && (
              <Nav.Item>
                <NavLink to={inviteRoute()} className="nav-link">
                  Invite a User
                </NavLink>
              </Nav.Item>
            )}
          </Nav>
        </Col>
        <Col>
          <Switch>
            <Route path={match.path} exact component={UpdateUser} />
            <Route path={`${match.path}/security`} component={SecurityTab} />
            {authority?.isProfileCompleted && (
              <Route path={`${match.path}/invite`} component={InviteTab} />
            )}
          </Switch>
        </Col>
      </Row>
    </StandardLayout>
  );
}

export default ProfilePage;
