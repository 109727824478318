import { Field } from 'formik';
import { Col } from 'react-bootstrap';

import FormikField from 'components/common/FormikField';

function ModelOffsetFormFields({ disabled }: { disabled: boolean }) {
  return (
    <>
      <Col>
        <Field
          name="offsetX"
          label="X"
          component={FormikField}
          type="number"
          placeholder="e.g. 500000"
          disabled={disabled}
        />
      </Col>
      <Col>
        <Field
          name="offsetY"
          label="Y"
          component={FormikField}
          type="number"
          placeholder="e.g. 500000"
          disabled={disabled}
        />
      </Col>
      <Col>
        <Field
          name="offsetZ"
          label="Z"
          component={FormikField}
          type="number"
          placeholder="e.g. 1500"
          disabled={disabled}
        />
      </Col>
    </>
  );
}

export default ModelOffsetFormFields;
