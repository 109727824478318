import React from 'react';
import * as R from 'ramda';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { NEWS_LIST } from 'apollo/operations/news';
import { NewsListData, NewsListVars } from 'apollo/schema/operations';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import AdminNewsList from 'components/modules/news/AdminNewsList';
import CreateNews from 'components/modules/news/CreateNews';
import UpdateNews from 'components/modules/news/UpdateNews';

function coerceToInt(num?: string): number | null {
  if (!num) return null;
  return parseInt(num);
}

type RouteParams = {
  newsId?: string;
};

const AdminNewsPage: React.FC = () => {
  const match = useRouteMatch<RouteParams>();
  const { data, loading } = useQuery<NewsListData, NewsListVars>(NEWS_LIST);

  const refetchQueries = [{ query: NEWS_LIST }];

  const newsId = coerceToInt(match.params.newsId);

  const newsList = R.sortBy(R.prop('createdAt'), data?.newsList || []);

  return (
    <StandardLayout breadcrumb={[[match.path, 'Manage News']]}>
      <h1>Manage News</h1>

      <div className="row">
        <div className="col-sm-3">
          {loading ? <Spinner /> : <AdminNewsList newsList={newsList} />}
        </div>

        <div className="col-sm-9">
          {newsId ? (
            <UpdateNews newsId={newsId} refetchQueries={refetchQueries} />
          ) : (
            <CreateNews refetchQueries={refetchQueries} />
          )}
        </div>
      </div>
    </StandardLayout>
  );
};

export default AdminNewsPage;
