import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SecuredRoute from 'components/common/SecuredRoute';
import HomePage from 'pages/HomePage';
import NotFoundPage from 'pages/NotFoundPage';
import ProfilePage from 'pages/ProfilePage';
import AdminDashboardPage from 'pages/AdminPage';
import AdminPagePage from 'pages/AdminPagePage';
import AdminUserPage from 'pages/AdminUserPage';
import ModelSearchPage from 'pages/ModelSearchPage';
import ModelPage from 'pages/ModelPage';
import CmsPage from 'pages/CmsPage';
import AdminNewsPage from 'pages/AdminNewsPage';
import NewsPage from 'pages/NewsPage';
import AdminFileManagerPage from 'pages/AdminFileManagerPage';
import OrganizationCreatePage from 'pages/OrganizationCreatePage';
import AdminOrganizationListPage from 'pages/AdminOrganizationListPage';
import AdminOrganizationPage from 'pages/AdminOrganizationPage';
import OrganizationPage from 'pages/OrganizationPage';
import OrganizationBrowsePage from 'pages/OrganizationBrowsePage';
import MyModelsPage from 'pages/MyModelsPage';
import ModelEditPage from 'pages/ModelEditPage';
import AdminModelsPage from 'pages/AdminModelsPage';
import AdminModelsHtmlReview from 'pages/AdminModelsHtmlReview';
import ModelReviewTasksPage from 'pages/ReviewTasksPage';
import ContributePage from 'pages/ContributePage';
import LoginPage from 'pages/LoginPage';
import Upload3DModelFilesPage from 'pages/Upload3DModelFilesPage';
import GuidelinesPage from 'pages/GuidelinesPage';
import ContributeCreateModelPage from 'pages/ContributeCreateModelPage';
import ForbiddenPage from 'pages/ForbiddenPage';

const Routes = (
  <Switch>
    <Route path="/" exact component={HomePage} />
    <Route path="/login" component={LoginPage} />

    <SecuredRoute path="/profile" component={ProfilePage} />

    {/* Org routes temporarily restricted to admins only */}
    <SecuredRoute
      path="/organization/create"
      component={OrganizationCreatePage}
      roles={['admin']}
    />
    <SecuredRoute
      path="/organization/browse"
      component={OrganizationBrowsePage}
      roles={['admin']}
    />
    <SecuredRoute
      path="/organization"
      component={OrganizationPage}
      roles={['admin']}
    />

    <Route path="/search" component={ModelSearchPage} />

    <Route path="/model/:modelId" exact component={ModelPage} />

    {/* This needs its own route because it opens in a separate window */}
    <SecuredRoute
      path="/model/:modelId/edit/upload-3d-model-files"
      component={Upload3DModelFilesPage}
      roles={['user']}
    />
    <SecuredRoute
      path="/model/:modelId/edit"
      component={ModelEditPage}
      roles={['user']}
    />

    <SecuredRoute path="/my-models" component={MyModelsPage} roles={['user']} />

    <Route path="/news" component={NewsPage} />

    <Route path="/contribute" component={ContributePage} />
    {/* NB: Content page */}
    <Route path="/create-model" component={ContributeCreateModelPage} />
    <Route path="/3D-modelling-how-to" component={GuidelinesPage} />

    <SecuredRoute
      path="/dashboard"
      exact
      component={AdminDashboardPage}
      roles={['admin', 'reviewer']}
    />
    <SecuredRoute
      path="/admin/user"
      component={AdminUserPage}
      roles={['admin']}
      exact
    />
    <SecuredRoute
      path="/admin/page/:pageId?"
      exact
      component={AdminPagePage}
      roles={['admin']}
    />
    <SecuredRoute
      path="/admin/models"
      exact
      component={AdminModelsPage}
      roles={['admin']}
    />
    <SecuredRoute
      path="/review-tasks"
      component={ModelReviewTasksPage}
      roles={['admin', 'reviewer']}
    />

    <SecuredRoute
      path="/admin/news/:newsId?"
      component={AdminNewsPage}
      roles={['admin']}
    />
    <SecuredRoute
      path="/admin/file-manager"
      component={AdminFileManagerPage}
      roles={['admin']}
    />
    <SecuredRoute
      path="/admin/organizations"
      component={AdminOrganizationListPage}
      roles={['admin']}
    />
    <SecuredRoute
      path="/admin/organization/:orgId"
      component={AdminOrganizationPage}
      roles={['admin']}
    />

    <SecuredRoute
      path="/admin/models-html"
      component={AdminModelsHtmlReview}
      roles={['admin']}
    />

    <Route path="/not-found" component={NotFoundPage} />
    <Route path="/forbidden" component={ForbiddenPage} />

    <Route path="/:slug" exact component={CmsPage} />

    <Route component={NotFoundPage} />
  </Switch>
);

export default Routes;

export const homeRoute = () => '/';
export const loginRoute = () => '/login';
export const aboutRoute = () => `${homeRoute()}#about`;
export const contributeRoute = () => '/contribute';
export const contributeCreateModelRoute = () => '/create-model';
export const guidelinesRoute = () => `/3d-modelling-how-to`;

// User
export const profileRoute = () => '/profile';
export const inviteRoute = () => '/profile/invite';
export const securityRoute = () => '/profile/security';

// Organizations
export const orgRoute = () => '/organization';
export const orgUsersTabRoute = () => `${orgRoute()}/members`;
export const orgRequestsTabRoute = () => `${orgRoute()}/requests`;
export const orgInvitesTabRoute = () => `${orgRoute()}/invites`;
export const orgCreateRoute = () => `${orgRoute()}/create`;
export const orgBrowseRoute = () => `${orgRoute()}/browse`;

// Model Search
export const modelSearchRoute = () => '/search';
export const modelSearchMapRoute = () => modelSearchRoute();
export const modelSearchListRoute = () => `${modelSearchRoute()}/list`;

// Model
export const modelRoute = (modelId: number) => `/model/${modelId}`;

// Edit Model
export const modelEditRoute = (modelId: number) =>
  `${modelRoute(modelId)}/edit`;

// Model info tab routes
export const modelEditInfoTabRoute = (modelId: number) =>
  `${modelEditRoute(modelId)}/info`;
export const modelEditImagesTabRoute = (modelId: number) =>
  `${modelEditInfoTabRoute(modelId)}/images`;
export const modelEditGeoreferencesTabRoute = (modelId: number) =>
  `${modelEditInfoTabRoute(modelId)}/georeferences`;
export const modelEditAgeTagsTabRoute = (modelId: number) =>
  `${modelEditInfoTabRoute(modelId)}/age-tags`;
export const modelEditGeologyTagsTabRoute = (modelId: number) =>
  `${modelEditInfoTabRoute(modelId)}/geology-tags`;
export const modelEditInterpretationsTabRoute = (modelId: number) =>
  `${modelEditInfoTabRoute(modelId)}/interpretations`;

export const modelEdit3DModelFilesTabRoute = (modelId: number) =>
  `${modelEditRoute(modelId)}/3d-model-files`;
export const modelEditTechnicalReviewTabRoute = (modelId: number) =>
  `${modelEditRoute(modelId)}/technical-review`;
export const modelEditPublishingTabRoute = (modelId: number) =>
  `${modelEditRoute(modelId)}/publishing`;
export const modelEditSharingTabRoute = (modelId: number) =>
  `${modelEditRoute(modelId)}/sharing`;
export const modelEditOwnershipTabRoute = (modelId: number) =>
  `${modelEditRoute(modelId)}/ownership`;
// Admin model file management
export const modelEditModelFilesTabRoute = (modelId: number) =>
  `${modelEditRoute(modelId)}/model-files`;

// Original 3D file upload
export const modelUpload3DModelFilesRoute = (modelId: number) =>
  `${modelEditRoute(modelId)}/upload-3d-model-files`;

// My models
export const myModelsRoute = () => `/my-models`;
export const myModelsMyModelsTabRoute = () => myModelsRoute();
export const myModelsSharedWithMeTabRoute = () =>
  `${myModelsRoute()}/shared-with-me`;
export const myModelsCreateRoute = () => `${myModelsRoute()}/create`;

// News
export const newsRoute = (slug?: string) => `/news${slug ? `/${slug}` : ''}`;

// CMS pages
export const cmsPage = (slug: string) => `/${slug}`;

// Error pages
export const notFoundRoute = () => '/not-found';
export const forbiddenRoute = () => '/forbidden';

// Admin
export const adminDashboardRoute = () => '/dashboard';
export const adminRoute = () => '/admin';
export const adminUserRoute = () => `${adminRoute()}/user`;
export const adminPageRoute = (pageId?: number) =>
  `${adminRoute()}/page${pageId ? `/${pageId}` : ''}`;
export const adminNewsRoute = (newsId?: number) =>
  `${adminRoute()}/news${newsId ? `/${newsId}` : ''}`;
export const adminFileManagerRoute = () => `${adminRoute()}/file-manager`;
export const adminOrgListRoute = () => `${adminRoute()}/organizations`;
export const adminOrgRoute = (orgId: number) =>
  `${adminRoute()}/organization/${orgId}`;

// Admin Models
export const adminModelsRoute = () => `${adminRoute()}/models`;
export const modelReviewTasksRoute = () => `/review-tasks`;
export const adminModelsHtmlRoute = () => `${adminRoute()}/models-html`;
