import React from 'react';

import Tooltip from 'components/common/Tooltip';
import Popover from 'components/common/Popover';
import HelpIcon from 'components/common/HelpIcon';
import { PopoverProps } from 'react-bootstrap';

const Asterisk: React.FC = () => (
  <Tooltip overlay="This field is required">
    <div
      className="text-danger"
      style={{
        display: 'inline-block',
        fontSize: '22pt',
        lineHeight: '16px',
        position: 'relative',
        top: '9px',
        left: '3px',
      }}
    >
      *
    </div>
  </Tooltip>
);

type Props = {
  name: string;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  helpTextPlacement?: PopoverProps['placement'];
  required?: boolean;
};

const FormLabel: React.FC<Props> = ({
  name,
  label = null,
  helpText = null,
  helpTextPlacement = 'auto',
  required = false,
}: Props) => {
  if (!label) return null;

  return (
    <>
      {helpText && (
        <div className="float-right">
          <Popover
            trigger={['click']}
            content={helpText}
            placement={helpTextPlacement}
          >
            <HelpIcon />
          </Popover>
        </div>
      )}
      <label htmlFor={name} className="control-label">
        {label}
        {required && <Asterisk />}
      </label>
    </>
  );
};

export default FormLabel;
