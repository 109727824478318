import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  colName: string,
};

export interface SortIndicatorProps {
  sort: string;
  sortReverse: boolean;
  onSortChange: (key: string) => any;
  categoryFilterOptions: (colName: string) => string[];
  getFiltersForCategory: (colName: string) => string[];
  onCategoryFilterChange: (colName: string) => (value: string) => void;
}

type ComponentProps = SortIndicatorProps & Props;

const SortIndicator: React.FC<ComponentProps> = ({
  colName,
  sort,
  sortReverse = false,
}: ComponentProps) => {
  if (sort !== colName) {
    return null;
  }

  if (sortReverse) {
    return <FontAwesomeIcon icon="chevron-down" />;
  } else {
    return <FontAwesomeIcon icon="chevron-up" />;
  }
};

export default SortIndicator;
