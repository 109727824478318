import { Card, Col, Row } from 'react-bootstrap';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Location } from 'history';

import * as env from 'config/constants';
import StandardLayout from 'components/layouts/StandardLayout';
import { LoginButton } from 'components/modules/auth/LoginButton';

// This is the shape of the possible state when landing on this page.
// It's possible to navigate here without setting state, so it might be undefined.
type LocationState = undefined | { postLoginRedirect?: Location };

function buildPostLoginRedirect(ls: LocationState): string | null {
  if (!ls?.postLoginRedirect) return null;

  // The query string needs to be decoded in case there are any brackets used,
  // they are automatically encoded when calling qs.stringify
  const url = new URL(ls.postLoginRedirect.pathname, env.CLIENT_URL);
  url.search = decodeURIComponent(ls.postLoginRedirect.search);

  return url.toString();
}

function LoginPage() {
  const match = useRouteMatch();
  const location = useLocation<LocationState>();

  const postLoginRedirect = buildPostLoginRedirect(location.state);

  return (
    <StandardLayout breadcrumb={[[match.path, 'Login']]}>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Log in</Card.Title>

              <p>Please log in to V3Geo</p>
              <div className="text-center">
                <LoginButton
                  postLoginRedirect={postLoginRedirect}
                  className="btn btn-primary btn-lg"
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </StandardLayout>
  );
}

export default LoginPage;
