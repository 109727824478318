import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { Card, Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ORG_REQUEST_LIST } from 'apollo/operations/organization';
import {
  OrgRequestListData,
  OrgRequestListVars,
} from 'apollo/schema/operations';
import { OrganizationRequest } from 'apollo/schema/types';
import Spinner from 'components/common/Spinner';
import Tooltip from 'components/common/Tooltip';
import AcceptRequest from './AcceptRequest';
import DeclineRequest from './DeclineRequest';

type Props = {
  orgId: number;
};

const OrganizationRequestList: React.FC<Props> = ({ orgId }: Props) => {
  const [showPast, setShowPast] = useState(false);
  const { data, loading } = useQuery<OrgRequestListData, OrgRequestListVars>(
    ORG_REQUEST_LIST,
    { variables: { orgId } },
  );

  const requests = R.pipe(
    R.pathOr<OrganizationRequest[]>([], ['organizationRequestList']),
    R.sortWith([R.descend(R.prop('createdAt'))]),
  )(data);

  const pendingRequests = requests.filter(req => req.status === 'PENDING');
  const pastRequests = requests.filter(req => req.status !== 'PENDING');

  const toggleShowPast = () => setShowPast(!showPast);

  if (loading) return <Spinner />;

  return (
    <Card className="mt-3">
      <Card.Header>Requests to Join Organization</Card.Header>
      <Card.Body>
        {!pendingRequests.length && (
          <div className="text-center text-muted">
            <i>No requests pending</i>
          </div>
        )}
        {pendingRequests.length > 0 && (
          <>
            <h3>Pending Requests</h3>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {pendingRequests.map(req => (
                  <tr key={req.id}>
                    <td>{req.requester?.name}</td>
                    <td>{req.requester?.email}</td>
                    <td className="text-right">
                      <DropdownButton
                        title="Respond"
                        variant="secondary"
                        size="sm"
                      >
                        <AcceptRequest requestId={req.id}>
                          {onClick => (
                            <Dropdown.Item onClick={onClick}>
                              Accept Request
                            </Dropdown.Item>
                          )}
                        </AcceptRequest>
                        <DeclineRequest requestId={req.id}>
                          {onClick => (
                            <Dropdown.Item onClick={onClick}>
                              Decline Request...
                            </Dropdown.Item>
                          )}
                        </DeclineRequest>
                      </DropdownButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {pastRequests.length > 0 && (
          <>
            <button
              type="button"
              onClick={toggleShowPast}
              className="btn btn-link mb-2"
              style={{ padding: 0 }}
            >
              {showPast ? 'Hide' : 'Show'} past requests
              <FontAwesomeIcon
                icon={showPast ? 'chevron-down' : 'chevron-right'}
                className="ml-2"
              />
            </button>
          </>
        )}
        {showPast && (
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Accepted/Declined By</th>
                <th className="text-right">Status</th>
              </tr>
            </thead>

            <tbody>
              {pastRequests.map(req => (
                <tr key={req.id}>
                  <td>{req.requester?.name}</td>
                  <td>{req.requester?.email}</td>
                  <td>{req.accepter?.name}</td>
                  <td className="text-right">
                    {req.status === 'ACCEPTED' && <>{req.status}</>}
                    {req.status === 'DECLINED' && (
                      <Tooltip
                        overlay={
                          <>
                            <b>Decline reason:</b>
                            <br />
                            {req.declineReason}
                          </>
                        }
                      >
                        <span>{req.status}</span>
                      </Tooltip>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Card.Body>
    </Card>
  );
};

export default OrganizationRequestList;
