import { gql } from '@apollo/client';

import * as fragments from 'apollo/fragments';

export const CREATE_GEOLOGY_TAG = gql`
  mutation CreateGeologyTag(
    $modelId: Int!
    $category: String!
    $subcategory: String
  ) {
    createGeologyTag(
      modelId: $modelId
      category: $category
      subcategory: $subcategory
    ) {
      ...geologyTagParts
    }
  }
  ${fragments.geologyTagParts}
`;

export const DELETE_GEOLOGY_TAG = gql`
  mutation DeleteGeologyTag($modelId: Int!, $geologyTagId: Int!) {
    deleteGeologyTag(modelId: $modelId, geologyTagId: $geologyTagId)
  }
`;

export const GEOLOGY_TAG_OPTIONS = gql`
  query GeologyTagOptions($category: String, $subcategory: String) {
    geologyTagOptions(category: $category, subcategory: $subcategory) {
      category
      subcategory
    }
  }
`;
