import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';
import { ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { orgRoute } from 'routes';
import { OrganizationPublicProfile } from 'apollo/schema/types';
import {
  CREATE_ORG_REQUEST,
  PUBLIC_ORG_LIST,
} from 'apollo/operations/organization';
import {
  CreateOrgRequestData,
  CreateOrgRequestVars,
  PublicOrgListData,
} from 'apollo/schema/operations';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import Confirm from 'components/common/Confirm';

const OrganizationBrowsePage: React.FC = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { data, loading: loadingList } = useQuery<PublicOrgListData>(
    PUBLIC_ORG_LIST,
  );
  const [createOrgRequest] = useMutation<
    CreateOrgRequestData,
    CreateOrgRequestVars
  >(CREATE_ORG_REQUEST);

  const orgs = R.pipe(
    R.pathOr<OrganizationPublicProfile[]>([], ['publicOrganizationList']),
    R.sortBy(R.prop('name')),
  )(data);

  const handleClick = (orgId: number) => async () => {
    try {
      await createOrgRequest({ variables: { orgId } });
      toast.success(
        'Your request to join the organization has been submitted.',
      );
      history.replace(orgRoute());
    } catch (err) {
      console.log('Error creating organization request', err);

      // TODO: Parse different errors
      // user_in_org
      // too_many_org_requests

      toast.error(
        'There was a problem requesting to join the organization. Please try again.',
      );
    }
  };

  return (
    <StandardLayout
      breadcrumb={[
        [orgRoute(), 'Organization'],
        [match.path, 'Browse Organizations'],
      ]}
    >
      <h1>Browse Organizations</h1>
      <p>Select from an organization below to submit a request to join.</p>

      <Spinner show={loadingList} />

      {data && (
        <ListGroup>
          {orgs.map(org => (
            <Confirm
              key={org.id}
              onConfirm={handleClick(org.id)}
              text={`Confirm your request to join ${org.name}.`}
            >
              {confirm => (
                <ListGroup.Item key={org.id} action onClick={confirm}>
                  {org.name}
                </ListGroup.Item>
              )}
            </Confirm>
          ))}
        </ListGroup>
      )}
    </StandardLayout>
  );
};

export default OrganizationBrowsePage;
