import React from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormikField from 'components/common/FormikField';
import { useMutation } from '@apollo/client';
import { INVITE_USER } from 'apollo/operations/user';
import { yup } from 'utils/validation';
import { toast } from 'react-toastify';
import { RefetchQueries } from 'apollo/schema/types';
import FormikErrors from 'components/common/FormikErrors';
import { InviteUserData, InviteUserVars } from 'apollo/schema/operations';
import { Card } from 'react-bootstrap';

type FormValues = {
  email: string;
};

const initialValues = () => ({ email: '' });
const validationSchema = () =>
  yup.object({
    email: yup
      .string()
      .email()
      .required(),
  });

type Props = {
  refetchQueries: RefetchQueries;
};
const CreateUserInvite: React.FC<Props> = ({ refetchQueries }: Props) => {
  const [inviteUser, { loading, error }] = useMutation<
    InviteUserData,
    InviteUserVars
  >(INVITE_USER);

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    try {
      await inviteUser({
        variables: { email: values.email },
        refetchQueries,
      });
      toast.success(
        `We sent an email to ${values.email} with instructions on how to register.`,
      );
      helpers.resetForm();
    } catch (err) {
      console.log('Error inviting user', JSON.parse(JSON.stringify(err)));
      if (err?.message === 'user_exists') {
        toast.info('This user is already registered with V3Geo.');
      } else if (err?.message === 'invite_exists') {
        toast.info(
          'You already have a pending invite for this user. You can use the "Resend Email" button to send them a reminder.',
        );
      } else {
        toast.error(
          'There was a problem sending the invite, please try again.',
        );
      }
    }
  }

  return (
    <Card>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues()}
        validationSchema={validationSchema()}
      >
        <Form>
          <Card.Body>
            <Card.Title>Invite a user</Card.Title>
            <p>
              Want to invite a friend or colleague to V3Geo? Use the form below
              and we'll send them an email to help them get started.
            </p>

            <Field
              name="email"
              label="Email address"
              component={FormikField}
              type="email"
              renderInput={(input: React.ReactNode) => (
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon="envelope" />
                    </span>
                  </div>
                  {input}
                </div>
              )}
            />

            <FormikErrors graphQLError={error} />
          </Card.Body>

          <Card.Footer className="text-right">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              Submit
            </button>
          </Card.Footer>
        </Form>
      </Formik>
    </Card>
  );
};

export default CreateUserInvite;
