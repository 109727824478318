import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { Role } from 'apollo/schema/types';
import { useAuth } from 'contexts/auth';
import { forbiddenRoute, loginRoute } from 'routes';

interface AdditionalProps {
  roles?: Role[];
  blockedRoles?: Role[];
}

type Props = RouteProps & AdditionalProps;

const SecuredRoute: React.FC<Props> = ({
  roles = [],
  blockedRoles = [],
  ...props
}: Props) => {
  const location = useLocation();
  const { isAuthenticated, hasAnyRole } = useAuth();

  if (!isAuthenticated) {
    // If not authenticated, redirect to the login page.
    // The current route will be saved in the router's `state` prop so
    // it can be used for redirect after a successful login attempt.
    return (
      <Redirect
        to={{
          pathname: loginRoute(),
          state: {
            postLoginRedirect: {
              pathname: location.pathname,
              search: location.search,
            },
          },
        }}
      />
    );
  }

  const hasAllowedRole = hasAnyRole(roles);
  const hasBlockedRole = blockedRoles.length > 0 && hasAnyRole(blockedRoles);

  // Allow access if the role list provided was empty; they just need to be logged in
  const isAllowed = (hasAllowedRole || !roles.length) && !hasBlockedRole;

  if (!isAllowed) {
    return <Redirect to={forbiddenRoute()} />;
  }

  return <Route {...props} />;
};

export default SecuredRoute;
