import React from 'react';
import * as R from 'ramda';
import { Formik, Form, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { modelEditAgeTagsTabRoute } from 'routes';
import { GeologyTag, Model, RefetchQueries } from 'apollo/schema/types';
import {
  defaultGeologyTag,
  GeologyTagFormValues,
} from 'utils/modules/geologyTag';
import { CREATE_GEOLOGY_TAG } from 'apollo/operations/geologyTag';
import { setValidationErrors } from 'utils/forms';
import { yup } from 'utils/validation';
import GeologyTagRow from 'components/modules/geologyTag/GeologyTagRow';
import HelpBox from 'components/common/HelpBox';
import Tooltip from 'components/common/Tooltip';
import {
  CreateGeologyTagData,
  CreateGeologyTagVars,
} from 'apollo/schema/operations';
import GeologyTagFormFields from 'components/modules/geologyTag/GeologyTagFormFields';

type Props = {
  model: Model;
  refetchQueries: RefetchQueries;
  locked: boolean;
};

const ModelGeologyTagsTab: React.FC<Props> = ({
  model,
  refetchQueries,
  locked,
}: Props) => {
  const geologyTags = R.pipe(
    R.pathOr<GeologyTag[]>([], ['geologyTags']),
    R.sortBy(R.prop('name')),
  )(model);

  const [createGeologyTag, { loading }] = useMutation<
    CreateGeologyTagData,
    CreateGeologyTagVars
  >(CREATE_GEOLOGY_TAG, {
    refetchQueries,
  });

  async function handleSubmit(
    values: GeologyTagFormValues,
    helpers: FormikHelpers<GeologyTagFormValues>,
  ) {
    try {
      await createGeologyTag({
        variables: {
          modelId: model.id,
          ...values,
        },
      });
      toast.success('Geology tag created.');
      helpers.resetForm();
    } catch (err) {
      console.log('Error creating geology tag', err);
      toast.error(
        'There was a problem creating the geology tag. Please try again.',
      );
      setValidationErrors(err, helpers);
    }
  }

  return (
    <>
      {!model.isPublished && (
        <Row className="justify-content-center">
          <Col md={10}>
            <HelpBox className="mb-3">
              <p className="mb-1">
                Adding geology tags to classify your model will help others find
                it when using the search tool.
              </p>
              <p className="mb-0">Multiple tags can be added if needed.</p>
            </HelpBox>
          </Col>
        </Row>
      )}

      <div className="row">
        <div className="col-md-8">
          <table className="table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Subcategory</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {geologyTags.map(tag => (
                <GeologyTagRow
                  key={tag.id}
                  geologyTag={tag}
                  refetchQueries={refetchQueries}
                  disabled={locked}
                />
              ))}

              {!geologyTags.length && (
                <tr>
                  <td colSpan={3} className="text-center text-muted">
                    <em>No geology tags have been added yet.</em>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Tags</h4>

              <Formik
                onSubmit={handleSubmit}
                initialValues={defaultGeologyTag()}
                validationSchema={yup.object({
                  category: yup.string().required(),
                  subcategory: yup.string().nullable(),
                })}
              >
                <Form>
                  <GeologyTagFormFields disabled={locked} />

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={locked || loading}
                    >
                      Save
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>

      {!model.isPublished && (
        <div className="mt-3 text-right">
          <Tooltip overlay="Continue to geological age">
            <Link
              to={modelEditAgeTagsTabRoute(model.id)}
              className="btn btn-primary"
            >
              Next
              <FontAwesomeIcon icon="chevron-right" className="ml-2" />
            </Link>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default ModelGeologyTagsTab;
