import React from 'react';
import * as R from 'ramda';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { PAGE_LIST } from 'apollo/operations/page';
import { adminDashboardRoute } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import CreatePageModal from 'components/modules/page/CreatePageModal';
import AdminPageList from 'components/modules/page/AdminPageList';
import UpdatePage from 'components/modules/page/UpdatePage';
import { Page } from 'apollo/schema/types';
import { Col, Row } from 'react-bootstrap';

type RouteParams = {
  pageId?: string;
};

/** A page... for administrating pages. */
function AdminPagePage() {
  const match = useRouteMatch<RouteParams>();
  const { loading, data } = useQuery(PAGE_LIST);
  const refetchQueries = [{ query: PAGE_LIST }];

  const pageId = match.params.pageId ? parseInt(match.params.pageId) : null;

  const pageList = R.pipe(
    R.pathOr<Page[]>([], ['pageList']),
    R.sortBy(R.prop('slug')),
  )(data);

  const page = pageId ? pageList.find(p => p.id === pageId) || null : null;

  return (
    <StandardLayout
      breadcrumb={[
        [adminDashboardRoute(), 'Dashboard'],
        [match.path, 'Pages'],
      ]}
    >
      <h1>Pages</h1>

      <Row>
        <Col sm={3}>
          <div className="text-center mt-2">
            <CreatePageModal refetchQueries={refetchQueries}>
              {showModal => (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={showModal}
                >
                  Create Page
                </button>
              )}
            </CreatePageModal>
          </div>

          <hr />

          <div className="mt-2" />
          <Spinner show={loading} />

          <AdminPageList pageList={pageList} />
        </Col>

        <Col sm={9}>
          {page && <UpdatePage page={page} />}
          {!page && (
            <div className="text-center">
              <p>
                Select a page to edit, or use the button below to create a new
                page.
              </p>
              <CreatePageModal refetchQueries={refetchQueries}>
                {showModal => (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={showModal}
                  >
                    Create Page
                  </button>
                )}
              </CreatePageModal>
            </div>
          )}
        </Col>
      </Row>
    </StandardLayout>
  );
}

export default AdminPagePage;
