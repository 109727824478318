import React from 'react';
import * as R from 'ramda';
import { Formik, Form, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AgeTag, Model, RefetchQueries } from 'apollo/schema/types';
import { CREATE_AGE_TAG } from 'apollo/operations/ageTag';
import { CreateAgeTagData, CreateAgeTagVars } from 'apollo/schema/operations';
import { setValidationErrors } from 'utils/forms';
import { yup } from 'utils/validation';
import { modelEdit3DModelFilesTabRoute } from 'routes';
import HelpBox from 'components/common/HelpBox';
import AgeTagRow from 'components/modules/ageTag/AgeTagRow';
import AgeTagFormFields from 'components/modules/ageTag/AgeTagFormFields';
import Tooltip from 'components/common/Tooltip';

type FormValues = {
  system: string;
};

type Props = {
  model: Model;
  refetchQueries: RefetchQueries;
  locked: boolean;
};

const ModelAgeTagsTab: React.FC<Props> = ({
  model,
  refetchQueries,
  locked,
}: Props) => {
  const [createAgeTag, { loading }] = useMutation<
    CreateAgeTagData,
    CreateAgeTagVars
  >(CREATE_AGE_TAG, { refetchQueries });

  async function handleSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    try {
      await createAgeTag({
        variables: {
          modelId: model.id,
          system: values.system,
        },
      });
      toast.success('Geological Age created successfully.');
      helpers.resetForm();
    } catch (err) {
      console.log('Error saving age tag', err);
      setValidationErrors(err, helpers);
      toast.error('There was a problem saving the age. Please try again.');
    }
  }

  const ageTags = R.pipe(
    R.pathOr<AgeTag[]>([], ['ageTags']),
    R.sortBy(R.prop('name')),
  )(model);

  return (
    <>
      {!model.isPublished && (
        <Row className="justify-content-center">
          <Col md={10}>
            <HelpBox className="mb-3">
              <p className="mb-1">
                Adding age tags to classify your model will help others find it
                when using the search tool.
              </p>
              <p className="my-0">Multiple tags can be added if needed.</p>
            </HelpBox>
          </Col>
        </Row>
      )}

      <Row>
        <Col md={8}>
          <table className="table table-condensed">
            <thead>
              <tr>
                <th>System</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {ageTags.map(ageTag => (
                <AgeTagRow
                  key={ageTag.id}
                  ageTag={ageTag}
                  refetchQueries={refetchQueries}
                  disabled={locked}
                />
              ))}
              {!ageTags.length && (
                <tr>
                  <td colSpan={2} className="text-center text-muted">
                    <em>No age tags have been added yet.</em>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>
        <Col md={4}>
          <Formik
            onSubmit={handleSubmit}
            initialValues={{ system: '' }}
            validationSchema={yup.object({ system: yup.string().required() })}
          >
            <Form>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Add Tags</h4>

                  <AgeTagFormFields
                    currentAgeTags={ageTags}
                    disabled={locked}
                  />

                  <div className="text-center mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={locked || loading}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </Col>
      </Row>

      {!model.isPublished && (
        <div className="mt-3 text-right">
          <Tooltip overlay="Continue to 3d model files">
            <Link
              to={modelEdit3DModelFilesTabRoute(model.id)}
              className="btn btn-primary"
            >
              Next
              <FontAwesomeIcon icon="chevron-right" className="ml-2" />
            </Link>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default ModelAgeTagsTab;
