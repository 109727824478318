import React from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';

import { GET_NEWS } from 'apollo/operations/news';
import { GetNewsData, GetNewsVars } from 'apollo/schema/operations';
import DateTime from 'components/common/DateTime';
import Spinner from 'components/common/Spinner';

type RouteParams = {
  slug: string;
};

const NewsArticlePage: React.FC = () => {
  const match = useRouteMatch<RouteParams>();
  const slug = match.params.slug;

  const { data, loading } = useQuery<GetNewsData, GetNewsVars>(GET_NEWS, {
    variables: { slug },
  });
  const news = data?.news;

  return (
    <>
      {loading && <Spinner />}
      {news && (
        <>
          <h1>{news.title}</h1>
          <em>
            Published <DateTime date={news.createdAt} format="D. MMM, YYYY" />
          </em>

          <div className="mt-3" />

          {/* <div dangerouslySetInnerHTML={{ __html: news.introduction }} /> */}
          {news.content && (
            <div dangerouslySetInnerHTML={{ __html: news.content }} />
          )}
        </>
      )}
    </>
  );
};

export default NewsArticlePage;
