import React from 'react';
import * as R from 'ramda';
import pb from 'pretty-bytes';

import { CmsFile, RefetchQueries } from 'apollo/schema/types';
import DeleteCmsFile from 'components/modules/fileManager/DeleteCmsFile';
import DateTime from 'components/common/DateTime';
import FilePreview from './FilePreview';

type Props = {
  files: CmsFile[];
  refetchQueries: RefetchQueries;
};

const CmsFileList: React.FC<Props> = ({ files, refetchQueries }: Props) => {
  const filename: (file: CmsFile) => string = R.pipe(
    R.prop('key'),
    R.replace('files/public/', ''),
  );

  return (
    <table className="table">
      <thead>
        <tr>
          <th />
          <th>Filename</th>
          <th>Last modified</th>
          <th>Size</th>
          <th />
        </tr>
      </thead>

      <tbody>
        {files.map(file => (
          <tr key={file.key}>
            <td className="align-middle text-center">
              <FilePreview file={file} />
            </td>
            <td className="align-middle">{filename(file)}</td>
            <td className="align-middle">
              <DateTime date={file.lastModified} />
            </td>
            <td className="align-middle">{pb(file.size)}</td>
            <td className="align-middle">
              <DeleteCmsFile
                filename={filename(file)}
                refetchQueries={refetchQueries}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CmsFileList;
