import React from 'react';
import { gql, useMutation } from '@apollo/client';

import { ModelReview } from 'apollo/schema/types';
import * as fragments from 'apollo/fragments';
import { toast } from 'react-toastify';

const CANCEL_REVIEW = gql`
  mutation CancelReview($reviewId: Int!) {
    cancelModelReview(modelReviewId: $reviewId) {
      ...modelReviewParts
    }
  }
  ${fragments.modelReviewParts}
`;
type MutationData = {
  cancelModelReview: ModelReview;
};
type MutationVars = {
  reviewId: number;
};

type Props = {
  children: (handleCancel: () => void) => JSX.Element;
  reviewId: number;
};

const CancelReview: React.FC<Props> = ({ children, reviewId }: Props) => {
  const [cancelReview] = useMutation<MutationData, MutationVars>(
    CANCEL_REVIEW,
    { variables: { reviewId } },
  );

  async function handleCancel() {
    try {
      await cancelReview();
      toast.success('The model review request has been canceled.');
    } catch (err) {
      console.log('Error canceling review', err);
      toast.error(
        'There was a problem canceling the review. Please refresh the page and try again.',
      );
    }
  }

  return children(handleCancel);
};

export default CancelReview;
