import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

type Props = Partial<FontAwesomeIconProps>;

const PublicModelIcon = (props: Props) => (
  <FontAwesomeIcon {...props} icon="globe-americas" />
);

export default PublicModelIcon;
