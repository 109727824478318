import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

type Props = Partial<FontAwesomeIconProps>;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 14pt;
  cursor: pointer;
`;

const HelpIcon: React.FC<Props> = (props: Props) => {
  return <StyledIcon icon="question-circle" className="text-info" {...props} />;
};

export default HelpIcon;
