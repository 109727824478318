import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth } from 'contexts/auth';
import { profileRoute } from 'routes';

type LocationState = {
  postLoginRedirect?: any;
};

/** Watch authentication and redirect to attempted or appropriate route when state is updated */
function AuthRedirect() {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const { isAuthenticated, authority } = useAuth();
  const isAuthRef = useRef(false);

  const postLoginRedirect = location.state?.postLoginRedirect;

  useEffect(() => {
    // When user becomes authenticated, redirect them to desired route or fallback
    if (isAuthenticated && !isAuthRef.current && authority) {
      if (!authority.isProfileCompleted) {
        toast.info(
          'We need a little more info from you to complete your account setup.',
        );
        history.replace(profileRoute());
        return;
      }

      // If the user tried to access a protected page before logging in, return them to that page
      if (postLoginRedirect) {
        history.replace(postLoginRedirect);
        return;
      }

      // No longer needed since auth is already completed when loading app
      // history.replace('/');
      // return;
    }

    // When user becomes UNauthenticated, redirect them to login route
    if (!isAuthenticated && isAuthRef.current) {
      history.push('/');
      return;
    }

    isAuthRef.current = isAuthenticated;
  }, [isAuthenticated]);

  return null;
}

export default AuthRedirect;
