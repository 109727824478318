import * as env from 'config/constants';

// Redirect back to API endpoint to create session and set cookie for browser
function buildAuthUrl(postLoginRedirect?: string | null): string {
  const callbackUrl = new URL('/api/oauth/callback', env.CLIENT_URL);

  if (postLoginRedirect) {
    // Double-encode the PLR so it doesn't get parsed by our API after redirect
    const doubleEnodedPlr = encodeURI(postLoginRedirect);
    callbackUrl.searchParams.set('postLoginRedirect', doubleEnodedPlr);
  }

  const encodedRedirectUri = encodeURI(callbackUrl.toString());
  console.log('Encoded redirect URI:', encodedRedirectUri);

  const authUrl =
    `https://${env.AUTH0_DOMAIN}/authorize?` +
    `response_type=code&` +
    `client_id=${env.AUTH0_CLIENT_ID}&` +
    `redirect_uri=${encodedRedirectUri}&` +
    `audience=${env.AUTH0_CLIENT_AUD}&` +
    `scope=openid profile email offline_access`;

  return authUrl;
}

type Props = {
  className?: string;
  postLoginRedirect?: string | null;
};

export function LoginButton({ className, postLoginRedirect }: Props) {
  return (
    <a href={buildAuthUrl(postLoginRedirect)} className={className}>
      Log In
    </a>
  );
}
