export const creativeCommonsInfoURL =
  'https://creativecommons.org/about/cclicenses/';

const CopyrightInfoOverlay = () => (
  <>
    For more information on copyright licensing, visit{' '}
    <a href={creativeCommonsInfoURL} target="_blank" rel="noopener noreferrer">
      creativecommons.org
    </a>
    .
  </>
);

export default CopyrightInfoOverlay;
