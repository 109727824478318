import React from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import { homeRoute } from 'routes';
import Spacer from 'components/common/Spacer';
import Navbar from 'components/layouts/Navbar';
import Footer from 'components/layouts/Footer';
import ProfileNotCompeltedNotice from 'components/modules/auth/ProfileNotCompletedNotice';

/** [URL, Label] */
export type BreadcrumbItem = [string, string];

type Props = {
  /** Array of [url, label] tuples to build breadcrumb. The last item will be "active" and used as the page title. */
  breadcrumb: BreadcrumbItem[];
  children: React.ReactNode;
  hideBreadcrumb?: boolean;
};

const StandardLayout: React.FC<Props> = ({
  breadcrumb = [],
  children,
  hideBreadcrumb = false,
}: Props) => {
  const pageName = R.pipe(
    R.last,
    R.pathOr<string | null>(null, [1]),
  )(breadcrumb);

  const title = `${pageName ? `${pageName} | ` : ''}V3Geo`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Navbar />

      {!R.isEmpty(breadcrumb) && !hideBreadcrumb && (
        <nav aria-label="breadcrumb">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item" aria-current="page">
                <Link to={homeRoute()}>Home</Link>
              </li>
              {breadcrumb.map(([url, label], i) => {
                const isLastItem = i === breadcrumb.length - 1;
                return (
                  <li
                    key={i}
                    className={classnames('breadcrumb-item', {
                      active: isLastItem,
                    })}
                  >
                    {isLastItem && label}
                    {!isLastItem && <Link to={url}>{label}</Link>}
                  </li>
                );
              })}
            </ol>
          </div>
        </nav>
      )}

      <div className="container mt-3">
        <ProfileNotCompeltedNotice />
        {children}
      </div>

      <Spacer v={80} />
      <Footer />
    </>
  );
};

export default StandardLayout;
