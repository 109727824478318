import { useEffect, useRef, useCallback } from 'react';
import {
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResult,
  QueryResult,
  TypedDocumentNode,
  useLazyQuery as apolloLazyQuery,
} from '@apollo/client';

export function useImperativeQuery<TData, TVars = undefined>(
  query: DocumentNode | TypedDocumentNode<TData, TVars>,
  options?: LazyQueryHookOptions<TData, TVars>,
): [
  (
    options?: LazyQueryHookOptions<TData, TVars>,
  ) => Promise<QueryResult<TData, TVars>>,
  LazyQueryResult<TData, TVars>,
] {
  const [execute, result] = apolloLazyQuery<TData, TVars>(query, options);
  const resolveRef = useRef<(value: any) => void>();

  useEffect(() => {
    if (result.called && !result.loading && resolveRef.current) {
      resolveRef.current(result);
      resolveRef.current = undefined;
    }
  }, [result.loading, result.called]);

  const queryLazily = useCallback(
    (options?: LazyQueryHookOptions<TData, TVars>) => {
      execute(options);

      return new Promise<QueryResult<TData, TVars>>(resolve => {
        resolveRef.current = resolve;
      });
    },
    [execute],
  );

  return [queryLazily, result];
}
