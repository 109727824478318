import { gql } from '@apollo/client';

export const authorityParts = gql`
  fragment authorityParts on Authority {
    anonymous
    authError
    metadata {
      name
      email
      affiliation
      isLocalUser
      isSubscriptionActive
      organization {
        id
        name
      }
    }
    roles
    userId
    isProfileCompleted
  }
`;

export const userParts = gql`
  fragment userParts on User {
    id
    name
    email
    isEnabled
    isEmailVerified
    isAdmin
    isReviewer
    isLocalUser
    isSubscriptionActive
    createdAt
    updatedAt
    roles
  }
`;

export const userPublicProfileParts = gql`
  fragment userPublicProfileParts on UserPublicProfile {
    id
    name
  }
`;

export const userInviteParts = gql`
  fragment userInviteParts on UserInvite {
    id
    sourceUserId
    targetUserId
    targetUserEmail
    createdAt
    updatedAt
  }
`;

export const orgParts = gql`
  fragment orgParts on Organization {
    id
    name
    createdAt
    updatedAt
  }
`;

export const orgPublicProfileParts = gql`
  fragment orgPublicProfileParts on OrganizationPublicProfile {
    id
    name
  }
`;

export const orgInviteParts = gql`
  fragment orgInviteParts on OrganizationInvite {
    id
    organizationId
    status
    inviterId
    inviteeId
    inviteeEmail
    createdAt
    updatedAt
  }
`;

export const orgRequestParts = gql`
  fragment orgRequestParts on OrganizationRequest {
    id
    organizationId
    status
    requesterId
    accepterId
    declineReason
    createdAt
    updatedAt
  }
`;

export const modelParts = gql`
  fragment modelParts on Model {
    id
    userId
    name
    description
    isPublished
    modelSize
    smallestVisibleFeatureSize
    isPrivate
    isDownloadable
    author
    copyrightLicense
    fundingSource
    source
    country
    region
    location
    literature
    createdAt
    updatedAt
  }
`;
export const modelOffsetParts = gql`
  fragment modelOffsetParts on Model {
    offsetX
    offsetY
    offsetZ
  }
`;

export const modelImageParts = gql`
  fragment modelImageParts on ModelImage {
    modelId
    fileId
    order
    # model {...}
    # file {...}
  }
`;

export const modelPublicLinkParts = gql`
  fragment modelPublicLinkParts on ModelPublicLink {
    id
    modelId
    key
    note
    accessCount
    accessedAt
    expiresAt
    createdAt
    updatedAt
  }
`;

export const georeferenceParts = gql`
  fragment georeferenceParts on Georeference {
    id
    name
    description
    dataType
    data {
      coordinates {
        latitude
        longitude
      }
    }
    createdAt
    updatedAt
  }
`;

export const ageTagParts = gql`
  fragment ageTagParts on AgeTag {
    id
    modelId
    system
    createdAt
    updatedAt
  }
`;

export const geologyTagParts = gql`
  fragment geologyTagParts on GeologyTag {
    id
    modelId
    category
    subcategory
    createdAt
    updatedAt
  }
`;

export const interpretationParts = gql`
  fragment interpretationParts on ModelInterpretation {
    id
    modelId
    createdById
    name
    isPublished
  }
`;

export const fileParts = gql`
  fragment fileParts on File {
    id
    filename
    mimetype
    signedUrl
  }
`;

export const newsParts = gql`
  fragment newsParts on News {
    id
    slug
    title
    introduction
    content
    createdAt
    updatedAt
  }
`;

export const pageParts = gql`
  fragment pageParts on Page {
    id
    slug
    title
    content
    isSecured
    createdAt
    updatedAt
  }
`;

export const modelShareParts = gql`
  fragment modelShareParts on ModelShare {
    id
    sourceUserId
    targetUserId
    targetUserInviteId
    allowInterpretationCreate
    createdAt
    updatedAt
  }
`;

export const modelReviewParts = gql`
  fragment modelReviewParts on ModelReview {
    id
    modelId
    comments
    reviewedById
    isApproved
    rejectionReason
    createdAt
    updatedAt
  }
`;

export const modelRequirementsMetParts = gql`
  fragment modelRequirementsMetParts on ModelRequirementsMet {
    details
    georeferences
    images
    geologyTags
    modelUserFiles
  }
`;

export const modelUserFileParts = gql`
  fragment modelUserFileParts on ModelUserFile {
    id
    fileId
    modelId
    createdAt
    updatedAt
    completedAt
    # file: File!
    # model: Model!
  }
`;

export const initializedModelUserFileParts = gql`
  fragment initializedModelUserFileParts on InitializedModelUserFile {
    modelId
    uploadId
  }
`;
