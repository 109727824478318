import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { Card } from 'react-bootstrap';

import { OrganizationInvite } from 'apollo/schema/types';
import { ORG_INVITES_BY_USER } from 'apollo/operations/organization';
import {
  OrgInvitesByUserData,
  OrgInvitesByUserVars,
} from 'apollo/schema/operations';
import { useAuth } from 'contexts/auth';
import Spinner from 'components/common/Spinner';
import AcceptRejectOrgInviteButtons from './AcceptRejectOrgInviteButtons';

const MyOrganizationInvites: React.FC = () => {
  const [showPastInvites, setShowPastInvites] = useState(false);
  const { authority } = useAuth();
  const userId = authority?.userId;
  if (!userId) {
    throw new Error('Cannot access my organization invites without userId');
  }

  const { data, loading } = useQuery<
    OrgInvitesByUserData,
    OrgInvitesByUserVars
  >(ORG_INVITES_BY_USER, { variables: { userId } });

  const invites = R.pipe(
    R.pathOr<OrganizationInvite[]>([], ['organizationInvitesByUser']),
    R.sortWith([R.descend(R.prop('createdAt'))]),
    R.when(
      () => showPastInvites === false,
      R.filter(R.propEq('status', 'PENDING')),
    ),
  )(data);

  const handlePastChange = () => setShowPastInvites(!showPastInvites);

  if (loading) return <Spinner />;
  if (!invites.length) {
    return null;
  }

  return (
    <Card>
      <Card.Header>Organization Invites</Card.Header>

      <Card.Body>
        <div className="float-right">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                value="1"
                onChange={handlePastChange}
                checked={showPastInvites}
              />{' '}
              Show past invites
            </label>
          </div>
        </div>

        <p>
          You've been invited to join the following organization:
          {invites.length > 1 && 's'}
        </p>

        <table className="table">
          <thead>
            <tr>
              <th>Organization</th>
              <th>Invited at</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {invites.map(oi => (
              <tr key={oi.id}>
                <td>{oi.organizationPublicProfile.name}</td>
                <td>{oi.createdAt}</td>
                <td className="text-right">
                  {oi.status === 'PENDING' ? (
                    <AcceptRejectOrgInviteButtons
                      inviteId={oi.id}
                      orgName={oi.organizationPublicProfile.name}
                    />
                  ) : (
                    oi.status
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
};

export default MyOrganizationInvites;
