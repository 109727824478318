import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth } from 'contexts/auth';
import Spinner from 'components/common/Spinner';
import { Authority } from 'apollo/schema/types';
import { useQueryString } from 'hooks/routing';

type Props = {
  children: React.ReactNode;
};

type QueryStringParams = {
  // The URL can have other query parameters but these are the only ones that
  // are used as part of the initial load + maybe redirection after auth
  status?: string;
};

function RestoreSession({ children }: Props) {
  const { replace } = useHistory();
  const { reloadAuthority } = useAuth();
  const [restoreCompleted, setRestoreCompleted] = useState(false);
  const { query } = useQueryString<QueryStringParams>();

  useEffect(() => {
    function redirectToIntended() {
      // Remove the success code on post-redirect
      if (query.status === 'success') {
        console.log('Handling post-login redirect');
        const nextUrl = new URL(window.location.toString());
        nextUrl.searchParams.delete('status');
        replace({
          pathname: nextUrl.pathname,
          hash: nextUrl.hash,
          search: nextUrl.search,
        });
      }
    }

    function handleStatusErrors(status: string) {
      let toastMessage: string;

      switch (status) {
        case 'email_not_verified':
          toastMessage = 'Please verify your email address to log in.';
          break;

        default:
          toastMessage =
            'There was a problem logging you in. Please try again.';
      }

      toast.error(toastMessage);
    }

    async function restoreSession() {
      let reloadedAuth: Authority | null = null;
      try {
        reloadedAuth = await reloadAuthority();
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === 'Unauthorized') {
            console.log('No active session');
          } else {
            console.log(
              'Error loading active session',
              JSON.parse(JSON.stringify(err)),
            );
          }
        } else {
          console.log(
            'Unexpected error reloading authority',
            JSON.parse(JSON.stringify(err)),
          );
        }
      }

      if (reloadedAuth) {
        redirectToIntended();
      } else if (query.status) {
        handleStatusErrors(query.status);
      }

      setRestoreCompleted(true);
    }

    restoreSession();
  }, [reloadAuthority, replace]);

  if (restoreCompleted) {
    return <>{children}</>;
  }

  return <Spinner />;
}

export default RestoreSession;
