import {
  Georeference,
  GeoreferenceCoordinate,
  GeoreferenceDataType,
} from 'apollo/schema/types';

export function toLatLng(coords: GeoreferenceCoordinate) {
  return new google.maps.LatLng(
    parseFloat(coords.latitude),
    parseFloat(coords.longitude),
  );
}

export const markerTypes: GeoreferenceDataType[] = ['POINT', 'CENTER'];
export const polygonTypes: GeoreferenceDataType[] = ['POLYGON', 'OUTLINE'];
export const polylineTypes: GeoreferenceDataType[] = ['POLYLINE'];

export const isMarkerType = (t: GeoreferenceDataType) =>
  markerTypes.includes(t);
export const isPolygonType = (t: GeoreferenceDataType) =>
  polygonTypes.includes(t);
export const isPolylineType = (t: GeoreferenceDataType) =>
  polylineTypes.includes(t);

export function getDrawingModes(
  dataType: GeoreferenceDataType | null,
): string[] {
  const drawingModes: string[] = [];
  if (!dataType) return drawingModes;

  if (isMarkerType(dataType)) {
    drawingModes.push('MARKER');
  }
  if (isPolygonType(dataType)) {
    drawingModes.push('POLYGON');
  }
  if (isPolylineType(dataType)) {
    drawingModes.push('POLYLINE');
  }

  return drawingModes;
}

export function createBounds(georefs: Georeference[]) {
  const bounds = new google.maps.LatLngBounds();

  georefs.forEach(georef => {
    georef.data.coordinates.forEach(coords => bounds.extend(toLatLng(coords)));
  });

  if (!georefs.length) {
    bounds.extend(new google.maps.LatLng(-50, -50));
    bounds.extend(new google.maps.LatLng(50, 50));
  }

  return bounds;
}
