import { useRouteMatch } from 'react-router';
import { Col, Row } from 'react-bootstrap';

import { cmsPage } from 'routes';
import StandardLayout from 'components/layouts/StandardLayout';
import PageLoader from 'components/modules/page/PageLoader';
import LinkBox from 'components/common/LinkBox';

const ContributeCreateModelPage = () => {
  const match = useRouteMatch();

  return (
    <PageLoader
      slug={'create-model'}
      render={page => (
        <StandardLayout breadcrumb={[[match.path, page.title]]} hideBreadcrumb>
          <h1>{page.title}</h1>
          <Row>
            <Col md={9}>
              <div dangerouslySetInnerHTML={{ __html: page.content }} />
            </Col>

            <Col md={3}>
              <LinkBox
                to={cmsPage('create-model-long')}
                header="Tutorial: Create a model"
              />
              <LinkBox
                to={cmsPage('create-public-model-video')}
                header="Video: Create a model"
              />
            </Col>
          </Row>
        </StandardLayout>
      )}
    />
  );
};

export default ContributeCreateModelPage;
