import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { RefetchQueries } from 'apollo/schema/types';

const DELETE_SHARE = gql`
  mutation DeleteModelShare($shareId: Int!) {
    deleteModelShare(modelShareId: $shareId)
  }
`;
type MutationData = {
  deleteModelShare: boolean;
};
type MutationVars = {
  shareId: number;
};

type Props = {
  children: (deleteShare: () => void) => JSX.Element;
  shareId: number;
  refetchQueries: RefetchQueries;
};

const DeleteModelShare: React.FC<Props> = ({
  children,
  shareId,
  refetchQueries,
}: Props) => {
  const [deleteShare] = useMutation<MutationData, MutationVars>(DELETE_SHARE, {
    variables: { shareId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteShare();
      toast.success('Model sharing revoked successfully.');
    } catch (err) {
      console.log('Error deleting model share', err);
      toast.error(
        'There was a problem revoking the model share. Please refresh the page, it may have already been deleted.',
      );
    }
  }

  return children(handleDelete);
};

export default DeleteModelShare;
