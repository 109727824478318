import * as R from 'ramda';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

export function useQueryString<T>() {
  const location = useLocation();

  const queryString = R.pipe(
    R.pathOr<string>('', ['search']),
    // Remove leading ? from search string
    R.replace(/^\?/, ''),
  )(location);

  const query = (qs.parse(queryString) as unknown) as T;

  const stringify = qs.stringify;

  return { query, stringify };
}
