import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { RefetchQueries } from 'apollo/schema/types';
import { adminPageRoute } from 'routes';
import { useModalState } from 'hooks/modal';
import { CREATE_PAGE } from 'apollo/operations/page';
import { CreatePageData, CreatePageVars } from 'apollo/schema/operations';
import { setValidationErrors } from 'utils/forms';
import {
  defaultPage,
  PageFormValues,
  validationSchema,
} from 'utils/modules/page';
import PageForm from 'components/modules/page/PageForm';
import FormikErrors from 'components/common/FormikErrors';

type Props = {
  children: (showModal: () => void) => React.ReactNode;
  refetchQueries: RefetchQueries;
};

const CreatePageModal: React.FC<Props> = ({
  children,
  refetchQueries,
}: Props) => {
  const { show, showModal, hideModal } = useModalState();
  const [createPage, { loading, error }] = useMutation<
    CreatePageData,
    CreatePageVars
  >(CREATE_PAGE, {
    refetchQueries,
  });
  const history = useHistory();

  async function handleSubmit(
    values: PageFormValues,
    helpers: FormikHelpers<PageFormValues>,
  ) {
    try {
      const res = await createPage({ variables: { page: values.page } });
      const pageId = res.data?.createPage.id;
      if (!pageId) throw new Error('Error parsing createPage response');
      hideModal();
      toast.success('Page created successfully.');
      history.push(adminPageRoute(pageId));
      // formikBag.resetForm();
    } catch (err) {
      setValidationErrors(err, helpers);
      console.log('Error creating page:', err);
      toast.error('There was a problem creating the page.');
    }
  }

  return (
    <>
      {children(showModal)}

      <Formik
        onSubmit={handleSubmit}
        initialValues={defaultPage()}
        validationSchema={validationSchema}
      >
        <Modal show={show} size="lg" onHide={hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create Page</Modal.Title>
          </Modal.Header>

          <Form>
            <Modal.Body>
              <PageForm />

              <FormikErrors graphQLError={error} />
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Formik>
    </>
  );
};

CreatePageModal.propTypes = {
  children: PropTypes.func.isRequired,
  refetchQueries: PropTypes.array.isRequired,
};

export default CreatePageModal;
