import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AuthContextProvider } from 'contexts/auth';
import Routes from 'routes';
import ToastContainer from 'components/ToastContainer';
import AuthRedirect from './modules/auth/AuthRedirect';
import RestoreSession from 'components/modules/auth/RestoreSession';
import ApolloProviderWrapper from './ApolloProvider';

function App() {
  return (
    <BrowserRouter>
      <ApolloProviderWrapper>
        <ToastContainer />
        <AuthContextProvider>
          <RestoreSession>
            <AuthRedirect />
            {Routes}
          </RestoreSession>
        </AuthContextProvider>
      </ApolloProviderWrapper>
    </BrowserRouter>
  );
}

export default App;
