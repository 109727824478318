import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { decodeHTML } from 'entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { adminDashboardRoute, modelEditRoute } from 'routes';
import * as fragments from 'apollo/fragments';
import { ModelListData } from 'apollo/schema/operations';
import { fullDateTime, prettyDate, stripTags, truncate } from 'utils/text';
import { useSortFilter } from 'hooks/data';
import StandardLayout from 'components/layouts/StandardLayout';
import Spinner from 'components/common/Spinner';
import SortTrigger from 'components/common/SortTrigger';
import Tooltip from 'components/common/Tooltip';

export const ADMIN_MODELS_PAGE = gql`
  query ModelListTab {
    modelList {
      ...modelParts
      user {
        ...userParts
      }
    }
  }

  ${fragments.modelParts}
  ${fragments.userParts}
`;

const YesIcon: React.FC = () => (
  <FontAwesomeIcon icon="check" className="text-success" />
);
const NoIcon: React.FC = () => (
  <FontAwesomeIcon icon="times-circle" className="text-danger" />
);

function AdminModelsPage() {
  const match = useRouteMatch();
  const { data, loading } = useQuery<ModelListData>(ADMIN_MODELS_PAGE);

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    data?.modelList || [],
    'name',
    'name',
  );

  const descriptionText = R.pipe(
    R.propOr('', 'description'),
    stripTags,
    decodeHTML,
    truncate(100),
  );

  return (
    <StandardLayout
      breadcrumb={[
        [adminDashboardRoute(), 'Dashboard'],
        [match.path, 'Models'],
      ]}
    >
      <h1>Model Management</h1>

      <Spinner show={loading} />

      <Spinner show={loading} />
      {data && (
        <table className="table">
          <thead>
            <tr>
              <th>
                <SortTrigger colName="id" sortIndicatorProps={siProps}>
                  ID
                </SortTrigger>
              </th>
              <th>
                <SortTrigger colName="name" sortIndicatorProps={siProps}>
                  Name
                </SortTrigger>
              </th>
              <th>Description</th>
              <th>
                <SortTrigger
                  colName="user.name"
                  sortIndicatorProps={siProps}
                  filterable
                >
                  Created By
                </SortTrigger>
              </th>
              <th className="text-center" style={{ minWidth: '100px' }}>
                <SortTrigger
                  colName="isPublished"
                  sortIndicatorProps={siProps}
                  filterable
                >
                  <Tooltip overlay="Model published">
                    <span>Publ.</span>
                  </Tooltip>
                </SortTrigger>
              </th>
              <th>
                <SortTrigger colName="createdAt" sortIndicatorProps={siProps}>
                  Created
                </SortTrigger>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map(model => (
              <tr key={model.id}>
                <td>{model.id}</td>
                <td style={{ maxWidth: '400px' }}>{model.name}</td>
                <td style={{ maxWidth: '400px' }}>{descriptionText(model)}</td>
                <td>{model.user?.name}</td>
                <td className="text-center">
                  {model.isPublished ? <YesIcon /> : <NoIcon />}
                </td>
                <td>
                  <Tooltip overlay={fullDateTime(model.createdAt)}>
                    <span>{prettyDate(model.createdAt)}</span>
                  </Tooltip>
                </td>
                <td>
                  <Link
                    to={modelEditRoute(model.id)}
                    className="btn btn-sm btn-secondary"
                    target="_blank"
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
            {!items.length && (
              <tr>
                <td colSpan={7} className="text-center text-muted">
                  <em>No items to display</em>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </StandardLayout>
  );
}

export default AdminModelsPage;
