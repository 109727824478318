import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { cmsPage } from 'routes';
import Tooltip from 'components/common/Tooltip';
import PublicModelIcon from '../model/PublicModelIcon';
import PrivateModelIcon from '../model/PrivateModelIcon';

function ArrowIcon() {
  return (
    <FontAwesomeIcon
      icon="long-arrow-alt-down"
      className="mt-1 mb-0"
      style={{ fontSize: '18pt', color: '#dddddd' }}
    />
  );
}

const WorkflowItemContainer = styled.div<{ disabled: boolean }>`
  text-align: left;
  padding: 14px 14px 14px 28px;
  background-color: ${({ disabled }) =>
    disabled ? 'hsl(0, 0%, 98%)' : 'hsl(0, 0%, 94%)'};
  border-radius: 5px;
  color: ${({ disabled }) =>
    disabled ? 'hsl(36, 10%, 70%)' : 'hsl(36, 10%, 25%)'};
  font-size: 11pt;
  cursor: default;
  font-weight: bold;
  margin: 5px;
  box-shadow: 5px 5px 10px
    ${({ disabled }) => (disabled ? 'hsl(0, 0%, 96%)' : 'hsl(0, 0%, 90%)')};
`;

const StepNumber = styled.div`
  font-size: 12pt;
  width: 34px;
  height: 34px;
  padding: 4px;
  border: 2px solid hsl(36, 100%, 80%);
  background-color: hsl(36, 30%, 95%);
  color: hsl(36, 80%, 50%);
  font-weight: bold;
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
`;

type WorkflowItemProps = { children: React.ReactNode; step: number | null };
function WorkflowItem({ children, step }: WorkflowItemProps): JSX.Element {
  const disabled = step === null;
  return (
    <WorkflowItemContainer disabled={disabled}>
      <Row className="d-flex">
        <Col xs={2} sm={3} className="align-self-center">
          {step && <StepNumber>{step}</StepNumber>}
        </Col>
        <Col className="d-flex">
          <div className="align-self-center">
            {children}
            {disabled && (
              <div style={{ fontSize: '8pt', color: '#cccccc' }}>
                <em>Not required for private models</em>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </WorkflowItemContainer>
  );
}

function ContributeWorkflow() {
  const [mode, setMode] = useState<'public' | 'private'>('public');

  // [stepName, requiredWhenPrivate][]
  const steps: [string, boolean][] = [
    ['Create model and upload 3D model files', true],
    ['Technical review of 3D model', false],
    ['Publication', true],
  ];
  /** Calculates the step number for the given step name (from the steps array)
   *  based on the current view mode that is set */
  const stepNumber = (stepName: string): number | null => {
    const modeSteps = steps.filter(([_, requiredWhenPrivate]) => {
      if (mode === 'public') return true;
      return requiredWhenPrivate === true;
    });
    const idx = modeSteps.findIndex(([n]) => n === stepName);
    if (idx === -1) return null;
    return idx + 1;
  };

  return (
    <div className="text-center">
      {steps.map(([name], i) => (
        <div key={i}>
          <WorkflowItem step={stepNumber(name)}>{name}</WorkflowItem>
          {i < steps.length - 1 && <ArrowIcon />}
        </div>
      ))}

      <div className="mt-3" />

      <Tooltip overlay="View public model workflow">
        <button
          type="button"
          className={`btn btn-sm btn-${
            mode === 'public' ? 'primary' : 'secondary'
          } mr-1`}
          onClick={() => setMode('public')}
        >
          <PublicModelIcon className="mr-2" />
          Public
        </button>
      </Tooltip>

      <Tooltip overlay="View private model workflow">
        <button
          type="button"
          className={`btn btn-sm btn-${
            mode === 'private' ? 'primary' : 'secondary'
          }`}
          onClick={() => setMode('private')}
        >
          <PrivateModelIcon className="mr-2" />
          Private
        </button>
      </Tooltip>

      <Link to={cmsPage('plans')} target="_blank" className="ml-2">
        <FontAwesomeIcon icon="question-circle" className="text-info" />
      </Link>
    </div>
  );
}

export default ContributeWorkflow;
