import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Container = styled.div`
  padding: 14px;
  /* border-left: 8px solid rgb(255, 152, 0); */
  border-left: 5px solid #ffc266;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff5e6;
  color: #663d00;

  .heading {
    color: #663d00;
  }
`;

type Props = {
  children: React.ReactNode;
  className?: string;
  helpIcon?: boolean;
};

function HelpBox({ children, className = '', helpIcon = true }: Props) {
  if (!helpIcon) {
    return <Container className={className}>{children}</Container>;
  }

  return (
    <Container className={className}>
      <Row>
        <Col
          md={2}
          xl={1}
          className="d-sm-none d-md-block col-md-2 col-xl-1 text-center my-auto"
        >
          <FontAwesomeIcon
            icon="question-circle"
            style={{ fontSize: '24pt', color: '#ffc266' }}
          />
        </Col>
        <Col sm={12} md={10} xl={11} className="my-auto">
          {children}
        </Col>
      </Row>
    </Container>
  );
}

export default HelpBox;
