import { useQuery } from '@apollo/client';

import { Model, RefetchQueries } from 'apollo/schema/types';
import { MODEL_IMAGE_LIST } from 'apollo/operations/modelImage';
import {
  ModelImageListData,
  ModelImageListVars,
} from 'apollo/schema/operations';
import UploadModelImage from 'components/modules/model/UploadModelImage';
import DeleteModelImageButton from 'components/modules/model/DeleteModelImageButton';
import { Card, Col, Row } from 'react-bootstrap';
import Spinner from 'components/common/Spinner';
import HelpBox from 'components/common/HelpBox';
import { Link } from 'react-router-dom';
import { modelEditGeoreferencesTabRoute } from 'routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/common/Tooltip';

type Props = {
  model: Model;
  locked: boolean;
};

function ModelImagesTab({ model, locked }: Props) {
  const modelId = model.id;

  const { data, loading } = useQuery<ModelImageListData, ModelImageListVars>(
    MODEL_IMAGE_LIST,
    { variables: { modelId } },
  );
  const refetchQueries: RefetchQueries = [
    { query: MODEL_IMAGE_LIST, variables: { modelId } },
  ];

  const MAX_IMAGES = 5;

  const images = data?.model.images ?? [];
  const hasImages = images.length > 0;
  const hasMaxImages = images.length >= MAX_IMAGES;

  if (loading) return <Spinner />;

  return (
    <>
      {!model.isPublished && (
        <Row className="justify-content-center mb-3">
          <Col md={10}>
            <HelpBox>
              Upload one or more screenshots of your model. These will be
              displayed as a slideshow on the model page.
            </HelpBox>
          </Col>
        </Row>
      )}

      <Row>
        <Col md={locked ? 12 : 8}>
          <Card>
            <Card.Body>
              {hasImages && (
                <div
                  className="float-right text-muted"
                  style={{ fontSize: '12pt' }}
                >
                  <span style={{ fontWeight: 'bold', color: '#555555' }}>
                    {images.length}
                  </span>{' '}
                  uploaded /{' '}
                  <span style={{ fontWeight: 'bold', color: '#555555' }}>
                    {MAX_IMAGES}
                  </span>{' '}
                  maximum
                </div>
              )}

              <h4 className="card-title">Model Images</h4>

              {!hasImages && (
                <div className="mt-3 text-muted">
                  <em>No images have been uploaded yet for this model.</em>
                </div>
              )}

              <div className="row">
                {images.map(image => (
                  <div key={image.file.id} className="col-sm-4">
                    <img
                      src={image.file.signedUrl}
                      alt={image.file.filename}
                      className="img-fluid"
                    />
                    <div className="text-center">
                      {image.file.filename}

                      {!locked && (
                        <DeleteModelImageButton
                          modelId={modelId}
                          fileId={image.file.id}
                          refetchQueries={refetchQueries}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>

        {!locked && (
          <Col md={4}>
            <Card>
              <Card.Body>
                <h4 className="card-title">Add Images</h4>
                <UploadModelImage
                  modelId={modelId}
                  refetchQueries={refetchQueries}
                  disabled={locked || hasMaxImages}
                />
                {hasMaxImages && (
                  <div className="mt-3 text-info">
                    A maximum of {MAX_IMAGES} images are allowed.
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>

      {!model.isPublished && (
        <div className="mt-3 text-right">
          <Tooltip overlay="Continue to georeference">
            <Link
              to={modelEditGeoreferencesTabRoute(modelId)}
              className="btn btn-primary"
            >
              Next
              <FontAwesomeIcon icon="chevron-right" className="ml-2" />
            </Link>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default ModelImagesTab;
