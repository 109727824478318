import React from 'react';
import { gql, useQuery } from '@apollo/client';

import * as fragments from 'apollo/fragments';
import { ModelShare, RefetchQueries } from 'apollo/schema/types';
import Spinner from 'components/common/Spinner';
import { useSortFilter } from 'hooks/data';
import SortTrigger from 'components/common/SortTrigger';
import { modelRoute } from 'routes';
import { Link } from 'react-router-dom';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import DeleteModelShare from 'components/modules/modelShare/DeleteModelShare';
import Confirm from 'components/common/Confirm';

const MODELS_SHARED_WITH_ME = gql`
  query ModelsSharedWithMe {
    modelsSharedWithMe {
      ...modelShareParts
      sourceUser {
        ...userParts
      }
      model {
        ...modelParts
      }
    }
  }

  ${fragments.modelShareParts}
  ${fragments.modelParts}
  ${fragments.userParts}
`;
type QueryData = {
  modelsSharedWithMe: ModelShare[];
};
type QueryVars = {};

const ModelsSharedWithMeTab: React.FC = () => {
  const { data, loading } = useQuery<QueryData, QueryVars>(
    MODELS_SHARED_WITH_ME,
  );

  const refetchQueries: RefetchQueries = [{ query: MODELS_SHARED_WITH_ME }];

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    data?.modelsSharedWithMe || [],
    'model.name',
    'model.name',
  );

  if (loading) return <Spinner />;

  return (
    <table className="table">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="model.name" sortIndicatorProps={siProps}>
              Model
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="sourceUser.name"
              sortIndicatorProps={siProps}
              filterable
            >
              Author
            </SortTrigger>
          </th>

          <th />
        </tr>
      </thead>

      <tbody>
        {!items.length && (
          <tr>
            <td colSpan={3} className="text-center text-muted">
              <i>No shared models</i>
            </td>
          </tr>
        )}

        {items.map(share => (
          <tr key={share.id}>
            <td className="align-middle">{share.model.name}</td>
            <td className="align-middle">{share.sourceUser.name}</td>
            <td className="text-right">
              <Dropdown as={ButtonGroup}>
                <Link
                  to={modelRoute(share.model.id)}
                  target="_blank"
                  className="btn btn-sm btn-secondary"
                >
                  View Model
                </Link>

                <Dropdown.Toggle split variant="secondary" size="sm" />

                <Dropdown.Menu alignRight>
                  <DeleteModelShare
                    shareId={share.id}
                    refetchQueries={refetchQueries}
                  >
                    {deleteShare => (
                      <Confirm
                        onConfirm={deleteShare}
                        text="You will no longer have access to this model unless the owner shares it with you again."
                      >
                        {confirmDelete => (
                          <Dropdown.Item onClick={confirmDelete}>
                            Remove Share
                          </Dropdown.Item>
                        )}
                      </Confirm>
                    )}
                  </DeleteModelShare>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ModelsSharedWithMeTab;
