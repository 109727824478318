import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { modelEditSharingTabRoute } from 'routes';
import { Model } from 'apollo/schema/types';
import HelpIcon from 'components/common/HelpIcon';
import Popover from 'components/common/Popover';
import CopyrightInfoOverlay, {
  creativeCommonsInfoURL,
} from 'components/common/CopyrightInfoOverlay';
import Tooltip from 'components/common/Tooltip';

type InfoSectionProps = {
  label: React.ReactNode;
  value?: React.ReactNode | string | number | null;
};

const InfoSection: React.FC<InfoSectionProps> = ({
  label,
  value,
}: InfoSectionProps) => {
  if (!value) return null;

  return (
    <Row className="mb-2">
      <Col sm={5}>
        <strong>{label}</strong>
      </Col>
      <Col sm={7}>{value}</Col>
    </Row>
  );
};

type EditSharesLinkProps = {
  modelId: number;
  children: React.ReactNode;
};

const EditSharesLink = ({ modelId, children }: EditSharesLinkProps) => (
  <Tooltip overlay="Click to manage model sharing">
    <Link to={modelEditSharingTabRoute(modelId)}>{children}</Link>
  </Tooltip>
);

type Props = {
  model: Model;
  isOwnerOrAdmin?: boolean;
};

function ModelInfo({ model, isOwnerOrAdmin = false }: Props) {
  const hasPublicLink = model.publicLinks.length > 0;
  const numPublicLinks = model.publicLinks.length;

  const isShared = model.shares.length > 0;
  const numShares = model.shares.length;

  return (
    <Card>
      <Card.Body>
        <Card.Title>Model Info</Card.Title>
        <InfoSection label="Author" value={model.author} />
        <InfoSection label="Funding Source" value={model.fundingSource} />
        <InfoSection label="Source" value={model.source} />
        {model.isPrivate && <InfoSection label="License" value="Private" />}
        {!model.isPrivate && model.copyrightLicense && (
          <InfoSection
            label="License"
            value={
              <span style={{ cursor: 'default' }}>
                <a
                  href={creativeCommonsInfoURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {model.copyrightLicense}
                </a>
                <Popover trigger="click" content={<CopyrightInfoOverlay />}>
                  <HelpIcon
                    className="ml-2 text-info"
                    style={{ fontSize: '11pt' }}
                  />
                </Popover>
              </span>
            }
          />
        )}
        {isOwnerOrAdmin && model.isPrivate && (
          <InfoSection
            label="Shared"
            value={
              <>
                {!isShared && !hasPublicLink && (
                  <EditSharesLink modelId={model.id}>Not shared</EditSharesLink>
                )}
                {isShared && (
                  <div>
                    <EditSharesLink modelId={model.id}>
                      with {numShares} users
                    </EditSharesLink>
                  </div>
                )}
                {hasPublicLink && (
                  <div>
                    <EditSharesLink modelId={model.id}>
                      with {numPublicLinks} link
                      {numPublicLinks !== 1 ? 's' : ''}
                    </EditSharesLink>
                  </div>
                )}
              </>
            }
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default ModelInfo;
