import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { adminNewsRoute } from 'routes';
import { RefetchQueries } from 'apollo/schema/types';
import { DeleteNewsData, DeleteNewsVars } from 'apollo/schema/operations';
import { DELETE_NEWS } from 'apollo/operations/news';
import Confirm from 'components/common/Confirm';

type Props = {
  newsId: number;
  refetchQueries: RefetchQueries;
};

const DeleteNewsButton: React.FC<Props> = ({
  newsId,
  refetchQueries,
}: Props) => {
  const history = useHistory();
  const [deleteNews, { loading }] = useMutation<DeleteNewsData, DeleteNewsVars>(
    DELETE_NEWS,
    { variables: { id: newsId }, refetchQueries },
  );

  async function handleDelete() {
    try {
      await deleteNews();
      toast.success('News deleted.');
      history.replace(adminNewsRoute());
    } catch (err) {
      console.log('Error deleting news', err);
      toast.error(
        'There was a problem deleting the news. It may have already been deleted.',
      );
    }
  }

  return (
    <Confirm
      onConfirm={handleDelete}
      text="The news article will be permanently deleted."
    >
      {confirmDelete => (
        <button
          type="button"
          className="btn btn-link text-danger"
          onClick={confirmDelete}
          disabled={loading}
        >
          Delete News Article
        </button>
      )}
    </Confirm>
  );
};

export default DeleteNewsButton;
