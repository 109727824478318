import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { Field, Form, Formik, FormikHelpers } from 'formik';

import { NOTIFICATIONS_QUERY } from 'components/modules/notifications/Notifications';
import * as fragments from 'apollo/fragments';
import { useModalState } from 'hooks/modal';
import {
  ApproveModelReviewData,
  ApproveModelReviewVars,
} from 'apollo/schema/operations';
import { setValidationErrors } from 'utils/forms';
import FormikField from 'components/common/FormikField';
import FormikErrors from 'components/common/FormikErrors';

const APPROVE_MODEL_REVIEW = gql`
  mutation ApproveModelReview(
    $modelReviewId: Int!
    $messageToSubmitter: String
  ) {
    approveModelReview(
      modelReviewId: $modelReviewId
      messageToSubmitter: $messageToSubmitter
    ) {
      ...modelReviewParts
      model {
        ...modelParts
      }
    }
  }
  ${fragments.modelReviewParts}
  ${fragments.modelParts}
`;

type FormValues = {
  messageToSubmitter: string;
};

type Props = {
  children: (handleClick: () => void) => React.ReactNode;
  modelReviewId: number;
};

function ApproveModelReview({ children, modelReviewId }: Props) {
  const { show, showModal, hideModal } = useModalState();
  const [approveReview, { loading, error }] = useMutation<
    ApproveModelReviewData,
    ApproveModelReviewVars
  >(APPROVE_MODEL_REVIEW, { refetchQueries: [{ query: NOTIFICATIONS_QUERY }] });

  async function handleApprove(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    const confirmMsg = 'Are you sure you want to approve this model?';
    if (!window.confirm(confirmMsg)) {
      return;
    }

    const variables: ApproveModelReviewVars = { modelReviewId };

    if (
      values.messageToSubmitter &&
      values.messageToSubmitter.trim().length > 0
    ) {
      variables.messageToSubmitter = values.messageToSubmitter.trim();
    }

    try {
      await approveReview({ variables });
      toast.success(
        'The model was approved successfully. It is now published.',
      );
    } catch (err) {
      console.log('Error approving model review', err);
      toast.error('There was a problem approving the model.');
      setValidationErrors(err, helpers);
    }
  }

  const handleCancel = (message: string) => (e: React.MouseEvent) => {
    e.preventDefault();

    const confirmText =
      'Are you sure you would like to cancel? You will lose any data entered into this form.';
    if (message.length > 0 && !window.confirm(confirmText)) {
      return;
    }

    hideModal();
  };

  return (
    <>
      {children(showModal)}

      <Modal show={show} onHide={hideModal} backdrop="static" keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Approve Model</Modal.Title>
        </Modal.Header>

        <Formik
          onSubmit={handleApprove}
          initialValues={{ messageToSubmitter: '' }}
        >
          {({ values, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <p>
                  The model will be <b>approved</b> and <b>published</b>.
                </p>

                <Field
                  name="messageToSubmitter"
                  label="Optional message to submitter"
                  component={FormikField}
                  type="textarea"
                  style={{ height: '200px' }}
                />

                <FormikErrors graphQLError={error} />
              </Modal.Body>

              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancel(values.messageToSubmitter)}
                  disabled={loading}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={loading}
                >
                  Approve &amp; Publish
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default ApproveModelReview;
