import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import { modelRoute } from 'routes';
import { CLIENT_URL } from 'config/constants';
import { copyToClipboard } from 'utils/clipboard';

type Props = {
  modelId: number;
};

function CopyModelLink({ modelId }: Props) {
  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    const fullModelUrl = `${CLIENT_URL}${modelRoute(modelId)}`;

    const target = e.target as HTMLButtonElement;
    copyToClipboard(fullModelUrl);
    target.focus();

    toast.info('Model URL copied to clipboard.');
  }

  return (
    <button
      type="button"
      className="btn btn-sm btn-primary btn-block"
      onClick={handleClick}
    >
      <FontAwesomeIcon icon="copy" className="mr-2" />
      Copy Link
    </button>
  );
}

export default CopyModelLink;
