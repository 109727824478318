import { gql, useMutation } from '@apollo/client';
import { useReducer, useState } from 'react';
import invariant from 'tiny-invariant';

import {
  faCheckCircle,
  faExclamationTriangle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ModelUserFileCompleteUploadData,
  ModelUserFileCompleteUploadVariables,
  ModelUserFileInitializeUploadData,
  ModelUserFileInitializeUploadVariables,
  ModelUserFileUploadPartData,
  ModelUserFileUploadPartVariables,
} from 'apollo/schema/operations';
import Dropzone from 'components/common/Dropzone';
import HelpBox from 'components/common/HelpBox';
import Tooltip from 'components/common/Tooltip';
import PageLoader from 'components/modules/page/PageLoader';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { zipMimes } from 'utils/validation';

const INITIALIZE_UPLOAD = gql`
  mutation MUFInitializeUpload($modelId: Int!, $file: FileInput!) {
    modelUserFileInitializeUpload(modelId: $modelId, file: $file) {
      id
    }
  }
`;

const UPLOAD_PART = gql`
  mutation MUFUploadPart(
    $modelUserFileId: Int!
    $partNumber: Int!
    $filePart: Upload!
    $size: Int!
  ) {
    modelUserFileUploadPart(
      modelUserFileId: $modelUserFileId
      partNumber: $partNumber
      filePart: $filePart
      size: $size
    )
  }
`;

const COMPLETE_UPLOAD = gql`
  mutation MUFCompleteUpload(
    $modelUserFileId: Int!
    $parts: [MultipartUploadResultPart!]!
  ) {
    modelUserFileCompleteUpload(
      modelUserFileId: $modelUserFileId
      parts: $parts
    ) {
      id
    }
  }
`;

type FilePart = {
  partNumber: number;
  eTag: string | null;
  start: number;
  stop: number;
  status: 'pending' | 'uploading' | 'success' | 'error';
};

type State = {
  uploadState: 'pending' | 'uploading' | 'success' | 'error';
  parts: FilePart[];
};

type Action =
  | { type: 'INITIALIZE'; payload: { parts: FilePart[] } }
  | { type: 'START_UPLOAD'; payload: { partNumber: number } }
  | { type: 'UPLOAD_SUCCESS'; payload: { partNumber: number; eTag: string } }
  | { type: 'UPLOAD_ERROR'; payload: { partNumber: number } }
  | { type: 'QUEUE_SUCCESS' }
  | { type: 'QUEUE_ERROR' };

const initialState: State = {
  uploadState: 'pending',
  parts: [],
};

function reducer(state: State, action: Action): State {
  console.log('Action:', action);

  function updatePartStatus(partNumber: number, status: FilePart['status']) {
    return state.parts.map(p => {
      if (p.partNumber !== partNumber) return p;
      return { ...p, status: status };
    });
  }

  if (action.type === 'INITIALIZE') {
    return {
      uploadState: 'pending',
      parts: action.payload.parts,
    };
  } else if (action.type === 'START_UPLOAD') {
    return {
      ...state,
      uploadState: 'uploading',
      parts: updatePartStatus(action.payload.partNumber, 'uploading'),
    };
  } else if (action.type === 'UPLOAD_SUCCESS') {
    return {
      ...state,
      parts: updatePartStatus(action.payload.partNumber, 'success'),
    };
  } else if (action.type === 'UPLOAD_ERROR') {
    return {
      ...state,
      parts: updatePartStatus(action.payload.partNumber, 'error'),
    };
  } else if (action.type === 'QUEUE_SUCCESS') {
    return { ...state, uploadState: 'success' };
  } else if (action.type === 'QUEUE_ERROR') {
    return { ...state, uploadState: 'error' };
  }

  return state;
}

type Props = {
  modelId: number;
};

export function UploadMUFMultipart({ modelId }: Props) {
  const [isGuidelinesVisible, setIsGuidelinesVisible] = useState(false);
  const [file, setFile] = useState<File>();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [initializeUpload, { loading: loadingInitialize }] = useMutation<
    ModelUserFileInitializeUploadData,
    ModelUserFileInitializeUploadVariables
  >(INITIALIZE_UPLOAD);
  const [uploadPart, { loading: loadingUploadPart }] = useMutation<
    ModelUserFileUploadPartData,
    ModelUserFileUploadPartVariables
  >(UPLOAD_PART);
  const [completeUpload, { loading: loadingComplete }] = useMutation<
    ModelUserFileCompleteUploadData,
    ModelUserFileCompleteUploadVariables
  >(COMPLETE_UPLOAD);

  const toggleGuidelines = () => setIsGuidelinesVisible(prev => !prev);

  function handleDrop(files: File[]) {
    if (state.uploadState !== 'pending') {
      return void console.log('Upload in progress, ignoring file drop');
    }
    if (!files || !files.length) {
      return void console.log('No file selected');
    }

    console.log('File selected:', files[0]);
    setFile(files[0]);
  }

  async function runPartQueue(mufId: number, parts: FilePart[]) {
    invariant(file, "Can't run queue without a file");

    // Need to duplicate the state here because reducer actions fire async.
    // We'll return this so the completion action can be called imperatively.
    let results: FilePart[] = [];

    let filePart: Blob;
    for (const part of parts) {
      filePart = file.slice(part.start, part.stop);

      try {
        dispatch({
          type: 'START_UPLOAD',
          payload: { partNumber: part.partNumber },
        });
        console.log('Starting upload for part:', part);

        const result = await uploadPart({
          variables: {
            modelUserFileId: mufId,
            partNumber: part.partNumber,
            filePart,
            size: filePart.size,
          },
        });
        invariant(result.data);

        const eTag = result.data.modelUserFileUploadPart;
        dispatch({
          type: 'UPLOAD_SUCCESS',
          payload: { partNumber: part.partNumber, eTag },
        });
        results.push({ ...part, eTag, status: 'success' });
      } catch (e) {
        console.log('Failed upload for chunk', part, e);
        dispatch({
          type: 'UPLOAD_ERROR',
          payload: { partNumber: part.partNumber },
        });
        results.push({ ...part, status: 'error' });
      }
    }

    return results;
  }

  /** @returns The created ModelUserFile stub ID */
  async function handleInitialize(file: File): Promise<number | null> {
    try {
      const result = await initializeUpload({
        variables: {
          modelId,
          file: { filename: file.name, mimetype: file.type },
        },
      });
      invariant(result.data);
      return result.data?.modelUserFileInitializeUpload.id;
    } catch (error) {
      console.log('Error initializing file:', error);
      return null;
    }
  }

  async function handleSubmit() {
    if (!file) {
      toast.info('Please select a file to upload');
      console.log('No file selected');
      return;
    }

    const mufId = await handleInitialize(file);
    if (!mufId) {
      const errorMessage =
        'There was a problem starting the file upload. Please try again.';
      toast.error(errorMessage);
      return;
    }

    const partSize = 10_000_000; // 10 MB (5 MiB minimum per chunk, last chunk can be any size)
    const totalParts = Math.floor(file.size / partSize) + 1;

    const parts = new Array(totalParts)
      .fill(undefined)
      .map<FilePart>((_, i) => {
        const partNumber = i + 1;
        const start = i * partSize;
        const stop = partNumber * partSize;

        return {
          partNumber,
          start,
          stop,
          status: 'pending',
          eTag: null,
        };
      });

    dispatch({ type: 'INITIALIZE', payload: { parts } });
    const results = await runPartQueue(mufId, parts);
    await handleQueueCompleted(mufId, results);
  }

  async function handleQueueCompleted(
    modelUserFileId: number,
    partResults: FilePart[],
  ) {
    const numTotal = partResults.length;
    const numSuccess = partResults.filter(p => p.status === 'success').length;
    const numErrors = partResults.filter(p => p.status === 'error').length;

    console.group('Upload queue completed');
    console.log('Total attempted:', numTotal);
    console.log('Successful:', numSuccess);
    console.log('Errors:', numErrors);
    console.groupEnd();

    if (numTotal === numSuccess && !numErrors) {
      console.log('Completing upload for MUF', modelUserFileId);

      // The reduce is kind of unnecessary but it asserts all parts have an ETag
      const parts = partResults.reduce<{ partNumber: number; eTag: string }[]>(
        (acc, cur) => {
          if (!cur.eTag) {
            throw new Error('Upload successful but missing an ETag');
          }
          acc.push({ partNumber: cur.partNumber, eTag: cur.eTag });
          return acc;
        },
        [],
      );
      try {
        await completeUpload({ variables: { modelUserFileId, parts } });
        toast.success('Upload completed successfully.');
        dispatch({ type: 'QUEUE_SUCCESS' });
      } catch (err) {
        console.log('Error completing upload', err);
        toast.error(
          'There was a problem completing the upload, please try again.',
        );
        dispatch({ type: 'QUEUE_ERROR' });
      }
    }
  }

  const loadingAny = loadingInitialize || loadingUploadPart || loadingComplete;
  const disableSubmit =
    loadingAny ||
    (state.uploadState !== 'pending' && state.uploadState !== 'error');

  return (
    <Card>
      <Card.Body>
        <h3>Upload 3D Model Files</h3>

        {(state.uploadState === 'pending' || state.uploadState === 'error') && (
          <>
            <Dropzone
              onDropAccepted={handleDrop}
              accept={zipMimes}
              onDropRejected={() =>
                toast.error('Please upload files in .zip format')
              }
            >
              <div className="text-center">
                Drag and drop files here, or click to select files.
                <br />
                (only .zip files are accepted)
              </div>
            </Dropzone>
            <div className="mb-3" />
          </>
        )}

        <p>
          V3Geo supports 3D models in <b>OBJ format</b>. Please upload your
          files in archives like <b>ZIP</b>. Here are{' '}
          <Tooltip overlay="Click to toggle guidelines">
            <a href="#toggle-guidelines" onClick={toggleGuidelines}>
              our guidelines
            </a>
          </Tooltip>{' '}
          for help on model creation and upload.
        </p>

        {isGuidelinesVisible && (
          <>
            <PageLoader slug="guidelines" showTitle={false} />
            <div className="text-center">
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                onClick={toggleGuidelines}
              >
                <FontAwesomeIcon icon="compress-alt" /> Collapse Guidelines
              </button>
            </div>
          </>
        )}

        {file && (
          <div className="text-center my-2">
            <strong>{file.name}</strong>
          </div>
        )}

        {state.uploadState === 'uploading' && (
          <HelpBox>
            Your files are currently uploading. Be sure to keep this window open
            or your upload will be canceled.
          </HelpBox>
        )}

        <UploadProgress uploadState={state.uploadState} parts={state.parts} />
      </Card.Body>

      <Card.Footer className="text-right">
        <button
          type="button"
          onClick={handleSubmit}
          className="btn btn-warning"
          disabled={disableSubmit}
        >
          {state.uploadState === 'uploading' && (
            <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
          )}
          Upload File
        </button>
      </Card.Footer>
    </Card>
  );
}

function backgroundColor(status: FilePart['status']) {
  switch (status) {
    case 'pending':
      return '#e0e0eb';
    case 'uploading':
      return '#d0d0e1';
    case 'success':
      return '#80ffdf';
    case 'error':
      return '#ff80aa';
  }
}

type UploadProgressProps = {
  uploadState: State['uploadState'];
  parts: FilePart[];
};

function UploadProgress({ uploadState, parts }: UploadProgressProps) {
  if (uploadState === 'pending') {
    return null;
  } else if (uploadState === 'success') {
    return (
      <div className="text-center">
        <h4 className="text-success">
          <FontAwesomeIcon icon={faCheckCircle} /> Upload Successful
        </h4>
        <p>The upload was successful. You may now close this window.</p>
      </div>
    );
  } else if (uploadState === 'error') {
    return (
      <div className="text-center text-error">
        <h4 className="text-danger">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Upload Error
        </h4>
        <p>There was a problem uploading that file. Please try again.</p>
      </div>
    );
  }

  return (
    <div className="py-4">
      <div
        style={{
          height: '30px',
          width: '100%',
          display: 'flex',
          backgroundColor: '#eeeeee',
        }}
      >
        {parts.map(part => (
          <div
            key={part.partNumber}
            style={{
              flexGrow: 1,
              backgroundColor: backgroundColor(part.status),
              transition: 'background-color 500ms linear',
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default UploadMUFMultipart;
