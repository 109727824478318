import React, { useEffect, useRef } from 'react';
import * as R from 'ramda';
import { useLocation } from 'react-router-dom';

type ChildFnProps = {
  id: string;
  ref: React.Ref<any>;
};

type Props = {
  id: string;
  children: (props: ChildFnProps) => JSX.Element;
};

function HashTarget({ id, children }: Props) {
  const { hash } = useLocation();
  const targetRef = useRef<any>();

  useEffect(() => {
    const [, currentHash] = R.split('#', hash);
    if (targetRef && targetRef.current && id === currentHash) {
      targetRef.current.scrollIntoView();
    }
  }, [id, hash]);

  return children({ id, ref: targetRef });
}

export default HashTarget;
