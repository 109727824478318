import moment from 'moment-timezone';
import Tooltip from '../Tooltip';

type Props = {
  date: string;
  format?: string;
  timezone?: string;
  tooltip?: boolean;
  render?: (datetime: string) => React.ReactNode;
};

const DateTime: React.FC<Props> = ({
  date,
  format = 'LLL z',
  timezone = 'utc',
  tooltip = false,
  render,
}: Props) => {
  const formatted = moment(date)
    .tz(timezone)
    .format(format);

  if (render) render(formatted);

  if (tooltip) {
    return (
      <Tooltip
        overlay={moment(date)
          .tz(timezone)
          .format('LLL z')}
      >
        <span>{formatted}</span>
      </Tooltip>
    );
  }

  return <>{formatted}</>;
};

export default DateTime;
