import React, { HTMLProps } from 'react';
import classnames from 'classnames';
import { ErrorMessage, FieldProps } from 'formik';

type Props = FieldProps &
  HTMLProps<HTMLInputElement> & {
    label?: React.ReactNode;
    className?: string;
  };

const FormikCheckboxField: React.FC<Props> = ({
  field,
  className,
  label,
  ...props
}: Props) => {
  return (
    <div className={classnames('checkbox', className)}>
      <label
        style={{
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        }}
      >
        <input {...field} {...props} type="checkbox" /> {label}
      </label>
      <div className="text-danger">
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
};

export default FormikCheckboxField;
